import React, { ImgHTMLAttributes } from "react";
import { Link, useParams } from "react-router-dom";
import Flexbox from "../layout/flexbox";
import {
  isoAlpha2toCountryName,
  matchSovrnToName,
  countryFlagWhiteList,
  canShowFlag,
} from "../utils/countryCode";
import { CheveronRight } from "@styled-icons/heroicons-outline";
import styled, { css } from "../designSystem";
import { TextOne, TextTwo, TextFour } from "../designSystem/typography";
import { observer } from "mobx-react-lite";
import moment from "moment";

type Size = "s" | "l";
type BreadcrumbProps = {
  countryName?: string;
  title?: string;
  isHeaderCollapsed?: boolean;
  useHambuger?: boolean;
  createdTime?: number;
};

const TextPrimary = ({ theme }) =>
  css`
    color: ${theme.textPrimary};
  `;

export const StyledDateStamp = styled.div`
  ${TextFour}
`;

const StyledChevronRight = styled(CheveronRight)<{}>``;

const StyledBreadCrumb = styled(Flexbox)<{
  isHeaderCollapsed?: boolean;
}>`
  // base for all
  // shoudl be global?
  color: ${({ theme }) => theme.textPrimary};

  span {
    ${TextOne}
    ${({ isHeaderCollapsed }) => isHeaderCollapsed && TextTwo};
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

type CountryFlagProps = ImgHTMLAttributes<HTMLImageElement> & { code: string };
const CountryFlagBase = ({ code, ...props }: CountryFlagProps) => {
  if (!code) {
    return null;
  }
  return (
    <img
      src={`/countryFlags/${code.toLowerCase()}.png`}
      alt={`flag ${code}`}
      width={30}
      height={20}
      {...props}
    />
  );
};

export const CountryFlag = observer(CountryFlagBase);

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  title,
  isHeaderCollapsed,
  useHambuger,
  createdTime,
}) => {
  const { id } = useParams();
  const upperCaseId = id?.toUpperCase();
  // const region = getRegionName(upperCaseId as RegionCode);

  const countryNameString = id && isoAlpha2toCountryName(upperCaseId);
  const iconSize = useHambuger ? "10" : isHeaderCollapsed ? "16" : "20";
  const showFlag = canShowFlag(upperCaseId);
  const createdTimeFormatted =
    createdTime &&
    moment.tz(createdTime, "Europe/Paris").format("YYYY/M/D, h:mma z");

  return (
    <Flexbox vertical left>
      <StyledBreadCrumb gap="ml" middle isHeaderCollapsed={isHeaderCollapsed}>
        <span>
          <Link to="/">{title ? title : "Global"}</Link>
        </span>

        {/* region not currently supported
      {region && (
        <>
          <StyledChevronRight size={iconSize} />
          <span>{region}</span>
        </>
      )} */}

        {id && countryNameString && (
          <>
            <StyledChevronRight size={iconSize} />
            {showFlag && <CountryFlag code={id} />}
          </>
        )}
        <span>{countryNameString}</span>
      </StyledBreadCrumb>
      {createdTimeFormatted && (
        <StyledDateStamp>
          Data last updated: {createdTimeFormatted}
          {/*
          Format from design
          29/03/2020 18:00 CET
          */}
        </StyledDateStamp>
      )}
    </Flexbox>
  );
};

Breadcrumb.displayName = "Breadcrumb";
export default observer(Breadcrumb);
