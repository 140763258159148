import React from "react";
import { observer } from "mobx-react-lite";
import Dropdown, { DropDownProps } from "../components/dropdown";
import styled from "../designSystem";
import Flexbox from "../layout/flexbox";
import { SortAscending } from "@styled-icons/heroicons-solid/SortAscending";
import { SortDescending } from "@styled-icons/heroicons-solid/SortDescending";
import theme from "../designSystem/theme";

export type Sort = "ASC" | "DESC";

const StyledSortIcon = styled(Flexbox)`
  padding: 0 0 0 14px;
  path {
    fill: ${theme.hardBorder};
  }
  border: 1px solid hsl(0, 0%, 80%);
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const StyledSortableDropdown = styled(Flexbox)`
  .dropdown__control,
  .dropdown__control:hover,
  .dropdown__control.dropdown__control--is-focused {
    border: 1px solid hsl(0, 0%, 80%);
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    box-shadow: none;
  }
`;
type SortableDropdownProps = DropDownProps & {
  sort: Sort;
  onSort: (sort: Sort) => void;
};
const SortableDropdown: React.FC<SortableDropdownProps> = (props) => {
  const iconSize = 20;
  const { sort, onSort } = props;
  return (
    <StyledSortableDropdown>
      <StyledSortIcon middle>
        {sort === "ASC" && (
          <SortAscending size={iconSize} onClick={() => onSort("DESC")} />
        )}
        {sort === "DESC" && (
          <SortDescending size={iconSize} onClick={() => onSort("ASC")} />
        )}
      </StyledSortIcon>
      <Dropdown {...props} />
    </StyledSortableDropdown>
  );
};

export default observer(SortableDropdown);
