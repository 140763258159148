// this is needed as a polyfill for resizing
import useResizeObserver from "use-resize-observer/polyfilled";
import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule, ThemeConsumer } from "styled-components";
import { Theme } from "./theme";

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
  withTheme,
  ThemeContext,
  createGlobalStyle,
} = styledComponents as ThemedStyledComponentsModule<Theme>;

export default styled;
export {
  css,
  keyframes,
  ThemeProvider,
  ThemeConsumer,
  withTheme,
  ThemeContext,
  createGlobalStyle,
};
