import React, { useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import styled from "../designSystem";
import { useScrollPosition } from "../hooks/useScrollPosition";
import { ReactComponent as Globe } from "../assets/images/globe.svg";
import Flexbox from "../layout/flexbox";
import theme, { shadow } from "../designSystem/theme";

const StyledScrollToTop = styled(Flexbox)`
  fill: ${theme.hardBorder};
  z-index: 1000;
  cursor: pointer;
`;

const ScrollToTopButton: React.FC = ({}) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const handleScrollPosition = useCallback(
    ({ prevPos, currPos }) => {
      const isScrolledDown = currPos.y < -145;
      if (isScrolledDown !== showScrollToTop) {
        setShowScrollToTop(isScrolledDown);
      }
    },
    [setShowScrollToTop, showScrollToTop]
  );
  useScrollPosition(handleScrollPosition, [
    showScrollToTop,
    setShowScrollToTop,
  ]);

  const setScroll = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showScrollToTop && (
        <StyledScrollToTop noGrow center middle onClick={setScroll} gap="ml">
          <span>Back to top</span>
          <Globe />
        </StyledScrollToTop>
      )}
    </>
  );
};

ScrollToTopButton.displayName = "ScrollToTopButton";
export default observer(ScrollToTopButton);
