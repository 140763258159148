import populationSource from "./unStats/2020-population-totals.json";
import densitySource from "./unStats/2020-population-density.json";
import medianAgeSource from "./unStats/2020-median-age.json";
import hospitalBedsSource from "./worldBankStats/hospital-beds.json";
import { countryToIso3, isoAlpha3toIsoAlpha2 } from "../utils/countryCode";

const populationsIndexed: { [country: string]: number } = {};
populationSource.forEach((row) => {
  const iso3 = countryToIso3(row.Country);
  if (!iso3) {
    // console.error(`No country code found for ${row.Country}`);
    return;
  }
  const iso2 = isoAlpha3toIsoAlpha2(iso3);
  populationsIndexed[iso3] = (populationsIndexed[iso3] || 0) + row.Population;
  populationsIndexed[iso2] = (populationsIndexed[iso2] || 0) + row.Population;
});

/**
 * Returns country population in thousands
 */
const countryPopulation = (countryIso2or3: string) => {
  if (!populationsIndexed[countryIso2or3]) {
    // console.error(`Country '${countryIso2or3}' population not available.`);
    return 0;
  }
  return populationsIndexed[countryIso2or3];
};

export { countryPopulation };

const densitiesIndexed: { [country: string]: number } = {};
densitySource.forEach((row) => {
  const iso3 = countryToIso3(row.Country);
  if (!iso3) {
    // console.error(`No country code found for ${row.Country}`);
    return;
  }
  const iso2 = isoAlpha3toIsoAlpha2(iso3);
  densitiesIndexed[iso3] = row["Population Density"];
  densitiesIndexed[iso2] = row["Population Density"];
});

const countryPopulationDensity = (countryIso2or3: string) => {
  if (!densitiesIndexed[countryIso2or3]) {
    // console.error(
    //   `Country '${countryIso2or3}' population density not available.`
    // );
    return 0;
  }
  return densitiesIndexed[countryIso2or3];
};

export { countryPopulationDensity };

const medianAgesIndexed: { [country: string]: number } = {};
medianAgeSource.forEach((row) => {
  const iso3 = countryToIso3(row.Country);
  if (!iso3) {
    // console.error(`No country code found for ${row.Country}`);
    return;
  }
  const iso2 = isoAlpha3toIsoAlpha2(iso3);
  medianAgesIndexed[iso3] = row["Median Age"];
  medianAgesIndexed[iso2] = row["Median Age"];
});

const countryMedianAge = (countryIso2or3: string) => {
  if (!medianAgesIndexed[countryIso2or3]) {
    // console.error(`Country '${countryIso2or3}' median age not available.`);
    return 0;
  }
  return medianAgesIndexed[countryIso2or3];
};

export { countryMedianAge };

const hospitalBedsIndexed: { [country: string]: number } = {};
hospitalBedsSource.forEach((row) => {
  const iso3 = countryToIso3(row.Country);
  if (!iso3) {
    // console.error(`No country code found for ${row.Country}`);
    return;
  }
  const iso2 = isoAlpha3toIsoAlpha2(iso3);
  hospitalBedsIndexed[iso3] = row["Hospital Beds"];
  hospitalBedsIndexed[iso2] = row["Hospital Beds"];
});

const countryHospitalBeds = (countryIso2or3: string) => {
  if (!hospitalBedsIndexed[countryIso2or3]) {
    // console.error(`Country '${countryIso2or3}' hospital beds not available.`);
    return 0;
  }
  return hospitalBedsIndexed[countryIso2or3];
};

export { countryHospitalBeds };
