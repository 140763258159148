import React from "react";
import { observer } from "mobx-react-lite";
import styled, { css } from "../designSystem";
import Flexbox from "../layout/flexbox";
import { getMetricColor, getRegionColor } from "../designSystem/theme";
import { Region } from "../utils/regions";
import Metric from "./metric";
import { DecimalFormat } from "../utils/numberUtils";

export type TooltipRow = {
  label: string;
  metric: {
    name: string;
    value: number;
    isPercentage?: boolean;
    format?: DecimalFormat;
  };
};
type TooltipInnerProps = {
  title?: string;
  rows: TooltipRow[];
  size?: "s" | "m";
};

const StyledMetricLabel = styled(Flexbox)<{ size: "s" | "m" }>`
  ${({ size }) =>
    size &&
    css`
      font-size: ${size === "m" ? 15 : 12}px;
      }
  `}
  text-transform: capitalize;
  white-space: initial;
`;

const Title = styled(Flexbox)`
  font-size: 15px;
  font-weight: bold;
`;

const MetricWrap = styled(Flexbox)`
  flex: 0 0 30%;
`;

const FlexRow = styled(Flexbox)`
  width: 100%;
`;

const StyledMetric = styled.span<{ metric?: string; region?: string }>`
  ${({ theme, metric, region }) =>
    metric &&
    css`
      > div > span {
        color: ${getMetricColor(metric, theme)};
        color: ${getRegionColor(metric as Region, theme)};
        color: ${getRegionColor(region as Region, theme)};
        font-size: 15px;
      }
    `}
`;

const TooltipInner: React.FC<TooltipInnerProps> = ({
  title,
  rows,
  size = "m",
}) => {
  return (
    <Flexbox vertical middle gap="m" style={{ minWidth: "250px" }}>
      {title && <Title>{title}</Title>}

      <Flexbox vertical gap="s" style={{ width: "100%" }}>
        {rows.map(
          ({ metric: { value, name, isPercentage, format }, label }, index) => (
            <FlexRow gap="s" top key={name + index}>
              <MetricWrap right>
                <StyledMetric metric={name}>
                  <Metric
                    value={value}
                    isPercentage={isPercentage}
                    format={isPercentage ? "1.23k" : format}
                  />
                </StyledMetric>
              </MetricWrap>
              <StyledMetricLabel size={size}>{label}</StyledMetricLabel>
            </FlexRow>
          )
        )}
      </Flexbox>
    </Flexbox>
  );
};

TooltipInner.displayName = "TooltipInner";
export default observer(TooltipInner);

export const getTooltipValues = (
  country,
  primaryFilter,
  selectedTimeRangeFilter?
) => {
  const isPerMillion = selectedTimeRangeFilter?.value === "Per Million";

  const tipCases = {
    title: isPerMillion
      ? `Confirmed Cases ${selectedTimeRangeFilter.metric}`
      : "Confirmed Cases",
    // title: "Confirmed Cases",
    value: country?.Confirmed,
    isPercentage: false,
  };

  const tipDeaths = {
    title: isPerMillion ? `Deaths ${selectedTimeRangeFilter.metric}` : "Deaths",
    // title: "Deaths",
    value: country?.Deaths,
    isPercentage: false,
  };

  const tooltipValues: Array<{
    title: string;
    value: number;
    isPercentage: boolean;
    format?: DecimalFormat;
  }> = [
    ["Cumulative Confirmed", "Confirmed"].includes(primaryFilter)
      ? tipCases
      : tipDeaths,
    // country.change !== undefined && {
    //   title: "Daily Change",
    //   value: (country.change || 0) * 100,
    //   isPercentage: true,
    //   format: "1.2k",
    // },
    ["Cumulative Deaths", "Deaths"].includes(primaryFilter)
      ? tipCases
      : tipDeaths,
  ];

  return tooltipValues;
};

export const getTooltipRows = (
  country,
  primaryFilter,
  selectedTimeRangeFilter
) => {
  const tooltipValues = getTooltipValues(
    country,
    primaryFilter,
    selectedTimeRangeFilter
  );

  const tooltipRows = tooltipValues
    .filter((value) => !!value)
    .map((val) => ({
      label: val.title,
      metric: {
        name: val.title.toLowerCase(),
        value: val.value,
        isPercentage: val.isPercentage,
        format: val.format,
      },
    }));
  return tooltipRows;
};
