import { Region } from "../utils/regions";

export type Theme = {
  positive: string;
  negative: string;
  neutral: string;
  background: string;
  chartBackground: string;
  textPrimary: string;
  textSecondary: string;
  textReverse: string;
  primary: string;
  primaryLight: string;
  primaryDark: string;
  secondary: string;
  chartPrimary: string;
  chartSecondary: string;
  mapLegendBorder: string;
  mapBackground: string;
  icon: string;
  softBorder: string;
  hardBorder: string;
  metric1: string;
  metric2: string;
  metric3: string;
  metric4: string;
  metric5: string;
  region1: string;
  region2: string;
  region3: string;
  region4: string;
  region5: string;
  region6: string;
  magicNumbers: {
    mobileNavHeight: string;
    navHeight: string;
    storyModeHeight: string;
  };
  mapDots: {
    zeroData: string;
  };
  metrics: {
    Confirmed: string;
    ConfirmedDark: string;
    ConfirmedLight: string;
    Deaths: string;
    DeathsDark: string;
    None: string;
    NA: string;
  };
  choropleth: {
    ConfirmedZero: string;
    ConfirmedColorStops: string[];
    DeathsZero: string;
    DeathsColorStops: string[];
    mapStyles: {
      selectedBorder: string;
      selectedWeight: number;
      generalBorder: string;
      generalWeight: number;
      notApplicable: string;
    };
  };
  notApplicable: string;
};
const primary = "#0093d5";
const primaryLight = "#8ADEFB";
// const primaryLight = "#CAF2FF" // this is for negative colors
const primaryDark = "#00B0E5";

const negative = "#d86422";
const negativeDark = "#C9530A";
const notApplicable = "#B6B6B6";

const theme: Theme = {
  mapBackground: "#e9e9e9",
  positive: "#26b6a1",
  negative,
  neutral: "#0093d5",
  background: "#ffffff",
  chartBackground: "#f4f4f4",
  textPrimary: "#474747",
  textSecondary: "rgb(71, 71, 71)",
  textReverse: "#ffffff",
  primary,
  primaryLight: "#CAF2FF",
  primaryDark: "#00B0E5",
  secondary: "#d86422",
  chartPrimary: primary,
  chartSecondary: "#AFADAD",
  mapLegendBorder: "#9d9d9d",
  softBorder: "#cccccc",
  hardBorder: "rgb(157, 157, 157)",
  icon: "#474747",
  metric1: "#255da5",
  metric2: "#f4b058",
  metric3: "#6db7aa",
  metric4: "#682674",
  metric5: "#cf2c2d",
  region1: "rgb(0, 174, 143)",
  region2: "rgb(200, 214, 91)",
  region3: "rgb(10, 113, 213)",
  region4: "rgb(255, 187, 48)",
  region5: "rgb(82, 0, 174)",
  region6: "rgb(193, 37, 146)",
  magicNumbers: {
    mobileNavHeight: "110px",
    navHeight: "145px",
    storyModeHeight: "85px",
  },
  mapDots: {
    zeroData: "#000000",
  },
  metrics: {
    Confirmed: primary,
    ConfirmedLight: primaryLight,
    ConfirmedDark: primaryDark,
    Deaths: negative,
    DeathsDark: negativeDark,
    None: "rgba(0,0,0,.5)",
    NA: notApplicable,
  },
  choropleth: {
    ConfirmedZero: "#FFFFFF",
    ConfirmedColorStops: [
      "#95DCF4",
      "#54CBF2",
      "#00ACE3",
      "#008EBC",
      "#007092",
    ],
    DeathsZero: "#FFFFFF",
    DeathsColorStops: ["#E2A786", "#E08D5E", "#D86422", "#BB551B", "#994414"],
    mapStyles: {
      selectedBorder: "#474747",
      selectedWeight: 1.75,
      generalBorder: "#777",
      generalWeight: 1.25,
      notApplicable: notApplicable,
    },
  },
  notApplicable,
};

// const shadow = `box-shadow: 0 1px 5px rgba(0,0,0,0.65);`;
const shadow = `border: 1px solid ${theme.softBorder};`;

export type MetricStrings =
  | "fever"
  | "cough"
  | "fatigue"
  | "shortness_of_breath"
  | "sore_throat"
  | "cases"
  | "confirmed"
  | "confirmed_cases"
  | "cumulative_confirmed"
  | "deaths"
  | "cumulative_deaths"
  | "recovered"
  | "confirmed_cases_per_1_million_population"
  | "deaths_per_1_million_population";

const getRegionColor = (region: Region, t?: Theme) => {
  switch (region) {
    case "Eastern Mediterranean":
      return theme.region1;
    case "Europe":
      return theme.region2;
    case "Africa":
      return theme.region3;
    case "Americas":
      return theme.region4;
    case "South-East Asia":
      return theme.region5;
    case "Western Pacific":
      return theme.region6;
  }
};

const getMetricColor = (metric: string, t?: Theme) => {
  switch (metric?.replace(/ /g, "_").toLowerCase() as MetricStrings) {
    case "fever":
      return theme.metric1;
    case "cough":
      return theme.metric2;
    case "fatigue":
      return theme.metric3;
    case "shortness_of_breath":
      return theme.metric4;
    case "sore_throat":
      return theme.metric5;
    case "cases":
      return theme.primary;
    case "confirmed_cases_per_1_million_population":
      return theme.primary;
    case "confirmed":
    case "confirmed_cases":
      return theme.primary;
    case "deaths_per_1_million_population":
      return theme.negative;
    case "deaths":
      return theme.negative;
    case "recovered":
      return theme.positive;
  }
};
const getColor = (aspect: string) => {
  return getMetricColor(aspect) || getRegionColor(aspect as Region);
};

export default theme;
export { getMetricColor, getRegionColor, getColor, shadow };
