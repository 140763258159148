import React, { useMemo, useState, useCallback } from "react";
import ContainedSection, { FullWidthSection } from "../layout/sections";
import Flexbox from "../layout/flexbox";
import DataSet from "../utils/data/DataSet";
import { DataSets } from "../App";
import Story, { StoryContainerSection } from "../components/story";
import Attribution from "../components/attribution";
import MapContainer from "../components/mapContainer";
import useBreakpoint from "../hooks/useBreakpoint";
import TrendGroup from "../components/trendGroup";
import MultiBarCombo from "../components/multiBarCombo";
import RegionSmallMultiples from "../components/regionSmallMultiples";
import RegionCards from "../components/regionCards";
import { observer } from "mobx-react-lite";
import LazyLoad from "react-lazyload";
import { ColumnItem } from "../components/ColumnChart/columnChart";
import { Helmet } from "react-helmet";
import useTimeout from "../hooks/useTimeout";

type WorldTrendsProps = {
  dataSets: DataSets;
};

export function getMetricCardData(
  byDayCumulative: DataSet,
  key: "Confirmed" | "Deaths" | "Recovered"
) {
  const index = byDayCumulative.fields.findIndex((field) => field.name === key);
  return byDayCumulative.rows.map((day) => ({
    date: day[0],
    value: day[index],
  }));
}

const WorldTrends: React.FC<WorldTrendsProps> = ({ dataSets }) => {
  const {
    today,
    yesterday,
    totals,
    byDayCumulative,
    byDay,
    dayGroups,
    countryGroups,
    byRegion,
    regionGroups,
    source,
  } = dataSets;

  const point = useBreakpoint();

  const [belowTheFold, setBelowTheFold] = useState(false);
  useTimeout(() => setBelowTheFold(true), 100);
  const useHamburger = point === "s" || point === "m";

  // Total cases, total deaths, new cases today/yesterday, percent change of cases today/yesterday

  return (
    <FullWidthSection h100 vertical gap="xl">
      <Helmet>
        <meta
          name="description"
          content="World Health Organization coronavirus disease situation dashboard presents official daily counts of COVID-19 cases and deaths worldwide, while providing a hub to other resources. Interactive tools, including maps, epidemic curves and other charts and graphics, with downloadable data, allow users to track and explore the latest trends, numbers and statistics at global, regional and country levels."
        />
        <title>WHO Coronavirus Disease (COVID-19) Dashboard</title>
      </Helmet>
      <Flexbox gap vertical h100 style={{ width: "100%" }} center>
        <MapContainer
          dataSets={dataSets}
          metricData={{
            totals,
            byDayCumulative,
            today,
            yesterday,
          }}
        />
      </Flexbox>

      <StoryContainerSection middle useHamburger={useHamburger}>
        <Story dataSets={dataSets} />
      </StoryContainerSection>

      <LazyLoad height={200}>
        <TrendGroup
          totals={[totals.Confirmed, totals["Cumulative Confirmed"]]}
          data={[
            byDay.toXYN(2) as ColumnItem[],
            byDay.toXYN(3) as ColumnItem[],
          ]}
          metric={byDay.metrics[2].name}
          title="Confirmed Cases Over Time"
        />
      </LazyLoad>
      <LazyLoad height={200}>
        <TrendGroup
          totals={[totals.Deaths, totals["Cumulative Deaths"]]}
          data={[
            byDay.toXYN(0) as ColumnItem[],
            byDay.toXYN(1) as ColumnItem[],
          ]}
          metric={byDay.metrics[0].name}
          title="Deaths Over Time"
        />
      </LazyLoad>

      <LazyLoad height={200}>
        <MultiBarCombo
          metric="Confirmed Cases"
          title="Case Comparison"
          subTitle="WHO Regions"
          byData={byRegion}
          dayGroups={dayGroups}
          groups={regionGroups}
        />
      </LazyLoad>
      <LazyLoad height={200}>
        <RegionSmallMultiples
          startDate={dataSets.startDate}
          endDate={dataSets.endDate}
          regionGroups={regionGroups.slice(0, 6)}
          title="Daily Cases"
          subTitle="By WHO Region"
        />
      </LazyLoad>
      <LazyLoad height={200}>
        <RegionCards
          countryGroups={countryGroups}
          source={source}
          title="Situation by Country, Territory or Area"
        />
      </LazyLoad>
    </FullWidthSection>
  );
};

WorldTrends.displayName = "HomePage";
export default observer(WorldTrends);
