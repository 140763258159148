import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import Accordion, { AccordionData } from "../components/accordion";
import ContainedSection, { FullWidthSection } from "../layout/sections";
import styled from "../designSystem";
import { StyledDownloadButton } from "../components/mapHud";
import Flexbox from "../layout/flexbox";
import { Download } from "@styled-icons/heroicons-solid/Download";
import { TextThree, TextEight, TextOne } from "../designSystem/typography";
import MarkdownRenderer from "../components/markdownRenderer";
import raw from "raw.macro";
import downloadIcon from "../assets/images/download-icon.svg";
import CsvDownloader from "react-csv-downloader";
import { StyledDateStamp } from "../components/breadcrumb";
import moment from "moment";

// import md files
const dataOverView = raw("./info-page-content/data-overview-visualizations.md");
const dataSources = raw("./info-page-content/data-sources.md");
const dataDownload = raw("./info-page-content/data-access.md");
const copyright = raw("./info-page-content/copyright.md");
const other = raw("./info-page-content/other-resources.md");

const DownloadDataButton = styled(StyledDownloadButton)`
  ${TextThree};
  font-size: 13px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.chartBackground};
  color: ${({ theme }) => theme.textPrimary};
  border-radius: 5px;
`;

const TitleWrap = styled(Flexbox)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.hardBorder};
`;

const Title = styled(Flexbox)`
  ${TextOne};
  padding: 0;
  margin: 0;
  background: none;
`;

const InfoPageContent = styled(ContainedSection)`
  table {
    margin-top: 1rem;
    border-spacing: 0;
    border: 1px solid ${({ theme }) => theme.softBorder};
    border-collapse: collapse;
  }
  th,
  td {
    padding: 4px 12px;
    border: 1px solid ${({ theme }) => theme.softBorder};
  }
  th {
    font-weight: bold;
  }

  .download-button {
    ${TextThree};
    font-size: 13px;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.chartBackground};
    color: ${({ theme }) => theme.textPrimary};
    border-radius: 5px;
    text-decoration: none;
    :after {
      content: url(/${downloadIcon}) " Download Data";
    }
  }
`;

type InfoPageProps = { csv: any[][] };

const InfoPage: React.FC<InfoPageProps> = ({ csv }) => {
  const downloadButton = (
    <CsvDownloader
      noHeader={true}
      datas={csv}
      filename="WHO-COVID-19-global-data"
      prefix={false}
      suffix={false}
    >
      <DownloadDataButton as="button">
        <Flexbox middle gap="s">
          <Download size="15" />
          <span>Download Map Data</span>
        </Flexbox>
      </DownloadDataButton>
    </CsvDownloader>
  );

  const infoData: AccordionData[] = useMemo(() => {
    const contentSplit = dataDownload.split("[downloadButton]");
    return [
      {
        title: "Data Overview and Visualizations",
        content: <MarkdownRenderer markdown={dataOverView} />,
      },
      {
        title: "Data Sources",
        content: <MarkdownRenderer markdown={dataSources} />,
      },
      {
        title: "Data Download",
        content: (
          <Flexbox vertical>
            <MarkdownRenderer markdown={contentSplit[0]} />
            {downloadButton}
            <MarkdownRenderer markdown={contentSplit[1]} />
          </Flexbox>
        ),
      },
      {
        title: "Copyright, Permissions and Referencing",
        content: <MarkdownRenderer markdown={copyright} />,
      },
      {
        title: "Other Resources",
        content: <MarkdownRenderer markdown={other} />,
      },
    ];
  }, []);

  return (
    <FullWidthSection h100 vertical gap="xl">
      <InfoPageContent vertical gap="m">
        <TitleWrap vertical>
          <Title>Information Note</Title>
          <StyledDateStamp>
            Last Updated:{" "}
            {moment.tz("May 22, 2020", "Europe/Paris").format("D MMM YYYY")}
          </StyledDateStamp>
        </TitleWrap>
        <Accordion data={infoData} />
        {downloadButton}
        {/* <DownloadDataButton href="/WHO-COVID-19-global-data.csv" download>
          <Flexbox middle gap="m">
            <Download size="15" />
            <div>Download Data</div>
          </Flexbox>
        </DownloadDataButton> */}
      </InfoPageContent>
    </FullWidthSection>
  );
};

InfoPage.displayName = "InfoPage";
export default observer(InfoPage);
