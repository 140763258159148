import React, { useEffect, useRef, useState, useMemo } from "react";
import { feature as topFeature } from "topojson-client/dist/topojson-client";
import southSudanData from "../assets/geo/south-sudan-topo.json";
import { GeoJSON, LayerGroup, TileLayer, Pane } from "react-leaflet";
import { observer } from "mobx-react-lite";
import { featureByIso2 } from "./countryFeature";
import arunachalPradesh from "../assets/geo/split-arunachal-pradesh.json";

export const featureStyle = () => {
  return {
    pointerEvents: "none",
    fillColor: "#FFFFFF",
    fillOpacity: 0,
    color: "#474747",
    weight: 1.5,
  };
};

const GeoRenderBase = ({ feature }: { feature?: any }) => {
  const isSouthSudan = feature?.properties["ISO_2_CODE"] === "SS";
  const borderingSouthSudan = ["UG", "CG", "CF", "ET", "SS"];
  const renderOverDisputedBorders: boolean = borderingSouthSudan.includes(
    feature?.properties["ISO_2_CODE"]
  );

  const arunachalBorderSegment = getArunachalBorderSegment(
    feature?.properties["ISO_2_CODE"]
  );

  let renderedFeature = feature;
  if (isSouthSudan) {
    renderedFeature = topFeature(
      southSudanData,
      (southSudanData as any).objects.countries
    );
  }

  const serbia = featureByIso2("RS");

  const isKosovoSelected = renderedFeature?.properties?.ISO_2_CODE === "XK";

  return (
    <>
      <Pane name="countries-and-selected" style={{ zIndex: 1 }}>
        <TileLayer
          url="https://dashboards-dev.sprinklr.com/WHO-maps/world-map-countries/{z}/{x}/{y}.png"
          opacity={1}
          noWrap={true}
        />
        {feature && !renderOverDisputedBorders && (
          <LayerGroup>
            <GeoJSONWrapper
              key={"min_geo1"}
              data={isKosovoSelected ? serbia : renderedFeature}
              style={featureStyle}
            />
          </LayerGroup>
        )}
      </Pane>

      <Pane name="disputed-borders" style={{ zIndex: 2 }}>
        <TileLayer
          url="https://dashboards-dev.sprinklr.com/WHO-maps/disputed-borders-v6/{z}/{x}/{y}.png"
          opacity={1}
          noWrap={true}
        />
      </Pane>

      {feature && arunachalBorderSegment && (
        <Pane name="arunachal-pradesh-india-border" style={{ zIndex: 3 }}>
          <GeoJSON
            key={`arunachal-pradesh-${feature.properties.ISO_2_CODE}`}
            data={arunachalBorderSegment}
            style={featureStyle}
          />
        </Pane>
      )}

      {feature && renderOverDisputedBorders && (
        <Pane name="selected-feature" style={{ zIndex: 3 }}>
          <LayerGroup>
            <GeoJSONWrapper
              key={"min_geo1"}
              data={isKosovoSelected ? serbia : renderedFeature}
              style={featureStyle}
            />
          </LayerGroup>
        </Pane>
      )}
    </>
  );
};

type GeoJSONWrapperProps = {
  data: any;
  style: any;
  onEachFeature?: any;
  deps?: any[];
};

const GeoJSONWrapperBase: React.FC<GeoJSONWrapperProps> = ({
  data: initialData,
  style,
}) => {
  const ref = useRef(null);
  const counter = useRef(0);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (ref.current) {
      ref.current?.leafletElement
        .clearLayers()
        .addData(initialData)
        .bringToBack();
      setData(initialData);
      counter.current = counter.current + 1;
    }
  }, [initialData]);

  return <GeoJSON key={counter.current} ref={ref} data={data} style={style} />;
};

const GeoRender = observer(GeoRenderBase);
const GeoJSONWrapper = observer(GeoJSONWrapperBase);

export { GeoRender, GeoJSONWrapper };

export const getArunachalBorderSegment = (iso2code) => {
  let arunachalBorderSegment;
  switch (iso2code) {
    case "IN":
      arunachalBorderSegment = topFeature(
        arunachalPradesh,
        (arunachalPradesh as any).objects.borders
      );
      break;
    case "BT":
      arunachalBorderSegment = topFeature(
        arunachalPradesh,
        (arunachalPradesh as any).objects.borders
      ).features.find((segment) => segment.properties.segment === 1);
      break;
    case "CN":
      arunachalBorderSegment = topFeature(
        arunachalPradesh,
        (arunachalPradesh as any).objects.borders
      ).features.find((segment) => segment.properties.segment === 2);
      break;
    case "MM":
      arunachalBorderSegment = topFeature(
        arunachalPradesh,
        (arunachalPradesh as any).objects.borders
      ).features.find((segment) => segment.properties.segment === 3);
      break;
    default:
      arunachalBorderSegment = null;
      break;
  }
  return arunachalBorderSegment;
};
