import React, { useCallback, useState } from "react";
import styled, { css } from "../designSystem";
import Flexbox from "../layout/flexbox";
import Metric from "./metric";
import { observer } from "mobx-react-lite";
import theme, { shadow } from "../designSystem/theme";
import { Close } from "@styled-icons/material-rounded/Close";
import { getPrettyNumberString, DecimalFormat } from "../utils/numberUtils";

type ChoroplethLegendProps = {
  colorStops: string[];
  metric: string;
  labels?: string[];
  onClose?: () => void;
  perMillion?: boolean;
};
const StyledChoroplethLegend = styled(Flexbox)`
  padding: 20px;
  background: #fff;
  ${shadow}
  border-radius: 3px;
  z-index: 10;
  h4 {
    margin: 0;
  }
  span {
    font-size: 13px;
    color: ${theme.textSecondary};
  }
`;
const StyledCellBar = styled(Flexbox)`
  flex-basis: 15px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledLabelBar = styled(Flexbox)`
  margin-left: 10px;
`;

const StyledLabel = styled(Flexbox)<{ height: number }>`
  ${({ height }) =>
    css`
      height: ${height}px;
    `}
  margin-top: 0.5px;
  margin-bottom: 0.5px;
`;

const StyledHeader = styled.span`
  font-size: 12px;
  letter-spacing: 0px;
  color: ${theme.textPrimary};
`;

const StyledCloseWrapper = styled(Flexbox)`
  cursor: pointer;
`;

const StyledClose = styled(Close)`
  color: ${theme.textPrimary};
`;

const StyledCell = styled(Flexbox)<{
  color: string;
  height: number;
  border: boolean;
}>`
  overflow: hidden;
  ${({ border }) => {
    return (
      border &&
      css`
        border: solid;
        border-width: 1px;
        border-color: #cacaca;
      `
    );
  }}
  ${({ color, height }) =>
    css`
      background-color: ${color};
      height: ${height}px;
    `}
  margin-top: .5px;
  margin-bottom: 0.5px;

  :last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  :first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
`;

const StyledLabeledCells = styled(Flexbox)``;

const ChoroplethLegend: React.FC<ChoroplethLegendProps> = ({
  metric,
  colorStops = ["red", "green", "blue"],
  labels = [
    "1 - 50,000",
    "50,000 - 100,000",
    "100,000 - 150,000",
    "150,000 - 200,000",
    "200,000 - 1,500,000",
  ],
  onClose,
  perMillion,
}) => {
  const colorStopsReversed = [...colorStops];
  colorStopsReversed.reverse();

  const labelsReversed = [...labels];
  labelsReversed.reverse();

  const zeroColor = theme.choropleth[`${metric}Zero`];
  const notApplicableColor = theme.notApplicable;

  return (
    <StyledChoroplethLegend vertical gap>
      <Flexbox between gap="s">
        {metric && (
          <StyledHeader>
            {metric} - {!perMillion && "Count"}
            {perMillion && "per 1 million population"}
          </StyledHeader>
        )}
        <StyledCloseWrapper onClick={onClose} noGrow>
          <StyledClose size="15" />
        </StyledCloseWrapper>
      </Flexbox>

      <StyledLabeledCells>
        <StyledCellBar vertical>
          {colorStops &&
            colorStopsReversed.map((color) => (
              <StyledCell
                key={color}
                color={color}
                height={40}
                border={false}
              />
            ))}
          <StyledCell
            key={zeroColor}
            color={zeroColor}
            height={25}
            border={true}
          />
          <StyledCell
            key={notApplicableColor}
            color={notApplicableColor}
            height={25}
            border={false}
          />
        </StyledCellBar>
        <StyledLabelBar vertical>
          {labels &&
            labelsReversed.map((label) => (
              <StyledLabel middle height={40}>
                <span>{label}</span>
              </StyledLabel>
            ))}
          <StyledLabel middle height={25}>
            <span>No Reported Data</span>
          </StyledLabel>
          <StyledLabel middle height={25}>
            <span>Not Applicable</span>
          </StyledLabel>
        </StyledLabelBar>
      </StyledLabeledCells>

      {/* <Flexbox between>
        <Metric value={min} />
        <Metric value={max} />
      </Flexbox> */}
    </StyledChoroplethLegend>
  );
};

ChoroplethLegend.displayName = "ChoroplethLegend";
export default observer(ChoroplethLegend);

export const makeLegendLabels = (
  thresholds: number[],
  format: DecimalFormat = "1,234",
  min = 1
) => {
  const legendLabels = [];
  let previous: string = getPrettyNumberString(min, format, 2);

  for (let i = 0; i < thresholds.length - 1; i++) {
    const current = getPrettyNumberString(thresholds[i], format, 2);
    legendLabels.push(`${previous} - ${current}`);
    previous = getPrettyNumberString(thresholds[i] + min, format, 2);
  }

  legendLabels.push(
    `${getPrettyNumberString(thresholds[thresholds.length - 2], format, 2)}+`
  );

  return legendLabels;
};

export const makeThresholds = (overallMax: number) => {
  let geoLegendBy = 0;

  if (overallMax > 0) {
    const power10 = Math.pow(10, Math.floor(Math.log10(overallMax)));
    const maxRounded = Math.round(overallMax / power10) * power10;
    geoLegendBy = Math.floor(maxRounded / 4);
    if (geoLegendBy === 0) {
      geoLegendBy = 1;
    }
  }

  const legendData = [];
  let legendBy = geoLegendBy * 4;
  for (let x = 0; x < 4; x++) {
    legendData.push(legendBy);
    legendBy -= geoLegendBy;
  }

  return legendData;
};
