import React, { useRef } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "../designSystem";
import appTheme from "../designSystem/theme";
import { Modifiers } from "popper.js";
import usePopper from "use-popper";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { useCallback } from "react";
import { observer } from "mobx-react-lite";
type Placement = "left" | "right" | "top" | "bottom";
type Positions = Placement | "auto";

/**
 * Popover Interface
 */

export type Offset = {
  enabled: boolean;
  offset: string;
};

type TriggerProps = {
  open?: boolean;
};
type PopoverProps = {
  children?: React.ReactNode;
  isOpen?: boolean;
  openOnHover?: boolean;
  positionFixed?: boolean;
  popoverContents?: React.ReactNode;
  position?: Positions;
  pad?: boolean;
  boundingElement?: any;
  offset?: Offset;
  onClickOutside?: () => void;
  hideArrow?: boolean;
  className?: string;
  clickOutExclude?: React.RefObject<HTMLElement>;
  style?: React.CSSProperties;
  delay?: number;
};

type StyledPopperInnerProps = {
  pad?: boolean;
  style?: React.CSSProperties;
  placement?: Placement;
  borderSquare?: boolean;
};
const StyledPopperInner = styled.div<StyledPopperInnerProps>`
  z-index: 10000000000; /* to beat the modal */
  border-radius: ${({ borderSquare }) => (borderSquare ? 0 : "4px")};
  ${({ theme }) =>
    css`
      border: 1px solid ${theme.softBorder};
      background: ${theme.background};
    `};
  ${({ pad }) =>
    pad !== false &&
    css`
      padding: 16px;
    `};

  ${({ placement }) => {
    switch (placement) {
      case "bottom":
        return css`
          margin-top: 10px;
        `;
      case "top":
        return css`
          margin-bottom: 10px;
        `;
      case "left":
        return css`
          margin-right: 10px;
        `;
      case "right":
        return css`
          margin-left: 10px;
        `;
    }
  }};
`;

const StyledTip = styled.div<{ placement?: Placement; style?: any }>`
  position: absolute;
  ${({ placement }) => {
    switch (placement) {
      case "bottom":
        return css`
          top: 0;
        `;
      case "top":
        return css`
          bottom: 0;
          transform: rotate(180deg);
        `;
      case "left":
        return css`
          right: 0;
          transform: rotate(90deg);
        `;
      case "right":
        return css`
          left: 0;
          transform: rotate(-90deg);
        `;
    }
  }};

  &:after,
  &:before {
    bottom: 100%;
    left: 0%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: transparent transparent #fff transparent;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-width: 11px;
    margin-left: -11px;
  }

  &:before {
    border-color: transparent transparent ${appTheme.softBorder} transparent;
  }
`;

export const StyledTrigger = styled.div<TriggerProps>`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Popover = ({
  isOpen,
  children,
  popoverContents,
  position,
  pad,
  boundingElement,
  openOnHover,
  onClickOutside,
  hideArrow,
  className,
  clickOutExclude,
  delay = 300,
}: PopoverProps) => {
  const timer = useRef(null);
  const [isHovered, setHovered] = React.useState<boolean>(false);
  const popperRef = React.useRef<HTMLInputElement>(null);
  const triggerRef = React.useRef<HTMLInputElement>(null);

  const modifiers: Modifiers = {
    options: {
      scroll: false,
      resize: false,
    },
    preventOverflow: {
      boundariesElement: undefined,
    },
  };
  if (boundingElement && modifiers.preventOverflow) {
    modifiers.preventOverflow.boundariesElement = boundingElement;
  }

  const { reference, popper, arrow } = usePopper({
    placement: position || "auto",
    modifiers,
  });

  const onClose = useCallback(() => {
    clearTimeout(timer.current);
    setHovered(false);
  }, [setHovered]);

  const refs = !!clickOutExclude ? [popperRef, clickOutExclude] : [popperRef];
  useOnClickOutside(refs, () => {
    onClose();
    if (onClickOutside) {
      onClickOutside();
    }
  });

  const onHoverOut = useCallback(() => {
    timer.current = setTimeout(() => {
      setHovered(false);
    }, delay);
  }, [setHovered, delay]);

  const onHover = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setHovered(true);
    }, 100);
  }, [setHovered]);

  const open = !openOnHover ? isOpen : !!isHovered;

  return (
    <>
      <StyledTrigger
        data-id="popover-trigger"
        ref={reference.ref as any}
        onMouseEnter={onHover}
        onMouseLeave={onHoverOut}
        open={open}
      >
        {children}
      </StyledTrigger>
      {open &&
        ReactDOM.createPortal(
          <StyledPopperInner
            className={className}
            borderSquare={hideArrow}
            data-id="popper-inner"
            ref={popper.ref as any}
            style={popper.styles}
            onClick={onClose}
            data-placement={popper.placement}
            placement={popper.placement as Placement}
            pad={pad}
            onMouseEnter={onHover}
            onMouseLeave={onHoverOut}
          >
            <div ref={popperRef}>
              {popoverContents}

              {!hideArrow && (
                <StyledTip
                  data-id="popover-tip"
                  placement={popper.placement as Placement}
                  ref={arrow.ref as any}
                  style={arrow.styles}
                />
              )}
            </div>
          </StyledPopperInner>,
          document.body
        )}
    </>
  );
};

export default observer(Popover);
