import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

export type BreakPoint = "s" | "m" | "l" | "xl" | "desktop";

const getDeviceConfig = (width): BreakPoint => {
  if (width < 576) {
    return "s";
  } else if (width >= 576 && width < 768) {
    return "m";
  } else if (width >= 768 && width < 1020) {
    return "l";
  } else if (width >= 1024 && width < 1420) {
    return "xl";
  } else {
    return "desktop";
  }
};

const useBreakpoint = (): BreakPoint => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};
export default useBreakpoint;
