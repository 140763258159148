import React, { useCallback } from "react";
import Flexbox from "../layout/flexbox";
import styled from "../designSystem";
import ErrorChart from "../assets/images/text_chart.svg";
import { observer } from "mobx-react-lite";
import Button from "../components/button";
import { media } from "../designSystem/responsive";

const StyledErrorPage = styled(Flexbox)`
  text-align: center;
  color: #474747;
`;

const StyledChart = styled.div`
  margin-bottom: 30px;
  ${(media as any).m`
    margin-bottom: 15px;
  `}
`;

const ErrorPage: React.FC<{}> = () => {
  const handleClick = useCallback(() => {
    window.location.href = "/";
  }, []);

  return (
    <StyledErrorPage vertical middle center>
      <Flexbox gap="xl" middle vertical center>
        <StyledChart>
          <img
            src={ErrorChart}
            style={{
              height: "100%",
              width: "100%",
            }}
            alt="404 image"
          />
        </StyledChart>
        <h1>This page cannot be found.</h1>
        <p>
          The page or file you are trying to access cannot be found. This is
          because the web address is incorrect or the file has been moved or
          deleted.
        </p>
        <Button onClick={handleClick} btnType="primary" fluid={false}>
          Return to home page
        </Button>
      </Flexbox>
    </StyledErrorPage>
  );
};

ErrorPage.displayName = "ErrorPage";
export default observer(ErrorPage);
