import React, { useCallback, useMemo } from "react";
import styled, { css } from "../../designSystem";
import HoverLine from "./hoverLine";
import Flexbox from "../../layout/flexbox";
import { PageTitle } from "../../designSystem/typography";
import { CountryFlag } from "../breadcrumb";
import {
  isoAlpha2toIsoAlpha3,
  findRegionByCountryIso2,
  getCountryNameFromId,
  canShowFlag,
} from "../../utils/countryCode";
import CountryShape from "./countryShape";
import { useHistory } from "react-router-dom";
import Metric from "../metric";
import appTheme, { getMetricColor, getColor } from "../../designSystem/theme";
import DataSet from "../../utils/data/DataSet";
import { observer } from "mobx-react-lite";
import theme from "../../designSystem/theme";
import { countryPopulation } from "../../data/countryStats";
import moment from "moment";

type CountryCardProps = {
  countryCodeIsoAlpha2: string;
  last7Days: boolean;
  last24Hours: boolean;
  perMillion: boolean;
  cumulative: boolean;
  data?: DataSet;
  useSvg?: boolean;
  selectedMetric: string;
};

const StyledCountryCard = styled(Flexbox)`
  position: relative;
  cursor: pointer;
  svg {
    overflow: visible;
  }
`;

type StyledCountryShapeProps = {
  isScrubbing: boolean;
};
const StyledCountryShape = styled(Flexbox)<StyledCountryShapeProps>`
  opacity: 0.3;
  flex-grow: 0;
  will-change: opacity;
  transition: opacity 300ms;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: ${theme.mapBackground};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  ${({ isScrubbing }) =>
    isScrubbing &&
    css`
      opacity: 0.2;
    `}
`;

const StyledHeader = styled(Flexbox)`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 15px;
  /* ${PageTitle} {
    padding-right: 100px;
  } */
`;

const StyledButton = styled.button`
  border: 1px solid ${theme.primary};
  font-weight: bold;
  color: ${theme.primary};
  padding: 3px 5px;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const StyledMetrics = styled(Flexbox)`
  position: absolute;
  top: 35px;
  left: 0;
  padding: 15px;
`;

const StyledSparkline = styled(Flexbox)<{
  isScrubbing: boolean;
  metric: string;
}>`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  path {
    will-change: stroke;
    transition: stroke 300ms;
  }

  ${({ isScrubbing }) =>
    !isScrubbing &&
    css`
      path {
        stroke: #afadad;
      }
    `}

  ${({ isScrubbing, metric }) =>
    isScrubbing &&
    metric &&
    css`
      path {
        stroke: ${getColor(metric)};
      }
    `}
`;
const StyledMetric = styled.span<{ metric?: string; isScrubbing: boolean }>`
  > div > span {
    will-change: color;
    transition: color 300ms;
  }
  ${(isScrubbing) =>
    !isScrubbing &&
    css`
      > div > span {
        color: ${appTheme.textPrimary};
      }
    `}
  ${({ theme, metric, isScrubbing }) =>
    metric &&
    isScrubbing &&
    css`
      > div > span {
        color: ${getMetricColor(metric, theme)};
      }
    `}
`;

const CountryCard: React.FC<CountryCardProps> = ({
  countryCodeIsoAlpha2,
  data,
  useSvg = false,
  selectedMetric = "Confirmed",
  last7Days,
  last24Hours,
  perMillion,
  cumulative,
}) => {
  const history = useHistory();
  const countryName = getCountryNameFromId(countryCodeIsoAlpha2);
  const countryCodeIsoAlpha3 = isoAlpha2toIsoAlpha3(countryCodeIsoAlpha2);
  const populationMillions =
    (countryPopulation(countryCodeIsoAlpha2) || 10000000) / 1000;

  // const [svg, setSvg] = useState();

  const handleClick = useCallback(() => {
    history.push({
      pathname: `/region/${findRegionByCountryIso2(
        countryCodeIsoAlpha2
      )?.toLowerCase()}/country/${countryCodeIsoAlpha2?.toLowerCase()}`,
    });
  }, [countryCodeIsoAlpha2, history]);

  // useEffect(() => {
  //   if (useSvg && !svg) {
  //     axios
  //       .get(`/countryVectors/${countryCodeIsoAlpha2}.svg`)
  //       .then((response) => {
  //         setSvg(response.data);
  //       });
  //   }
  // }, [useSvg]);

  const [isScrubbing, setIsScrubbing] = React.useState(false);
  const [scrubbingValue, setScrubbingValue] = React.useState<number>(undefined);

  const trend = useMemo(() => {
    const key = cumulative ? "Cumulative " + selectedMetric : selectedMetric;
    return data?.toObjects().map((values) => {
      return {
        date: values.day,
        value: values[key],
      };
    });
  }, [data, populationMillions, selectedMetric, cumulative]);

  const renderMap = useMemo(() => {
    return (
      <CountryShape
        key={countryCodeIsoAlpha2}
        countryCodeIsoAlpha2={countryCodeIsoAlpha2}
      />
    );
  }, [countryCodeIsoAlpha2]);

  const metricFieldIndex = data.getMetricFieldIndex(selectedMetric);
  const metricIndex = data.getMetricIndex(selectedMetric);
  const metric = data.metrics[metricIndex];

  const metricValue = useMemo(() => {
    if (isScrubbing && scrubbingValue !== undefined) {
      return scrubbingValue;
    } else if (last24Hours) {
      if (data.rows.length > 0) {
        return data.rows[data.rows.length - 1][metricFieldIndex];
      } else {
        return 0;
      }
    } else if (last7Days) {
      if (data.rows.length > 6) {
        return data
          .derivedDataset((rows) => rows.slice(rows.length - 7, rows.length))
          .sum(metric);
      } else {
        return data.sum(metric);
      }
    } else if (perMillion) {
      return data.totals[metricIndex] / populationMillions;
    } else {
      return data.totals[metricIndex];
    }
  }, [
    isScrubbing,
    scrubbingValue,
    last24Hours,
    last7Days,
    perMillion,
    populationMillions,
    data,
    metricIndex,
  ]);

  let metricLabel =
    selectedMetric === "Confirmed" ? "Confirmed Cases" : selectedMetric;

  if (isScrubbing && scrubbingValue !== undefined) {
  } else if (last24Hours) {
    metricLabel += " last 24 hours";
  } else if (last7Days) {
    metricLabel += " last 7 days";
  } else if (perMillion) {
    metricLabel += " per 1 million population";
  }

  const startScrubbing = useCallback(() => setIsScrubbing(true), [
    setIsScrubbing,
  ]);
  const stopScrubbing = useCallback(() => setIsScrubbing(false), [
    setIsScrubbing,
  ]);
  const showFlag = canShowFlag(countryCodeIsoAlpha2);

  return (
    <StyledCountryCard
      className={`country-card country-${countryCodeIsoAlpha2}`}
      vertical
      onClick={handleClick}
      onMouseEnter={startScrubbing}
      onMouseLeave={stopScrubbing}
    >
      {/*{useSvg && svg && (*/}
      {/*  <StyledCountryShape*/}
      {/*    dangerouslySetInnerHTML={{ __html: svg }}*/}
      {/*    isScrubbing={isScrubbing}*/}
      {/*    center*/}
      {/*    middle*/}
      {/*  />*/}
      {/*)}*/}
      {useSvg && (
        <StyledCountryShape
          style={{
            backgroundImage: `url('/countryVectors/${countryCodeIsoAlpha2}.svg')`,
          }}
          isScrubbing={isScrubbing}
          center
          middle
        />
      )}
      {!useSvg && (
        <StyledCountryShape isScrubbing={isScrubbing} center middle>
          {renderMap}
        </StyledCountryShape>
      )}

      {data && (
        <StyledMetrics vertical gap>
          <StyledMetric isScrubbing={isScrubbing} metric={selectedMetric}>
            <Metric
              size="s"
              align="left"
              value={metricValue}
              label={metricLabel}
            />
          </StyledMetric>
        </StyledMetrics>
      )}
      {isScrubbing && <StyledButton>View Detail</StyledButton>}

      <StyledHeader>
        {countryCodeIsoAlpha3 && showFlag && (
          <CountryFlag
            code={countryCodeIsoAlpha2}
            style={{ paddingRight: "10px", height: "27px", width: "40px" }}
            alt={countryName}
          />
        )}
        <PageTitle>{countryName}</PageTitle>
      </StyledHeader>
      {data && (
        <StyledSparkline isScrubbing={isScrubbing} metric={selectedMetric}>
          <HoverLine
            color={getColor(selectedMetric)}
            onScrub={setScrubbingValue}
            data={trend}
            countryName={countryName}
          />
        </StyledSparkline>
      )}
    </StyledCountryCard>
  );
};

CountryCard.displayName = "CountryCard";
export default observer(CountryCard);
