import Field, { FieldDataType, FieldType } from "./Field";
import moment from "moment";

export default class Dimension extends Field<string> {
  constructor(name: string, type?: FieldType, dataType?: FieldDataType) {
    super(
      name as any,
      typeof type !== "undefined" && type !== null ? type : "STRING",
      dataType || "STRING"
    );
  }

  stringify(value: any): string {
    if (value === undefined || value === null || value === "") {
      return "";
    }
    switch (this.type) {
      case "DATE":
      case "TIMESTAMP":
        if (value instanceof Date) {
          return value.toISOString();
        }

        if (moment.isMoment(value)) {
          return value.toISOString();
        }
      // fall through
      default:
      case "TIME_INTERVAL":
      case "NUMBER":
      case "DECIMAL":
      case "INTEGER":
      case "STRING":
        return value + "";
    }
  }

  isTimeSeries(): boolean {
    if (this.type === "DATE" || this.type === "TIMESTAMP") {
      return true;
    }

    return false;
  }
}
