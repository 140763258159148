import React, { useState, useContext, useCallback, useMemo } from "react";
import ContainedSection, { Layout } from "../layout/sections";
import Flexbox from "../layout/flexbox";
// import ColumnChart from "./columnChart";
import StackedColumnChart from "./ColumnChart/stackedColumnChart";

import Header, { SubHeader, HeaderLockup } from "./header";
import Dropdown from "./dropdown";
import Attribution from "./attribution";
import styled from "../designSystem";
import { media } from "../designSystem/responsive";
import { getRegionName } from "../utils/regions";
import Barchart from "./barchart";
import DataSet, { GroupedData } from "../utils/data/DataSet";
import { observer } from "mobx-react-lite";

type MultiBarComboProps = {
  metric: string;
  title: string;
  subTitle?: string;
  byData: DataSet;
  dayGroups: GroupedData[];
  groups: GroupedData[];
};

const StyledRegionsMultiLayout = styled(Layout)`
  & > div:first-child {
    flex: 0 0 32%;
  }
  max-height: 408px; /* safari fix */
  ${(media as any).m`
    flex-direction: column;
    & > div {
      flex: 1 1 auto;
    }
    & > div:last-child {
      min-height: 209px;
      margin: 20px 0 0 0;
    }
    .bar_fill {
        height: 10px;
    }

    max-height: 560px;  /* safari fix */
  `}
`;

const MultiBarCombo = ({
  title,
  subTitle,
  byData,
  dayGroups,
  groups,
  metric,
}: MultiBarComboProps) => {
  const [selected, setSelected] = useState();
  const [scrubIndex, setScrubIndex] = useState();

  const onEnter = useCallback(
    (id: string) => {
      setSelected(id);
    },
    [setSelected]
  );

  const handleOnLeave = useCallback(() => setSelected(undefined), [
    setSelected,
  ]);
  const handleOnScrub = useCallback(
    (value: string, index: number) => setScrubIndex(index),
    [setScrubIndex]
  );
  const getGroupedData = useMemo(
    () =>
      dayGroups
        .map((object) => {
          const byRegion = object.data.groupByAggregate(
            object.data.dimensions[1]
          );
          const indexed: any = byRegion.toMap();
          return {
            x: object.value,
            data: groups.map(
              (regionGroup) => indexed[regionGroup.value]?.Confirmed || 0
            ),
          };
        })
        .slice(0, -1), // remove last day so that there's no 0 at the end
    [dayGroups]
  );

  return (
    <ContainedSection vertical gap="xxl">
      <HeaderLockup title={title} subTitle={subTitle} />
      <StyledRegionsMultiLayout gap>
        <Flexbox noGrow>
          <Barchart
            activeItems={!!selected ? [selected] : undefined}
            popoverEnabled={false}
            onEnter={onEnter}
            onLeave={handleOnLeave}
            metric={metric}
            data={byData
              .toObjects()
              .map((region) => {
                return {
                  label: getRegionName(region.Region),
                  value: region.Confirmed,
                };
              })
              .slice(0, 6)}
          />
        </Flexbox>
        <Flexbox>
          <StackedColumnChart
            activeSegments={selected}
            data={getGroupedData}
            metrics={groups.map((regionGroup) =>
              getRegionName(regionGroup.value)
            )}
          />
          {/* <ColumnChart
            activeItems={selected}
            onScrub={handleOnScrub}
            activeIndex={!!scrubIndex ? scrubIndex : undefined}
            data={getGroupedData}
            metrics={groups.map((regionGroup) =>
              getRegionName(regionGroup.value)
            )}
          /> */}
        </Flexbox>
      </StyledRegionsMultiLayout>
      <Flexbox center>
        <Attribution source="World Health Organization" />
      </Flexbox>
    </ContainedSection>
  );
};

export default observer(MultiBarCombo);
