import React from "react";
import getPrettyNumber, {
  DecimalFormat,
  getDecimalsByFormat,
} from "../utils/numberUtils";
import styled, { css } from "../designSystem";
import Flexbox from "../layout/flexbox";
import theme, { getMetricColor } from "../designSystem/theme";
import { darken, lighten, desaturate } from "polished";
import { observer } from "mobx-react-lite";
import { Close } from "@styled-icons/material-rounded/Close";

export function getLegendItems(zScale, thresholds: number[]) {
  const items = thresholds.slice(0, thresholds.length - 1).map((item) => {
    return {
      value: item,
      radius: zScale(item),
    };
  });
  return items;
}

const StackedSizeLegendCircle = styled(Flexbox)<{
  metric?: string;
  index?: number;
}>`
  opacity: 0.7;
  border-radius: 50%;
  ${({ theme, metric, index }) =>
    metric &&
    css`
      background-color: ${theme.metrics[metric]};
    `}
  ${({ theme, metric, index }) =>
    !metric &&
    css`
      background-color: ${index > 0
        ? lighten((index + 1) * -0.075, "#ffffff")
        : "#fff"};
    `}
`;

const StyledStackedSizeLegend = styled(Flexbox)``;

const StackedSizeLegendText = styled(Flexbox)`
  color: ${theme.textPrimary};
  letter-spacing: 0px;
  font-size: 13px;
`;

const StyledCloseWrapper = styled(Flexbox)`
  cursor: pointer;
`;

const StyledClose = styled(Close)`
  color: ${theme.textPrimary};
`;

const StyledHeader = styled.span`
  font-size: 13px;
  letter-spacing: 0px;
  color: ${theme.textPrimary};
`;

type IStackedSizeLegendProps = {
  items: Array<{ value: string; radius: number }>;
  decimalFormat: DecimalFormat;
  isPercentage: boolean;
  metric?: string;
  showValues?: boolean;
  onClose?: () => void;
  perMillion?: boolean;
};

export interface ZAxis {
  enabled: boolean;
  numberFormat: DecimalFormat;
  radius: {
    min?: number;
    max?: number;
    size?: number;
  };
}

const StackedSizeLegend: React.FC<IStackedSizeLegendProps> = ({
  items,
  decimalFormat,
  isPercentage,
  metric,
  showValues = true,
  onClose,
  perMillion,
}) => {
  if (!items) {
    return;
  }
  const circlesData = items
    .slice()
    .sort((a, b) => b.radius - a.radius)
    .map((circle) => {
      return {
        metric,
        ...circle,
      };
    });

  const circles = [
    ...circlesData,
    {
      value: "No Reported Data",
      metric: "None",
      radius: circlesData[circlesData.length - 1].radius,
    },
    {
      value: "Not Applicable",
      metric: "NA",
      radius: circlesData[circlesData.length - 1].radius,
    },
  ];

  const values = showValues && circles.map((circle) => circle.value);
  return (
    <StyledStackedSizeLegend gap="l" vertical>
      <Flexbox between gap="s">
        {metric && (
          <StyledHeader>
            {metric} - {!perMillion && "Count"}
            {perMillion && "per 1 million population"}
          </StyledHeader>
        )}
        <StyledCloseWrapper onClick={onClose} noGrow>
          <StyledClose size="15" />
        </StyledCloseWrapper>
      </Flexbox>

      <Flexbox vertical gap="ml">
        {circles &&
          circles.map((circle, index) => {
            const diameter = circle.radius * 2;
            return (
              <Flexbox gap="m" key={index} style={{ height: `${diameter}px` }}>
                <Flexbox
                  center
                  middle
                  style={{ minWidth: `${circles[0].radius * 2}px` }}
                  noGrow
                >
                  <StackedSizeLegendCircle
                    index={index}
                    noGrow
                    metric={circle.metric}
                    style={{
                      width: diameter,
                      height: diameter,
                    }}
                    key={index}
                  />
                </Flexbox>
                <StackedSizeLegendText middle left>
                  {values[index]}
                </StackedSizeLegendText>
              </Flexbox>
            );
          })}
      </Flexbox>
    </StyledStackedSizeLegend>
  );
};

export default observer(StackedSizeLegend);

// background-color: ${index > 0
//   ? darken( (index + 1) * 0.075, `#FFCD64`)
//   : `#FFCD64`
//   };
