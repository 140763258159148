import React from "react";
import Flexbox from "../layout/flexbox";
import { TextFour } from "../designSystem/typography";
import styled from "../designSystem";
import CsvDownloader from "react-csv-downloader";
import theme from "../designSystem/theme";

type AttributionProps = {
  source?: string;
  csv?: any;
};

const StyledSource = styled.span``;
export const StyledAttribution = styled(Flexbox)`
  ${TextFour}
  white-space: nowrap;
`;

const StyledDownload = styled.button`
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  will-change: color;
  transition: color 300ms;
  padding: 0;
  color: ${theme.primary};
`;

export default ({ source, csv }: AttributionProps) => {
  return (
    <StyledAttribution gap="s" middle noGrow>
      <span>Source:</span>
      <StyledSource>{source}</StyledSource>
      {!!csv && (
        <>
          <StyledDownload>|</StyledDownload>
          <CsvDownloader
            noHeader={true}
            datas={csv}
            filename="WHO-COVID-19-global-data"
            prefix={false}
            suffix={false}
          >
            <StyledDownload title="download map data">
              Download Map Data
            </StyledDownload>
          </CsvDownloader>
        </>
      )}
    </StyledAttribution>
  );
};
