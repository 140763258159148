import React, { useCallback } from "react";
import styled from "../designSystem";
import Flexbox from "../layout/flexbox";
import { TextEight } from "../designSystem/typography";
import Button from "./button";
import { observer } from "mobx-react-lite";

const Label = styled.div`
  ${TextEight};
`;

const DonateCta: React.FC<{ fluid?: boolean }> = ({ fluid }) => {
  const handleClick = useCallback(() => {
    if (window) {
      window.open(
        "https://www.who.int/Covid19ResponseFund",
        "_blank",
        "noopener=true,noreferrer=true"
      );
    }
  }, []);
  return (
    <Flexbox noGrow vertical gap="s" center>
      <Label>Covid-19 Response Fund</Label>
      <Button onClick={handleClick} btnType="secondary" fluid={fluid}>
        Donate
      </Button>
    </Flexbox>
  );
};

export default observer(DonateCta);
