import React from "react";
import { Group } from "@vx/group";
import { AxisBottom } from "@vx/axis";
import { Line } from "@vx/shape";
import { Text } from "@vx/text";
import {
  getPrettyTimestamp,
  DecimalFormat,
  CurrencyFormat,
} from "../utils/numberUtils";
import moment from "moment";
import { getPrettyNumberString } from "../utils/numberUtils";
import { observer } from "mobx-react-lite";

export type FieldType =
  | "STRING"
  | "INTEGER"
  | "DECIMAL"
  | "NUMBER"
  | "TIMESTAMP"
  | "TIME_INTERVAL"
  | "DATE";

export type FieldDataType =
  | "NUMERIC"
  | "PERCENTAGE"
  | "DATETIME"
  | "STRING"
  | "TIME_DIFFERENCE"
  | "DATE_HISTOGRAM"
  | "RANGE"
  | "PICKLIST"
  | "CUSTOM"
  | "CURRENCY"
  | "CURRENCY_AUD"
  | "CURRENCY_CAD"
  | "CURRENCY_CHF"
  | "CURRENCY_CNY"
  | "CURRENCY_CZK"
  | "CURRENCY_DKK"
  | "CURRENCY_EUR"
  | "CURRENCY_GBP"
  | "CURRENCY_INR"
  | "CURRENCY_JPY"
  | "CURRENCY_KRW"
  | "CURRENCY_NOK"
  | "CURRENCY_NZD"
  | "CURRENCY_PLN"
  | "CURRENCY_RUB"
  | "CURRENCY_SEK"
  | "CURRENCY_SGD"
  | "CURRENCY_TRY"
  | "CURRENCY_USD"
  | "CURRENCY_ZAR";

export type XAxisDateFormats =
  | "auto"
  | "MMM D"
  | "MM D"
  | "MMMM"
  | "MMM"
  | "MM"
  | "D"
  | "k"
  | "ddd ha";

type FieldTypeValueOpts = {
  dateFormat?: XAxisDateFormats;
  decimalFormat?: DecimalFormat;
  currencyFormat?: CurrencyFormat;
  isPercentage?: boolean;
};

export const getFieldTypeValue = (
  value,
  type: FieldType,
  options: FieldTypeValueOpts
) => {
  const {
    dateFormat,
    currencyFormat = "",
    isPercentage,
    decimalFormat,
  } = options;
  switch (type) {
    case "TIMESTAMP":
    case "TIME_INTERVAL":
    case "DATE":
      return dateFormat === "auto"
        ? getPrettyTimestamp(moment.utc(value).valueOf())
        : moment.utc(value).format(dateFormat);
    case "STRING":
      return value;
    default:
      return `${currencyFormat}${getPrettyNumberString(
        value,
        decimalFormat || "1,234"
      )}${isPercentage ? "%" : ""}`;
  }
};

export type ITicks = {
  enabled: boolean;
  label: {
    enabled: boolean;
    size: number;
    numberFormat: DecimalFormat;
    timeFormat: XAxisDateFormats;
  };
  line: {
    enabled: boolean;
  };
};

export type IAxis = {
  enabled: boolean;
  label: {
    enabled: boolean;
    size: number;
    padding: number;
  };
  ticks: ITicks;
  padding?: {
    start?: number;
    end?: number;
  };
};

export type AxisProps = {
  enabled: boolean;
  label: {
    enabled: boolean;
    size: number;
    padding: number;
  };
  ticks: ITicks;
};

export type XAxisProps = {
  bandwidth: number;
  dateFormat?: XAxisDateFormats;
  xMax?: number;
  hideAxisLine?: boolean;
  label: string;
  labelType?: FieldType;
  left?: number;
  numTicksForWidth: number;
  top?: number;
  options: AxisProps;
  xScale: any;
  xValues?: string[] | number[];
};

const XAxis: React.FC<XAxisProps> = ({
  bandwidth,
  xMax,
  dateFormat,
  hideAxisLine,
  label,
  labelType,
  left,
  numTicksForWidth,
  top,
  options,
  xScale,
  xValues,
}) => {
  const { ticks, label: xAxisLabel } = options;

  if (!ticks) {
    return <div />;
  }

  const { line } = ticks;
  const tickValueSize = ticks.enabled ? ticks.label.size * 0.4 : 0;
  const labelSize = xAxisLabel.size * 0.75;
  const labelPadding = labelSize * xAxisLabel.padding * 0.02;
  const labelOffset = labelPadding + labelSize;
  const labelY =
    ticks && ticks.label && ticks.label.enabled
      ? ticks.label.size + labelOffset - ticks.label.size * 0.3
      : labelOffset;
  return (
    <AxisBottom
      hideAxisLine={hideAxisLine}
      rangePadding={1}
      key="bottom"
      label={label}
      left={left || 0}
      numTicks={numTicksForWidth}
      scale={xScale}
      top={top || 0}
    >
      {(axis) => {
        const tickRotate = 0;
        const axisCenter = (axis.axisToPoint.x - axis.axisFromPoint.x) / 2;
        return (
          <>
            {ticks.enabled &&
              axis.ticks.map((tick, i) => {
                const tickX = tick.to.x;
                const tickY = tick.to.y;

                // const resolvedLabel =
                //     tick && tick.value && xValues && xValues.length > 0
                //         ? xValues[tick.value as string | number]
                //         : getFieldTypeValue(tick.value, labelType || 'STRING', {
                //               dateFormat,
                //           });
                return (
                  <Group
                    key={`vx-tick-${tick.value}-${i}`}
                    className={`vx-axis-tick`}
                  >
                    {ticks.label &&
                      ticks.label.enabled &&
                      line.enabled &&
                      !hideAxisLine && <Line from={tick.from} to={tick.to} />}
                    {ticks.label && ticks.label.enabled && (
                      <Text
                        verticalAnchor="middle"
                        className="primary_font_family"
                        fontSize={ticks.label.size * 0.5}
                        textAnchor="middle"
                        x={
                          !!xMax
                            ? Math.min(tickX, xMax - bandwidth * 0.5)
                            : tickX
                        }
                        y={tickY}
                        angle={tickRotate}
                        width={bandwidth}
                        style={{ fill: "black" }}
                      >
                        {labelType === "NUMBER"
                          ? tick.formattedValue
                          : moment.utc(tick.value).format(dateFormat)}
                      </Text>
                    )}
                  </Group>
                );
              })}

            {!hideAxisLine && (
              <Line
                className="vx-axis-line"
                x1={axis.axisFromPoint.x}
                x2={axis.axisToPoint.x}
                y1={0}
                y2={0.5}
              />
            )}

            {xAxisLabel.enabled && (
              <Text
                verticalAnchor="start"
                className="vx-axis-label bottom-axis-label"
                fontSize={xAxisLabel.size}
                textAnchor="middle"
                style={{ fill: "black" }}
                x={axisCenter}
                y={labelY}
              >
                {axis.label}
              </Text>
            )}
          </>
        );
      }}
    </AxisBottom>
  );
};

export default observer(XAxis);
