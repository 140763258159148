import React from "react";
// import styled, { css } from "../../designSystem";
import Flexbox from "../layout/flexbox";
import { observer } from "mobx-react-lite";
import Cloropleth from "../assets/images/glyphs/cloropleth";
import BubbleMap from "../assets/images/glyphs/bubble_map";
import styled, { css } from "../designSystem";
import theme from "../designSystem/theme";
import { media } from "../designSystem/responsive";
import useBreakpoint from "../hooks/useBreakpoint";

export type ModeName = "Bubble Map" | "Choropleth Map";
export type ModeIcon = "bubble_map" | "choropleth_map";
export type MapMode = {
  name: ModeName;
  icon: ModeIcon;
};

export const modes: MapMode[] = [
  {
    name: "Choropleth Map",
    icon: "choropleth_map",
  },
  {
    name: "Bubble Map",
    icon: "bubble_map",
  },
];

type MapModeToggleProps = {
  modes: any;
  active: MapMode;
  onSetMode?: (mode: MapMode) => void;
  metric: string;
};
const StyledIcon = styled(Flexbox)`
  svg {
    polygon,
    circle {
      fill: #586e80;
    }
    margin-top: 5px;
  }
`;
const StyledMapToggle = styled(Flexbox)`
  z-index: 1;
  ${(media as any).m`
      > * + * {
          margin-left: 4px;
      }
  `}
`;

const StyledLabel = styled.span`
  text-align: center;
  font-size: 11px;
  max-width: 53px;
`;
const StyledItem = styled(Flexbox)<{ active: boolean; metric: string }>`
  cursor: pointer;
  border: 1px solid ${theme.softBorder};
  border-radius: 4px;
  padding: 5px;
  ${(media as any).m`
      padding: 1px 2px 2px 2px;
      height: 38px;
      width: 47px;
      svg {
        width: 100%;
      }
  `}
  background: rgb(249, 249, 249);
  ${({ active, metric }) =>
    active &&
    css`
      color: #fff;
      background-color: ${theme.metrics[metric]};
      ${StyledIcon} {
        polygon,
        circle {
          fill: #fff;
        }
      }
    `}
`;

const MapModeToggle: React.FC<MapModeToggleProps> = ({
  modes,
  onSetMode,
  active,
  metric,
}) => {
  const point = useBreakpoint();
  const useHambuger = point === "s" || point === "m";
  return (
    <StyledMapToggle gap="m">
      {modes.map((mode) => (
        <StyledItem
          gap="s"
          vertical
          center
          middle
          active={mode.name === active.name}
          metric={metric}
          key={mode.name}
          onClick={() => onSetMode(mode)}
        >
          <StyledIcon>
            {mode.icon === "bubble_map" ? <BubbleMap /> : <Cloropleth />}
          </StyledIcon>
          {!useHambuger && <StyledLabel>{mode.name}</StyledLabel>}
        </StyledItem>
      ))}
    </StyledMapToggle>
  );
};

MapModeToggle.displayName = "MapModeToggle";
export default observer(MapModeToggle);
