import React, { useRef, useCallback } from "react";
import Flexbox from "../layout/flexbox";
import styled, { css } from "../designSystem";
import DataSet from "../utils/data/DataSet";
import Metric from "./metric";
import theme, { getMetricColor, getRegionColor } from "../designSystem/theme";
import { PageTitle } from "../designSystem/typography";
import ColumnChart from "./ColumnChart/columnChart";

import { Region } from "../utils/regions";
import { media } from "../designSystem/responsive";
import { observer } from "mobx-react-lite";

type TrendCardProps = {
  label: string;
  data: DataSet;
  metric: string;
  region: Region;
  max?: number;
  startDate?: Date;
  endDate?: Date;
};

export const StyledTrendCard = styled(Flexbox)`
  height: 200px;
  ${(media as any).m`
    padding: 10px 10px 0px 10px;
    height: 180px;
    border: 1px solid ${theme.softBorder};
    border-radius: 5px;
  `}
`;

const StyledMetric = styled.span<{ metric?: string; isScrubbing: boolean }>`
  > div > span {
    transition: color 300ms;
  }
  ${(isScrubbing) =>
    !isScrubbing &&
    css`
      > div > span {
        color: ${theme.textPrimary};
      }
    `}
  ${({ theme, metric, isScrubbing }) =>
    metric &&
    isScrubbing &&
    css`
      > div > span {
        color: ${getMetricColor(metric)};
        color: ${getRegionColor(metric as Region)};
      }
    `}
`;

const TrendCard: React.FC<TrendCardProps> = ({
  label,
  data,
  metric,
  max,
  region,
  startDate,
  endDate,
}) => {
  const totals = data.indexedTotals() as any;
  const trend = data.toObjects().map((values) => {
    return {
      x: values.day,
      y: values.Confirmed,
    };
  });

  const [scrubbingValue, setScrubbingValue] = React.useState<string>();
  const handleScrub = useCallback((value: string) => setScrubbingValue(value), [
    setScrubbingValue,
  ]);

  return (
    <Flexbox vertical gap>
      <Flexbox gap between noGrow>
        <PageTitle>{label}</PageTitle>
        <StyledMetric metric={scrubbingValue} isScrubbing={!!scrubbingValue}>
          <Metric
            size="s"
            align="right"
            value={totals.Confirmed}
            label={metric === "Confirmed" ? "Confirmed Cases" : metric}
          />
        </StyledMetric>
      </Flexbox>
      <ColumnChart
        onScrub={handleScrub}
        sharedMax={max}
        data={trend}
        metric={metric}
        region={region}
      />
    </Flexbox>
  );
};

TrendCard.displayName = "TrendCard";
export default observer(TrendCard);
