import React from "react";
import { Layout } from "../layout/sections";
import Flexbox from "../layout/flexbox";
import CountryCard from "./CountryCard/countryCard";
import { GroupedData } from "../utils/data/DataSet";
import styled from "../designSystem";
import { media } from "../designSystem/responsive";
import appTheme from "../designSystem/theme";
import { observer } from "mobx-react-lite";

type CountryCardListProps = {
  last7Days: boolean;
  last24Hours: boolean;
  perMillion: boolean;
  cumulative: boolean;
  countryGroups: GroupedData[];
  selectedMetric: string;
};

const StyledLayout = styled(Layout)`
  flex-wrap: wrap;
  > div {
    flex: 0 0 31.5%;
    height: 230px;
    margin-bottom: 3em;
  }
  margin-top: 55px;
  ${(media as any).xl`
      > div {
        flex: 0 0 48.5%;
        height: 230px;
        margin-bottom: 2em;
      }
  `};
  ${(media as any).m`
    > div {
      border-radius: 3px;
      flex: 0 0 100%;
      height: 230px;
      margin-bottom: 1em;
    }
  `};
`;

const CountryCardList: React.FC<CountryCardListProps> = ({
  countryGroups,
  selectedMetric,
  perMillion,
  cumulative,
  last7Days,
  last24Hours,
}) => {
  return (
    <StyledLayout between>
      {countryGroups?.map((group, index) => (
        <Flexbox key={index}>
          <CountryCard
            data={group.data}
            last7Days={last7Days}
            last24Hours={last24Hours}
            perMillion={perMillion}
            cumulative={cumulative}
            countryCodeIsoAlpha2={group.value}
            useSvg
            selectedMetric={selectedMetric}
          />
        </Flexbox>
      ))}
    </StyledLayout>
  );
};

CountryCardList.displayName = "CountryCardList";
export default observer(CountryCardList);
