import { XAxisDateFormats, IAxis, ITicks } from "../xAxis";

export const axisLabel = {
  enabled: false,
  size: 40,
  padding: 50,
};

export const axisTicks: ITicks = {
  enabled: true,
  label: {
    enabled: true,
    size: 20,
    numberFormat: "1.2k",
    timeFormat: "ddd ha",
  },
  line: {
    enabled: true,
  },
};

export const options: IAxis = {
  enabled: true,
  label: axisLabel,
  ticks: axisTicks,
  padding: {
    start: 0,
    end: 0,
  },
};

export const getBandwidth = (width: number): number => {
  if (width <= 500) {
    return 20;
  } else if (width > 500) {
    return 60;
  }
};

export const numTicksForWidth = (width: number): number => {
  if (width <= 500) {
    return 3;
  } else if (width > 500) {
    return 7;
  }
};

export const getDateFormat = (width: number): XAxisDateFormats => {
  if (width <= 500) {
    return "MMM";
  } else if (width > 500) {
    return "MMM D";
  }
};
