import { css, createGlobalStyle } from ".";

export const gridUnit = 4;
type PxGridMultiplierValues =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 8
  | 10
  | 14
  | 18
  | 24
  | 30
  | 55
  | 95;

const getPx = (multiple: PxGridMultiplierValues): string => {
  if (multiple === 0) {
    return "2px";
  } else {
    return `${gridUnit * multiple}px`;
  }
};

export const getSpacing = (size: SpacingSizes): string => {
  if (typeof size === "boolean" && size) {
    return getPx(4); // 16px
  } else {
    switch (size) {
      case "s":
        return getPx(1); // 4px
      case "m":
        return getPx(2); // 8px
      case "ml":
        return getPx(3); // 12px
      case "l":
        return getPx(4); // 16px
      case "lxl":
        return getPx(5); // 20px
      case "xl":
        return getPx(6); // 24px
      case "xxl":
        return getPx(8); // 32px
      case "xxxl":
        return getPx(10); // 40px
      default:
        return "";
    }
  }
};

export type SpacingSizes =
  | boolean
  | "s"
  | "m"
  | "ml"
  | "l"
  | "lxl"
  | "xl"
  | "xxl"
  | "xxxl";

export const reset = css`
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
`;

export type Sentiment = "positive" | "negative" | "neutral";

export const fullbleed = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const fullbleedStyles: React.CSSProperties = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: "100%",
  width: "100%",
};

export const GlobalStyles = createGlobalStyle`
  html {
    ${reset};
  }
  html, body {
      width: 100%;
      height: 100%;
  }
  body {
    margin: 0;
  font-family: 
    -apple-system /* system font for Safari and Firefox */, 
    Helvetica /* fallback for Chrome */,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  #root {
    min-height: 100%;
  }
`;
