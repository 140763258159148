import React from "react";
import Select from "react-select";
import { ActionMeta, Theme } from "react-select/src/types";
import appTheme from "../designSystem/theme";
import { SelectComponentsProps } from "react-select/src/Select";
import { observer } from "mobx-react-lite";

export type DropDownProps = {
  selected: any;
  placeholder?: string;
  options: any[];
  onChange:
    | (((value: any, actionMeta: ActionMeta) => void) &
        ((value: any, action: ActionMeta) => void))
    | undefined;
  className?: string;
  name?: string;
  styles?: any;
};

function customTheme(selectTheme: Theme, theme: any): Theme {
  return {
    ...selectTheme,
    borderRadius: 4,
    colors: {
      ...selectTheme.colors,
      // primary25: appTheme.textSecondary, // highlight
      // primary50: appTheme.chartBackground, // outlines
      // primary: appTheme.textPrimary, // multi value color
      // neutral0: appTheme.background, // background
      // neutral20: appTheme.textSecondary, // outlines
      // neutral50: appTheme.textPrimary, // placeholder color
      // neutral60: appTheme.textPrimary, // icons
      // neutral80: appTheme.textPrimary, // multi value color
      // neutral90: appTheme.metric1, // multi value color
    },
  };
}

const fontSize = "14px";
const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  singleValue: (provided, state) => {
    return {
      ...provided,
      ...{
        fontSize,
      },
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      ...{
        fontSize,
      },
    };
  },
};

const Dropdown: React.FC<DropDownProps & SelectComponentsProps> = ({
  placeholder,
  selected,
  options,
  onChange,
  className,
  styles = {},
  components,
  isSearchable = false,
  ...props
}) => {
  return (
    <Select
      placeholder={placeholder || "Collection"}
      value={selected}
      onChange={onChange}
      options={options}
      className={className}
      classNamePrefix="dropdown"
      theme={(theme) => customTheme(theme, appTheme)}
      styles={{ ...customStyles, ...styles }}
      components={{ IndicatorSeparator: () => null, ...components }}
      isSearchable={isSearchable}
      {...props}
    />
  );
};

export default observer(Dropdown);
