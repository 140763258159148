import styled, { css } from "../../designSystem";
import theme from "../../designSystem/theme";
import React from "react";
import ColumnPopover from "../columnPopover";
import { Region } from "../../utils/regions";
import { observer } from "mobx-react-lite";
import { ColumnItem } from "./columnChart";

export const tooltipWidth = 225;
export const arrowWidth = 18;
type Placement = "left" | "right" | "top" | "bottom";

export const StyledTip = styled.div<{ placement?: Placement }>`
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: 0px;
  ${({ placement }) => {
    switch (placement) {
      case "bottom":
        return css`
          top: 0;
        `;
      case "top":
        return css`
          bottom: 0;
          transform: rotate(180deg);
        `;
      case "left":
        return css`
          right: 0;
          transform: rotate(90deg);
        `;
      case "right":
        return css`
          left: 0;
          transform: rotate(-90deg);
        `;
    }
  }};

  &:after,
  &:before {
    bottom: 100%;
    left: 0%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: transparent transparent #fff transparent;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-width: 11px;
    margin-left: -11px;
  }

  &:before {
    border-color: transparent transparent ${theme.softBorder} transparent;
  }
`;

// this wrapper was required for mobile to prevent horizontal scrolling jank as you scrubbed
const StyledTooltipWrapper = styled.div`
  height: 100vh;
  right: -${arrowWidth}px;
  top: 0;
  left: -${arrowWidth}px;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  /* outline: 1px solid gold; */
`;
export const StyledTooltip = styled.div`
  position: absolute;
  top: 0;
  left: ${arrowWidth}px;
  padding: 16px;
  border: 1px solid ${theme.softBorder};
  background: ${theme.background};
  border-radius: 4px;
  min-width: ${tooltipWidth}px;
  width: ${tooltipWidth}px;
  z-index: 1000;
  color: ${theme.textPrimary};
  font-size: 16px;
  line-height: 1.1;
`;

export type TooltipData = {
  bar: ColumnItem;
  delta?: number;
};

type ToolTip = {
  open: boolean;
  offset: number;
  x: number;
  y: number;
  index: number;
  data?: TooltipData;
};

export const emptyTip = {
  open: false,
  offset: 0,
  x: 0,
  y: 0,
  index: undefined,
  data: undefined,
};

export const useToolTip = (
  initialValue?: ToolTip
): [ToolTip, (ToolTip) => void] => {
  const [toolTip, setToolTip] = React.useState<ToolTip>(
    initialValue || emptyTip
  );
  return [toolTip, setToolTip];
};

export const getTipOffsets = (
  width: number,
  barX: number,
  arrowWidth: number,
  tooltipWidth: number
): { barXOffset: number; tipOffset: number } => {
  const adjustedWidth = width - tooltipWidth / 2;
  const barXAdjustedLeft = Math.max(barX, tooltipWidth / 2 - arrowWidth);
  const barXAdjustedRight = Math.min(barX, adjustedWidth);
  const barXOffset = barX > width / 2 ? barXAdjustedRight : barXAdjustedLeft;
  const useOffsetRight = barX > adjustedWidth;
  const useOffsetLeft = barX < tooltipWidth / 2;
  const offsetRight = barX - adjustedWidth;
  const offsetLeft = barX - tooltipWidth / 2 + arrowWidth;
  const tipOffset = useOffsetRight
    ? offsetRight
    : useOffsetLeft
    ? offsetLeft
    : 0;

  return {
    barXOffset,
    tipOffset,
  };
};

export const getTipData = (
  data: ColumnItem[],
  bar: ColumnItem,
  barIndex: number
): { bar: ColumnItem; delta: number } => {
  const prevDay = barIndex > 0 ? data[barIndex - 1].y : undefined;
  const curDay = bar.y;
  const delta = barIndex > 0 ? curDay - prevDay : undefined;
  return { bar, delta };
};

type ColumnToolTipProps = {
  toolTip: ToolTip;
  metrics: string[];
  regions: Region[];
};

const ColumnToolTip: React.FC<ColumnToolTipProps> = ({
  toolTip,
  metrics,
  regions,
}) => (
  <StyledTooltipWrapper>
    <StyledTooltip
      style={{
        transform: `translate3d(${
          toolTip.x - tooltipWidth / 2 + arrowWidth / 2
        }px, ${toolTip.y + 15}px, 0)`,
      }}
    >
      <StyledTip
        style={
          toolTip.offset
            ? {
                transform: `translate3d(${toolTip.offset}px, 0px, 0px)`,
              }
            : {}
        }
      />
      <ColumnPopover
        size="s"
        bar={toolTip.data?.bar}
        metrics={metrics}
        regions={regions}
        delta={toolTip.data?.delta}
      />
    </StyledTooltip>
  </StyledTooltipWrapper>
);

export default observer(ColumnToolTip);
