import React, { useState, useCallback, useMemo, CSSProperties } from "react";
import { observer } from "mobx-react-lite";
import ContainedSection from "../layout/sections";
import Flexbox from "../layout/flexbox";
import { Link, Switch, Route, useLocation } from "react-router-dom";
import CountryNavDropdown from "./CountryNavDropdown";
import Button from "./button";
import useBreakpoint from "../hooks/useBreakpoint";
import { DataSets } from "../App";
import Breadcrumb from "./breadcrumb";
import Nav from "./nav";
import { ReactComponent as Logo } from "../assets/images/who_logo.svg";
import { ReactComponent as LogoCollapsed } from "../assets/images/who_logo_collapsed.svg";
import styled, { css } from "../designSystem";
import Popover from "./popover";
import { useScrollPosition } from "../hooks/useScrollPosition";
import { media } from "../designSystem/responsive";
import { Menu } from "@styled-icons/heroicons-outline/Menu";
import { Close } from "@styled-icons/material-rounded/Close";
import { Search } from "@styled-icons/heroicons-solid/Search";
import appTheme from "../designSystem/theme";
import SiteInfoLink from "./siteInfo";
import DonateCta from "./donateCta";
import HeaderBreadcrumb from "./headerBreadcrumbs";
import { getEndianTime } from "../utils/numberUtils";
import ScrollToTopButton from "../components/scrollToTopButton";
import { ExternalLink, TextFour } from "../designSystem/typography";
import { SerbiaKosovoTotals } from "../App";
import theme from "../designSystem/theme";

const links = [
  { title: "Overview", route: "/" },
  { title: "Explorer", route: "/explorer" },
];

const StyledNavSectionBreak = styled.div`
  background: #dedede;
  height: 1px;
  width: 70%;
  margin: 40px 0;
  margin: 20px 0;
`;

const StickyHeader = styled(Flexbox)<{
  isHeaderCollapsed: boolean;
  mobileSearchOpen?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: ${theme.magicNumbers.navHeight};
  z-index: 20;
  background: ${({ theme }) => theme.background};

  ${(media as any).m`
    box-shadow: none;
  `}

  ${({ isHeaderCollapsed }) =>
    isHeaderCollapsed &&
    css`
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    `}
`;

const DisclosureTrigger = styled(Flexbox)`
  a {
    ${TextFour};
    text-decoration: none;
  }
`;

const StyledMobileOverlay = styled(Flexbox)<{ active?: boolean }>`
  position: fixed;
  z-index: 19;
  top: 0;
  top: ${({ active }) => (active ? 0 : "-100vh")};
  will-change: top;
  transition: top 0.3s ease-in-out;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100vh;
  overflow: hidden;
  width: 100%;
  background: #fff;
  padding: 20px;
  padding-top: 90px;
  nav {
    flex: 0 0 auto;
  }
  ul {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  li + li {
    margin-top: 40px;
    margin-top: 20px;
  }

  ${ExternalLink} {
    margin-bottom: 0;
  }

  /* // small phone fixes */
  @media (max-width: ${374 / 16}em) {
    li + li {
      margin-top: 20px;
    }
    ${StyledNavSectionBreak} {
      margin: 20px 0;
    }
  }
`;

const StyledClose = styled(Close)`
  color: ${appTheme.textPrimary};
`;
const StyledSearch = styled(Search)`
  color: ${appTheme.textPrimary};
`;

const StyledMenu = styled(Menu)`
  color: ${appTheme.textPrimary};
`;

const StyledHamburger = styled(Flexbox)`
  align-self: stretch;
  padding: 9px 0 9px 9px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledSearchWrap = styled(Flexbox)<{ mobileSearchOpen?: boolean }>`
  padding: 4px 9px;
  position: absolute;
  right: 0;
  border-radius: 4px;
  ${({ mobileSearchOpen }) =>
    mobileSearchOpen &&
    css`
      background: linear-gradient(
        -90deg,
        rgba(244, 244, 244, 1) 0%,
        rgba(244, 244, 244, 1) 80%,
        rgba(244, 244, 244, 0)
      );
    `}
`;

const StyledLogoWrap = styled(Flexbox)`
  ${(media as any).m`
    margin-right: 8px;
  `}
`;

type SiteHeaderProps = {
  createdTime?: number;
  serbiaKosovo: SerbiaKosovoTotals;
};

const SiteHeader: React.FC<SiteHeaderProps> = ({
  createdTime,
  serbiaKosovo,
}) => {
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);
  const [mobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [mobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const point = useBreakpoint();
  const useHambuger = point === "s" || point === "m";
  const location = useLocation();

  const handleScrollPosition = useCallback(
    ({ prevPos, currPos }) => {
      const isCollapsed = currPos.y < -145;
      if (isCollapsed !== isHeaderCollapsed) {
        setIsHeaderCollapsed(isCollapsed);
      }
    },
    [setIsHeaderCollapsed, isHeaderCollapsed]
  );

  const handleNavClick = useCallback(() => setIsMobileNavOpen(false), [
    setIsMobileNavOpen,
  ]);

  const toggleSearchClick = useCallback(() => {
    return setIsMobileSearchOpen(!mobileSearchOpen);
  }, [setIsMobileSearchOpen, mobileSearchOpen]);

  const toggleMobileNav = useCallback(
    () => setIsMobileNavOpen(!mobileNavOpen),
    [setIsMobileNavOpen, mobileNavOpen]
  );

  useScrollPosition(handleScrollPosition, [
    isHeaderCollapsed,
    setIsHeaderCollapsed,
  ]);

  const nav = <Nav links={links} />;

  const hamburger = (
    <StyledHamburger onClick={toggleMobileNav} style={{ position: "relative" }}>
      {mobileNavOpen ? <StyledClose size="25" /> : <StyledMenu size="25" />}
    </StyledHamburger>
  );

  const showSearch = location.pathname !== "/explorer";

  const search = showSearch && (
    <Flexbox middle style={{ position: "relative" }}>
      <CountryNavDropdown
        isSmall={isHeaderCollapsed}
        isHeaderCollapsed={isHeaderCollapsed}
        useHambuger={useHambuger}
        mobileSearchOpen={mobileSearchOpen}
      />
      {useHambuger && (
        <StyledSearchWrap
          middle
          center
          style={{}}
          onClick={toggleSearchClick}
          mobileSearchOpen={mobileSearchOpen}
        >
          {mobileSearchOpen ? (
            <StyledClose size="24" />
          ) : (
            <StyledSearch size="24" />
          )}
        </StyledSearchWrap>
      )}
    </Flexbox>
  );

  // 4 on mobile; 20 default desktop; 12 when collapsed
  const verticalGapCompute = useHambuger
    ? "s"
    : isHeaderCollapsed
    ? "ml"
    : "lxl";

  const disabledStyles: CSSProperties =
    location.pathname === "/" ? { pointerEvents: "none" } : {};

  return (
    <>
      <StickyHeader center isHeaderCollapsed={isHeaderCollapsed}>
        <ContainedSection
          vertical
          style={{
            paddingTop: "18px",
            paddingBottom: "12px",
          }}
          gap={verticalGapCompute}
        >
          {!isHeaderCollapsed && !useHambuger ? (
            <>
              <Flexbox gap middle between>
                <Flexbox noGrow>
                  <a
                    href="https://www.who.int/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ width: "200px", height: "62px" }}
                  >
                    <Logo />
                  </a>
                </Flexbox>
                {search}
                <Flexbox noGrow gap middle>
                  <SiteInfoLink />
                  <DonateCta fluid />
                </Flexbox>
              </Flexbox>

              <Flexbox gap middle>
                <HeaderBreadcrumb
                  isHeaderCollapsed={isHeaderCollapsed}
                  useHambuger={useHambuger}
                  createdTime={createdTime}
                />
                {nav}
              </Flexbox>
            </>
          ) : (
            <>
              <Flexbox gap={useHambuger ? null : "xl"} middle between>
                <StyledLogoWrap noGrow>
                  {useHambuger ? (
                    !mobileSearchOpen ? (
                      <a
                        href="https://www.who.int/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          width: "138px",
                          height: "48px",
                        }}
                      >
                        <Logo />
                      </a>
                    ) : (
                      <a
                        href="https://www.who.int/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          width: "48px",
                          height: "48px",
                        }}
                      >
                        <LogoCollapsed />
                      </a>
                    )
                  ) : (
                    <a
                      href="https://www.who.int/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        width: "58px",
                        height: "52px",
                      }}
                    >
                      <LogoCollapsed />
                    </a>
                  )}
                </StyledLogoWrap>
                {search}
                <Flexbox noGrow middle>
                  {!useHambuger && isHeaderCollapsed && <SiteInfoLink />}
                  {!useHambuger && nav}
                  {useHambuger && hamburger}
                </Flexbox>
              </Flexbox>
              <Flexbox between>
                <HeaderBreadcrumb
                  isHeaderCollapsed={isHeaderCollapsed}
                  useHambuger={useHambuger}
                  createdTime={createdTime}
                />
                <ScrollToTopButton />
              </Flexbox>
            </>
          )}
        </ContainedSection>
      </StickyHeader>
      {useHambuger && (
        <StyledMobileOverlay gap vertical between active={mobileNavOpen}>
          <Flexbox vertical middle center>
            <Nav
              links={links}
              navClicked={handleNavClick}
              useHambuger={useHambuger}
            />
            <StyledNavSectionBreak />
            <Flexbox vertical noGrow gap="xl" center>
              <ExternalLink href="https://extranet.who.int/publicemergency">
                WHO Health Emergency Dashboard
              </ExternalLink>
              <ExternalLink href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019">
                WHO (COVID-19) Homepage
              </ExternalLink>
            </Flexbox>
            <StyledNavSectionBreak />
            <DonateCta />
          </Flexbox>
          <DisclosureTrigger
            noGrow
            middle
            center
            gap="m"
            onClick={handleNavClick}
          >
            <SiteInfoLink />
            <Link to="/info">Source Disclosure</Link>
          </DisclosureTrigger>
        </StyledMobileOverlay>
      )}
    </>
  );
};

SiteHeader.displayName = "SiteHeader";
export default observer(SiteHeader);
