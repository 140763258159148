import styled from "../designSystem";

type ButtonType = "primary" | "secondary";
type ButtonProps = {
  btnType: ButtonType;
  fluid?: boolean;
};

const Button = styled.button<ButtonProps>`
  height: 38px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0 25px;
  background: ${({ btnType, theme }) =>
    btnType === "primary" ? theme.primary : theme.secondary};
  color: ${({ theme }) => theme.background};
  border: none;
  width: ${({ fluid }) => (fluid ? "100%" : "auto")};
  cursor: pointer;
`;

Button.displayName = "Button";
export default Button;
