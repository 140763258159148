import styled, { keyframes, css } from "styled-components";
import React from "react";
import theme from "./theme";
import Flexbox from "../layout/flexbox";
import { media } from "./responsive";

const StyledHeroLoading = styled(Flexbox)`
  width: 100%;
  height: calc(100vh);
  background: ${theme.mapBackground};
`;

export const HeroLoaded = () => (
  <StyledHeroLoading center middle></StyledHeroLoading>
);

export const HeroLoading = () => (
  <StyledHeroLoading center middle>
    <div
      style={{ transform: "scale(2) translate3d(0, -100%, 0)" }}
      className="loader-inner ball-scale-ripple-multiple"
    >
      <div />
      <div />
      <div />
    </div>
  </StyledHeroLoading>
);

const ballScaleRippleMultiple = keyframes`
    0% {
        opacity: 1;
        -webkit-transform: scale(.1);
                transform: scale(.1);
    }
    70% {
        opacity: .5;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    95% {
        opacity: 0;
    }
`;

export const animations = () => css`
  .ball-scale-ripple-multiple,
  .ball-scale-ripple-multiple > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .ball-scale-ripple-multiple {
    display: block;
    font-size: 0;
    color: #fff;
  }

  .ball-scale-ripple-multiple.la-dark {
    color: #333;
  }

  .ball-scale-ripple-multiple > div {
    display: inline-block;
    float: none;
    background-color: #fff;
    border: 0 solid #fff;
  }

  .ball-scale-ripple-multiple {
    width: 32px;
    height: 32px;
  }

  .ball-scale-ripple-multiple > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    background: transparent;
    border-width: 2px;
    border-radius: 100%;
    opacity: 0;
    animation: ${ballScaleRippleMultiple} 1.25s 0s infinite
      cubic-bezier(0.21, 0.53, 0.56, 0.8);
  }

  .ball-scale-ripple-multiple > div:nth-child(1) {
    animation-delay: 0s;
  }

  .ball-scale-ripple-multiple > div:nth-child(2) {
    animation-delay: 0.25s;
  }

  .ball-scale-ripple-multiple > div:nth-child(3) {
    animation-delay: 0.5s;
  }

  .ball-scale-ripple-multiple.la-sm {
    width: 16px;
    height: 16px;
  }

  .ball-scale-ripple-multiple.la-sm > div {
    width: 16px;
    height: 16px;
    border-width: 1px;
  }

  .ball-scale-ripple-multiple.la-2x {
    width: 64px;
    height: 64px;
  }

  .ball-scale-ripple-multiple.la-2x > div {
    width: 64px;
    height: 64px;
    border-width: 4px;
  }

  .ball-scale-ripple-multiple.la-3x {
    width: 96px;
    height: 96px;
  }

  .ball-scale-ripple-multiple.la-3x > div {
    width: 96px;
    height: 96px;
    border-width: 6px;
  }
`;
