import React, { useCallback } from "react";
import MapMetricHud, { MetricHudData } from "./mapMetricHud";
import SizeLegend, { getSizeLegendItems } from "./sizeLegend";
import StackedSizeLegend from "./stackedSizeLegend";

import { observer } from "mobx-react-lite";
import styled, { css } from "../designSystem";
import Flexbox from "../layout/flexbox";
import { Download } from "@styled-icons/heroicons-solid/Download";
import theme, { shadow } from "../designSystem/theme";
import { media } from "../designSystem/responsive";
import { ReactComponent as Globe } from "../assets/images/globe.svg";
import CsvDownloader from "react-csv-downloader";
import { IndexedTotals, IndexedKeys, DataSets } from "../App";
import DataSet from "../utils/data/DataSet";
import useWindowSize from "../hooks/useWindowSize";
import useBreakpoint from "../hooks/useBreakpoint";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { TextFour } from "../designSystem/typography";
import ChoroplethLegend from "./ChoroplethLegend";
import { scaleLinear, scaleOrdinal, scaleLog } from "@vx/scale";
import { MapMode, modes } from "./mapModeToggle";
import { TimeRangeFilterValues } from "./mapContainer";
export const hubBreakpoint = 680;
const bottomOffset = 120;

const StyledDownloadWrapper = styled(Flexbox)`
  background-color: ${({ theme }) => theme.textSecondary};
  border-radius: 50%;
  padding: 3px;
`;

const StyledDownload = styled(Download)`
  color: #fff;
`;

const StyledDownloadAttributionContainer = styled(Flexbox)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  > span {
    ${TextFour}
  }
`;

export const StyledDownloadButton = styled.a`
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  will-change: background-color;
  transition: background-color 300ms, color 300ms;
  margin: 0;
  padding: 0;
  ${(media as any).m`
    display: none;
  `}
  &:hover {
    ${StyledDownloadWrapper} {
      background-color: ${({ theme }) => theme.primary};
    }
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`;
export const sizeLegendShared = () => css`
  position: absolute;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  background: ${({ theme }) => theme.background};
  ${shadow};
  /* ${(media as any).m`
      top: initial;
      bottom: ${bottomOffset}px;
  `} */
`;
const SizeLegendExpanded = styled(Flexbox)`
  ${sizeLegendShared};
  width: 190px;
  top: 150px;
  z-index: 10;
`;

const SizeLegendCollapsed = styled(Flexbox)<{ collapsed?: boolean }>`
  ${sizeLegendShared};
  top: 150px;
  width: 38px;
  height: 38px;
  padding: 2px;
  > div {
    transform: scale(0.5) translate3d(0, 11px, 0);
    transform-origin: top center;
  }
  cursor: pointer !important;
`;

const StyledChoroplethLegend = styled(Flexbox)`
  position: absolute;
  top: 150px;
  right: 20px;
  width: 190px;
  /* ${(media as any).m`
      top: initial;
      bottom: ${bottomOffset}px;
  `} */
`;

const ZoomReset = styled(Flexbox)`
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 103px;
  right: 20px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  ${shadow};
  &:hover {
    background: #f4f4f4;
  }
  fill: ${({ theme }) => theme.icon};
  /* ${(media as any).m`
    top: initial;
    right: 65px;
    bottom: ${bottomOffset}px;
  `} */
`;

type MapHudProps = {
  totals: IndexedTotals;
  byDayCumulative: DataSet;
  today: IndexedTotals | undefined;
  yesterday: IndexedTotals | undefined;
  primaryMapFilter: IndexedKeys;
  setPrimaryMapFilter: React.Dispatch<React.SetStateAction<IndexedKeys>>;
  selectedTimeRangeFilter: TimeRangeFilterValues;
  setTimeRangeFilter: (selection: TimeRangeFilterValues) => void;
  sizeLegendItems: any[];
  min: number;
  max: number;
  colorStops: string[];
  handleMapReset: () => void;
  dataSets: DataSets;
  showDownload?: boolean;
  noReportedData?: boolean;
  onSetMode: (mode: MapMode) => void;
  mode: MapMode;
  legendExpanded: boolean;
  setLegendExpanded: (isExpanded: boolean) => void;
  legendLabels?: string[];
};

const MapHud: React.FC<MapHudProps> = ({
  totals,
  byDayCumulative,
  today,
  yesterday,
  primaryMapFilter,
  setPrimaryMapFilter,
  sizeLegendItems,
  handleMapReset,
  dataSets,
  showDownload,
  noReportedData,
  min,
  max,
  colorStops,
  onSetMode,
  mode,
  legendLabels,
  setTimeRangeFilter,
  selectedTimeRangeFilter,
  legendExpanded,
  setLegendExpanded,
}) => {
  const point = useBreakpoint();
  const { height } = useWindowSize();
  const sizeLegendRef = React.useRef(null);

  const useHambuger = point === "s" || point === "m";

  const toggleSizeLegend = useCallback(
    () => setLegendExpanded(!legendExpanded),
    [setLegendExpanded, legendExpanded]
  );

  // const handleClose = (
  //   event: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent
  // ) => {
  //   event.stopPropagation();
  //   if (legendExpanded) {
  //     setLegendExpanded(false);
  //   }
  // };

  // useOnClickOutside([sizeLegendRef], (event) => handleClose(event));

  const perMillion = (selectedTimeRangeFilter as any).value === "Per Million";

  return (
    <>
      {totals && (
        <MapMetricHud
          setTimeRangeFilter={setTimeRangeFilter}
          selectedTimeRangeFilter={selectedTimeRangeFilter}
          mode={mode}
          onSetMode={onSetMode}
          data={{ totals, byDayCumulative, today, yesterday }}
          primaryMapFilter={primaryMapFilter}
          setPrimaryFilter={setPrimaryMapFilter}
          noReportedData={noReportedData}
        />
      )}
      {mode.name === "Bubble Map" && (
        <SizeLegendCollapsed vertical middle gap="s" title="Legend">
          <SizeLegend
            metric={primaryMapFilter}
            items={sizeLegendItems}
            decimalFormat={"1,234"}
            isPercentage={false}
            showValues={false}
            onClick={toggleSizeLegend}
          />
        </SizeLegendCollapsed>
      )}

      <ZoomReset title="Full map view" middle center onClick={handleMapReset}>
        <Globe />
      </ZoomReset>

      {legendExpanded && mode.name === "Bubble Map" && (
        <SizeLegendExpanded ref={sizeLegendRef} vertical gap="s">
          <StackedSizeLegend
            metric={primaryMapFilter}
            items={sizeLegendItems}
            decimalFormat={"1,234"}
            isPercentage={false}
            onClose={toggleSizeLegend}
            perMillion={perMillion}
          />
        </SizeLegendExpanded>
      )}

      {mode.name === "Choropleth Map" && (
        <SizeLegendCollapsed vertical middle gap="s" title="Legend">
          <SizeLegend
            metric={primaryMapFilter}
            items={sizeLegendItems}
            decimalFormat={"1,234"}
            isPercentage={false}
            showValues={false}
            onClick={toggleSizeLegend}
          />
        </SizeLegendCollapsed>
      )}

      {legendExpanded && mode.name === "Choropleth Map" && (
        <StyledChoroplethLegend>
          <ChoroplethLegend
            labels={legendLabels}
            metric={primaryMapFilter}
            colorStops={colorStops}
            onClose={toggleSizeLegend}
            perMillion={perMillion}
          />
        </StyledChoroplethLegend>
      )}

      <StyledDownloadAttributionContainer vertical gap="s" right>
        {showDownload && (
          <CsvDownloader
            noHeader={true}
            datas={dataSets.csv}
            filename="WHO-COVID-19-global-data"
            prefix={false}
            suffix={false}
          >
            <StyledDownloadButton>
              <Flexbox middle gap="s">
                <StyledDownloadWrapper noGrow>
                  <StyledDownload size="15" />
                </StyledDownloadWrapper>
                <span>Download Map Data</span>
              </Flexbox>
            </StyledDownloadButton>
          </CsvDownloader>
        )}

        {!useHambuger && <span>Source: World Health Organization</span>}
      </StyledDownloadAttributionContainer>

      {/* hide for now
      <MapScrubber
        start={startDate}
        end={endDate}
        value={sliderValue}
        onScrubberChange={(value: number[]) => setSliderValue(value)}
      /> */}
    </>
  );
};

MapHud.displayName = "MapHud";
export default observer(MapHud);
