import React, { useState, useCallback } from "react";
import Flexbox from "../layout/flexbox";
import styled, { css } from "../designSystem";
import Dropdown from "./dropdown";
import Metric from "./metric";
import MetricCard from "./metricCard";
import TabGroup from "./tabGroup";
import { getMetricCardData } from "../pages/worldTrends";
import { IndexedTotals, IndexedKeys } from "../App";
import DataSet from "../utils/data/DataSet";
import {
  FilterOption,
  primaryFilterOptions,
  timeRangeFilterOptions,
  TimeRangeFilterValues,
} from "./mapContainer";
import { media } from "../designSystem/responsive";
import useBreakpoint from "../hooks/useBreakpoint";
import theme, { shadow } from "../designSystem/theme";
import { observer } from "mobx-react-lite";
import useWindowSize from "../hooks/useWindowSize";
import Attribution from "./attribution";
import MapModeToggle, { MapMode, modes } from "./mapModeToggle";

export const hubBreakpoint = 667;

export type MetricHudData = {
  totals: IndexedTotals;
  byDayCumulative: DataSet;
  today?: IndexedTotals;
  yesterday?: IndexedTotals;
};
type MapMetricHudProps = {
  data: MetricHudData;
  primaryMapFilter: IndexedKeys;
  setPrimaryFilter: (selection: IndexedKeys) => void;
  selectedTimeRangeFilter: TimeRangeFilterValues;
  setTimeRangeFilter: (selection: TimeRangeFilterValues) => void;
  onSetMode: (mode: MapMode) => void;
  mode: MapMode;
  noReportedData?: boolean;
};

const StyledHandleWrapper = styled(Flexbox)`
  position: absolute;
  width: 100%;
  padding: 10px;
  left: 0;
  justify-content: center;
`;

const StyledHandle = styled(Flexbox)`
  background: ${theme.softBorder};
  height: 6px;
  border-radius: 5px;
  width: 80px;
`;
const StyledHud = styled(Flexbox)<{ collapsed: boolean }>`
  padding: 9px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 10;
  background: #fff;
  gap: 20px;
  border-radius: 5px;
  width: 175px;

  ${({ collapsed }) =>
    collapsed &&
    css`
      transform: scale(0.7) translate3d(0, 0, 0);
      transform-origin: bottom left;
    `};

  ${shadow};

  ${(media as any).m`
      bottom: -35px;
      left: 0;
      right: 0;
      width: 100%;
      background: transparent;
      border: none;
      flex-direction: row;
      justify-content: space-evenly;
      padding-top: 0;

      [data-id="metric"] {
        flex-direction: column-reverse;
        font-size: 23px;
      }
      [data-id="metric-label"] {
        font-size: 11px;
      }
      [data-id="metric-card"] {
        flex: 0 0 auto;
        margin: 0;
        margin-top: 40px;
        justify-content: flex-end;
      }
  `}
  ${(media as any).s`
    bottom: -25px;
    [data-id="metric"] {
      font-size: 15px;
    }
    [data-id="metric-label"] {
      font-size: 9px;
    }
`}
`;

const StyledToggles = styled(Flexbox)`
  ${(media as any).m`
    max-width: 245px;
    > * + * {
      margin-top: 0px;
    }
  `}
`;

const StyledDropdownGroup = styled(Flexbox)`
  ${(media as any).m`
    width: 150px;
    .filter-switcher + div {
      font-size: 9px;
      margin-top: 2px
    }
  `}
`;
const StyledControlsGroup = styled(Flexbox)`
.dropdown__control, .dropdown__menu {
  font-size: 12px;
}

${(media as any).m`
    
    position: absolute;
    bottom: 130px;
    padding: 0 15px;
    width: 100%;
    > * + * {
      margin-top: 0px;
    }
    > * + * {
      margin-left: 8px;
    }
  `}
  /* ${(media as any).m`
    position: absolute;
    bottom: 141px;
    left: 20px;
    width: 150px;
    > * + * {
      margin-top: 2px;
    }
    // span {
    //   font-size: 8px;
    // }
  `}
  ${(media as any).s`
    bottom: 145px;
    width: 220px;
     > * + * {
      margin-top: 0;
    }
    > * + * {
      margin-left: 8px;
    }
  `} */
`;

const MapMetricHud: React.FC<MapMetricHudProps> = ({
  data: { totals, byDayCumulative, today, yesterday },
  primaryMapFilter,
  setPrimaryFilter,
  setTimeRangeFilter,
  selectedTimeRangeFilter,
  noReportedData,
  onSetMode,
  mode,
}) => {
  const handleMetricChange: any = useCallback(
    (selection: IndexedKeys) => {
      setPrimaryFilter(selection);
    },
    [setPrimaryFilter]
  );

  const handleFilterChange: any = useCallback(
    (selection: TimeRangeFilterValues) => {
      setTimeRangeFilter(selection);
    },
    [setTimeRangeFilter]
  );

  const selectedPrimaryFilter = primaryFilterOptions.find(
    (option) => option.value === primaryMapFilter
  );
  const { height } = useWindowSize();
  const point = useBreakpoint();
  const useHambuger = point === "s" || point === "m";

  const getSentiment = useCallback((primaryMapFilter) => {
    if (primaryMapFilter === "Confirmed") {
      return "neutral";
    } else if (primaryMapFilter === "Deaths") {
      return "negative";
    }
  }, []);

  return (
    <StyledHud vertical gap collapsed={height < hubBreakpoint && !useHambuger}>
      {useHambuger && (
        <StyledHandleWrapper center>
          <StyledHandle noGrow />
        </StyledHandleWrapper>
      )}
      <StyledControlsGroup
        vertical={!useHambuger}
        gap="s"
        between={useHambuger}
      >
        <StyledToggles vertical={!useHambuger} gap="s">
          <MapModeToggle
            metric={primaryMapFilter}
            onSetMode={onSetMode}
            modes={modes}
            active={mode}
          />

          <TabGroup
            grow
            tabs={primaryFilterOptions}
            isActive={selectedPrimaryFilter.value}
            onSelectTab={handleMetricChange}
          />
        </StyledToggles>

        <StyledDropdownGroup gap="s" vertical={useHambuger}>
          <Dropdown
            className="filter-switcher"
            options={timeRangeFilterOptions}
            selected={selectedTimeRangeFilter}
            onChange={handleFilterChange}
          />

          {useHambuger && <Attribution source="World Health Organization" />}
        </StyledDropdownGroup>
      </StyledControlsGroup>

      {today && !useHambuger && (
        <Metric
          data-id="metric-group"
          label={`New ${primaryMapFilter === "Confirmed" ? "Cases" : "Deaths"}`}
          value={today[primaryMapFilter]}
          size="m"
          align="right"
        />
      )}

      {today && useHambuger && (
        <MetricCard
          size="sm"
          label={`New ${primaryMapFilter === "Confirmed" ? "Cases" : "Deaths"}`}
          total={today[primaryMapFilter]}
          sentiment={getSentiment(primaryMapFilter)}
        />
      )}

      <MetricCard
        total={totals[primaryMapFilter]}
        size="sm"
        label={primaryMapFilter === "Confirmed" ? "Confirmed Cases" : "Deaths"}
        sparkData={getMetricCardData(byDayCumulative, primaryMapFilter as any)}
        sentiment={getSentiment(primaryMapFilter)}
      />

      <>
        {primaryMapFilter === "Confirmed" && (
          <MetricCard
            total={totals.Deaths}
            size="sm"
            label="Deaths"
            sparkData={getMetricCardData(byDayCumulative, "Deaths")}
            sentiment="negative"
          />
        )}
        {primaryMapFilter === "Deaths" && (
          <MetricCard
            total={totals.Confirmed}
            size="sm"
            label="Confirmed Cases"
            sparkData={getMetricCardData(byDayCumulative, "Confirmed")}
            sentiment="neutral"
          />
        )}
      </>
    </StyledHud>
  );
};

MapMetricHud.displayName = "MapMetricHud";
export default observer(MapMetricHud);
