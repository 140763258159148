import React from "react";
const Cloropleth = () => {
  return (
    <svg width="53px" height="27px" viewBox="0 0 53 27" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="_bits-/-map-/-heatmap-/-active"
          transform="translate(-9.000000, -6.000000)"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(9.000000, 6.000000)">
            <g
              id="Africa"
              opacity="0.699999988"
              transform="translate(20.000000, 8.000000)"
            >
              <polygon
                id="Path"
                points="8.1974893 13.0698819 8.04104866 13.2325272 7.77733445 13.2325272 7.77733445 12.9930153 7.98182471 12.8615623 8.1974893 12.8615623"
              ></polygon>
              <polygon
                id="Path"
                points="8.65004971 12.3680566 8.65004971 12.6042264 8.44891175 12.4839135 8.44891175 12.3001021 8.65004971 12.3001021 8.65004971 11.635039 8.13603048 11.635039 6.91355865 12.4516072 6.61296915 12.1073786 6.61296915 12.7835817 5.86987613 12.7835817 6.30120531 13.9610887 7.71699306 13.9610887 8.80649034 12.7044871 8.8556574 12.3647145 8.65004971 12.3680566"
              ></polygon>
              <polygon
                id="Path"
                points="10.6893651 11.7391988 10.8793288 12.1870302 11.334124 12.2070823 12.1085052 9.73064145 11.8324992 9.73064145 10.9329656 10.6619526 10.8424535 11.3080775"
              ></polygon>
              <polygon
                id="Path"
                points="5.6899694 5.65671236 5.55811229 5.2679234 5.82182651 5.2679234 5.49665347 4.57946614 5.49665347 4.96045705 5.02956643 5.92518841 4.62170334 5.94858259 4.56471426 6.34516961 4.74126869 6.34516961 4.73232922 6.75066871 5.85758437 6.75066871 5.90786886 6.60696161 5.90563399 6.60696161 5.56928662 6.13462201"
              ></polygon>
              <polygon
                id="Path"
                points="5.04185819 7.04588096 4.72562462 7.04588096 4.71556773 7.52824664 5.12119595 8.06854076 5.12119595 7.60622724 5.64191977 7.60622724 5.37597069 6.75066871 5.04185819 6.75066871"
              ></polygon>
              <polygon
                id="Path"
                points="5.04185819 7.04588096 5.04185819 6.75066871 4.73344666 6.75066871 4.72562462 7.04588096"
              ></polygon>
              <polygon
                id="Path"
                points="3.98588391 6.02767719 4.05739962 6.34516961 4.56471426 6.34516961 4.62170334 5.94858259 5.02956643 5.92518841 5.49665347 4.96045705 5.49665347 4.57946614 3.74787065 4.57946614 3.46404264 5.95192461"
              ></polygon>
              <polygon
                id="Path"
                points="1.40573085 5.76477119 1.53423566 5.90847828 1.53423566 6.50670085 2.48628867 6.23265476 2.48628867 5.35481606 1.55658432 5.17211866 1.55658432 5.63331818"
              ></polygon>
              <polygon
                id="Path"
                points="8.65004971 12.2960916 8.44891175 12.2960916 8.44891175 12.479903 8.65004971 12.6002159"
              ></polygon>
              <polygon
                id="Path"
                points="1.53423566 6.50670085 1.53423566 5.90847828 1.19006626 5.52191733 0.885007023 5.80821752"
              ></polygon>
              <polygon
                id="Path"
                points="7.26555008 9.29840615 6.93702475 9.13687492 6.93702475 8.53976636 6.56603696 8.53976636 6.49116894 8.80267237 6.12129858 8.81492646 6.02519933 8.48406594 5.43742952 8.48852197 5.43742952 9.79525394 5.10219958 10.3455741 5.13125284 10.7042849 7.20409126 10.7042849 6.86550903 10.2854177 6.86550903 9.81864812 7.27895928 9.81864812"
              ></polygon>
              <polygon
                id="Path"
                points="3.13216501 6.04661534 2.97795924 5.10639216 2.48628867 5.10639216 2.48628867 6.23265476"
              ></polygon>
              <polygon
                id="Path"
                points="5.37597069 6.75066871 5.64191977 7.60622724 5.12119595 7.60622724 5.12119595 8.06854076 5.3256862 8.34035884 5.82182651 8.11421511 6.34366777 7.25085853 6.42635782 6.60696161 5.90786886 6.60696161 5.85758437 6.75066871"
              ></polygon>
              <polygon
                id="Path"
                points="10.2256304 4.37894461 10.5329245 4.86353831 10.650255 4.76550556 9.8714041 3.43203739 9.46912818 4.37894461"
              ></polygon>
              <polygon
                id="Path"
                points="0.886124457 5.80821752 1.19006626 5.52191733 0.878302425 5.17211866 0.575478052 5.47512898"
              ></polygon>
              <polygon
                id="Path"
                points="10.6144971 5.17991672 10.8100479 5.03955165 10.650255 4.76550556 10.4167115 4.96045705"
              ></polygon>
              <polygon
                id="Path"
                points="9.16853867 9.53123393 9.16853867 9.81864812 9.42107855 9.9868634 9.42107855 10.3455741 9.18083044 10.5550077 9.18083044 10.1350265 8.94058232 9.93896104 8.93387772 9.94341707 8.48019988 10.2586815 8.93387772 10.5550077 8.93387772 10.9694189 8.65004971 11.6311399 8.65004971 12.3641575 8.8556574 12.3608155 8.8690666 12.2616688 9.29480861 11.9653425 9.26352049 11.0730217 10.2625057 10.2564535 10.1630541 9.32514235"
              ></polygon>
              <polygon
                id="Path"
                points="10.8100479 5.03955165 10.6144971 5.17991672 10.8134002 5.40271842 11.6056603 5.68233456 10.3686618 6.70945039 10.3686618 7.60288521 12.3074083 5.35481606 12.2280705 4.96045705 10.8804462 5.15986457"
              ></polygon>
              <polygon
                id="Path"
                points="5.86987613 12.7796827 6.61408658 12.7796827 6.61408658 10.8825262 7.60189745 10.8825262 7.47339264 10.7042849 5.13125284 10.7042849"
              ></polygon>
              <polygon
                id="Path"
                points="9.55517053 6.28612716 9.21547086 6.33402953 9.31268754 6.75066871 9.26463792 7.1038094 9.11266702 7.37117144 10.0423714 7.98276211 10.3686618 7.60288521 10.3686618 6.70945039 10.7284753 6.40978211 9.67361844 6.40978211"
              ></polygon>
              <polygon
                id="Path"
                points="1.5018301 2.07963107 0.949818144 2.07963107 0.949818144 2.71127389 0.746445318 3.01874023 0.0648111204 3.01874023 0.040227592 3.72836365 0.878302425 4.32881423 0.878302425 4.11381059 1.88622709 4.11381059 1.79012784 2.40046552 2.32090857 2.40046552 1.5018301 1.79778692"
              ></polygon>
              <polygon
                id="Path"
                points="8.51595773 7.66861171 8.65451944 7.3254971 8.3595171 7.3254971 8.26565272 7.72097011"
              ></polygon>
              <polygon
                id="Path"
                points="8.31258491 8.16991554 8.51595773 7.66861171 8.26565272 7.72097011 8.1974893 8.0072703"
              ></polygon>
              <polygon
                id="Path"
                points="0.252539883 5.12755832 0.575478052 5.47512898 0.878302425 5.17211866 1.19006626 5.52191733 1.40573085 5.76477119 1.55658432 5.63331818 1.55658432 5.17211866 1.48395117 4.84348616 0.524076129 4.84348616"
              ></polygon>
              <polygon
                id="Path"
                points="1.88622709 4.11381059 0.878302425 4.11381059 0.878302425 4.32881423 1.10625878 4.84348616 1.48395117 4.84348616 1.55658432 5.17211866 2.0214365 5.26346736 2.0214365 4.79892582 3.80597717 4.07816232 3.80597717 3.49107984 2.32090857 2.40046552 1.79012784 2.40046552"
              ></polygon>
              <polygon
                id="Path"
                points="8.08909829 9.42428911 8.44891175 9.56799621 8.32375924 9.81864812 7.26555008 9.29840615 7.27895928 9.81864812 6.86550903 9.81864812 6.86550903 10.2854177 7.20409126 10.7042849 7.83767584 10.7042849 8.93387772 9.94341707 8.93387772 9.04218419 8.79643345 8.84946072 8.1974893 8.93969541"
              ></polygon>
              <polygon
                id="Path"
                points="6.61408658 12.1034796 6.91355865 12.4477082 8.13603048 11.6311399 7.60189745 10.8825262 6.61408658 10.8825262"
              ></polygon>
              <polygon
                id="Path"
                points="2.0214365 5.26346736 2.48628867 5.35481606 2.48628867 5.10639216 2.97795924 5.10639216 3.54896756 4.89695856 3.0036602 4.40233879 2.0214365 4.79892582"
              ></polygon>
              <polygon
                id="Path"
                points="8.93387772 9.04218419 8.93387772 9.94341707 8.94058232 9.93896104 9.18083044 10.1350265 9.18083044 10.5550077 9.42107855 10.3455741 9.42107855 9.9868634 9.16853867 9.81864812 9.16853867 9.04218419"
              ></polygon>
              <polygon
                id="Path"
                points="10.5329245 4.86353831 10.2256304 4.37894461 9.46912818 4.37894461 8.93387772 5.63888823 9.67361844 6.40978211 10.7284753 6.40978211 11.6056603 5.68233456 10.8134002 5.40271842 10.4167115 4.96045705"
              ></polygon>
              <polygon
                id="Path"
                points="8.3595171 7.3254971 8.80649034 7.3254971 8.93387772 7.1038094 9.26463792 7.1038094 9.31268754 6.75066871 9.21547086 6.33402953 8.57518169 6.42315021"
              ></polygon>
              <polygon
                id="Path"
                points="3.80597717 3.49107984 3.80597717 4.07816232 3.0036602 4.40233879 3.55008499 4.89695856 3.69311643 4.84460017 3.74787065 4.57946614 5.49665347 4.57946614 5.89110736 3.77292399 5.73578416 2.71127389 4.95805071 2.71127389"
              ></polygon>
              <polygon
                id="Path"
                points="0.0659285535 3.01874023 0.746445318 3.01874023 0.950935577 2.71127389 0.950935577 2.07963107 1.50294753 2.07963107 1.50294753 1.69652354 0.895063921 1.69652354 0.0748680184 2.76140427"
              ></polygon>
              <polygon
                id="Path"
                points="0.0156440635 4.41904892 0.566538587 4.57946614 0.010056898 4.57946614 7.10542736e-15 4.84348616 1.10625878 4.84348616 0.878302425 4.32881423 0.040227592 3.72836365"
              ></polygon>
              <polygon
                id="Path"
                points="0.566538587 4.57946614 0.0156440635 4.41904892 0.010056898 4.57946614"
              ></polygon>
              <polygon
                id="Path"
                points="5.88998993 3.77292399 5.49665347 4.57946614 5.82182651 5.2679234 5.55811229 5.2679234 5.6899694 5.65671236 6.99513127 5.04177967 6.99513127 4.37226056 7.28566388 4.08707439 7.31471714 3.43649342 6.18163997 2.71127389 5.73578416 2.71127389"
              ></polygon>
              <polygon
                id="Path"
                points="5.89334222 1.13495186 5.65532897 0.847314865 5.14466204 0.725108133 4.66975297 1.23432142 4.97816451 2.71149669 6.18163997 2.71149669 7.31471714 3.43671622 7.42534302 0.936881149 6.76605748 0.847760469 6.4934038 1.25448497"
              ></polygon>
              <polygon
                id="Path"
                points="1.40684829 1.69618934 1.50294753 1.69618934 1.50294753 1.61475532 2.87068566 0.870486242 2.87068566 0.336430567 1.862761 0.441147366 0.895063921 1.69663495"
              ></polygon>
              <polygon
                id="Path"
                points="2.87068566 0.337098972 2.87068566 0.871154647 1.50294753 1.61542373 1.50294753 1.79812112 3.80597717 3.49141404 4.95805071 2.71160809 4.97816451 2.71160809 4.41609565 0.0190495454 3.75457525 -7.05213665e-13"
              ></polygon>
              <polygon
                id="Path"
                points="4.41609565 0.018603942 4.66975297 1.23510122 5.14466204 0.725887939 4.95805071 0.681327599 4.98151681 0.0352026686"
              ></polygon>
              <polygon
                id="Path"
                points="7.47339264 10.7041735 8.13603048 11.6310285 8.65004971 11.6310285 8.93387772 10.9693075 8.93387772 10.5548963 8.48019988 10.2585701 7.83767584 10.7041735"
              ></polygon>
              <polygon
                id="Path"
                points="7.77733445 13.2285167 8.04104866 13.2285167 8.1974893 13.0658715 8.1974893 12.8575519 7.98182471 12.8575519 7.77733445 12.9890049"
              ></polygon>
              <polygon
                id="Path"
                points="9.19088733 1.48352512 9.19423963 1.47416745 8.99421911 1.14921117 7.42534302 0.936658347 7.34153554 2.83270081 9.06126509 2.83270081 9.40766936 2.63552131 8.69474703 1.41456799 9.08473119 1.73150341"
              ></polygon>
              <polygon
                id="Path"
                points="8.1974893 8.0071589 8.57518169 6.42303881 8.02428717 6.22251728 6.46993771 6.26596361 6.34366777 7.25074712 5.82182651 8.11410371 5.3256862 8.34024744 5.43742952 8.48841057 6.02519933 8.48395453 6.12129858 8.81481506 6.49116894 8.80256097 6.56603696 8.53965496 6.93702475 8.53965496 6.93702475 9.13676352 8.32375924 9.81853672 8.44891175 9.5678848 8.08909829 9.42417771 8.1974893 8.93958401 8.79643345 8.84934932"
              ></polygon>
              <polygon
                id="Path"
                points="5.56928662 6.13451061 5.90563399 6.60685021 6.42635782 6.60685021 6.46993771 6.26596361 8.02428717 6.22251728 6.99513127 5.04166827 5.6899694 5.65660096"
              ></polygon>
              <polygon
                id="Path"
                points="3.69311643 4.84448876 3.20591559 5.02273012 3.36570853 5.97966343 3.46404264 5.95181321"
              ></polygon>
              <polygon
                id="Path"
                points="3.20591559 5.02273012 2.97795924 5.10628076 3.13216501 6.04650394 3.36570853 5.97966343"
              ></polygon>
              <polygon
                id="Path"
                points="0.252539883 5.12744692 0.524076129 4.84337476 0.00111743311 4.84337476 1.40998324e-14 4.85562885"
              ></polygon>
              <polygon
                id="Path"
                points="9.11266702 7.37117144 9.05456049 7.47254622 8.72044799 7.47254622 8.80649034 7.3254971 8.65451944 7.3254971 8.31258491 8.16991554 8.93387772 9.04218419 9.16853867 9.04218419 9.16853867 9.53123393 10.1630541 9.32514235 10.0423714 7.98276211"
              ></polygon>
              <polygon
                id="Path"
                points="9.40766936 2.63652392 9.06126509 2.83370342 7.3404181 2.83370342 7.28566388 4.08696298 6.99513127 4.37214916 6.99513127 5.04166827 8.02428717 6.22251728 8.57518169 6.42303881 9.55405309 6.28601576 8.93387772 5.63877683 9.46912818 4.37883321 9.8714041 3.43192599"
              ></polygon>
            </g>
            <g id="Americas" opacity="0.699999988">
              <polygon
                id="Path"
                points="10.1637246 7.1551652 10.4453177 6.96366713 10.7850174 6.96366713 10.5045417 7.1551652"
              ></polygon>
              <polygon
                id="Path"
                points="12.7304685 6.13840964 12.370655 6.44944081 11.5370499 6.44944081 11.2789228 6.62567696 11.4778259 6.62567696 11.2007025 6.81416719 11.0029169 6.81416719 11.2789228 6.62567696 10.7347329 6.62567696 10.4989545 6.80837435 10.2587064 6.83232553 10.306756 6.41958538 10.1212621 6.41958538 9.90448012 6.56318108 9.5022042 7.1552766 9.26530838 7.1552766 9.72122109 6.41958538 10.2587064 6.30495391 10.2285357 6.20435894 9.87430942 6.19232765 9.70669446 6.09061867 9.49661703 6.19232765 9.25078175 6.19232765 9.35135073 5.975653 8.84280692 5.74371643 4.50604902 5.74371643 3.0779695 7.24539988 3.0779695 8.39951269 3.59936379 8.91942046 5.42301463 9.15313944 5.66627982 9.52098505 6.17918161 9.50304951 6.60246528 10.3318718 7.39114957 9.3447489 9.26620233 9.35065315 9.3902374 10.2850835 9.68747461 10.2928815 9.69529664 9.12985666 12.730245 6.54312893 12.9369701 6.50748065 13.0543006 6.13907804 12.730245 6.13907804"
              ></polygon>
              <polygon
                id="Path"
                points="14.525066 5.97854942 15.5843926 5.97854942 15.3642583 5.62897355 15.1653552 5.58162819 15.1653552 5.33086488"
              ></polygon>
              <polygon
                id="Path"
                points="6.67174613 11.6332566 6.26310084 10.7554179 6.60235353 10.3309806 6.17918161 9.5019355 5.66627982 9.51987104 5.42301463 9.15213683 3.59936379 8.91841785 3.60282783 8.92198268 3.68048944 9.74055612 4.24144086 10.7988642 4.47129685 10.6217368 4.02890508 9.68140227 4.02890508 9.15213683 4.28982571 9.15213683 4.29999435 9.64441719 5.13806919 11.6073002 6.50468988 12.1754445 6.84573046 12.0852098 7.145873 12.3904481 7.29538555 12.1253141 7.6102782 12.1253141 7.56535739 11.7810855 7.83521748 11.7810855 8.18620322 11.5204075 8.20106508 11.1405306 7.81722681 11.1338466 7.51205583 11.6329224"
              ></polygon>
              <polygon
                id="Path"
                points="5.71075365 2.04231178 5.30289057 1.8747649 3.16747589 1.8867962 2.3037001 2.23369844 2.3037001 2.61647176 1.33198027 2.60455187 1.1476038 2.86110803 1.96768796 2.83169821 0.911937161 3.22650282 0.401828947 3.91551708 0.839974469 3.90827602 0 4.45848482 0.307070619 4.482436 1.91505686 3.71700076 3.77111326 3.65238827 4.33497001 4.21462836 4.54795276 3.98603381 4.36190015 3.79453575 4.15204621 3.79453575 3.94710898 3.56037117 5.71153586 2.04197758"
              ></polygon>
              <polygon
                id="Path"
                points="4.3150797 5.60268295 4.02108305 5.36339392 3.89503659 5.47111855 4.09997383 5.73825778 4.3150797 5.73825778"
              ></polygon>
              <polygon
                id="Path"
                points="15.5788055 5.97309078 15.3586711 5.62351491 15.159768 5.57616955 15.159768 5.32540623 14.5194789 5.97309078"
              ></polygon>
              <polygon
                id="Path"
                points="15.2435755 1.9270119 13.9540577 1.4019797 13.7618592 1.39997448 13.3137685 1.76692888 13.4635046 1.39774647 13.2467226 1.39607545 12.7248813 1.94918067 13.9931679 1.88044635 14.767549 2.57124302 14.2468252 2.56233095 13.9842284 2.77677759 13.3931063 2.77677759 13.3931063 2.94521567 13.9931679 2.94521567 14.5015999 3.36441707 14.6401616 3.30403781 14.5194789 3.03778978 14.8692354 3.20377705 14.9832136 3.15442647 14.9832136 2.6340731 15.3854895 2.77677759 15.6167982 2.62293301 15.4860585 2.37952216 15.2536324 2.29508031"
              ></polygon>
              <polygon
                id="Path"
                points="10.9012305 6.62032971 11.2733357 6.62032971 11.5314627 6.44409357 12.3650678 6.44409357 12.7248813 6.1330624 13.0489369 6.1330624 12.9316064 6.5011308 13.5070845 6.40220685 13.1293921 6.58156222 13.1651499 6.73718921 14.1909535 6.37792146 13.6903435 6.35419308 13.4568 6.14442528 13.6881086 5.78682855 13.5752479 5.67943814 12.8500338 6.00751364 12.8500338 5.8191348 13.5439598 5.48716027 14.6558057 5.4393693 15.4916457 4.99588252 15.4961154 4.79346718 14.8871144 4.68830477 14.8133638 3.6957232 14.016634 4.00831398 14.0222211 3.54132162 13.6378241 3.21536273 13.1159829 3.21536273 11.7046649 5.26291036 11.3627303 5.26402437 11.3694349 4.59060623 10.1536677 3.93278421 11.3694349 3.03567316 12.0588912 2.91023581 11.9147423 2.69768298 12.9763037 2.58104629 13.4087504 2.13254647 13.0031221 2.08776333 12.445523 2.45549754 12.5080993 2.14156994 12.2477374 2.11461093 12.1862785 1.73529104 11.7348356 1.93514417 11.852166 2.17744101 11.2487521 2.4644096 10.1961302 2.2671187 9.63964847 2.52723968 8.28867184 1.98906218 5.70628392 2.04286879 3.94185704 3.56126237 4.14679427 3.79542696 4.35664821 3.79542696 4.54270082 3.98692502 4.32971807 4.21551957 4.11371825 5.20097148 4.50068534 5.55088155 4.50068534 5.73926039 8.83856067 5.73926039 9.34811017 5.97108556 10.2107685 5.78849957 10.3191595 6.16982468 10.4934791 6.29815846 10.7862466 6.2775493"
              ></polygon>
              <polygon
                id="Path"
                points="12.4578148 2.77700039 12.1270546 3.04514224 12.7807529 3.11409936 12.8500338 2.94543847"
              ></polygon>
              <polygon
                id="Path"
                points="11.1817062 2.09489298 11.3035064 2.25163398 11.6119179 2.09489298 11.5202884 1.94940347"
              ></polygon>
              <polygon
                id="Path"
                points="11.4152497 1.56841257 11.6119179 1.76715168 12.0343076 1.62656381 12.1449335 1.33224277"
              ></polygon>
              <polygon
                id="Path"
                points="12.220919 1.45010486 12.2745557 1.6384837 12.8500338 1.48597594 12.7338208 1.33224277"
              ></polygon>
              <polygon
                id="Path"
                points="13.6881086 1.05986769 13.3729925 0.929751494 13.0668158 1.18987248 14.308284 1.28734822 14.6323396 1.05986769"
              ></polygon>
              <polygon
                id="Path"
                points="16.7107652 0.364614982 17.3588764 -9.81437154e-14 14.7854279 0.190272652 14.7854279 0.328743908 15.6536735 0.310808372 15.5318733 0.472339604 14.6323396 0.49016374 14.7496701 0.696589515 14.3619208 0.633759436 14.5150091 0.831050341 13.849019 0.831050341 13.9484705 0.929751494 14.6770369 0.929751494 14.7943674 1.02845265 15.8559289 0.535058283"
              ></polygon>
              <polygon
                id="Path"
                points="13.5618387 0.514337725 14.0412175 0.604683814 14.316106 0.429227475 14.1172029 0.281175745"
              ></polygon>
              <polygon
                id="Path"
                points="8.49874926 1.76759729 9.62512184 1.38560377 9.36587736 1.26584786 8.3009636 1.53621772"
              ></polygon>
              <polygon
                id="Path"
                points="9.77038814 1.4553407 9.16351022 1.66109807 9.52120056 1.81126642 8.96326621 1.9529683 9.65640997 2.22211276 10.1335539 2.01568698 10.6643346 2.14134714 11.0621408 2.05033264 10.6732741 1.87131148 10.9727462 1.48586454 10.6732741 1.48586454 10.4933674 1.71780111 10.4207342 1.48586454 10.1033832 1.60862827"
              ></polygon>
              <polygon
                id="Path"
                points="8.89130352 10.9054748 9.44409768 10.9054748 9.88995349 11.2018011 9.72010365 11.3900685 10.4933674 11.3900685 9.56925018 10.7261194 9.08026146 10.7261194"
              ></polygon>
              <polygon
                id="Path"
                points="9.66534943 11.7309551 9.78156248 11.8301019 10.1145575 11.8301019 10.1145575 11.713131 9.9983445 11.5961601 9.66534943 11.5961601"
              ></polygon>
              <polygon
                id="Path"
                points="11.7158392 11.6396064 11.6476758 11.7521213 11.8342871 11.7521213 11.9024505 11.6396064"
              ></polygon>
              <polygon
                id="Path"
                points="7.60480278 12.1208581 7.28991013 12.1208581 7.14039758 12.3859921 7.58625339 12.6099078 7.88829556 12.0662717 7.82974206 11.7766295 7.55988197 11.7766295"
              ></polygon>
              <polygon
                id="Path"
                points="7.95578852 12.5608914 7.90159301 12.7513869 7.98271865 12.7881492 8.72748782 12.1665324 7.82996555 12.1754445 7.68190566 12.4394645"
              ></polygon>
              <polygon
                id="Path"
                points="7.90159301 12.7513869 7.95578852 12.5608914 7.68190566 12.4394645 7.58625339 12.6099078"
              ></polygon>
              <polygon
                id="Path"
                points="8.1807278 11.5159515 7.82974206 11.7766295 7.88829556 12.0662717"
              ></polygon>
              <polygon
                id="Path"
                points="8.26609969 13.1691401 8.75162438 13.2203845 8.72748782 12.1665324 7.98271865 12.7881492"
              ></polygon>
              <polygon
                id="Path"
                points="9.04830287 16.1034385 9.44555034 16.2928199 10.1540029 15.4261213 9.43214114 15.0150522 9.08964789 15.4450594"
              ></polygon>
              <polygon
                id="Path"
                points="10.5076705 13.0354591 10.315472 13.0343451 9.91431353 13.5746392 9.70982327 13.5880073 9.76792979 13.7840728 9.66065621 13.9355779 9.74334626 14.209624 9.66512595 14.7209539 9.43158243 15.0150522 11.0809137 15.9541613 11.0809137 14.9003093 11.4641933 14.8746871 11.4641933 14.0380667 10.8350784 13.9734542 10.5613073 13.6715579"
              ></polygon>
              <polygon
                id="Path"
                points="13.1269337 25.8242766 13.372769 26.0493064 14.1303887 26.0771566 13.3124276 25.6516053"
              ></polygon>
              <polygon
                id="Path"
                points="14.291299 20.3934852 13.9605388 20.5940067 13.5158004 20.5940067 13.5158004 20.1996477 12.6073273 19.5869431 12.5559254 19.6805198 11.7983058 19.6805198 11.5949329 21.5631941 12.0229098 23.4291584 12.4095417 24.1153876 12.4274206 24.6300595 13.0017812 25.5067842 13.2107412 25.5279504 13.1023502 23.2854513 14.3203523 22.7596393 13.7683403 21.9932014 13.7683403 21.149897 14.3058257 20.5995768"
              ></polygon>
              <polygon
                id="Path"
                points="14.425391 22.2338272 14.6891052 21.7781978 13.7683403 21.149897 13.7683403 21.9932014"
              ></polygon>
              <polygon
                id="Path"
                points="11.860882 13.3340133 11.4530189 13.0443711 10.5076705 13.0354591 10.5613073 13.6715579 10.8350784 13.9734542 11.4641933 14.0380667 11.4641933 14.8746871 11.5647622 14.868003 11.6921496 15.1520752 12.1011301 15.0206222 12.0676071 14.5794748 12.6151494 14.3789533 12.7771772 13.5813232 12.5928007 13.4053099"
              ></polygon>
              <polygon
                id="Path"
                points="8.26498226 13.1691401 8.58825566 13.6047174 8.70491567 13.6459357 8.86873137 13.4342741 8.75397099 13.3707756 8.75050694 13.2203845"
              ></polygon>
              <polygon
                id="Path"
                points="13.347068 14.1227314 12.7760597 13.5813232 12.6140319 14.3789533 12.8520452 14.2920607 12.8196396 14.9727198 13.3660644 14.9114494 13.1794531 14.4346537"
              ></polygon>
              <polygon
                id="Path"
                points="11.9346326 17.4079424 11.3770335 17.5650176 11.3770335 18.8361013 11.7971883 19.6805198 12.554808 19.6805198 12.8498103 19.1413397 13.4208186 19.1379976 13.4074094 18.7124464 12.4352426 17.9203863"
              ></polygon>
              <polygon
                id="Path"
                points="13.8566175 14.857977 14.2231356 14.8167587 14.3315266 14.5104063 14.1873777 14.1951419 13.8812011 14.1695197"
              ></polygon>
              <polygon
                id="Path"
                points="13.4208186 19.1379976 12.8498103 19.1413397 12.6062099 19.5869431 13.514683 20.1996477 13.514683 20.5940067 13.9594214 20.5940067 14.2901816 20.3934852 14.2678329 20.0815628 13.4364627 19.6393015"
              ></polygon>
              <polygon
                id="Path"
                points="13.3660644 14.9114494 13.8566175 14.857977 13.8812011 14.1695197 13.347068 14.1227314 13.1794531 14.4346537"
              ></polygon>
              <polygon
                id="Path"
                points="15.6623894 15.5943366 15.2467043 15.4149812 14.8310192 15.6578351 14.9662286 15.2946683 14.6902226 15.1754694 14.2980036 15.4762517 14.5829491 15.0573845 14.3315266 14.5104063 14.2231356 14.8167587 12.8196396 14.9727198 12.8520452 14.2920607 12.6140319 14.3789533 12.0664897 14.5794748 12.1000127 15.0206222 11.6910322 15.1520752 11.5636448 14.868003 11.0797963 14.9003093 11.0797963 16.1446568 10.5881257 16.2048132 10.3713437 16.9099806 11.1937744 17.616262 11.9346326 17.4079424 12.4352426 17.9203863 13.4074094 18.7124464 13.4364627 19.6393015 14.2678329 20.0815628 14.3047082 20.5995768 13.7672229 21.149897 14.6879878 21.7781978 15.2243557 20.8457727 15.3506256 20.0381165 16.6535526 19.4632881 16.8960356 17.6675064 17.4581045 16.9344888 17.4581045 16.4911134 16.538457 15.8940049 15.6389234 15.8394185"
              ></polygon>
              <polygon
                id="Path"
                points="9.22373987 13.418678 9.0710985 13.545675 8.86861962 13.4342741 8.70480393 13.6459357 9.18675283 13.817493 9.30352459 13.6437077 9.57953057 13.6704439 9.65998575 13.9355779 9.76725933 13.7840728 9.71697484 13.6002614 9.65663345 13.3808017"
              ></polygon>
              <polygon
                id="Path"
                points="10.5881257 16.2048132 11.0797963 16.1446568 11.0797963 15.9541613 10.1523268 15.4261213 9.44387419 16.2928199 9.04662672 16.1034385 9.03388798 16.306188 10.2407157 18.3548497 11.2832808 19.051105 11.3771452 18.8361013 11.3771452 17.5650176 11.1938862 17.616262 10.3714554 16.9099806"
              ></polygon>
              <polygon
                id="Path"
                points="12.0217924 23.4291584 11.5938155 21.5631941 11.7971883 19.6805198 11.3770335 18.8361013 11.2831691 19.051105 11.4586061 23.3121875 11.920106 23.6842663 11.9413372 24.4484761 12.7414193 25.480048 13.0006638 25.5067842 12.4263032 24.6300595 12.4084242 24.1153876"
              ></polygon>
              <polygon
                id="Path"
                points="11.4630758 11.4869872 10.9188859 11.4268308 10.9188859 11.7264991 11.1010275 11.591704 11.3915601 11.591704"
              ></polygon>
              <polygon
                id="Path"
                points="10.9188859 11.6529745 10.9188859 11.4268308 10.5791862 11.4268308 10.6898121 11.582792 10.4920264 11.582792 10.4920264 11.6529745"
              ></polygon>
            </g>
            <g
              id="Top"
              opacity="0.900000036"
              transform="translate(15.000000, 0.000000)"
            >
              <polygon
                id="Path"
                points="5.77377688 3.07833969 6.28109151 2.83225521 6.28109151 2.76296388 6.02966906 2.50874714 5.67767763 2.62527243 5.25528792 2.61636036 5.16589327 2.73778729 4.92564515 2.73221725 4.92564515 2.95724696"
              ></polygon>
              <polygon
                id="Path"
                points="10.2893241 8.42001045 10.2904415 8.18116702 10.0189053 8.17403737 10.0166704 8.35005071"
              ></polygon>
              <polygon
                id="Path"
                points="-1.77635684e-15 0.78236817 -1.77635684e-15 1.03413409 1.26046455 1.05206963 1.58340272 1.60762567 1.58340272 2.1100435 0.978871405 2.45237831 0.996750335 3.13415151 1.37667759 3.51703624 1.8258857 3.51703624 2.51198963 2.79415612 3.48080414 2.50930415 3.8439699 2.23202743 4.57700602 2.18691009 5.08990782 2.03596193 6.05648746 0.504200247 6.65207931 0.382773321 6.86215673 0.172225714 5.14466204 1.58095759e-13 3.66071087 0.172225714 1.86387843 0.172225714 0.828017935 0.531047852"
              ></polygon>
              <polygon
                id="Path"
                points="11.1799183 4.47853697 11.4492196 4.56799185 11.5229702 4.71994261 11.5810768 4.88203085 12.0090536 4.52020089 11.6615319 4.36167748 11.1788008 4.23891374"
              ></polygon>
              <polygon
                id="Path"
                points="10.941905 2.11906697 9.81329758 2.94432447 9.81329758 3.67834467 9.65797437 3.92086432 10.082599 4.40824304 10.7754075 3.79665237 10.4513519 3.39560931 11.1810357 2.60054144 11.3251846 2.60054144 11.1262815 2.11906697"
              ></polygon>
              <polygon
                id="Path"
                points="8.95175665 3.97611914 9.61215962 3.86850592 9.65797437 3.92097572 9.81329758 3.67845607 9.81329758 2.94443587 10.9430225 2.11917837 11.5676676 2.11917837 11.5676676 1.9136438 10.9273784 1.94617285 8.73162233 3.46122441"
              ></polygon>
              <polygon
                id="Path"
                points="11.4447499 4.56877166 11.1754485 4.47931678 11.176566 4.65789234 11.5185005 4.72072242"
              ></polygon>
              <polygon
                id="Path"
                points="10.6625467 5.8844157 10.3843059 6.11178483 10.7888167 6.26161898 11.2201459 6.18742601 11.5620804 5.82893808 11.426871 5.82893808 11.426871 5.74416203"
              ></polygon>
              <polygon
                id="Path"
                points="10.4558216 5.68946421 10.6625467 5.8844157 11.426871 5.74416203 11.426871 5.62618853 10.7340625 5.54230369 10.7050092 5.5309408"
              ></polygon>
              <polygon
                id="Path"
                points="8.99645397 6.24390624 9.24452412 6.26161898 9.46912818 6.20992898 9.38867299 5.96763213 9.17747814 5.96763213 9.04003386 6.03079642"
              ></polygon>
              <polygon
                id="Path"
                points="9.94627212 5.68946421 10.4558216 5.68946421 10.7050092 5.5309408 10.2178084 5.33955414 9.78200945 5.47412637"
              ></polygon>
              <polygon
                id="Path"
                points="6.52245707 8.02442603 6.62302605 8.03200129 6.73923909 7.05880346 6.3615467 6.99586198 6.34255034 7.70749061"
              ></polygon>
              <polygon
                id="Path"
                points="11.5173831 4.72027682 11.1754485 4.65744674 10.8156351 4.66925523 10.7340625 4.59472806 10.2178084 4.72027682 10.1719936 4.71971981 10.2178084 5.33921994 10.7061266 5.5306066 10.7340625 5.54196949 11.426871 5.62585433 11.7151687 5.26747779 11.6648842 5.12800393 11.5754896 4.88236505"
              ></polygon>
              <polygon
                id="Path"
                points="11.5620804 5.82860387 11.2201459 6.18709181 11.5263225 6.69396568 12.2906468 6.60718441 12.5085462 6.66700667 12.5979409 6.39117817 12.4325608 6.32222104 12.1934301 5.82860387"
              ></polygon>
              <polygon
                id="Path"
                points="10.1708762 4.71971981 9.50823834 4.71214455 9.47806764 4.59472806 9.24564156 4.59472806 9.29257375 4.70779992 8.94393462 4.91600811 8.91264649 5.51590169 9.12384135 5.62585433 9.04003386 6.03046221 9.17747814 5.96729793 9.6691487 5.96729793 9.71161116 5.95493244 10.0680723 5.85066124 9.94627212 5.68913001 9.78200945 5.47379217 10.2178084 5.33921994"
              ></polygon>
              <polygon
                id="Path"
                points="9.24564156 4.59472806 9.47806764 4.59472806 9.46577588 4.54504328 9.54176133 4.11926923 9.33280134 4.11637281 9.18418273 4.45035256"
              ></polygon>
              <polygon
                id="Path"
                points="9.86581693 4.44567372 9.73507726 4.31656014 9.61886421 4.3641283 9.58310636 4.5471599 9.79988838 4.54504328"
              ></polygon>
              <polygon
                id="Path"
                points="10.0680723 5.85043844 9.6691487 5.96707513 9.38867299 5.96707513 9.46912818 6.20937198 9.89487019 6.11122783 10.3843059 6.11122783 10.5887962 5.94368095 10.6625467 5.8838587 10.4558216 5.68890721 9.94627212 5.68890721"
              ></polygon>
              <polygon
                id="Path"
                points="10.3116727 7.78769922 9.87922613 7.78769922 9.87922613 7.89542384 10.0412539 8.00604489 10.3116727 8.04782021"
              ></polygon>
              <polygon
                id="Path"
                points="9.17747814 7.18067599 9.17747814 7.62327157 9.38420326 7.62327157 9.38420326 7.24049825"
              ></polygon>
              <polygon
                id="Path"
                points="8.99645397 6.24290363 8.95734381 6.43306489 9.20764883 6.54680515 9.49818144 6.54680515 10.3921279 7.43611814 10.4379427 7.81454683 10.6022054 7.7188535 10.6536073 7.44681262 10.8569801 7.40615131 9.76636539 6.46514833 10.0758944 6.44665579 9.89487019 6.11078223 9.24452412 6.26061637"
              ></polygon>
              <polygon
                id="Path"
                points="9.28139942 6.87577186 9.28139942 7.05824646 9.38420326 7.09690255 9.43001802 6.87577186"
              ></polygon>
              <polygon
                id="Path"
                points="7.21861789 5.75730733 7.21861789 5.88887173 7.58960569 6.08872486 7.63877274 6.74409606 8.18854983 7.09467453 8.35839967 6.87577186 9.16295151 6.88156471 9.1864176 6.54680515 9.20764883 6.54680515 8.95734381 6.43306489 9.12384135 5.62518592 8.39192266 5.24452922 7.53373403 5.75730733"
              ></polygon>
              <polygon
                id="Path"
                points="8.59641292 5.12276809 8.39192266 5.24452922 8.80202061 5.45775044 8.91935109 5.39469756 8.92382082 5.29599641"
              ></polygon>
              <polygon
                id="Path"
                points="8.92382082 5.29599641 8.94393462 4.91533971 8.59641292 5.12276809"
              ></polygon>
              <polygon
                id="Path"
                points="8.80202061 5.45775044 8.91264649 5.51523328 8.91823365 5.39469756"
              ></polygon>
              <polygon
                id="Path"
                points="7.45886601 4.52532533 7.46333574 4.80037403 7.27448955 4.90353121 7.44098708 5.14515966 7.10352228 5.46421169 7.54937809 5.38344608 8.00305594 5.31170393 8.0321092 5.14571666 8.12485615 5.14571666 8.14496994 4.96045705 7.85108503 4.89862958 7.48344954 4.05643915 7.20297383 4.04786129 7.20297383 4.50137415"
              ></polygon>
              <polygon
                id="Path"
                points="7.10352228 4.72406445 7.10352228 4.54437487 6.77499695 4.52220611 6.96384314 4.77341502"
              ></polygon>
              <polygon
                id="Path"
                points="6.44200188 4.76561696 6.44200188 5.25611491 6.63084808 5.2548895 6.63084808 5.08745402 6.99066154 5.13680459 6.96384314 4.77341502 6.77499695 4.52220611"
              ></polygon>
              <polygon
                id="Path"
                points="6.62302605 8.03188989 7.41081639 8.09059813 8.18854983 7.09512014 7.63877274 6.74454166 6.36713386 6.75367653 6.3615467 6.99575058 6.73923909 7.05869206"
              ></polygon>
              <polygon
                id="Path"
                points="8.36845656 7.61302269 8.36845656 7.40236368 8.12485615 7.61302269"
              ></polygon>
              <polygon
                id="Path"
                points="11.2469643 7.36805222 11.7464569 7.20696659 11.6313612 6.93938175 11.2201459 7.01959036"
              ></polygon>
              <polygon
                id="Path"
                points="10.9307307 6.23621958 11.0223602 6.74420746 11.2201459 7.01925616 11.6313612 6.93904755 11.5263225 6.69340867 11.2201459 6.1865348"
              ></polygon>
              <polygon
                id="Path"
                points="10.0758944 6.44676719 10.1217091 6.44409357 10.3563701 6.32166404 10.4625262 6.14008065 10.3843059 6.11089363 9.89487019 6.11089363"
              ></polygon>
              <polygon
                id="Path"
                points="10.6603119 6.89526701 10.7843469 6.92590225 11.0223602 6.74420746 10.9553142 6.37056901 10.4468821 6.43919193"
              ></polygon>
              <polygon
                id="Path"
                points="11.0614704 7.42742887 11.2201459 7.61012627 11.5263225 7.69545932 11.3397112 7.77433112 11.5866639 8.08380268 11.7419871 7.83872081 11.568785 7.46185174 11.9129544 7.29296805 12.1967824 7.29296805 12.1934301 7.06247969"
              ></polygon>
              <polygon
                id="Path"
                points="10.7888167 6.26106197 10.4625262 6.14041485 10.3563701 6.32199824 10.1217091 6.44442777 10.2032817 6.43952613 10.5820916 6.87621747 10.6603119 6.89560121 10.4468821 6.43952613 10.9553142 6.37090321 10.9307307 6.23655379"
              ></polygon>
              <polygon
                id="Path"
                points="10.9329656 6.96288733 11.1117549 6.8680852 11.0223602 6.74454166 10.7843469 6.92623645"
              ></polygon>
              <polygon
                id="Path"
                points="10.9329656 6.96288733 11.0335345 7.39523403 11.0614704 7.42776308 11.2469643 7.36805222 11.2201459 7.01959036 11.1117549 6.8680852"
              ></polygon>
              <polygon
                id="Path"
                points="11.7464569 7.20696659 12.4012727 6.99586198 12.5085462 6.66678387 12.2906468 6.60696161 11.5263225 6.69374287"
              ></polygon>
              <polygon
                id="Path"
                points="13.6013958 8.27752876 13.2002373 8.42558049 13.4874176 8.49732264"
              ></polygon>
              <polygon
                id="Path"
                points="11.6615319 3.97533933 11.535262 4.00508336 11.5676676 4.02925734 11.5319097 4.19078858 11.1776834 4.02925734 11.1788008 4.23891374 11.6615319 4.36167748 12.0090536 4.52020089 12.1431456 4.40590362 12.095096 4.06546262 11.9185416 4.11893503"
              ></polygon>
              <polygon
                id="Path"
                points="12.5476564 4.40590362 12.1431456 4.40590362 11.5810768 4.88203085 11.6704714 5.12766973 12.4761407 5.2557807 12.9789856 5.14638507 12.6795135 5.02495814 12.8594202 4.91054947"
              ></polygon>
              <polygon
                id="Path"
                points="11.4257536 3.78339567 11.3832911 3.89100889 11.535262 4.00508336 11.6615319 3.97533933 11.9185416 4.11893503 12.095096 4.06546262 12.0403418 3.67767626 11.9397728 3.77180998"
              ></polygon>
              <polygon
                id="Path"
                points="12.4761407 5.25611491 11.6704714 5.12800393 11.7207559 5.26747779 11.4324582 5.62585433 11.4324582 5.82860387 12.5219554 5.82860387 12.6269941 6.02712019 12.438148 6.32222104 12.6035281 6.39117817 12.6482254 6.25437792 13.2941017 6.25437792 13.1510703 6.40231825 13.3499734 6.51449891 13.6181573 6.49355555 13.3633826 6.16915627 14.0338424 6.02712019 14.2048097 5.64434687 12.9789856 5.14671927"
              ></polygon>
              <polygon
                id="Path"
                points="11.5676676 2.11906697 11.1273989 2.11906697 11.3251846 2.60054144 11.4413976 2.60054144 11.0905236 3.39293569 11.5006216 3.59925006 12.1252667 3.59925006 12.4761407 3.19553338 11.893958 1.89659947 11.5676676 1.9130868"
              ></polygon>
              <polygon
                id="Path"
                points="27.546961 1.88066915 27.3402359 1.60071881 26.0618924 1.39239922 25.702079 1.71535029 24.6673359 1.71535029 24.2360067 1.39239922 21.8961018 1.39239922 21.7519529 0.878172901 19.5919547 0.878172901 17.6878487 1.72727018 18.1046513 2.2417193 17.7448378 2.61246133 17.4196648 1.55292785 16.8453041 1.5075877 17.1213101 2.13399468 14.9009705 2.19381694 12.8258972 3.04302562 12.5521261 2.52300645 13.3410339 2.55263908 13.7254309 2.36125242 12.2135439 1.88066915 11.8917232 1.89704507 12.4739058 3.19597899 12.1230318 3.59969567 12.0381069 3.67879027 12.0928611 4.06657663 12.1409107 4.40701763 12.5454215 4.40701763 12.8571854 4.91166348 12.6772786 5.02607215 12.9767507 5.14749908 14.2025748 5.64512667 14.0316076 6.02789999 14.1120628 6.21917525 13.9142771 6.27298186 13.9489175 6.58757786 14.6651921 6.83767277 14.9121449 6.78442317 15.5211459 7.05969467 15.7848601 7.12475276 15.6205975 6.83767277 15.6205975 6.25515773 15.3568832 5.92630242 15.3568832 5.27884068 17.3280352 5.27884068 17.3280352 4.77775966 18.4398812 4.52788755 21.2602824 5.51946651 21.7441309 5.80131067 22.3397227 5.34902321 23.13757 5.59120866 23.3532346 5.10683777 25.3154471 5.59120866 26.2753222 5.59120866 26.6351356 5.51946651 26.4429371 4.84359756 27.2832468 4.84359756 28.1470226 5.68701339 29.3225623 5.98601327 29.3225623 6.93102669 29.8376989 6.840792 30.1148223 6.42860885 30.1148223 5.66306221 29.3225623 4.76595117 28.5548857 4.75403127 28.6867428 3.80912926 30.2701455 3.82104916 30.4422302 3.28476546 31.1104552 3.53686559 31.1126901 3.2896671 31.2780702 3.30671143 31.5663679 3.58176013 31.2881271 3.77058457 31.2546041 4.33527548 32.3820941 5.3999334 32.3820941 4.37125795 31.7462747 3.7611155 33.2525745 3.28565667 33.2715709 3.1323691 32.5139512 2.92683453 32.8089536 2.69768298 33.486118 2.83715685 33.945383 2.84618032 33.5766301 2.45170991 31.2121416 1.98482894"
              ></polygon>
              <polygon
                id="Path"
                points="12.4325608 6.32222104 12.621407 6.02712019 12.5163683 5.82860387 12.1934301 5.82860387"
              ></polygon>
              <polygon
                id="Path"
                points="12.1967824 7.29296805 12.5398344 7.29296805 12.4012727 6.99552778 12.1934301 7.06247969"
              ></polygon>
            </g>
            <g
              id="Asia"
              opacity="0.5"
              transform="translate(28.000000, 5.000000)"
            >
              <polygon
                id="Path"
                points="17.0341503 5.95326142 16.8877666 5.80064226 16.6475185 6.14152886 16.8877666 6.36544457"
              ></polygon>
              <polygon
                id="Path"
                points="14.7277684 1.72782718 14.775818 1.45868273 14.2718557 1.37791711 14.0796572 1.06387812 13.1197822 1.06387812 11.1575696 0.579507222 10.941905 1.06387812 10.1440578 0.82169267 9.54846593 1.27398012 11.4883298 2.40358474 13.7321355 2.42753592 14.0025543 1.87899814"
              ></polygon>
              <polygon
                id="Path"
                points="15.1535104 6.85003827 14.8652127 6.91353675 14.8652127 7.08286604 14.9825431 7.22434512 15.1535104 7.0115695"
              ></polygon>
              <polygon
                id="Path"
                points="14.0796572 1.06387812 14.2718557 1.37791711 14.775818 1.45868273 14.7277684 1.72782718 14.0036717 1.87899814 13.7321355 2.42753592 11.4883298 2.40358474 9.54846593 1.27398012 8.70927366 1.91174999 8.8489528 2.55910033 8.0086431 2.8702429 8.24889122 3.45621137 9.14283771 3.86282447 9.17859557 4.58058015 11.5185005 5.38734511 11.9498297 4.9932089 12.8683597 5.38734511 12.7420898 5.72823171 13.2281732 6.53588787 14.0796572 6.10588059 14.6361389 6.58044821 14.8719173 6.44676719 14.8808567 6.67513893 16.2754133 6.17829114 16.731326 5.16097858 15.9178347 3.8263964 16.3357546 3.37199233 15.8552584 3.37199233 15.7010526 3.47147329 15.3993457 2.97741052 15.7837427 2.64242816 16.0474569 3.14417759 16.4173273 2.89051786 17.1268973 2.40325054 17.1268973 1.45823713 15.9513577 1.15923725 15.0875819 0.31582141 14.2483896 0.31582141 14.4394707 0.991690367"
              ></polygon>
              <polygon
                id="Path"
                points="18.9281995 2.48535296 19.2567248 2.48089693 19.3964039 2.24316752 18.6667201 1.93815199 18.6812467 2.53915957 18.8253956 2.62438122"
              ></polygon>
              <polygon
                id="Path"
                points="18.0208438 3.87452156 17.967207 4.05933557 18.6901862 3.95294776 18.7460579 4.23122709 18.9103205 4.16171295 18.9281995 3.94269888 19.4131654 3.81848694 19.3874645 3.18650991 19.1673301 2.80340239 18.9483133 2.74012671 19.0734658 3.31428669 18.6253751 3.71945158"
              ></polygon>
              <polygon
                id="Path"
                points="17.8934564 4.23122709 18.1549357 4.19836383 18.2398607 4.32402399 18.1459963 4.62001605"
              ></polygon>
              <polygon
                id="Path"
                points="18.5907347 4.10868615 18.4644647 4.3373921 18.2979672 4.14901326"
              ></polygon>
              <polygon
                id="Path"
                points="17.799592 8.01217193 17.4029033 7.97095362 17.2587544 7.79159825 17.4029033 7.46185174 17.2598718 7.14547332 16.9983925 7.13990328 16.9346988 7.69690753 17.3045691 8.14139692 17.7381332 8.19598334"
              ></polygon>
              <polygon
                id="Path"
                points="16.5503018 9.00029747 16.7257388 9.03483174 17.0375026 8.77638177 17.030798 8.56472015"
              ></polygon>
              <polygon
                id="Path"
                points="17.9191574 9.40022653 17.9191574 9.64865042 18.1795193 9.57066983 18.0912421 9.4102526 18.3013195 9.35678019 18.1225302 8.80088995 17.9906731 9.14400457 17.5593439 9.14400457"
              ></polygon>
              <polygon
                id="Path"
                points="17.1514808 8.16033507 17.3369747 8.34191845 17.2766333 8.4533193 17.1268973 8.30738419"
              ></polygon>
              <polygon
                id="Path"
                points="17.3369747 8.60482446 17.4688318 8.69394514 17.6129807 8.47671348 17.4207822 8.4533193"
              ></polygon>
              <polygon
                id="Path"
                points="17.8286453 8.37756672 18.0096694 8.6103945 18.2074551 8.6103945 18.0186089 8.30738419"
              ></polygon>
              <polygon
                id="Path"
                points="17.8286453 8.57363222 17.9303317 8.75075957 17.8487591 8.85881839 17.7057276 8.64381475"
              ></polygon>
              <polygon
                id="Path"
                points="17.6129807 8.75298759 17.7057276 8.89223865 17.6129807 9.0036395 17.5068245 8.87441451"
              ></polygon>
              <polygon
                id="Path"
                points="17.9649721 12.4024794 18.0867723 12.5695807 18.4287069 12.5283624 18.4287069 12.4024794"
              ></polygon>
              <polygon
                id="Path"
                points="15.2172041 10.4373684 15.2339656 10.9186201 15.5088541 11.3976438 16.3826868 11.3976438 16.8151334 10.8473236 16.8151334 9.84917195 16.5033696 9.8814782 16.2508297 10.3571598 15.4049329 10.5454273 15.2227913 10.4351404"
              ></polygon>
              <polygon
                id="Path"
                points="14.5959113 11.69397 14.5959113 11.3263472 13.15554 9.83691786 12.662752 9.64865042 14.3310796 11.9134297"
              ></polygon>
              <polygon
                id="Path"
                points="16.2787656 12.349007 16.3055839 12.473776 14.5053992 12.1696517 14.775818 11.953534 15.2250261 12.1239773 15.6306543 12.0348566"
              ></polygon>
              <polygon
                id="Path"
                points="17.4297217 12.349007 17.1514808 12.349007 17.2051176 12.5283624 17.4710667 12.5283624 17.58281 12.4381277"
              ></polygon>
              <polygon
                id="Path"
                points="16.9436383 12.6397632 17.0252109 12.73891 17.2185268 12.73891 17.1514808 12.5952029"
              ></polygon>
              <polygon
                id="Path"
                points="17.9649721 12.4024794 17.6867313 12.7121738 17.8979261 12.73891 18.0867723 12.5695807"
              ></polygon>
              <polygon
                id="Path"
                points="17.0386201 11.8555013 17.2453452 11.7920028 17.1827689 11.428836 17.3045691 11.294041 17.407373 11.563631 17.6554431 11.6494097 17.6554431 11.4555722 17.515764 11.1481059 17.7805957 10.9264182 17.3582059 10.9442423 17.2710462 10.8239294 17.4386611 10.640118 17.7493075 10.7470628 17.9023959 10.7336947 18.1314697 10.4641046 17.9303317 10.4596486 17.799592 10.5454273 17.3671454 10.4407105 17.0810825 10.6980464 17.1011963 11.1057735 16.9346988 11.3263472"
              ></polygon>
              <polygon
                id="Path"
                points="18.5907347 11.2773308 18.542685 11.4455461 19.0108895 11.3976438 19.0108895 11.2773308"
              ></polygon>
              <polygon
                id="Path"
                points="18.6991257 10.1911726 18.542685 10.3449057 18.4834611 10.7281247 18.6991257 10.7804831 18.651076 10.4407105 18.7762286 10.2847493"
              ></polygon>
              <polygon
                id="Path"
                points="19.7595697 11.2104903 19.6969934 10.8785158 19.1840916 10.9509264"
              ></polygon>
              <polygon
                id="Path"
                points="19.7249293 11.4867644 20.4400865 11.7842047 20.5685913 12.1484855 20.330578 12.2933066 20.9429314 12.5049682 20.9429314 11.302953 20.4456736 11.0445031 19.9819389 11.4199239 19.4913857 11.2272005 19.4366315 11.4967905"
              ></polygon>
              <polygon
                id="Path"
                points="17.5951018 3.55725194 17.1123707 3.16344994 16.8989409 3.35996104 17.1872387 3.98202338 17.5358778 3.9580722"
              ></polygon>
              <polygon
                id="Path"
                points="17.1123707 3.16344994 16.8989409 2.98921901 17.1749469 2.95334793 17.1268973 2.40313914 16.4173273 2.89040645 16.6251698 3.28365145 16.8989409 3.35996104"
              ></polygon>
              <polygon
                id="Path"
                points="16.4944301 9.51107037 16.1100332 10.0268563 15.2227913 10.4356974 15.4049329 10.5459843 16.2508297 10.3577168 16.5033696 9.8820352 16.8151334 9.84972896 16.8151334 9.48433417"
              ></polygon>
              <polygon
                id="Path"
                points="13.5533462 9.51107037 13.707552 10.0101462 14.342254 10.5493263 14.391421 10.3577168 13.7924769 9.4754221"
              ></polygon>
              <polygon
                id="Path"
                points="21.1653005 12.5835058 21.5910426 12.1936028 22.1430545 12.7305549 22.6470168 12.8330437 22.0234892 12.211427 22.0536599 11.8827945 20.9429314 11.30351 20.9429314 12.5055252"
              ></polygon>
              <polygon
                id="Path"
                points="22.3341356 11.9396089 22.5587396 12.0732899 22.9464889 11.8560583 22.9464889 11.6310285"
              ></polygon>
              <polygon
                id="Path"
                points="22.6470168 11.3079661 23.0995773 11.6310285 23.2806014 11.5697581 22.8213364 11.296826"
              ></polygon>
              <polygon
                id="Path"
                points="13.2281732 6.53555367 12.7420898 5.72789751 12.8672423 5.3870109 12.5856491 5.26669799 12.2079567 5.53740205 12.0302849 6.76169739 12.3107606 6.99563918 12.4180342 7.55487145 12.6158198 7.6139139 12.7577338 7.43678654 13.021448 7.73311281 13.1678318 8.27452094 13.1331913 8.8337532 13.3600303 8.39037782 12.9521672 6.92434263 13.2281732 6.72493511"
              ></polygon>
              <polygon
                id="Path"
                points="14.0438993 7.22401092 14.3701898 7.6662723 14.0651306 7.97151062 14.7489996 7.97151062 14.7489996 7.7977253 14.0830095 7.09144391 13.9355083 6.63692844 13.472891 6.41189872 13.2281732 6.53555367 13.2281732 6.72493511 13.4673038 6.86418618 13.4673038 7.22401092"
              ></polygon>
              <polygon
                id="Path"
                points="13.4673038 7.22401092 13.4673038 6.86418618 13.2281732 6.72493511 12.9521672 6.92434263 13.3600303 8.39037782 13.1331913 8.8337532 13.1197822 9.05432689 13.5153535 9.38852944 13.5533462 9.51107037 13.7924769 9.4754221 13.4080799 8.91061979 13.4974745 8.03723713 13.9299212 8.46390238 13.9299212 8.10630565 14.3701898 7.6662723 14.0438993 7.22401092"
              ></polygon>
              <polygon
                id="Path"
                points="13.9299212 8.10630565 13.9299212 8.46390238 14.1455857 8.67779201 14.3880687 8.64437176 14.7489996 8.3747817 14.7489996 7.97151062 14.0651306 7.97151062"
              ></polygon>
              <polygon
                id="Path"
                points="14.0830095 7.09144391 14.7489996 7.7977253 14.7489996 8.3747817 14.3880687 8.64437176 14.3970082 9.0298187 15.0875819 8.57530323 15.0641158 7.89910007 14.3433714 7.14046028 14.3791293 6.72493511 14.6361389 6.58011401 14.0796572 6.10554639 13.472891 6.41189872 13.9355083 6.63692844"
              ></polygon>
              <polygon
                id="Path"
                points="11.2033844 5.62095269 11.050296 5.69224923 11.2670781 6.37513644 11.4067572 6.24034142 12.0302849 6.76169739 12.0705125 6.48319527 11.7431046 6.17795694 11.6894678 5.81256215 11.4279884 5.91950697"
              ></polygon>
              <polygon
                id="Path"
                points="11.4279884 5.57416433 11.050296 5.48058762 11.1028154 5.24441782 10.9944244 5.20698713 10.7631157 5.57416433 9.49259427 5.09447227 9.49594657 4.68986438 9.17859557 4.58024595 9.14283771 3.86249027 8.8657143 3.73660731 8.60982212 3.73660731 8.22989486 4.00575176 8.21983796 4.23490331 8.45002918 4.36490811 8.55953763 4.81285092 8.06004503 5.27115402 7.74045916 5.27115402 7.74045916 5.6098126 7.97958984 5.70004729 7.97958984 5.99191752 7.54826066 6.1066604 7.58513595 6.15010673 7.94159712 6.26262159 7.77509958 6.43306489 7.93265765 6.63692844 8.21536823 6.6703487 8.26565272 6.43640691 8.37739603 6.43752092 9.28586915 9.04207279 9.86246463 8.6889321 9.95185928 7.64176411 11.2670781 6.37513644 11.050296 5.69224923 11.2033844 5.62095269 11.4279884 5.91950697 11.6894678 5.81256215 11.7431046 6.17795694 12.0705125 6.48319527 12.2079567 5.53740205 12.5856491 5.26669799 11.9498297 4.9928747 11.5185005 5.3870109"
              ></polygon>
              <polygon
                id="Path"
                points="9.49594657 4.68986438 9.49259427 5.09447227 10.7631157 5.57416433 10.9944244 5.20698713"
              ></polygon>
              <polygon
                id="Path"
                points="11.1028154 5.24441782 11.050296 5.48058762 11.4279884 5.57416433 11.5185005 5.3870109"
              ></polygon>
              <polygon
                id="Path"
                points="3.90542872 2.21765672 4.1747301 2.44068122 4.51778207 2.3462133 4.82172387 2.59909323 4.76920452 1.94249662 5.23740899 1.8348834 5.85423207 2.25653562 6.37048616 2.15783446 6.74817855 2.65423665 7.11246175 2.63819493 7.46892291 2.3462133 8.31035004 2.30978522 8.8489528 2.55854332 8.70927366 1.91119298 9.54846593 1.27364592 9.06461739 0.991578966 6.24421622 -1.77635684e-14 5.13237028 0.249872107 5.13237028 0.75095313 3.16121827 0.75095313 3.16121827 1.39841487 3.42493248 1.72727018 3.9892362 1.50001245 4.32558357 1.72727018 3.91772049 1.92456108"
              ></polygon>
              <polygon
                id="Path"
                points="6.06430949 3.76390052 5.87546329 3.45899639 5.13795744 3.22572301 4.70215853 3.37054412 4.6943365 3.56404739 4.13897224 3.56371319 3.83279557 3.02509008 3.10087688 3.09716643 3.10087688 3.31250428 3.9892362 4.81530174 4.25295042 4.84047833 4.86530376 5.47735699 5.99279377 5.81267355 6.30567504 5.81267355 6.53251396 5.42388459 6.28109151 5.10962279 5.92127805 4.06902745"
              ></polygon>
              <polygon
                id="Path"
                points="4.82172387 2.59976164 4.51778207 2.34688171 4.1747301 2.44134963 4.72227232 2.89341428 4.70215853 3.37087832 5.13795744 3.22605722 5.87658073 3.4593306 6.06542692 3.76423472 6.76717492 3.40541258 5.61063165 2.48267934 5.12007851 2.48267934"
              ></polygon>
              <polygon
                id="Path"
                points="7.74045916 5.27115402 8.06004503 5.27115402 8.55953763 4.81285092 8.45002918 4.36490811 8.21983796 4.23490331 8.22989486 4.00575176 8.60982212 3.73660731 8.8657143 3.73660731 8.3326987 3.49386486 7.76504268 3.57552168 7.58513595 4.35287681 6.92920272 4.95744923 6.22521986 4.95043097 6.28109151 5.10951139 6.53251396 5.42377318 6.30567504 5.81256215 7.28901618 5.81256215 7.54826066 6.1066604 7.97958984 5.99191752 7.97958984 5.70004729 7.74045916 5.6098126"
              ></polygon>
              <polygon
                id="Path"
                points="5.85423207 2.25664702 5.23740899 1.8349948 4.76920452 1.94260802 4.82172387 2.59920463 5.12007851 2.48212234 5.61063165 2.48212234 6.76717492 3.40485558 7.14598474 3.50589615 6.97390004 3.0012503 7.51138537 2.84751713 7.54155606 2.61936819 6.74817855 2.65434805 6.37048616 2.15794587"
              ></polygon>
              <polygon
                id="Path"
                points="6.06430949 3.76390052 5.92127805 4.06902745 6.22521986 4.95054237 6.92920272 4.95756063 7.58513595 4.35298821 7.76504268 3.57563308 7.64882964 3.24811458 7.14598474 3.50611895 6.76717492 3.40507838"
              ></polygon>
              <polygon
                id="Path"
                points="7.60078002 3.09716643 7.14933704 2.95111992 6.97390004 3.0014731 7.14598474 3.50611895 7.64882964 3.24811458 7.76504268 3.57563308 8.3326987 3.49397626 8.24889122 3.45598857 8.08351112 3.05149208"
              ></polygon>
              <polygon
                id="Path"
                points="7.46892291 2.3465475 7.11246175 2.63852913 7.54155606 2.61959099 7.51138537 2.84773993 7.14933704 2.95111992 7.60078002 3.09716643 8.08351112 3.05149208 8.0086431 2.8700201 8.8489528 2.55887752 8.31035004 2.31011943"
              ></polygon>
              <polygon
                id="Path"
                points="3.16121827 2.76675151 3.48303901 3.05951294 3.83279557 3.02509008 3.58919515 2.59719942 3.32548094 2.53214132"
              ></polygon>
              <polygon
                id="Path"
                points="3.10087688 3.09716643 3.48303901 3.05951294 3.16121827 2.76675151 2.94667111 2.76675151"
              ></polygon>
              <polygon
                id="Path"
                points="18.2990846 1.20190377 18.5147492 1.20190377 17.7537773 0.424437239 17.5381127 0.418310192 18.1493486 1.17193694 18.4488207 1.72816139 18.6711898 1.72816139"
              ></polygon>
              <polygon
                id="Path"
                points="0.372105226 2.90544557 -5.32907052e-15 2.90243775 0.306176672 3.51581083 1.71302496 3.59323442 3.09528972 3.31217007 3.09528972 3.09683223 2.94108395 2.76641731 2.46394001 2.64532458 0.537485326 2.70815466"
              ></polygon>
              <polygon
                id="Path"
                points="4.43509202 5.81523577 4.58706292 5.91549654 4.68651447 5.81300775 4.5121949 5.56792588"
              ></polygon>
              <polygon
                id="Path"
                points="4.38592496 5.78326373 3.9892362 5.1371388 3.81603407 5.1371388 3.691999 4.97683297 3.35341676 4.97571897 2.28179841 4.4172665 2.10524398 4.49123667 2.03708056 4.89495335 1.81135907 4.99967015 1.66162304 4.98173461 3.05953186 7.40358909 3.08746768 7.49382378 3.23273399 7.24539988 3.72887429 7.30778436 3.68305953 7.4960518 4.38592496 7.12842899 5.37485326 6.86440898 5.37485326 6.35085106 4.8597166 6.27844051 4.70327596 5.99214032"
              ></polygon>
              <polygon
                id="Path"
                points="3.53555836 4.97627597 3.69311643 4.97683297 3.81603407 5.1371388 3.9892362 5.1371388 3.77915878 4.7962522 3.58137312 4.7962522"
              ></polygon>
              <polygon
                id="Path"
                points="2.53210343 4.02480131 2.23933595 4.19903224 2.29297274 4.42305935 3.35341676 4.97571897 3.53555836 4.97627597 3.58137312 4.7962522 3.97135727 4.79391278 3.09528972 3.31339548 2.6930138 3.39527511"
              ></polygon>
              <polygon
                id="Path"
                points="5.07873349 6.06566488 4.70327596 5.99214032 4.8597166 6.27844051 5.15024921 6.31965882 5.56369946 5.90079162 5.37485326 5.73369035"
              ></polygon>
              <polygon
                id="Path"
                points="5.15024921 6.31965882 5.37485326 6.35085106 5.37485326 6.86440898 4.87759553 6.99697599 4.87759553 7.29553027 5.15471894 7.48268369 6.07101409 6.35085106 5.56369946 5.90079162 5.15024921 6.31965882"
              ></polygon>
              <polygon
                id="Path"
                points="1.82476827 3.96130283 1.66162304 4.26219652 1.87840506 4.41281047 2.53210343 4.0241329 2.6930138 3.3943839 1.71302496 3.59356862 1.6470964 3.89992096"
              ></polygon>
              <polygon
                id="Path"
                points="4.38592496 7.12842899 3.68305953 7.4960518 3.72887429 7.30778436 3.23273399 7.24539988 3.08746768 7.49382378 3.33553783 8.30036593 5.15471894 7.48268369 4.87759553 7.29553027 4.87759553 6.99697599"
              ></polygon>
              <polygon
                id="Path"
                points="1.66162304 4.26208512 1.82476827 3.96119142 1.6470964 3.89980956 1.58228528 4.20593909"
              ></polygon>
              <polygon
                id="Path"
                points="1.6571533 4.99153789 1.66162304 4.98095481 1.66162304 4.26208512 1.58228528 4.20593909 1.45713278 4.66658161"
              ></polygon>
              <polygon
                id="Path"
                points="23.6236534 18.6141908 23.5119101 18.9706736 24.3276362 18.3913891 24.0929753 18.0794668 24.0929753 17.722984 23.9588833 17.7786845 23.9588833 18.2799883"
              ></polygon>
              <polygon
                id="Path"
                points="21.4781818 19.7838998 23.1766801 18.9261132 23.1766801 19.1489149 21.8692834 20.0067015"
              ></polygon>
              <polygon
                id="Path"
                points="14.5165735 15.3835662 15.9468879 14.7151611 17.0084494 13.5454522 17.5895146 13.8016741 17.8465242 13.26695 18.818691 13.2446699 18.5728557 13.6902733 19.3774076 14.3809585 19.9137755 13.8462344 19.9137755 13.0998488 20.1596107 13.1778293 20.438969 14.5146395 21.3105668 15.7400489 21.2211722 16.664676 19.6344172 18.3022685 18.2599745 18.2242879 18.0923595 17.2996608 17.6230376 17.5001823 17.1760643 17.1102794 14.4607019 17.7007039 14.2930869 17.5001823 14.5165735 16.9654583"
              ></polygon>
              <polygon
                id="Path"
                points="2.46952717 2.64565879 2.94667111 2.76675151 3.16121827 2.76675151 3.32548094 2.53214132 2.71647989 2.25686982 2.46952717 2.31011943"
              ></polygon>
              <polygon
                id="Path"
                points="1.66162304 4.26264212 1.66162304 4.98151181 1.81247651 4.99944735 2.03708056 4.89473055 2.10636141 4.49101387 2.28179841 4.4170437 2.29073788 4.41325607 2.23933595 4.19880944 1.87840506 4.41325607"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Cloropleth;
