import React from "react";
import getPrettyNumber, {
  DecimalFormat,
  getDecimalsByFormat,
} from "../utils/numberUtils";
import styled, { css } from "../designSystem";
import Flexbox from "../layout/flexbox";
import { getMetricColor } from "../designSystem/theme";
import { darken, lighten, desaturate } from "polished";
import { observer } from "mobx-react-lite";

export function getSizeLegendItems(
  min: number,
  max: number,
  zScale,
  steps: number
) {
  const items = [];
  for (let index = 0; index < steps; index++) {
    let stepValue;
    if (index === steps - 1) {
      stepValue = max;
    } else if (index === 0) {
      stepValue = min;
    } else {
      stepValue = (min + max) * (index / steps);
    }
    items.push({
      value: stepValue,
      radius: zScale(stepValue),
    });
  }
  return items;
}

const SizeLegendCircle = styled(Flexbox)<{ metric?: string; index?: number }>`
  position: absolute;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  color: ${({ theme }) => theme.mapLegendBorder};
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  opacity: 0.7;

  ${({ theme, metric, index }) =>
    metric &&
    css`
      border-color: #fff;
      background-color: ${index > 0
        ? lighten(
            (index + 1) * 0.05,
            lighten(0.15, desaturate(0.2, getMetricColor(metric, theme)))
          )
        : getMetricColor(metric, theme)};
    `}
  ${({ theme, metric, index }) =>
    !metric &&
    css`
      border-color: ${theme.hardBorder};
      background-color: ${index > 0
        ? lighten((index + 1) * -0.075, "#ffffff")
        : "#fff"};
    `}
`;

const SizeLegendText = styled(Flexbox)`
  color: ${({ theme }) => theme.textPrimary};
  letter-spacing: 1px;
  font-size: 13px;
`;

type ISizeLegendProps = {
  items: Array<{ value: number; radius: number }>;
  decimalFormat: DecimalFormat;
  isPercentage: boolean;
  metric?: string;
  showValues?: boolean;
  onClick?: () => void;
};

export interface ZAxis {
  enabled: boolean;
  numberFormat: DecimalFormat;
  radius: {
    min?: number;
    max?: number;
    size?: number;
  };
}

const SizeLegend: React.FC<ISizeLegendProps> = ({
  items,
  decimalFormat,
  isPercentage,
  metric,
  showValues = true,
  onClick,
}) => {
  if (!items) {
    return;
  }
  const circles = items.slice().sort((a, b) => b.radius - a.radius);
  const maxDiameter = circles[0].radius * 2;

  const values =
    showValues &&
    circles.map((circle) => {
      const value = getPrettyNumber(
        circle.value,
        getDecimalsByFormat(decimalFormat),
        isPercentage
      );

      return value.value + value.suffix;
    });

  return (
    <Flexbox h100 gap="l" center onClick={!!onClick ? onClick : undefined}>
      <Flexbox
        center
        bottom
        noGrow
        style={{
          minWidth: maxDiameter,
          minHeight: maxDiameter,
          position: "relative",
        }}
      >
        {circles &&
          circles.map((circle, index) => {
            const diameter = circle.radius * 2;
            return (
              <SizeLegendCircle
                index={index}
                noGrow
                metric={metric}
                style={{
                  width: diameter,
                  height: diameter,
                }}
                key={index}
              />
            );
          })}
      </Flexbox>
      {values && (
        <Flexbox vertical noGrow h100>
          {values.map((value, index) => (
            <SizeLegendText key={index} left>
              {value}
            </SizeLegendText>
          ))}
        </Flexbox>
      )}
    </Flexbox>
  );
};

export default observer(SizeLegend);

// background-color: ${index > 0
//   ? darken( (index + 1) * 0.075, `#FFCD64`)
//   : `#FFCD64`
//   };
