import React, { useCallback } from "react";
import styled, { css } from "../designSystem";
import theme, { getMetricColor } from "../designSystem/theme";
import { DataSets } from "../App";
import { addComma, getEndianTime } from "../utils/numberUtils";
import { media } from "../designSystem/responsive";
import appTheme from "../designSystem/theme";
import { observer } from "mobx-react-lite";
import ContainedSection from "../layout/sections";
import Button from "./button";
import Flexbox from "../layout/flexbox";

type StoryProps = {
  dataSets: DataSets;
  countryData?: any;
  countryName?: string;
  noReportedData?: boolean;
};

export const StoryContainerSection = styled(Flexbox)<{ useHamburger: boolean }>`
  ${({ useHamburger }) =>
    !useHamburger &&
    css`
      height: ${theme.magicNumbers.storyModeHeight};
    `}
  margin: 0 100px;
  ${(media as any).m`
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 0;
    margin: 0 20px;
  `}
`;
const StyledStory = styled.h2`
  font-size: 23px;
  font-weight: 300;
  line-height: 1.3;
  margin: 0;
  ${(media as any).xl`
    font-size: 21px;
    // outline: 1px solid red;
  `}
  ${(media as any).l`
    font-size: 18px;
    // outline: 1px solid green;
  `}
  ${(media as any).m`
    margin: 0;
    // outline: 1px solid orange;
    font-size: 18px;
    padding: 20px 10px 10px;
    border-top: 1px solid ${appTheme.chartSecondary};
  `}
  p {
    margin: 0;
    text-align: center;
  }
`;
const StyledWord = styled.span<{ metric?: string }>`
  ${({ theme, metric }) =>
    metric &&
    css`
      color: ${getMetricColor(metric, theme)};
      font-weight: bold;
    `}
`;

const Story: React.FC<StoryProps> = ({
  dataSets,
  countryData,
  countryName,
  noReportedData,
}) => {
  //check here if this is global or country
  // “Globally as of 10:00 CET, 2 Apr 2020, there have been 896,450 confirmed cases of COVID-19, including 45,525 deaths, reported to WHO”

  const { totals, startDate, endDate, lastUpdate} = dataSets;

  const storyStartDate = getEndianTime(startDate.valueOf(), "MMM D");
  const storyEndDate = getEndianTime(
    Math.min(lastUpdate.valueOf(), new Date().valueOf()), // protect against embarrassing future dates from WHO data
    "h:mma z, D MMMM YYYY",
    "Europe/Paris"
  );

  const handleClick = useCallback(() => {
    window.location.href = "/";
  }, []);

  return (
    <StyledStory>
      {!countryData && !noReportedData && (
        <p>
          <StyledWord metric="cases">Globally</StyledWord>, as of{" "}
          <StyledWord metric="cases">{storyEndDate}</StyledWord>, there have
          been{" "}
          <StyledWord metric="cases">
            {addComma(totals.Confirmed)} confirmed cases
          </StyledWord>{" "}
          of COVID-19, including{" "}
          <StyledWord metric="deaths">
            {addComma(totals.Deaths)} deaths,
          </StyledWord>{" "}
          reported to WHO.
        </p>
      )}
      {!countryData && noReportedData && (
        <Flexbox gap="xl" middle vertical center>
          <h3>
            0 cases reported to the WHO for this country, territory, or area
          </h3>
          <Button onClick={handleClick} btnType="primary" fluid={false}>
            Return to home page
          </Button>
        </Flexbox>
      )}
      {countryData && (
        <p>
          In <StyledWord metric="cases">{countryName}</StyledWord>, from{" "}
          <StyledWord metric="cases">{storyStartDate}</StyledWord> to{" "}
          <StyledWord metric="cases">{storyEndDate}</StyledWord>, there have
          been{" "}
          <StyledWord metric="cases">
            {addComma(countryData.Confirmed)} confirmed cases
          </StyledWord>{" "}
          of COVID-19 with{" "}
          <StyledWord metric="deaths">
            {addComma(countryData.Deaths)} deaths
          </StyledWord>
          .
        </p>
      )}
      {/* <p>
        On modern channels including social, messaging, and websites, there have
        been <StyledWord metric="cases">{addComma(totalSocial)}</StyledWord>{" "}
        people self-reporting symptoms including
        <StyledWord metric="cough"> {addComma(totals.Cough)} cough</StyledWord>,
        <StyledWord metric="fever"> {addComma(totals.Fever)} fever</StyledWord>,
        <StyledWord metric="Sore Throat">
          {" "}
          {addComma(totals["Sore Throat"])} sore throat
        </StyledWord>
        ,
        <StyledWord metric="Shortness of Breath">
          {" "}
          {addComma(totals["Shortness of Breath"])} shortness of breath
        </StyledWord>{" "}
        and
        <StyledWord metric="Fatigue">
          {" "}
          {addComma(totals.Fatigue)} fatigue
        </StyledWord>
        .
      </p> */}
    </StyledStory>
  );
};

Story.displayName = "Story";
export default observer(Story);
