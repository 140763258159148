import React from "react";
import styled from "../designSystem";
import Flexbox from "../layout/flexbox";
import { media } from "../designSystem/responsive";
import { Info as InfoIcon } from "@styled-icons/entypo/Info";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const StyledInfoIconWrap = styled(Flexbox)`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.chartBackground};
  svg {
    fill: ${({ theme }) => theme.textPrimary};
    width: auto;
    height: 18px;
  }
  cursor: pointer;

  ${(media as any).m`
    width: 12px;
    height: 12px;
    svg {
      height: 8px;
    }
  `}
`;

type SiteInfoLinkProps = {};
const SiteInfoLink: React.FC<SiteInfoLinkProps> = ({}) => {
  return (
    <Flexbox noGrow>
      <Link to="/info">
        <StyledInfoIconWrap center middle>
          <InfoIcon />
        </StyledInfoIconWrap>
      </Link>
    </Flexbox>
  );
};

export default observer(SiteInfoLink);
