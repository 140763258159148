import React from "react";
import Flexbox from "../layout/flexbox";
import { DecimalFormat } from "../utils/numberUtils";
import { Region } from "../utils/regions";
import styled, { css } from "../designSystem";
import Metric from "./metric";
import appTheme, {
  getMetricColor,
  getRegionColor,
} from "../designSystem/theme";
import Popover from "./popover";
import { observer } from "mobx-react-lite";
import { PageTitle } from "../designSystem/typography";

type Item = {
  label: string;
  value: number;
};
type BarchartProps = {
  data: Item[];
  format?: DecimalFormat;
  metric?: string;
  popoverEnabled?: boolean;
  onEnter?: (id: string) => void;
  onLeave?: () => void;
  activeItems?: string[];
};

const StyledBar = styled(Flexbox)<{ inactive?: boolean }>`
  width: 100%;
  position: relative;
  height: 12px;
  cursor: pointer;
  will-change: opacity;
  transition: opacity 300ms;
  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.5;
    `}
`;
const StyledBarGroup = styled(Flexbox)<{ inactive?: boolean }>`
  cursor: pointer;
  will-change: opacity;
  transition: opacity 200ms;
  ${({ inactive }) =>
    inactive &&
    css`
      opacity: 0.75;
    `}
`;

const StyledBarFill = styled(Flexbox)<{ metric: string; inactive?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${appTheme.primary};
  will-change: background-color;
  transition: background-color 300ms;
  border-radius: 2px;
  ${({ theme, metric }) =>
    css`
      background-color: ${getMetricColor(metric, theme)};
      background-color: ${getRegionColor(metric as Region, theme)};
    `}
  ${({ inactive }) =>
    inactive &&
    css`
      background-color: ${appTheme.chartSecondary};
    `}
`;

const StyledMetric = styled(Flexbox)`
  flex: 0 0 100px;
`;
const StyledBarchart = styled(Flexbox)``;
const StyledLabel = styled(Flexbox)`
  font-size: 16px;
  font-weight: bold;
`;

const StyledFillNest = styled(Flexbox)`
  height: 100%;
  width: 100%;
`;
const Barchart: React.FC<BarchartProps> = ({
  data,
  format,
  metric = "",
  popoverEnabled,
  onEnter,
  onLeave,
  activeItems,
}) => {
  const max = Math.max(...data.map((item) => item.value));
  return (
    <StyledBarchart vertical gap>
      {data.map((bar, index) => {
        const widthPercentage = (bar.value / max) * 100;
        const inactive =
          activeItems &&
          !!activeItems.length &&
          !activeItems.includes(bar.label);
        const handleMouseEnter = () => {
          if (!!onEnter) {
            onEnter(bar.label);
          }
        };
        const handleMouseLeave = () => {
          if (!!onLeave) {
            onLeave();
          }
        };
        return (
          <StyledBarGroup
            inactive={inactive}
            style={{ cursor: "pointer" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            middle
            key={index}
          >
            <Flexbox vertical gap="m">
              <PageTitle>{bar.label}</PageTitle>
              <StyledBar>
                <StyledBarFill
                  className="bar_fill"
                  inactive={inactive}
                  metric={bar.label}
                  style={{ width: `${widthPercentage}%` }}
                >
                  {popoverEnabled && (
                    <Popover
                      delay={100}
                      position="bottom"
                      openOnHover
                      popoverContents={
                        <Flexbox middle gap="m">
                          <Metric
                            size="s"
                            label={bar.label}
                            value={bar.value}
                          />
                        </Flexbox>
                      }
                    >
                      <StyledFillNest />
                    </Popover>
                  )}
                </StyledBarFill>
              </StyledBar>
            </Flexbox>
            <StyledMetric right>
              <Metric
                align="right"
                value={bar.value}
                format={format}
                label={metric}
                size="s"
              />
            </StyledMetric>
          </StyledBarGroup>
        );
      })}
    </StyledBarchart>
  );
};

Barchart.displayName = "Barchart";
export default observer(Barchart);
