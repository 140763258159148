// file generated by scripts/generate-countryname-mapping.js

export const whoCountryNamesByIso2 = {
  AW: {
    ISO_2_CODE: "AW",
    ISO_3_CODE: "ABW",
    ADM0_VIZ_NAME: "Aruba",
    ADM0_SOVRN: "Netherlands",
    ADM0_NAME: "ARUBA",
  },
  AF: {
    ISO_2_CODE: "AF",
    ISO_3_CODE: "AFG",
    ADM0_VIZ_NAME: "Afghanistan",
    ADM0_SOVRN: "Afghanistan",
    ADM0_NAME: "AFGHANISTAN",
  },
  AO: {
    ISO_2_CODE: "AO",
    ISO_3_CODE: "AGO",
    ADM0_VIZ_NAME: "Angola",
    ADM0_SOVRN: "Angola",
    ADM0_NAME: "ANGOLA",
  },
  AI: {
    ISO_2_CODE: "AI",
    ISO_3_CODE: "AIA",
    ADM0_VIZ_NAME: "Anguilla",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "ANGUILLA",
  },
  AL: {
    ISO_2_CODE: "AL",
    ISO_3_CODE: "ALB",
    ADM0_VIZ_NAME: "Albania",
    ADM0_SOVRN: "Albania",
    ADM0_NAME: "ALBANIA",
  },
  AD: {
    ISO_2_CODE: "AD",
    ISO_3_CODE: "AND",
    ADM0_VIZ_NAME: "Andorra",
    ADM0_SOVRN: "Andorra",
    ADM0_NAME: "ANDORRA",
  },
  AE: {
    ISO_2_CODE: "AE",
    ISO_3_CODE: "ARE",
    ADM0_VIZ_NAME: "United Arab Emirates",
    ADM0_SOVRN: "United Arab Emirates",
    ADM0_NAME: "UNITED ARAB EMIRATES",
  },
  AR: {
    ISO_2_CODE: "AR",
    ISO_3_CODE: "ARG",
    ADM0_VIZ_NAME: "Argentina",
    ADM0_SOVRN: "Argentina",
    ADM0_NAME: "ARGENTINA",
  },
  AM: {
    ISO_2_CODE: "AM",
    ISO_3_CODE: "ARM",
    ADM0_VIZ_NAME: "Armenia",
    ADM0_SOVRN: "Armenia",
    ADM0_NAME: "ARMENIA",
  },
  AS: {
    ISO_2_CODE: "AS",
    ISO_3_CODE: "ASM",
    ADM0_VIZ_NAME: "American Samoa",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "AMERICAN SAMOA",
  },
  AG: {
    ISO_2_CODE: "AG",
    ISO_3_CODE: "ATG",
    ADM0_VIZ_NAME: "Antigua and Barbuda",
    ADM0_SOVRN: "Antigua and Barbuda",
    ADM0_NAME: "ANTIGUA AND BARBUDA",
  },
  AU: {
    ISO_2_CODE: "AU",
    ISO_3_CODE: "AUS",
    ADM0_VIZ_NAME: "Australia",
    ADM0_SOVRN: "Australia",
    ADM0_NAME: "AUSTRALIA",
  },
  AT: {
    ISO_2_CODE: "AT",
    ISO_3_CODE: "AUT",
    ADM0_VIZ_NAME: "Austria",
    ADM0_SOVRN: "Austria",
    ADM0_NAME: "AUSTRIA",
  },
  AZ: {
    ISO_2_CODE: "AZ",
    ISO_3_CODE: "AZE",
    ADM0_VIZ_NAME: "Azerbaijan",
    ADM0_SOVRN: "Azerbaijan",
    ADM0_NAME: "AZERBAIJAN",
  },
  BI: {
    ISO_2_CODE: "BI",
    ISO_3_CODE: "BDI",
    ADM0_VIZ_NAME: "Burundi",
    ADM0_SOVRN: "Burundi",
    ADM0_NAME: "BURUNDI",
  },
  BE: {
    ISO_2_CODE: "BE",
    ISO_3_CODE: "BEL",
    ADM0_VIZ_NAME: "Belgium",
    ADM0_SOVRN: "Belgium",
    ADM0_NAME: "BELGIUM",
  },
  BJ: {
    ISO_2_CODE: "BJ",
    ISO_3_CODE: "BEN",
    ADM0_VIZ_NAME: "Benin",
    ADM0_SOVRN: "Benin",
    ADM0_NAME: "BENIN",
  },
  BQ: {
    ISO_2_CODE: "BQ",
    ISO_3_CODE: "BES",
    ADM0_VIZ_NAME: "Bonaire, Sint Eustatius and Saba",
    ADM0_SOVRN: "Netherlands",
    ADM0_NAME: "BONAIRE, SINT EUSTATIUS AND SABA",
  },
  BF: {
    ISO_2_CODE: "BF",
    ISO_3_CODE: "BFA",
    ADM0_VIZ_NAME: "Burkina Faso",
    ADM0_SOVRN: "Burkina Faso",
    ADM0_NAME: "BURKINA FASO",
  },
  BD: {
    ISO_2_CODE: "BD",
    ISO_3_CODE: "BGD",
    ADM0_VIZ_NAME: "Bangladesh",
    ADM0_SOVRN: "Bangladesh",
    ADM0_NAME: "BANGLADESH",
  },
  BG: {
    ISO_2_CODE: "BG",
    ISO_3_CODE: "BGR",
    ADM0_VIZ_NAME: "Bulgaria",
    ADM0_SOVRN: "Bulgaria",
    ADM0_NAME: "BULGARIA",
  },
  BH: {
    ISO_2_CODE: "BH",
    ISO_3_CODE: "BHR",
    ADM0_VIZ_NAME: "Bahrain",
    ADM0_SOVRN: "Bahrain",
    ADM0_NAME: "BAHRAIN",
  },
  BS: {
    ISO_2_CODE: "BS",
    ISO_3_CODE: "BHS",
    ADM0_VIZ_NAME: "Bahamas",
    ADM0_SOVRN: "Bahamas",
    ADM0_NAME: "BAHAMAS",
  },
  BA: {
    ISO_2_CODE: "BA",
    ISO_3_CODE: "BIH",
    ADM0_VIZ_NAME: "Bosnia and Herzegovina",
    ADM0_SOVRN: "Bosnia and Herzegovina",
    ADM0_NAME: "BOSNIA AND HERZEGOVINA",
  },
  BL: {
    ISO_2_CODE: "BL",
    ISO_3_CODE: "BLM",
    ADM0_VIZ_NAME: "Saint Barthélemy",
    ADM0_SOVRN: "France",
    ADM0_NAME: "SAINT BARTHELEMY",
  },
  BY: {
    ISO_2_CODE: "BY",
    ISO_3_CODE: "BLR",
    ADM0_VIZ_NAME: "Belarus",
    ADM0_SOVRN: "Belarus",
    ADM0_NAME: "BELARUS",
  },
  BZ: {
    ISO_2_CODE: "BZ",
    ISO_3_CODE: "BLZ",
    ADM0_VIZ_NAME: "Belize",
    ADM0_SOVRN: "Belize",
    ADM0_NAME: "BELIZE",
  },
  BM: {
    ISO_2_CODE: "BM",
    ISO_3_CODE: "BMU",
    ADM0_VIZ_NAME: "Bermuda",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "BERMUDA",
  },
  BO: {
    ISO_2_CODE: "BO",
    ISO_3_CODE: "BOL",
    ADM0_VIZ_NAME: "Bolivia (Plurinational State of)",
    ADM0_SOVRN: "Plurinational State of Bolivia",
    ADM0_NAME: "PLURINATIONAL STATE OF BOLIVIA",
  },
  BR: {
    ISO_2_CODE: "BR",
    ISO_3_CODE: "BRA",
    ADM0_VIZ_NAME: "Brazil",
    ADM0_SOVRN: "Brazil",
    ADM0_NAME: "BRAZIL",
  },
  BB: {
    ISO_2_CODE: "BB",
    ISO_3_CODE: "BRB",
    ADM0_VIZ_NAME: "Barbados",
    ADM0_SOVRN: "Barbados",
    ADM0_NAME: "BARBADOS",
  },
  BN: {
    ISO_2_CODE: "BN",
    ISO_3_CODE: "BRN",
    ADM0_VIZ_NAME: "Brunei Darussalam",
    ADM0_SOVRN: "Brunei Darussalam",
    ADM0_NAME: "BRUNEI DARUSSALAM",
  },
  BT: {
    ISO_2_CODE: "BT",
    ISO_3_CODE: "BTN",
    ADM0_VIZ_NAME: "Bhutan",
    ADM0_SOVRN: "Bhutan",
    ADM0_NAME: "BHUTAN",
  },
  BW: {
    ISO_2_CODE: "BW",
    ISO_3_CODE: "BWA",
    ADM0_VIZ_NAME: "Botswana",
    ADM0_SOVRN: "Botswana",
    ADM0_NAME: "BOTSWANA",
  },
  CF: {
    ISO_2_CODE: "CF",
    ISO_3_CODE: "CAF",
    ADM0_VIZ_NAME: "Central African Republic",
    ADM0_SOVRN: "Central African Republic",
    ADM0_NAME: "CENTRAL AFRICAN REPUBLIC",
  },
  CA: {
    ISO_2_CODE: "CA",
    ISO_3_CODE: "CAN",
    ADM0_VIZ_NAME: "Canada",
    ADM0_SOVRN: "Canada",
    ADM0_NAME: "CANADA",
  },
  CC: {
    ISO_2_CODE: "CC",
    ISO_3_CODE: "CCK",
    ADM0_VIZ_NAME: "Cocos (Keeling) Islands",
    ADM0_SOVRN: "Australia",
    ADM0_NAME: "COCOS (KEELING) ISLANDS",
  },
  CH: {
    ISO_2_CODE: "CH",
    ISO_3_CODE: "CHE",
    ADM0_VIZ_NAME: "Switzerland",
    ADM0_SOVRN: "Switzerland",
    ADM0_NAME: "SWITZERLAND",
  },
  CL: {
    ISO_2_CODE: "CL",
    ISO_3_CODE: "CHL",
    ADM0_VIZ_NAME: "Chile",
    ADM0_SOVRN: "Chile",
    ADM0_NAME: "CHILE",
  },
  CN: {
    ISO_2_CODE: "CN",
    ISO_3_CODE: "CHN",
    ADM0_VIZ_NAME: "China",
    ADM0_SOVRN: "China",
    ADM0_NAME: "CHINA",
  },
  CM: {
    ISO_2_CODE: "CM",
    ISO_3_CODE: "CMR",
    ADM0_VIZ_NAME: "Cameroon",
    ADM0_SOVRN: "Cameroon",
    ADM0_NAME: "CAMEROON",
  },
  CK: {
    ISO_2_CODE: "CK",
    ISO_3_CODE: "COK",
    ADM0_VIZ_NAME: "Cook Islands",
    ADM0_SOVRN: "New Zealand",
    ADM0_NAME: "COOK ISLANDS",
  },
  CO: {
    ISO_2_CODE: "CO",
    ISO_3_CODE: "COL",
    ADM0_VIZ_NAME: "Colombia",
    ADM0_SOVRN: "Colombia",
    ADM0_NAME: "COLOMBIA",
  },
  KM: {
    ISO_2_CODE: "KM",
    ISO_3_CODE: "COM",
    ADM0_VIZ_NAME: "Comoros",
    ADM0_SOVRN: "Comoros",
    ADM0_NAME: "COMOROS",
  },
  CV: {
    ISO_2_CODE: "CV",
    ISO_3_CODE: "CPV",
    ADM0_VIZ_NAME: "Cabo Verde",
    ADM0_SOVRN: "Cabo Verde",
    ADM0_NAME: "CABO VERDE",
  },
  CR: {
    ISO_2_CODE: "CR",
    ISO_3_CODE: "CRI",
    ADM0_VIZ_NAME: "Costa Rica",
    ADM0_SOVRN: "Costa Rica",
    ADM0_NAME: "COSTA RICA",
  },
  CU: {
    ISO_2_CODE: "CU",
    ISO_3_CODE: "CUB",
    ADM0_VIZ_NAME: "Cuba",
    ADM0_SOVRN: "Cuba",
    ADM0_NAME: "CUBA",
  },
  CW: {
    ISO_2_CODE: "CW",
    ISO_3_CODE: "CUW",
    ADM0_VIZ_NAME: "Curacao",
    ADM0_SOVRN: "Netherlands",
    ADM0_NAME: "CURACAO",
  },
  CX: {
    ISO_2_CODE: "CX",
    ISO_3_CODE: "CXR",
    ADM0_VIZ_NAME: "Christmas Island",
    ADM0_SOVRN: "Australia",
    ADM0_NAME: "CHRISTMAS ISLAND",
  },
  KY: {
    ISO_2_CODE: "KY",
    ISO_3_CODE: "CYM",
    ADM0_VIZ_NAME: "Cayman Islands",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "CAYMAN ISLANDS",
  },
  CY: {
    ISO_2_CODE: "CY",
    ISO_3_CODE: "CYP",
    ADM0_VIZ_NAME: "Cyprus",
    ADM0_SOVRN: "Cyprus",
    ADM0_NAME: "CYPRUS",
  },
  CZ: {
    ISO_2_CODE: "CZ",
    ISO_3_CODE: "CZE",
    ADM0_VIZ_NAME: "Czechia",
    ADM0_SOVRN: "Czech Republic",
    ADM0_NAME: "CZECH REPUBLIC",
  },
  DE: {
    ISO_2_CODE: "DE",
    ISO_3_CODE: "DEU",
    ADM0_VIZ_NAME: "Germany",
    ADM0_SOVRN: "Germany",
    ADM0_NAME: "GERMANY",
  },
  DJ: {
    ISO_2_CODE: "DJ",
    ISO_3_CODE: "DJI",
    ADM0_VIZ_NAME: "Djibouti",
    ADM0_SOVRN: "Djibouti",
    ADM0_NAME: "DJIBOUTI",
  },
  DM: {
    ISO_2_CODE: "DM",
    ISO_3_CODE: "DMA",
    ADM0_VIZ_NAME: "Dominica",
    ADM0_SOVRN: "Dominica",
    ADM0_NAME: "DOMINICA",
  },
  DK: {
    ISO_2_CODE: "DK",
    ISO_3_CODE: "DNK",
    ADM0_VIZ_NAME: "Denmark",
    ADM0_SOVRN: "Denmark",
    ADM0_NAME: "DENMARK",
  },
  DO: {
    ISO_2_CODE: "DO",
    ISO_3_CODE: "DOM",
    ADM0_VIZ_NAME: "Dominican Republic",
    ADM0_SOVRN: "Dominican Republic",
    ADM0_NAME: "DOMINICAN REPUBLIC",
  },
  DZ: {
    ISO_2_CODE: "DZ",
    ISO_3_CODE: "DZA",
    ADM0_VIZ_NAME: "Algeria",
    ADM0_SOVRN: "Algeria",
    ADM0_NAME: "ALGERIA",
  },
  EC: {
    ISO_2_CODE: "EC",
    ISO_3_CODE: "ECU",
    ADM0_VIZ_NAME: "Ecuador",
    ADM0_SOVRN: "Ecuador",
    ADM0_NAME: "ECUADOR",
  },
  EG: {
    ISO_2_CODE: "EG",
    ISO_3_CODE: "EGY",
    ADM0_VIZ_NAME: "Egypt",
    ADM0_SOVRN: "Egypt",
    ADM0_NAME: "EGYPT",
  },
  ER: {
    ISO_2_CODE: "ER",
    ISO_3_CODE: "ERI",
    ADM0_VIZ_NAME: "Eritrea",
    ADM0_SOVRN: "Eritrea",
    ADM0_NAME: "ERITREA",
  },
  EH: {
    ISO_2_CODE: "EH",
    ISO_3_CODE: "ESH",
    ADM0_VIZ_NAME: "Western Sahara",
    ADM0_SOVRN: "Western Sahara",
    ADM0_NAME: "WESTERN SAHARA",
  },
  ES: {
    ISO_2_CODE: "ES",
    ISO_3_CODE: "ESP",
    ADM0_VIZ_NAME: "Spain",
    ADM0_SOVRN: "Spain",
    ADM0_NAME: "SPAIN",
  },
  EE: {
    ISO_2_CODE: "EE",
    ISO_3_CODE: "EST",
    ADM0_VIZ_NAME: "Estonia",
    ADM0_SOVRN: "Estonia",
    ADM0_NAME: "ESTONIA",
  },
  ET: {
    ISO_2_CODE: "ET",
    ISO_3_CODE: "ETH",
    ADM0_VIZ_NAME: "Ethiopia",
    ADM0_SOVRN: "Ethiopia",
    ADM0_NAME: "ETHIOPIA",
  },
  FI: {
    ISO_2_CODE: "FI",
    ISO_3_CODE: "FIN",
    ADM0_VIZ_NAME: "Finland",
    ADM0_SOVRN: "Finland",
    ADM0_NAME: "FINLAND",
  },
  FJ: {
    ISO_2_CODE: "FJ",
    ISO_3_CODE: "FJI",
    ADM0_VIZ_NAME: "Fiji",
    ADM0_SOVRN: "Fiji",
    ADM0_NAME: "FIJI",
  },
  FK: {
    ISO_2_CODE: "FK",
    ISO_3_CODE: "FLK",
    ADM0_VIZ_NAME: "Falkland Islands (Malvinas) [2]",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "FALKLAND ISLANDS (MALVINAS)",
  },
  FR: {
    ISO_2_CODE: "FR",
    ISO_3_CODE: "FRA",
    ADM0_VIZ_NAME: "France",
    ADM0_SOVRN: "France",
    ADM0_NAME: "FRANCE",
  },
  FO: {
    ISO_2_CODE: "FO",
    ISO_3_CODE: "FRO",
    ADM0_VIZ_NAME: "Faroe Islands",
    ADM0_SOVRN: "Denmark",
    ADM0_NAME: "FAROE ISLANDS",
  },
  FM: {
    ISO_2_CODE: "FM",
    ISO_3_CODE: "FSM",
    ADM0_VIZ_NAME: "Micronesia (Federated States of)",
    ADM0_SOVRN: "Federated States of Micronesia",
    ADM0_NAME: "FEDERATED STATES OF MICRONESIA",
  },
  GA: {
    ISO_2_CODE: "GA",
    ISO_3_CODE: "GAB",
    ADM0_VIZ_NAME: "Gabon",
    ADM0_SOVRN: "Gabon",
    ADM0_NAME: "GABON",
  },
  GB: {
    ISO_2_CODE: "GB",
    ISO_3_CODE: "GBR",
    ADM0_VIZ_NAME: "The United Kingdom",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
  },
  GE: {
    ISO_2_CODE: "GE",
    ISO_3_CODE: "GEO",
    ADM0_VIZ_NAME: "Georgia",
    ADM0_SOVRN: "Georgia",
    ADM0_NAME: "GEORGIA",
  },
  GG: {
    ISO_2_CODE: "GG",
    ISO_3_CODE: "GGY",
    ADM0_VIZ_NAME: "Guernsey",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "GUERNSEY",
  },
  GH: {
    ISO_2_CODE: "GH",
    ISO_3_CODE: "GHA",
    ADM0_VIZ_NAME: "Ghana",
    ADM0_SOVRN: "Ghana",
    ADM0_NAME: "GHANA",
  },
  GI: {
    ISO_2_CODE: "GI",
    ISO_3_CODE: "GIB",
    ADM0_VIZ_NAME: "Gibraltar",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "GIBRALTAR",
  },
  GN: {
    ISO_2_CODE: "GN",
    ISO_3_CODE: "GIN",
    ADM0_VIZ_NAME: "Guinea",
    ADM0_SOVRN: "Guinea",
    ADM0_NAME: "GUINEA",
  },
  GP: {
    ISO_2_CODE: "GP",
    ISO_3_CODE: "GLP",
    ADM0_VIZ_NAME: "Guadeloupe",
    ADM0_SOVRN: "France",
    ADM0_NAME: "GUADELOUPE",
  },
  GM: {
    ISO_2_CODE: "GM",
    ISO_3_CODE: "GMB",
    ADM0_VIZ_NAME: "Gambia",
    ADM0_SOVRN: "Gambia",
    ADM0_NAME: "GAMBIA",
  },
  GW: {
    ISO_2_CODE: "GW",
    ISO_3_CODE: "GNB",
    ADM0_VIZ_NAME: "Guinea-Bissau",
    ADM0_SOVRN: "Guinea-Bissau",
    ADM0_NAME: "GUINEA-BISSAU",
  },
  GQ: {
    ISO_2_CODE: "GQ",
    ISO_3_CODE: "GNQ",
    ADM0_VIZ_NAME: "Equatorial Guinea",
    ADM0_SOVRN: "Equatorial Guinea",
    ADM0_NAME: "EQUATORIAL GUINEA",
  },
  GR: {
    ISO_2_CODE: "GR",
    ISO_3_CODE: "GRC",
    ADM0_VIZ_NAME: "Greece",
    ADM0_SOVRN: "Greece",
    ADM0_NAME: "GREECE",
  },
  GD: {
    ISO_2_CODE: "GD",
    ISO_3_CODE: "GRD",
    ADM0_VIZ_NAME: "Grenada",
    ADM0_SOVRN: "Grenada",
    ADM0_NAME: "GRENADA",
  },
  GL: {
    ISO_2_CODE: "GL",
    ISO_3_CODE: "GRL",
    ADM0_VIZ_NAME: "Greenland",
    ADM0_SOVRN: "Denmark",
    ADM0_NAME: "GREENLAND",
  },
  GT: {
    ISO_2_CODE: "GT",
    ISO_3_CODE: "GTM",
    ADM0_VIZ_NAME: "Guatemala",
    ADM0_SOVRN: "Guatemala",
    ADM0_NAME: "GUATEMALA",
  },
  GF: {
    ISO_2_CODE: "GF",
    ISO_3_CODE: "GUF",
    ADM0_VIZ_NAME: "French Guiana",
    ADM0_SOVRN: "France",
    ADM0_NAME: "FRENCH GUIANA",
  },
  GU: {
    ISO_2_CODE: "GU",
    ISO_3_CODE: "GUM",
    ADM0_VIZ_NAME: "Guam",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "GUAM",
  },
  GY: {
    ISO_2_CODE: "GY",
    ISO_3_CODE: "GUY",
    ADM0_VIZ_NAME: "Guyana",
    ADM0_SOVRN: "Guyana",
    ADM0_NAME: "GUYANA",
  },
  HN: {
    ISO_2_CODE: "HN",
    ISO_3_CODE: "HND",
    ADM0_VIZ_NAME: "Honduras",
    ADM0_SOVRN: "Honduras",
    ADM0_NAME: "HONDURAS",
  },
  HR: {
    ISO_2_CODE: "HR",
    ISO_3_CODE: "HRV",
    ADM0_VIZ_NAME: "Croatia",
    ADM0_SOVRN: "Croatia",
    ADM0_NAME: "CROATIA",
  },
  HT: {
    ISO_2_CODE: "HT",
    ISO_3_CODE: "HTI",
    ADM0_VIZ_NAME: "Haiti",
    ADM0_SOVRN: "Haiti",
    ADM0_NAME: "HAITI",
  },
  HU: {
    ISO_2_CODE: "HU",
    ISO_3_CODE: "HUN",
    ADM0_VIZ_NAME: "Hungary",
    ADM0_SOVRN: "Hungary",
    ADM0_NAME: "HUNGARY",
  },
  ID: {
    ISO_2_CODE: "ID",
    ISO_3_CODE: "IDN",
    ADM0_VIZ_NAME: "Indonesia",
    ADM0_SOVRN: "Indonesia",
    ADM0_NAME: "INDONESIA",
  },
  IM: {
    ISO_2_CODE: "IM",
    ISO_3_CODE: "IMN",
    ADM0_VIZ_NAME: "Isle of Man",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "ISLE OF MAN",
  },
  IN: {
    ISO_2_CODE: "IN",
    ISO_3_CODE: "IND",
    ADM0_VIZ_NAME: "India",
    ADM0_SOVRN: "India",
    ADM0_NAME: "INDIA",
  },
  IE: {
    ISO_2_CODE: "IE",
    ISO_3_CODE: "IRL",
    ADM0_VIZ_NAME: "Ireland",
    ADM0_SOVRN: "Ireland",
    ADM0_NAME: "IRELAND",
  },
  IR: {
    ISO_2_CODE: "IR",
    ISO_3_CODE: "IRN",
    ADM0_VIZ_NAME: "Iran (Islamic Republic of)",
    ADM0_SOVRN: "Islamic Republic of Iran",
    ADM0_NAME: "ISLAMIC REPUBLIC OF IRAN",
  },
  IQ: {
    ISO_2_CODE: "IQ",
    ISO_3_CODE: "IRQ",
    ADM0_VIZ_NAME: "Iraq",
    ADM0_SOVRN: "Iraq",
    ADM0_NAME: "IRAQ",
  },
  IS: {
    ISO_2_CODE: "IS",
    ISO_3_CODE: "ISL",
    ADM0_VIZ_NAME: "Iceland",
    ADM0_SOVRN: "Iceland",
    ADM0_NAME: "ICELAND",
  },
  IL: {
    ISO_2_CODE: "IL",
    ISO_3_CODE: "ISR",
    ADM0_VIZ_NAME: "Israel",
    ADM0_SOVRN: "Israel",
    ADM0_NAME: "ISRAEL",
  },
  IT: {
    ISO_2_CODE: "IT",
    ISO_3_CODE: "ITA",
    ADM0_VIZ_NAME: "Italy",
    ADM0_SOVRN: "Italy",
    ADM0_NAME: "ITALY",
  },
  JM: {
    ISO_2_CODE: "JM",
    ISO_3_CODE: "JAM",
    ADM0_VIZ_NAME: "Jamaica",
    ADM0_SOVRN: "Jamaica",
    ADM0_NAME: "JAMAICA",
  },
  JE: {
    ISO_2_CODE: "JE",
    ISO_3_CODE: "JEY",
    ADM0_VIZ_NAME: "Jersey",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "JERSEY",
  },
  JO: {
    ISO_2_CODE: "JO",
    ISO_3_CODE: "JOR",
    ADM0_VIZ_NAME: "Jordan",
    ADM0_SOVRN: "Jordan",
    ADM0_NAME: "JORDAN",
  },
  JP: {
    ISO_2_CODE: "JP",
    ISO_3_CODE: "JPN",
    ADM0_VIZ_NAME: "Japan",
    ADM0_SOVRN: "Japan",
    ADM0_NAME: "JAPAN",
  },
  KZ: {
    ISO_2_CODE: "KZ",
    ISO_3_CODE: "KAZ",
    ADM0_VIZ_NAME: "Kazakhstan",
    ADM0_SOVRN: "Kazakhstan",
    ADM0_NAME: "KAZAKHSTAN",
  },
  KE: {
    ISO_2_CODE: "KE",
    ISO_3_CODE: "KEN",
    ADM0_VIZ_NAME: "Kenya",
    ADM0_SOVRN: "Kenya",
    ADM0_NAME: "KENYA",
  },
  KG: {
    ISO_2_CODE: "KG",
    ISO_3_CODE: "KGZ",
    ADM0_VIZ_NAME: "Kyrgyzstan",
    ADM0_SOVRN: "Kyrgyzstan",
    ADM0_NAME: "KYRGYZSTAN",
  },
  KH: {
    ISO_2_CODE: "KH",
    ISO_3_CODE: "KHM",
    ADM0_VIZ_NAME: "Cambodia",
    ADM0_SOVRN: "Cambodia",
    ADM0_NAME: "CAMBODIA",
  },
  KI: {
    ISO_2_CODE: "KI",
    ISO_3_CODE: "KIR",
    ADM0_VIZ_NAME: "Kiribati",
    ADM0_SOVRN: "Kiribati",
    ADM0_NAME: "KIRIBATI",
  },
  KN: {
    ISO_2_CODE: "KN",
    ISO_3_CODE: "KNA",
    ADM0_VIZ_NAME: "Saint Kitts and Nevis",
    ADM0_SOVRN: "Saint Kitts and Nevis",
    ADM0_NAME: "SAINT KITTS AND NEVIS",
  },
  KR: {
    ISO_2_CODE: "KR",
    ISO_3_CODE: "KOR",
    ADM0_VIZ_NAME: "Republic of Korea",
    ADM0_SOVRN: "Republic of Korea",
    ADM0_NAME: "REPUBLIC OF KOREA",
  },
  KW: {
    ISO_2_CODE: "KW",
    ISO_3_CODE: "KWT",
    ADM0_VIZ_NAME: "Kuwait",
    ADM0_SOVRN: "Kuwait",
    ADM0_NAME: "KUWAIT",
  },
  LA: {
    ISO_2_CODE: "LA",
    ISO_3_CODE: "LAO",
    ADM0_VIZ_NAME: "Lao People's Democratic Republic",
    ADM0_SOVRN: "Lao People's Democratic Republic",
    ADM0_NAME: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
  },
  LB: {
    ISO_2_CODE: "LB",
    ISO_3_CODE: "LBN",
    ADM0_VIZ_NAME: "Lebanon",
    ADM0_SOVRN: "Lebanon",
    ADM0_NAME: "LEBANON",
  },
  LR: {
    ISO_2_CODE: "LR",
    ISO_3_CODE: "LBR",
    ADM0_VIZ_NAME: "Liberia",
    ADM0_SOVRN: "Liberia",
    ADM0_NAME: "LIBERIA",
  },
  LY: {
    ISO_2_CODE: "LY",
    ISO_3_CODE: "LBY",
    ADM0_VIZ_NAME: "Libya",
    ADM0_SOVRN: "Libya",
    ADM0_NAME: "LIBYA",
  },
  LC: {
    ISO_2_CODE: "LC",
    ISO_3_CODE: "LCA",
    ADM0_VIZ_NAME: "Saint Lucia",
    ADM0_SOVRN: "Saint Lucia",
    ADM0_NAME: "SAINT LUCIA",
  },
  LI: {
    ISO_2_CODE: "LI",
    ISO_3_CODE: "LIE",
    ADM0_VIZ_NAME: "Liechtenstein",
    ADM0_SOVRN: "Liechtenstein",
    ADM0_NAME: "LIECHTENSTEIN",
  },
  LK: {
    ISO_2_CODE: "LK",
    ISO_3_CODE: "LKA",
    ADM0_VIZ_NAME: "Sri Lanka",
    ADM0_SOVRN: "Sri Lanka",
    ADM0_NAME: "SRI LANKA",
  },
  LS: {
    ISO_2_CODE: "LS",
    ISO_3_CODE: "LSO",
    ADM0_VIZ_NAME: "Lesotho",
    ADM0_SOVRN: "Lesotho",
    ADM0_NAME: "LESOTHO",
  },
  LT: {
    ISO_2_CODE: "LT",
    ISO_3_CODE: "LTU",
    ADM0_VIZ_NAME: "Lithuania",
    ADM0_SOVRN: "Lithuania",
    ADM0_NAME: "LITHUANIA",
  },
  LU: {
    ISO_2_CODE: "LU",
    ISO_3_CODE: "LUX",
    ADM0_VIZ_NAME: "Luxembourg",
    ADM0_SOVRN: "Luxembourg",
    ADM0_NAME: "LUXEMBOURG",
  },
  LV: {
    ISO_2_CODE: "LV",
    ISO_3_CODE: "LVA",
    ADM0_VIZ_NAME: "Latvia",
    ADM0_SOVRN: "Latvia",
    ADM0_NAME: "LATVIA",
  },
  MF: {
    ISO_2_CODE: "MF",
    ISO_3_CODE: "MAF",
    ADM0_VIZ_NAME: "Saint Martin",
    ADM0_SOVRN: "France",
    ADM0_NAME: "SAINT MARTIN",
  },
  MA: {
    ISO_2_CODE: "MA",
    ISO_3_CODE: "MAR",
    ADM0_VIZ_NAME: "Morocco",
    ADM0_SOVRN: "Morocco",
    ADM0_NAME: "MOROCCO",
  },
  MC: {
    ISO_2_CODE: "MC",
    ISO_3_CODE: "MCO",
    ADM0_VIZ_NAME: "Monaco",
    ADM0_SOVRN: "Monaco",
    ADM0_NAME: "MONACO",
  },
  MD: {
    ISO_2_CODE: "MD",
    ISO_3_CODE: "MDA",
    ADM0_VIZ_NAME: "Republic of Moldova",
    ADM0_SOVRN: "Republic of Moldova",
    ADM0_NAME: "REPUBLIC OF MOLDOVA",
  },
  MG: {
    ISO_2_CODE: "MG",
    ISO_3_CODE: "MDG",
    ADM0_VIZ_NAME: "Madagascar",
    ADM0_SOVRN: "Madagascar",
    ADM0_NAME: "MADAGASCAR",
  },
  MV: {
    ISO_2_CODE: "MV",
    ISO_3_CODE: "MDV",
    ADM0_VIZ_NAME: "Maldives",
    ADM0_SOVRN: "Maldives",
    ADM0_NAME: "MALDIVES",
  },
  MX: {
    ISO_2_CODE: "MX",
    ISO_3_CODE: "MEX",
    ADM0_VIZ_NAME: "Mexico",
    ADM0_SOVRN: "Mexico",
    ADM0_NAME: "MEXICO",
  },
  MH: {
    ISO_2_CODE: "MH",
    ISO_3_CODE: "MHL",
    ADM0_VIZ_NAME: "Marshall Islands",
    ADM0_SOVRN: "Marshall Islands",
    ADM0_NAME: "MARSHALL ISLANDS",
  },
  MK: {
    ISO_2_CODE: "MK",
    ISO_3_CODE: "MKD",
    ADM0_VIZ_NAME: "North Macedonia",
    ADM0_SOVRN: "North Macedonia",
    ADM0_NAME: "NORTH MACEDONIA",
  },
  ML: {
    ISO_2_CODE: "ML",
    ISO_3_CODE: "MLI",
    ADM0_VIZ_NAME: "Mali",
    ADM0_SOVRN: "Mali",
    ADM0_NAME: "MALI",
  },
  MT: {
    ISO_2_CODE: "MT",
    ISO_3_CODE: "MLT",
    ADM0_VIZ_NAME: "Malta",
    ADM0_SOVRN: "Malta",
    ADM0_NAME: "MALTA",
  },
  MM: {
    ISO_2_CODE: "MM",
    ISO_3_CODE: "MMR",
    ADM0_VIZ_NAME: "Myanmar",
    ADM0_SOVRN: "Myanmar",
    ADM0_NAME: "MYANMAR",
  },
  ME: {
    ISO_2_CODE: "ME",
    ISO_3_CODE: "MNE",
    ADM0_VIZ_NAME: "Montenegro",
    ADM0_SOVRN: "Montenegro",
    ADM0_NAME: "MONTENEGRO",
  },
  MN: {
    ISO_2_CODE: "MN",
    ISO_3_CODE: "MNG",
    ADM0_VIZ_NAME: "Mongolia",
    ADM0_SOVRN: "Mongolia",
    ADM0_NAME: "MONGOLIA",
  },
  MP: {
    ISO_2_CODE: "MP",
    ISO_3_CODE: "MNP",
    ADM0_VIZ_NAME: "Northern Mariana Islands (Commonwealth of the)",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "NORTHERN MARIANA ISLANDS, COMMONWEALTH OF THE",
  },
  MZ: {
    ISO_2_CODE: "MZ",
    ISO_3_CODE: "MOZ",
    ADM0_VIZ_NAME: "Mozambique",
    ADM0_SOVRN: "Mozambique",
    ADM0_NAME: "MOZAMBIQUE",
  },
  MR: {
    ISO_2_CODE: "MR",
    ISO_3_CODE: "MRT",
    ADM0_VIZ_NAME: "Mauritania",
    ADM0_SOVRN: "Mauritania",
    ADM0_NAME: "MAURITANIA",
  },
  MS: {
    ISO_2_CODE: "MS",
    ISO_3_CODE: "MSR",
    ADM0_VIZ_NAME: "Montserrat",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "MONTSERRAT",
  },
  MQ: {
    ISO_2_CODE: "MQ",
    ISO_3_CODE: "MTQ",
    ADM0_VIZ_NAME: "Martinique",
    ADM0_SOVRN: "France",
    ADM0_NAME: "MARTINIQUE",
  },
  MU: {
    ISO_2_CODE: "MU",
    ISO_3_CODE: "MUS",
    ADM0_VIZ_NAME: "Mauritius",
    ADM0_SOVRN: "Mauritius",
    ADM0_NAME: "MAURITIUS",
  },
  MW: {
    ISO_2_CODE: "MW",
    ISO_3_CODE: "MWI",
    ADM0_VIZ_NAME: "Malawi",
    ADM0_SOVRN: "Malawi",
    ADM0_NAME: "MALAWI",
  },
  MY: {
    ISO_2_CODE: "MY",
    ISO_3_CODE: "MYS",
    ADM0_VIZ_NAME: "Malaysia",
    ADM0_SOVRN: "Malaysia",
    ADM0_NAME: "MALAYSIA",
  },
  YT: {
    ISO_2_CODE: "YT",
    ISO_3_CODE: "MYT",
    ADM0_VIZ_NAME: "Mayotte",
    ADM0_SOVRN: "France",
    ADM0_NAME: "MAYOTTE",
  },
  NA: {
    ISO_2_CODE: "NA",
    ISO_3_CODE: "NAM",
    ADM0_VIZ_NAME: "Namibia",
    ADM0_SOVRN: "Namibia",
    ADM0_NAME: "NAMIBIA",
  },
  NC: {
    ISO_2_CODE: "NC",
    ISO_3_CODE: "NCL",
    ADM0_VIZ_NAME: "New Caledonia",
    ADM0_SOVRN: "France",
    ADM0_NAME: "NEW CALEDONIA",
  },
  NE: {
    ISO_2_CODE: "NE",
    ISO_3_CODE: "NER",
    ADM0_VIZ_NAME: "Niger",
    ADM0_SOVRN: "Niger",
    ADM0_NAME: "NIGER",
  },
  NF: {
    ISO_2_CODE: "NF",
    ISO_3_CODE: "NFK",
    ADM0_VIZ_NAME: "Norfolk Island",
    ADM0_SOVRN: "Australia",
    ADM0_NAME: "NORFOLK ISLAND",
  },
  NG: {
    ISO_2_CODE: "NG",
    ISO_3_CODE: "NGA",
    ADM0_VIZ_NAME: "Nigeria",
    ADM0_SOVRN: "Nigeria",
    ADM0_NAME: "NIGERIA",
  },
  NI: {
    ISO_2_CODE: "NI",
    ISO_3_CODE: "NIC",
    ADM0_VIZ_NAME: "Nicaragua",
    ADM0_SOVRN: "Nicaragua",
    ADM0_NAME: "NICARAGUA",
  },
  NU: {
    ISO_2_CODE: "NU",
    ISO_3_CODE: "NIU",
    ADM0_VIZ_NAME: "Niue",
    ADM0_SOVRN: "New Zealand",
    ADM0_NAME: "NIUE",
  },
  NL: {
    ISO_2_CODE: "NL",
    ISO_3_CODE: "NLD",
    ADM0_VIZ_NAME: "Netherlands",
    ADM0_SOVRN: "Netherlands",
    ADM0_NAME: "NETHERLANDS",
  },
  NO: {
    ISO_2_CODE: "NO",
    ISO_3_CODE: "NOR",
    ADM0_VIZ_NAME: "Norway",
    ADM0_SOVRN: "Norway",
    ADM0_NAME: "NORWAY",
  },
  NP: {
    ISO_2_CODE: "NP",
    ISO_3_CODE: "NPL",
    ADM0_VIZ_NAME: "Nepal",
    ADM0_SOVRN: "Nepal",
    ADM0_NAME: "NEPAL",
  },
  NR: {
    ISO_2_CODE: "NR",
    ISO_3_CODE: "NRU",
    ADM0_VIZ_NAME: "Nauru",
    ADM0_SOVRN: "Nauru",
    ADM0_NAME: "NAURU",
  },
  NZ: {
    ISO_2_CODE: "NZ",
    ISO_3_CODE: "NZL",
    ADM0_VIZ_NAME: "New Zealand",
    ADM0_SOVRN: "New Zealand",
    ADM0_NAME: "NEW ZEALAND",
  },
  OM: {
    ISO_2_CODE: "OM",
    ISO_3_CODE: "OMN",
    ADM0_VIZ_NAME: "Oman",
    ADM0_SOVRN: "Oman",
    ADM0_NAME: "OMAN",
  },
  PK: {
    ISO_2_CODE: "PK",
    ISO_3_CODE: "PAK",
    ADM0_VIZ_NAME: "Pakistan",
    ADM0_SOVRN: "Pakistan",
    ADM0_NAME: "PAKISTAN",
  },
  PA: {
    ISO_2_CODE: "PA",
    ISO_3_CODE: "PAN",
    ADM0_VIZ_NAME: "Panama",
    ADM0_SOVRN: "Panama",
    ADM0_NAME: "PANAMA",
  },
  PN: {
    ISO_2_CODE: "PN",
    ISO_3_CODE: "PCN",
    ADM0_VIZ_NAME: "Pitcairn Islands",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "PITCAIRN ISLANDS",
  },
  PE: {
    ISO_2_CODE: "PE",
    ISO_3_CODE: "PER",
    ADM0_VIZ_NAME: "Peru",
    ADM0_SOVRN: "Peru",
    ADM0_NAME: "PERU",
  },
  PH: {
    ISO_2_CODE: "PH",
    ISO_3_CODE: "PHL",
    ADM0_VIZ_NAME: "Philippines",
    ADM0_SOVRN: "Philippines",
    ADM0_NAME: "PHILIPPINES",
  },
  PW: {
    ISO_2_CODE: "PW",
    ISO_3_CODE: "PLW",
    ADM0_VIZ_NAME: "Palau",
    ADM0_SOVRN: "Palau",
    ADM0_NAME: "PALAU",
  },
  PL: {
    ISO_2_CODE: "PL",
    ISO_3_CODE: "POL",
    ADM0_VIZ_NAME: "Poland",
    ADM0_SOVRN: "Poland",
    ADM0_NAME: "POLAND",
  },
  PR: {
    ISO_2_CODE: "PR",
    ISO_3_CODE: "PRI",
    ADM0_VIZ_NAME: "Puerto Rico",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "PUERTO RICO",
  },
  KP: {
    ISO_2_CODE: "KP",
    ISO_3_CODE: "PRK",
    ADM0_VIZ_NAME: "Democratic People's Republic of Korea",
    ADM0_SOVRN: "Democratic People's Republic of Korea",
    ADM0_NAME: "DEMOCRATIC PEOPLE'S REPUBLIC OF KOREA",
  },
  PT: {
    ISO_2_CODE: "PT",
    ISO_3_CODE: "PRT",
    ADM0_VIZ_NAME: "Portugal",
    ADM0_SOVRN: "Portugal",
    ADM0_NAME: "PORTUGAL",
  },
  PY: {
    ISO_2_CODE: "PY",
    ISO_3_CODE: "PRY",
    ADM0_VIZ_NAME: "Paraguay",
    ADM0_SOVRN: "Paraguay",
    ADM0_NAME: "PARAGUAY",
  },
  PS: {
    ISO_2_CODE: "PS",
    ISO_3_CODE: "PSE",
    ADM0_VIZ_NAME: "occupied Palestinian territory, including east Jerusalem",
    ADM0_SOVRN: "occupied Palestinian territory",
    ADM0_NAME: "OCCUPIED PALESTINIAN TERRITORY",
  },
  PF: {
    ISO_2_CODE: "PF",
    ISO_3_CODE: "PYF",
    ADM0_VIZ_NAME: "French Polynesia",
    ADM0_SOVRN: "France",
    ADM0_NAME: "FRENCH POLYNESIA",
  },
  QA: {
    ISO_2_CODE: "QA",
    ISO_3_CODE: "QAT",
    ADM0_VIZ_NAME: "Qatar",
    ADM0_SOVRN: "Qatar",
    ADM0_NAME: "QATAR",
  },
  RE: {
    ISO_2_CODE: "RE",
    ISO_3_CODE: "REU",
    ADM0_VIZ_NAME: "Réunion",
    ADM0_SOVRN: "France",
    ADM0_NAME: "RÉUNION",
  },
  RO: {
    ISO_2_CODE: "RO",
    ISO_3_CODE: "ROU",
    ADM0_VIZ_NAME: "Romania",
    ADM0_SOVRN: "Romania",
    ADM0_NAME: "ROMANIA",
  },
  RU: {
    ISO_2_CODE: "RU",
    ISO_3_CODE: "RUS",
    ADM0_VIZ_NAME: "Russian Federation",
    ADM0_SOVRN: "Russian Federation",
    ADM0_NAME: "RUSSIAN FEDERATION",
  },
  RW: {
    ISO_2_CODE: "RW",
    ISO_3_CODE: "RWA",
    ADM0_VIZ_NAME: "Rwanda",
    ADM0_SOVRN: "Rwanda",
    ADM0_NAME: "RWANDA",
  },
  SA: {
    ISO_2_CODE: "SA",
    ISO_3_CODE: "SAU",
    ADM0_VIZ_NAME: "Saudi Arabia",
    ADM0_SOVRN: "Saudi Arabia",
    ADM0_NAME: "SAUDI ARABIA",
  },
  SD: {
    ISO_2_CODE: "SD",
    ISO_3_CODE: "SDN",
    ADM0_VIZ_NAME: "Sudan",
    ADM0_SOVRN: "Sudan",
    ADM0_NAME: "SUDAN",
  },
  SN: {
    ISO_2_CODE: "SN",
    ISO_3_CODE: "SEN",
    ADM0_VIZ_NAME: "Senegal",
    ADM0_SOVRN: "Senegal",
    ADM0_NAME: "SENEGAL",
  },
  SG: {
    ISO_2_CODE: "SG",
    ISO_3_CODE: "SGP",
    ADM0_VIZ_NAME: "Singapore",
    ADM0_SOVRN: "Singapore",
    ADM0_NAME: "SINGAPORE",
  },
  SH: {
    ISO_2_CODE: "SH",
    ISO_3_CODE: "SHN",
    ADM0_VIZ_NAME: "Saint Helena",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "SAINT HELENA",
  },
  SJ: {
    ISO_2_CODE: "SJ",
    ISO_3_CODE: "SJM",
    ADM0_VIZ_NAME: "Svalbard and Jan Mayen Islands",
    ADM0_SOVRN: "Norway",
    ADM0_NAME: "SVALBARD AND JAN MAYEN ISLANDS",
  },
  SB: {
    ISO_2_CODE: "SB",
    ISO_3_CODE: "SLB",
    ADM0_VIZ_NAME: "Solomon Islands",
    ADM0_SOVRN: "Solomon Islands",
    ADM0_NAME: "SOLOMON ISLANDS",
  },
  SL: {
    ISO_2_CODE: "SL",
    ISO_3_CODE: "SLE",
    ADM0_VIZ_NAME: "Sierra Leone",
    ADM0_SOVRN: "Sierra Leone",
    ADM0_NAME: "SIERRA LEONE",
  },
  SV: {
    ISO_2_CODE: "SV",
    ISO_3_CODE: "SLV",
    ADM0_VIZ_NAME: "El Salvador",
    ADM0_SOVRN: "El Salvador",
    ADM0_NAME: "EL SALVADOR",
  },
  SM: {
    ISO_2_CODE: "SM",
    ISO_3_CODE: "SMR",
    ADM0_VIZ_NAME: "San Marino",
    ADM0_SOVRN: "San Marino",
    ADM0_NAME: "SAN MARINO",
  },
  SO: {
    ISO_2_CODE: "SO",
    ISO_3_CODE: "SOM",
    ADM0_VIZ_NAME: "Somalia",
    ADM0_SOVRN: "Somalia",
    ADM0_NAME: "SOMALIA",
  },
  PM: {
    ISO_2_CODE: "PM",
    ISO_3_CODE: "SPM",
    ADM0_VIZ_NAME: "Saint Pierre and Miquelon",
    ADM0_SOVRN: "France",
    ADM0_NAME: "SAINT PIERRE AND MIQUELON",
  },
  RS: {
    ISO_2_CODE: "RS",
    ISO_3_CODE: "SRB",
    ADM0_VIZ_NAME: "Serbia",
    ADM0_SOVRN: "Serbia",
    ADM0_NAME: "SERBIA",
  },
  SS: {
    ISO_2_CODE: "SS",
    ISO_3_CODE: "SSD",
    ADM0_VIZ_NAME: "South Sudan",
    ADM0_SOVRN: "South Sudan",
    ADM0_NAME: "SOUTH SUDAN",
  },
  ST: {
    ISO_2_CODE: "ST",
    ISO_3_CODE: "STP",
    ADM0_VIZ_NAME: "Sao Tome and Principe",
    ADM0_SOVRN: "Sao Tome and Principe",
    ADM0_NAME: "SAO TOME AND PRINCIPE",
  },
  SR: {
    ISO_2_CODE: "SR",
    ISO_3_CODE: "SUR",
    ADM0_VIZ_NAME: "Suriname",
    ADM0_SOVRN: "Suriname",
    ADM0_NAME: "SURINAME",
  },
  SK: {
    ISO_2_CODE: "SK",
    ISO_3_CODE: "SVK",
    ADM0_VIZ_NAME: "Slovakia",
    ADM0_SOVRN: "Slovakia",
    ADM0_NAME: "SLOVAKIA",
  },
  SI: {
    ISO_2_CODE: "SI",
    ISO_3_CODE: "SVN",
    ADM0_VIZ_NAME: "Slovenia",
    ADM0_SOVRN: "Slovenia",
    ADM0_NAME: "SLOVENIA",
  },
  SE: {
    ISO_2_CODE: "SE",
    ISO_3_CODE: "SWE",
    ADM0_VIZ_NAME: "Sweden",
    ADM0_SOVRN: "Sweden",
    ADM0_NAME: "SWEDEN",
  },
  SZ: {
    ISO_2_CODE: "SZ",
    ISO_3_CODE: "SWZ",
    ADM0_VIZ_NAME: "Eswatini",
    ADM0_SOVRN: "Eswatini",
    ADM0_NAME: "ESWATINI",
  },
  SX: {
    ISO_2_CODE: "SX",
    ISO_3_CODE: "SXM",
    ADM0_VIZ_NAME: "Sint Maarten",
    ADM0_SOVRN: "Netherlands",
    ADM0_NAME: "SINT MAARTEN",
  },
  SC: {
    ISO_2_CODE: "SC",
    ISO_3_CODE: "SYC",
    ADM0_VIZ_NAME: "Seychelles",
    ADM0_SOVRN: "Seychelles",
    ADM0_NAME: "SEYCHELLES",
  },
  SY: {
    ISO_2_CODE: "SY",
    ISO_3_CODE: "SYR",
    ADM0_VIZ_NAME: "Syrian Arab Republic",
    ADM0_SOVRN: "Syrian Arab Republic",
    ADM0_NAME: "SYRIAN ARAB REPUBLIC",
  },
  TC: {
    ISO_2_CODE: "TC",
    ISO_3_CODE: "TCA",
    ADM0_VIZ_NAME: "Turks and Caicos Islands",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "TURKS AND CAICOS ISLANDS",
  },
  TD: {
    ISO_2_CODE: "TD",
    ISO_3_CODE: "TCD",
    ADM0_VIZ_NAME: "Chad",
    ADM0_SOVRN: "Chad",
    ADM0_NAME: "CHAD",
  },
  TG: {
    ISO_2_CODE: "TG",
    ISO_3_CODE: "TGO",
    ADM0_VIZ_NAME: "Togo",
    ADM0_SOVRN: "Togo",
    ADM0_NAME: "TOGO",
  },
  TH: {
    ISO_2_CODE: "TH",
    ISO_3_CODE: "THA",
    ADM0_VIZ_NAME: "Thailand",
    ADM0_SOVRN: "Thailand",
    ADM0_NAME: "THAILAND",
  },
  TJ: {
    ISO_2_CODE: "TJ",
    ISO_3_CODE: "TJK",
    ADM0_VIZ_NAME: "Tajikistan",
    ADM0_SOVRN: "Tajikistan",
    ADM0_NAME: "TAJIKISTAN",
  },
  TK: {
    ISO_2_CODE: "TK",
    ISO_3_CODE: "TKL",
    ADM0_VIZ_NAME: "Tokelau",
    ADM0_SOVRN: "New Zealand",
    ADM0_NAME: "TOKELAU",
  },
  TM: {
    ISO_2_CODE: "TM",
    ISO_3_CODE: "TKM",
    ADM0_VIZ_NAME: "Turkmenistan",
    ADM0_SOVRN: "Turkmenistan",
    ADM0_NAME: "TURKMENISTAN",
  },
  TL: {
    ISO_2_CODE: "TL",
    ISO_3_CODE: "TLS",
    ADM0_VIZ_NAME: "Timor-Leste",
    ADM0_SOVRN: "Timor-Leste",
    ADM0_NAME: "TIMOR-LESTE",
  },
  TO: {
    ISO_2_CODE: "TO",
    ISO_3_CODE: "TON",
    ADM0_VIZ_NAME: "Tonga",
    ADM0_SOVRN: "Tonga",
    ADM0_NAME: "TONGA",
  },
  TT: {
    ISO_2_CODE: "TT",
    ISO_3_CODE: "TTO",
    ADM0_VIZ_NAME: "Trinidad and Tobago",
    ADM0_SOVRN: "Trinidad and Tobago",
    ADM0_NAME: "TRINIDAD AND TOBAGO",
  },
  TN: {
    ISO_2_CODE: "TN",
    ISO_3_CODE: "TUN",
    ADM0_VIZ_NAME: "Tunisia",
    ADM0_SOVRN: "Tunisia",
    ADM0_NAME: "TUNISIA",
  },
  TR: {
    ISO_2_CODE: "TR",
    ISO_3_CODE: "TUR",
    ADM0_VIZ_NAME: "Turkey",
    ADM0_SOVRN: "Turkey",
    ADM0_NAME: "TURKEY",
  },
  TV: {
    ISO_2_CODE: "TV",
    ISO_3_CODE: "TUV",
    ADM0_VIZ_NAME: "Tuvalu",
    ADM0_SOVRN: "Tuvalu",
    ADM0_NAME: "TUVALU",
  },
  TZ: {
    ISO_2_CODE: "TZ",
    ISO_3_CODE: "TZA",
    ADM0_VIZ_NAME: "United Republic of Tanzania",
    ADM0_SOVRN: "United Republic of Tanzania",
    ADM0_NAME: "UNITED REPUBLIC OF TANZANIA",
  },
  UG: {
    ISO_2_CODE: "UG",
    ISO_3_CODE: "UGA",
    ADM0_VIZ_NAME: "Uganda",
    ADM0_SOVRN: "Uganda",
    ADM0_NAME: "UGANDA",
  },
  UA: {
    ISO_2_CODE: "UA",
    ISO_3_CODE: "UKR",
    ADM0_VIZ_NAME: "Ukraine",
    ADM0_SOVRN: "Ukraine",
    ADM0_NAME: "UKRAINE",
  },
  UY: {
    ISO_2_CODE: "UY",
    ISO_3_CODE: "URY",
    ADM0_VIZ_NAME: "Uruguay",
    ADM0_SOVRN: "Uruguay",
    ADM0_NAME: "URUGUAY",
  },
  US: {
    ISO_2_CODE: "US",
    ISO_3_CODE: "USA",
    ADM0_VIZ_NAME: "United States of America",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "UNITED STATES OF AMERICA",
  },
  UZ: {
    ISO_2_CODE: "UZ",
    ISO_3_CODE: "UZB",
    ADM0_VIZ_NAME: "Uzbekistan",
    ADM0_SOVRN: "Uzbekistan",
    ADM0_NAME: "UZBEKISTAN",
  },
  VA: {
    ISO_2_CODE: "VA",
    ISO_3_CODE: "VAT",
    ADM0_VIZ_NAME: "Holy See",
    ADM0_SOVRN: "Holy See",
    ADM0_NAME: "HOLY SEE",
  },
  VC: {
    ISO_2_CODE: "VC",
    ISO_3_CODE: "VCT",
    ADM0_VIZ_NAME: "Saint Vincent and the Grenadines",
    ADM0_SOVRN: "Saint Vincent and the Grenadines",
    ADM0_NAME: "SAINT VINCENT AND THE GRENADINES",
  },
  VE: {
    ISO_2_CODE: "VE",
    ISO_3_CODE: "VEN",
    ADM0_VIZ_NAME: "Venezuela (Bolivarian Republic of)",
    ADM0_SOVRN: "Bolivarian Republic of Venezuela",
    ADM0_NAME: "BOLIVARIAN REPUBLIC OF VENEZUELA",
  },
  VG: {
    ISO_2_CODE: "VG",
    ISO_3_CODE: "VGB",
    ADM0_VIZ_NAME: "British Virgin Islands",
    ADM0_SOVRN: "United Kingdom of Great Britain and Northern Ireland",
    ADM0_NAME: "BRITISH VIRGIN ISLANDS",
  },
  VI: {
    ISO_2_CODE: "VI",
    ISO_3_CODE: "VIR",
    ADM0_VIZ_NAME: "United States Virgin Islands",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "UNITED STATES VIRGIN ISLANDS",
  },
  VN: {
    ISO_2_CODE: "VN",
    ISO_3_CODE: "VNM",
    ADM0_VIZ_NAME: "Viet Nam",
    ADM0_SOVRN: "Viet Nam",
    ADM0_NAME: "VIET NAM",
  },
  VU: {
    ISO_2_CODE: "VU",
    ISO_3_CODE: "VUT",
    ADM0_VIZ_NAME: "Vanuatu",
    ADM0_SOVRN: "Vanuatu",
    ADM0_NAME: "VANUATU",
  },
  WF: {
    ISO_2_CODE: "WF",
    ISO_3_CODE: "WLF",
    ADM0_VIZ_NAME: "Wallis and Futuna",
    ADM0_SOVRN: "France",
    ADM0_NAME: "WALLIS AND FUTUNA",
  },
  WS: {
    ISO_2_CODE: "WS",
    ISO_3_CODE: "WSM",
    ADM0_VIZ_NAME: "Samoa",
    ADM0_SOVRN: "Samoa",
    ADM0_NAME: "SAMOA",
  },
  YE: {
    ISO_2_CODE: "YE",
    ISO_3_CODE: "YEM",
    ADM0_VIZ_NAME: "Yemen",
    ADM0_SOVRN: "Yemen",
    ADM0_NAME: "YEMEN",
  },
  ZA: {
    ISO_2_CODE: "ZA",
    ISO_3_CODE: "ZAF",
    ADM0_VIZ_NAME: "South Africa",
    ADM0_SOVRN: "South Africa",
    ADM0_NAME: "SOUTH AFRICA",
  },
  ZM: {
    ISO_2_CODE: "ZM",
    ISO_3_CODE: "ZMB",
    ADM0_VIZ_NAME: "Zambia",
    ADM0_SOVRN: "Zambia",
    ADM0_NAME: "ZAMBIA",
  },
  ZW: {
    ISO_2_CODE: "ZW",
    ISO_3_CODE: "ZWE",
    ADM0_VIZ_NAME: "Zimbabwe",
    ADM0_SOVRN: "Zimbabwe",
    ADM0_NAME: "ZIMBABWE",
  },
  CD: {
    ISO_2_CODE: "CD",
    ISO_3_CODE: "COD",
    ADM0_VIZ_NAME: "Democratic Republic of the Congo",
    ADM0_SOVRN: "Democratic Republic of the Congo",
    ADM0_NAME: "DEMOCRATIC REPUBLIC OF THE CONGO",
  },
  CG: {
    ISO_2_CODE: "CG",
    ISO_3_CODE: "COG",
    ADM0_VIZ_NAME: "Congo",
    ADM0_SOVRN: "Congo",
    ADM0_NAME: "CONGO",
  },
  PG: {
    ISO_2_CODE: "PG",
    ISO_3_CODE: "PNG",
    ADM0_VIZ_NAME: "Papua New Guinea",
    ADM0_SOVRN: "Papua New Guinea",
    ADM0_NAME: "PAPUA NEW GUINEA",
  },
  JT: {
    ISO_2_CODE: "JT",
    ISO_3_CODE: "JTN",
    ADM0_VIZ_NAME: "Johnston Atoll",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "JOHNSTON ATOLL",
  },
  UM: {
    ISO_2_CODE: "UM",
    ISO_3_CODE: "MID",
    ADM0_VIZ_NAME: "Midway Islands",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "MIDWAY ISLANDS",
  },
  WK: {
    ISO_2_CODE: "WK",
    ISO_3_CODE: "WAK",
    ADM0_VIZ_NAME: "Wake Island",
    ADM0_SOVRN: "United States of America",
    ADM0_NAME: "WAKE ISLAND",
  },
  CI: {
    ISO_2_CODE: "CI",
    ISO_3_CODE: "CIV",
    ADM0_VIZ_NAME: "Côte d’Ivoire",
    ADM0_SOVRN: "Cote d Ivoire",
    ADM0_NAME: "COTE D IVOIRE",
  },
  XK: {
    ISO_2_CODE: "XK",
    ISO_3_CODE: "XKX",
    ADM0_VIZ_NAME: "Kosovo[1]",
    ADM0_SOVRN: "Kosovo",
    ADM0_NAME: "KOSOVO",
  },
};
