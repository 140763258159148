import React from "react";

function useOnClickOutside(
  refs: React.RefObject<HTMLElement>[],
  onClickOutside: (e: MouseEvent) => void
): [boolean] {
  const [isActive, setActive] = React.useState(false);

  const isOutside = React.useCallback(
    (e: MouseEvent) => {
      const test = refs.map((ref) => {
        return (
          ref.current !== null && !ref.current.contains(e.target as HTMLElement)
        );
      });

      return test.every(Boolean);
    },
    [refs]
  );

  const mousedown = React.useCallback(
    (e: MouseEvent) => {
      if (isOutside(e)) {
        setActive(true);
        onClickOutside(e);
      }
    },
    [isOutside, onClickOutside]
  );

  React.useEffect(() => {
    document.addEventListener("click", mousedown);

    return () => {
      document.removeEventListener("click", mousedown);
    };
  }, [refs, onClickOutside, mousedown]);

  return [isActive];
}

export default useOnClickOutside;
