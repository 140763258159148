import React, { useCallback } from "react";
import { Bar } from "@vx/shape";
import { Group } from "@vx/group";
import { scaleBand, scaleLinear, scaleTime } from "@vx/scale";
import moment from "moment";
import styled from "../../designSystem";
import theme, { getColor } from "../../designSystem/theme";
import XAxis from "../xAxis";
import { extent } from "d3-array";
import useResizeObserver from "use-resize-observer/polyfilled";
import Flexbox from "../../layout/flexbox";
import ColumnToolTip, {
  tooltipWidth,
  arrowWidth,
  useToolTip,
  emptyTip,
  getTipOffsets,
  getTipData,
} from "./columnToolTip";
import { Region } from "../../utils/regions";
import {
  getDateFormat,
  numTicksForWidth,
  options,
  getBandwidth,
} from "./columnAxis";
import { media } from "../../designSystem/responsive";
import { localPoint } from "@vx/event";

type SingleColumn = {
  x: Date;
  y?: number;
};

export type ColumnItem = {
  x: Date;
  y?: number;
  data?: number[];
};

const StyledColumnChart = styled(Flexbox)`
  max-width: 100%;
  position: relative;
  cursor: pointer;
  .bar_fill {
    transition: fill 300ms;
  }
  .vx-axis-bottom {
    pointer-events: none;
    user-select: none;
    touch-action: none;
  }
  ${(media as any).s`
    .primary_font_family {
      font-size: 11px;
    }
  `}
`;

// accessors
const x = (d) => moment.utc(d?.x).valueOf();
const y = (d) => d?.y;

type ColumnChartProps = {
  metric: string;
  region?: Region;
  sharedMax?: number;
  data: SingleColumn[];
  onScrub?: (metric: string) => void;
};

const ColumnChart: React.FC<ColumnChartProps> = ({
  data,
  metric,
  region,
  onScrub,
  sharedMax,
}) => {
  const { ref: chartRef, height = 0, width = 0 } = useResizeObserver();
  const tooltipTimeout = React.useRef(null);

  const margin = {
    top: 0,
    right: 0,
    bottom: 40,
    left: 0,
  };

  const [toolTip, setToolTip] = useToolTip();
  const { open: tooltipOpen, index: tooltipIndex } = toolTip;

  const yMaxDomain = sharedMax || Math.max(...data.map((item) => item.y));

  // bounds
  const xMax = width;
  const yMax = !!height ? height - margin.bottom : 0;

  // scales
  const xScale = scaleBand({
    domain: data.map(x),
    padding: 0.2,
    range: [0, xMax],
  });

  const yScale = scaleLinear({
    domain: [0, yMaxDomain],
    nice: true,
    range: [yMax, 0],
  });

  const xScaleTicks = scaleTime({
    range: [0, xMax],
    domain: extent(data, x),
  });

  const xTicks = data.map((xTicks) => moment.utc(xTicks.x).format("MMM D"));

  const handleScrub = (event) => {
    if (event) {
      const { x: localX } = localPoint(event);
      const percentge = localX / width;
      const barIndex = Math.abs(Math.ceil(data.length * percentge - 1));

      if (tooltipIndex !== barIndex) {
        if (tooltipTimeout.current) {
          clearTimeout(tooltipTimeout.current);
        }
        const bar = data[barIndex];

        if (bar) {
          const barX = xScale(x(bar));

          const { barXOffset, tipOffset } = getTipOffsets(
            width,
            barX,
            arrowWidth,
            tooltipWidth
          );

          setToolTip({
            open: true,
            offset: tipOffset,
            x: barXOffset + xScale.bandwidth() / 2 - 1,
            index: barIndex,
            y: yMax,
            data: getTipData(data, bar, barIndex),
          });
          if (onScrub) {
            onScrub(region || metric);
          }
        }
      }
    }
  };

  const resetScrub = useCallback(() => {
    tooltipTimeout.current = setTimeout(() => {
      setToolTip(emptyTip);
      if (onScrub) {
        onScrub(undefined);
      }
    }, 300);
  }, [setToolTip, onScrub]);

  return (
    <StyledColumnChart ref={chartRef}>
      <svg width={width} height={height}>
        <Group>
          {data.map((bar, index) => {
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - yScale(y(bar));
            const barX = xScale(x(bar));
            const barY = yMax - barHeight;
            return (
              <Bar
                rx={2}
                ry={2}
                className={`bar_fill`}
                key={`bar-${index}`}
                x={barX}
                y={barY}
                fill={
                  tooltipIndex === index
                    ? getColor(region || metric)
                    : theme.chartSecondary
                }
                width={barWidth}
                height={barHeight}
              />
            );
          })}
        </Group>

        <XAxis
          left={0}
          xMax={xMax}
          top={yMax + 15}
          bandwidth={getBandwidth(width)}
          dateFormat={getDateFormat(width)}
          hideAxisLine={false}
          label=""
          labelType="STRING"
          numTicksForWidth={numTicksForWidth(width)}
          options={options}
          xScale={xScaleTicks}
          xValues={xTicks}
        />

        <Bar
          x={0}
          y={0}
          width={width}
          height={height}
          fill="transparent"
          onTouchStart={handleScrub}
          onTouchMove={handleScrub}
          onTouchEnd={resetScrub}
          onMouseMove={handleScrub}
          onMouseLeave={resetScrub}
        />
      </svg>

      {tooltipOpen && (
        <ColumnToolTip
          toolTip={toolTip}
          metrics={[metric]}
          regions={[region]}
        />
      )}
    </StyledColumnChart>
  );
};

export default ColumnChart;
