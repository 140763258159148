import React, { useCallback } from "react";
import styled from "../designSystem";
import { Link, useLocation } from "react-router-dom";
import Flexbox from "../layout/flexbox";
import { observer } from "mobx-react-lite";

type NavProps = {
  links: { title: string; route: string }[];
  navClicked?: () => void;
  useHambuger?: boolean;
};

const StyledNav = styled(Flexbox)``;

const NavList = styled(Flexbox)`
  margin: 0;
`;

const NavItem = styled.li<{ active: boolean }>`
  list-style: none;
  a {
    display: block;
    transition: color 200ms;
    color: ${({ active, theme }) =>
      active ? theme.primary : theme.textPrimary};
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    :active,
    :hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

const Nav: React.FC<NavProps> = ({ links, navClicked, useHambuger }) => {
  const location = useLocation();
  const handleClick = useCallback(() => {
    if (!!navClicked) {
      navClicked();
    }
  }, [navClicked]);
  return (
    <StyledNav as="nav" middle>
      <NavList as="ul" gap="xxxl" right vertical={useHambuger ? true : false}>
        {links.map((link) => {
          const isActive = location.pathname === link.route;
          return (
            <NavItem key={link.title} active={isActive}>
              <Link onClick={handleClick} to={link.route}>
                {link.title}
              </Link>
            </NavItem>
          );
        })}
      </NavList>
    </StyledNav>
  );
};

Nav.displayName = "Nav";
export default observer(Nav);
