const test = {
  Thailand: [
    {
      date: "2020-1-22",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
};

const data = {
  Thailand: [
    {
      date: "2020-1-22",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 8,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-1-27",
      confirmed: 8,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-1-28",
      confirmed: 14,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-1-29",
      confirmed: 14,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-1-30",
      confirmed: 14,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-1-31",
      confirmed: 19,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-1",
      confirmed: 19,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-2",
      confirmed: 19,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-3",
      confirmed: 19,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-4",
      confirmed: 25,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-5",
      confirmed: 25,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-6",
      confirmed: 25,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-7",
      confirmed: 25,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-8",
      confirmed: 32,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-9",
      confirmed: 32,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-10",
      confirmed: 32,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-11",
      confirmed: 33,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-12",
      confirmed: 33,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-13",
      confirmed: 33,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-14",
      confirmed: 33,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-15",
      confirmed: 33,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-16",
      confirmed: 34,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-17",
      confirmed: 35,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-18",
      confirmed: 35,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-19",
      confirmed: 35,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-20",
      confirmed: 35,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-21",
      confirmed: 35,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-2-22",
      confirmed: 35,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-2-23",
      confirmed: 35,
      deaths: 0,
      recovered: 21,
    },
    {
      date: "2020-2-24",
      confirmed: 35,
      deaths: 0,
      recovered: 21,
    },
    {
      date: "2020-2-25",
      confirmed: 37,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-2-26",
      confirmed: 40,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-2-27",
      confirmed: 40,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-2-28",
      confirmed: 41,
      deaths: 0,
      recovered: 28,
    },
    {
      date: "2020-2-29",
      confirmed: 42,
      deaths: 0,
      recovered: 28,
    },
    {
      date: "2020-3-1",
      confirmed: 42,
      deaths: 1,
      recovered: 28,
    },
    {
      date: "2020-3-2",
      confirmed: 43,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-3",
      confirmed: 43,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-4",
      confirmed: 43,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-5",
      confirmed: 47,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-6",
      confirmed: 48,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-7",
      confirmed: 50,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-8",
      confirmed: 50,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-9",
      confirmed: 50,
      deaths: 1,
      recovered: 31,
    },
    {
      date: "2020-3-10",
      confirmed: 53,
      deaths: 1,
      recovered: 33,
    },
    {
      date: "2020-3-11",
      confirmed: 59,
      deaths: 1,
      recovered: 34,
    },
    {
      date: "2020-3-12",
      confirmed: 70,
      deaths: 1,
      recovered: 34,
    },
    {
      date: "2020-3-13",
      confirmed: 75,
      deaths: 1,
      recovered: 35,
    },
    {
      date: "2020-3-14",
      confirmed: 82,
      deaths: 1,
      recovered: 35,
    },
    {
      date: "2020-3-15",
      confirmed: 114,
      deaths: 1,
      recovered: 35,
    },
    {
      date: "2020-3-16",
      confirmed: 147,
      deaths: 1,
      recovered: 35,
    },
    {
      date: "2020-3-17",
      confirmed: 177,
      deaths: 1,
      recovered: 41,
    },
    {
      date: "2020-3-18",
      confirmed: 212,
      deaths: 1,
      recovered: 42,
    },
    {
      date: "2020-3-19",
      confirmed: 272,
      deaths: 1,
      recovered: 42,
    },
    {
      date: "2020-3-20",
      confirmed: 322,
      deaths: 1,
      recovered: 42,
    },
  ],
  Japan: [
    {
      date: "2020-1-22",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-1-27",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-1-28",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-1-29",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-1-30",
      confirmed: 11,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-1-31",
      confirmed: 15,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-1",
      confirmed: 20,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-2",
      confirmed: 20,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-3",
      confirmed: 20,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-4",
      confirmed: 22,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-5",
      confirmed: 22,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-6",
      confirmed: 45,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-7",
      confirmed: 25,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-8",
      confirmed: 25,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-9",
      confirmed: 26,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-10",
      confirmed: 26,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-11",
      confirmed: 26,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-2-12",
      confirmed: 28,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-2-13",
      confirmed: 28,
      deaths: 1,
      recovered: 9,
    },
    {
      date: "2020-2-14",
      confirmed: 29,
      deaths: 1,
      recovered: 9,
    },
    {
      date: "2020-2-15",
      confirmed: 43,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-2-16",
      confirmed: 59,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-2-17",
      confirmed: 66,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-2-18",
      confirmed: 74,
      deaths: 1,
      recovered: 13,
    },
    {
      date: "2020-2-19",
      confirmed: 84,
      deaths: 1,
      recovered: 18,
    },
    {
      date: "2020-2-20",
      confirmed: 94,
      deaths: 1,
      recovered: 18,
    },
    {
      date: "2020-2-21",
      confirmed: 105,
      deaths: 1,
      recovered: 22,
    },
    {
      date: "2020-2-22",
      confirmed: 122,
      deaths: 1,
      recovered: 22,
    },
    {
      date: "2020-2-23",
      confirmed: 147,
      deaths: 1,
      recovered: 22,
    },
    {
      date: "2020-2-24",
      confirmed: 159,
      deaths: 1,
      recovered: 22,
    },
    {
      date: "2020-2-25",
      confirmed: 170,
      deaths: 1,
      recovered: 22,
    },
    {
      date: "2020-2-26",
      confirmed: 189,
      deaths: 2,
      recovered: 22,
    },
    {
      date: "2020-2-27",
      confirmed: 214,
      deaths: 4,
      recovered: 22,
    },
    {
      date: "2020-2-28",
      confirmed: 228,
      deaths: 4,
      recovered: 22,
    },
    {
      date: "2020-2-29",
      confirmed: 241,
      deaths: 5,
      recovered: 32,
    },
    {
      date: "2020-3-1",
      confirmed: 256,
      deaths: 6,
      recovered: 32,
    },
    {
      date: "2020-3-2",
      confirmed: 274,
      deaths: 6,
      recovered: 32,
    },
    {
      date: "2020-3-3",
      confirmed: 293,
      deaths: 6,
      recovered: 43,
    },
    {
      date: "2020-3-4",
      confirmed: 331,
      deaths: 6,
      recovered: 43,
    },
    {
      date: "2020-3-5",
      confirmed: 360,
      deaths: 6,
      recovered: 43,
    },
    {
      date: "2020-3-6",
      confirmed: 420,
      deaths: 6,
      recovered: 46,
    },
    {
      date: "2020-3-7",
      confirmed: 461,
      deaths: 6,
      recovered: 76,
    },
    {
      date: "2020-3-8",
      confirmed: 502,
      deaths: 6,
      recovered: 76,
    },
    {
      date: "2020-3-9",
      confirmed: 511,
      deaths: 10,
      recovered: 76,
    },
    {
      date: "2020-3-10",
      confirmed: 581,
      deaths: 10,
      recovered: 101,
    },
    {
      date: "2020-3-11",
      confirmed: 639,
      deaths: 15,
      recovered: 118,
    },
    {
      date: "2020-3-12",
      confirmed: 639,
      deaths: 16,
      recovered: 118,
    },
    {
      date: "2020-3-13",
      confirmed: 701,
      deaths: 19,
      recovered: 118,
    },
    {
      date: "2020-3-14",
      confirmed: 773,
      deaths: 22,
      recovered: 118,
    },
    {
      date: "2020-3-15",
      confirmed: 839,
      deaths: 22,
      recovered: 118,
    },
    {
      date: "2020-3-16",
      confirmed: 825,
      deaths: 27,
      recovered: 144,
    },
    {
      date: "2020-3-17",
      confirmed: 878,
      deaths: 29,
      recovered: 144,
    },
    {
      date: "2020-3-18",
      confirmed: 889,
      deaths: 29,
      recovered: 144,
    },
    {
      date: "2020-3-19",
      confirmed: 924,
      deaths: 29,
      recovered: 150,
    },
    {
      date: "2020-3-20",
      confirmed: 963,
      deaths: 33,
      recovered: 191,
    },
  ],
  Singapore: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 28,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 28,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 30,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 33,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-9",
      confirmed: 40,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-10",
      confirmed: 45,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-11",
      confirmed: 47,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-2-12",
      confirmed: 50,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-13",
      confirmed: 58,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-14",
      confirmed: 67,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-2-15",
      confirmed: 72,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-2-16",
      confirmed: 75,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-2-17",
      confirmed: 77,
      deaths: 0,
      recovered: 24,
    },
    {
      date: "2020-2-18",
      confirmed: 81,
      deaths: 0,
      recovered: 29,
    },
    {
      date: "2020-2-19",
      confirmed: 84,
      deaths: 0,
      recovered: 34,
    },
    {
      date: "2020-2-20",
      confirmed: 84,
      deaths: 0,
      recovered: 34,
    },
    {
      date: "2020-2-21",
      confirmed: 85,
      deaths: 0,
      recovered: 37,
    },
    {
      date: "2020-2-22",
      confirmed: 85,
      deaths: 0,
      recovered: 37,
    },
    {
      date: "2020-2-23",
      confirmed: 89,
      deaths: 0,
      recovered: 51,
    },
    {
      date: "2020-2-24",
      confirmed: 89,
      deaths: 0,
      recovered: 51,
    },
    {
      date: "2020-2-25",
      confirmed: 91,
      deaths: 0,
      recovered: 53,
    },
    {
      date: "2020-2-26",
      confirmed: 93,
      deaths: 0,
      recovered: 62,
    },
    {
      date: "2020-2-27",
      confirmed: 93,
      deaths: 0,
      recovered: 62,
    },
    {
      date: "2020-2-28",
      confirmed: 93,
      deaths: 0,
      recovered: 62,
    },
    {
      date: "2020-2-29",
      confirmed: 102,
      deaths: 0,
      recovered: 72,
    },
    {
      date: "2020-3-1",
      confirmed: 106,
      deaths: 0,
      recovered: 72,
    },
    {
      date: "2020-3-2",
      confirmed: 108,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-3",
      confirmed: 110,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-4",
      confirmed: 110,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-5",
      confirmed: 117,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-6",
      confirmed: 130,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-7",
      confirmed: 138,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-8",
      confirmed: 150,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-9",
      confirmed: 150,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-10",
      confirmed: 160,
      deaths: 0,
      recovered: 78,
    },
    {
      date: "2020-3-11",
      confirmed: 178,
      deaths: 0,
      recovered: 96,
    },
    {
      date: "2020-3-12",
      confirmed: 178,
      deaths: 0,
      recovered: 96,
    },
    {
      date: "2020-3-13",
      confirmed: 200,
      deaths: 0,
      recovered: 97,
    },
    {
      date: "2020-3-14",
      confirmed: 212,
      deaths: 0,
      recovered: 105,
    },
    {
      date: "2020-3-15",
      confirmed: 226,
      deaths: 0,
      recovered: 105,
    },
    {
      date: "2020-3-16",
      confirmed: 243,
      deaths: 0,
      recovered: 109,
    },
    {
      date: "2020-3-17",
      confirmed: 266,
      deaths: 0,
      recovered: 114,
    },
    {
      date: "2020-3-18",
      confirmed: 313,
      deaths: 0,
      recovered: 114,
    },
    {
      date: "2020-3-19",
      confirmed: 345,
      deaths: 0,
      recovered: 114,
    },
    {
      date: "2020-3-20",
      confirmed: 385,
      deaths: 0,
      recovered: 124,
    },
  ],
  Nepal: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-17",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-19",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
  ],
  Malaysia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 12,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-8",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-9",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-10",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-11",
      confirmed: 18,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-12",
      confirmed: 18,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-13",
      confirmed: 19,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-14",
      confirmed: 19,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-15",
      confirmed: 22,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-16",
      confirmed: 22,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-17",
      confirmed: 22,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-18",
      confirmed: 22,
      deaths: 0,
      recovered: 13,
    },
    {
      date: "2020-2-19",
      confirmed: 22,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-20",
      confirmed: 22,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-21",
      confirmed: 22,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-22",
      confirmed: 22,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-23",
      confirmed: 22,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-24",
      confirmed: 22,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-2-25",
      confirmed: 22,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-2-26",
      confirmed: 22,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-2-27",
      confirmed: 23,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-2-28",
      confirmed: 23,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-2-29",
      confirmed: 25,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-3-1",
      confirmed: 29,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-3-2",
      confirmed: 29,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-3-3",
      confirmed: 36,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-3-4",
      confirmed: 50,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-3-5",
      confirmed: 50,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-3-6",
      confirmed: 83,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-3-7",
      confirmed: 93,
      deaths: 0,
      recovered: 23,
    },
    {
      date: "2020-3-8",
      confirmed: 99,
      deaths: 0,
      recovered: 24,
    },
    {
      date: "2020-3-9",
      confirmed: 117,
      deaths: 0,
      recovered: 24,
    },
    {
      date: "2020-3-10",
      confirmed: 129,
      deaths: 0,
      recovered: 24,
    },
    {
      date: "2020-3-11",
      confirmed: 149,
      deaths: 0,
      recovered: 26,
    },
    {
      date: "2020-3-12",
      confirmed: 149,
      deaths: 0,
      recovered: 26,
    },
    {
      date: "2020-3-13",
      confirmed: 197,
      deaths: 0,
      recovered: 26,
    },
    {
      date: "2020-3-14",
      confirmed: 238,
      deaths: 0,
      recovered: 35,
    },
    {
      date: "2020-3-15",
      confirmed: 428,
      deaths: 0,
      recovered: 42,
    },
    {
      date: "2020-3-16",
      confirmed: 566,
      deaths: 0,
      recovered: 42,
    },
    {
      date: "2020-3-17",
      confirmed: 673,
      deaths: 2,
      recovered: 49,
    },
    {
      date: "2020-3-18",
      confirmed: 790,
      deaths: 2,
      recovered: 60,
    },
    {
      date: "2020-3-19",
      confirmed: 900,
      deaths: 2,
      recovered: 75,
    },
    {
      date: "2020-3-20",
      confirmed: 1030,
      deaths: 3,
      recovered: 87,
    },
  ],
  Canada: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-17",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-19",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 9,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-22",
      confirmed: 9,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-23",
      confirmed: 9,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-24",
      confirmed: 10,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-25",
      confirmed: 11,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-26",
      confirmed: 11,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-27",
      confirmed: 13,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-2-28",
      confirmed: 14,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-2-29",
      confirmed: 20,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-1",
      confirmed: 24,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-2",
      confirmed: 27,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-3",
      confirmed: 30,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-4",
      confirmed: 33,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-5",
      confirmed: 37,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-6",
      confirmed: 49,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-7",
      confirmed: 54,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-8",
      confirmed: 64,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-9",
      confirmed: 77,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-10",
      confirmed: 79,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-11",
      confirmed: 108,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-12",
      confirmed: 117,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-13",
      confirmed: 193,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-14",
      confirmed: 198,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-15",
      confirmed: 252,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-16",
      confirmed: 415,
      deaths: 4,
      recovered: 9,
    },
    {
      date: "2020-3-17",
      confirmed: 478,
      deaths: 5,
      recovered: 9,
    },
    {
      date: "2020-3-18",
      confirmed: 657,
      deaths: 8,
      recovered: 9,
    },
    {
      date: "2020-3-19",
      confirmed: 800,
      deaths: 9,
      recovered: 9,
    },
    {
      date: "2020-3-20",
      confirmed: 943,
      deaths: 12,
      recovered: 9,
    },
  ],
  Australia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 9,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-1-31",
      confirmed: 9,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-1",
      confirmed: 12,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-2",
      confirmed: 12,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-3",
      confirmed: 12,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-4",
      confirmed: 13,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-5",
      confirmed: 13,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-6",
      confirmed: 14,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-7",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-8",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-9",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-10",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-11",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-12",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-13",
      confirmed: 15,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-14",
      confirmed: 15,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-15",
      confirmed: 15,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-16",
      confirmed: 15,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-17",
      confirmed: 15,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-18",
      confirmed: 15,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-19",
      confirmed: 15,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-20",
      confirmed: 15,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-21",
      confirmed: 19,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-22",
      confirmed: 22,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-23",
      confirmed: 22,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-24",
      confirmed: 22,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-25",
      confirmed: 22,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-26",
      confirmed: 22,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-27",
      confirmed: 23,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-28",
      confirmed: 23,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-2-29",
      confirmed: 25,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-3-1",
      confirmed: 27,
      deaths: 1,
      recovered: 11,
    },
    {
      date: "2020-3-2",
      confirmed: 30,
      deaths: 1,
      recovered: 11,
    },
    {
      date: "2020-3-3",
      confirmed: 39,
      deaths: 1,
      recovered: 11,
    },
    {
      date: "2020-3-4",
      confirmed: 52,
      deaths: 2,
      recovered: 11,
    },
    {
      date: "2020-3-5",
      confirmed: 55,
      deaths: 2,
      recovered: 21,
    },
    {
      date: "2020-3-6",
      confirmed: 60,
      deaths: 2,
      recovered: 21,
    },
    {
      date: "2020-3-7",
      confirmed: 63,
      deaths: 2,
      recovered: 21,
    },
    {
      date: "2020-3-8",
      confirmed: 76,
      deaths: 3,
      recovered: 21,
    },
    {
      date: "2020-3-9",
      confirmed: 91,
      deaths: 3,
      recovered: 21,
    },
    {
      date: "2020-3-10",
      confirmed: 107,
      deaths: 3,
      recovered: 21,
    },
    {
      date: "2020-3-11",
      confirmed: 128,
      deaths: 3,
      recovered: 21,
    },
    {
      date: "2020-3-12",
      confirmed: 128,
      deaths: 3,
      recovered: 21,
    },
    {
      date: "2020-3-13",
      confirmed: 200,
      deaths: 3,
      recovered: 23,
    },
    {
      date: "2020-3-14",
      confirmed: 250,
      deaths: 3,
      recovered: 23,
    },
    {
      date: "2020-3-15",
      confirmed: 297,
      deaths: 3,
      recovered: 23,
    },
    {
      date: "2020-3-16",
      confirmed: 377,
      deaths: 3,
      recovered: 23,
    },
    {
      date: "2020-3-17",
      confirmed: 452,
      deaths: 5,
      recovered: 23,
    },
    {
      date: "2020-3-18",
      confirmed: 568,
      deaths: 6,
      recovered: 23,
    },
    {
      date: "2020-3-19",
      confirmed: 681,
      deaths: 6,
      recovered: 26,
    },
    {
      date: "2020-3-20",
      confirmed: 791,
      deaths: 7,
      recovered: 26,
    },
  ],
  Cambodia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-17",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-19",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 3,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 3,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 5,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 33,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 35,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 37,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 51,
      deaths: 0,
      recovered: 1,
    },
  ],
  "Sri Lanka": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-9",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-10",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-11",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-12",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-17",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-19",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 28,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 44,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 51,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 60,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-20",
      confirmed: 73,
      deaths: 0,
      recovered: 3,
    },
  ],
  Germany: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-17",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 16,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-19",
      confirmed: 16,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-20",
      confirmed: 16,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-21",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-22",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-23",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-24",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-25",
      confirmed: 17,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-26",
      confirmed: 27,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-2-27",
      confirmed: 46,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-2-28",
      confirmed: 48,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-2-29",
      confirmed: 79,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-1",
      confirmed: 130,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-2",
      confirmed: 159,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-3",
      confirmed: 196,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-4",
      confirmed: 262,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-5",
      confirmed: 482,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-6",
      confirmed: 670,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-3-7",
      confirmed: 799,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-3-8",
      confirmed: 1040,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-3-9",
      confirmed: 1176,
      deaths: 2,
      recovered: 18,
    },
    {
      date: "2020-3-10",
      confirmed: 1457,
      deaths: 2,
      recovered: 18,
    },
    {
      date: "2020-3-11",
      confirmed: 1908,
      deaths: 3,
      recovered: 25,
    },
    {
      date: "2020-3-12",
      confirmed: 2078,
      deaths: 3,
      recovered: 25,
    },
    {
      date: "2020-3-13",
      confirmed: 3675,
      deaths: 7,
      recovered: 46,
    },
    {
      date: "2020-3-14",
      confirmed: 4585,
      deaths: 9,
      recovered: 46,
    },
    {
      date: "2020-3-15",
      confirmed: 5795,
      deaths: 11,
      recovered: 46,
    },
    {
      date: "2020-3-16",
      confirmed: 7272,
      deaths: 17,
      recovered: 67,
    },
    {
      date: "2020-3-17",
      confirmed: 9257,
      deaths: 24,
      recovered: 67,
    },
    {
      date: "2020-3-18",
      confirmed: 12327,
      deaths: 28,
      recovered: 105,
    },
    {
      date: "2020-3-19",
      confirmed: 15320,
      deaths: 44,
      recovered: 113,
    },
    {
      date: "2020-3-20",
      confirmed: 19848,
      deaths: 67,
      recovered: 180,
    },
  ],
  Finland: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-17",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-19",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-26",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-27",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-28",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 3,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 12,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 15,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 15,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 23,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 30,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 40,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 59,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 59,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 155,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 225,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 244,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-3-16",
      confirmed: 277,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-3-17",
      confirmed: 321,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-3-18",
      confirmed: 336,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-3-19",
      confirmed: 400,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-3-20",
      confirmed: 450,
      deaths: 0,
      recovered: 10,
    },
  ],
  "United Arab Emirates": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 8,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-16",
      confirmed: 9,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-17",
      confirmed: 9,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-18",
      confirmed: 9,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-19",
      confirmed: 9,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-20",
      confirmed: 9,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-21",
      confirmed: 9,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-22",
      confirmed: 13,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-23",
      confirmed: 13,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-24",
      confirmed: 13,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-25",
      confirmed: 13,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-26",
      confirmed: 13,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-27",
      confirmed: 13,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-2-28",
      confirmed: 19,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-29",
      confirmed: 21,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-1",
      confirmed: 21,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-2",
      confirmed: 21,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-3",
      confirmed: 27,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-4",
      confirmed: 27,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-5",
      confirmed: 29,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-6",
      confirmed: 29,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-7",
      confirmed: 45,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-3-8",
      confirmed: 45,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-3-9",
      confirmed: 45,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-3-10",
      confirmed: 74,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-3-11",
      confirmed: 74,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-3-12",
      confirmed: 85,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-3-13",
      confirmed: 85,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-3-14",
      confirmed: 85,
      deaths: 0,
      recovered: 17,
    },
    {
      date: "2020-3-15",
      confirmed: 98,
      deaths: 0,
      recovered: 23,
    },
    {
      date: "2020-3-16",
      confirmed: 98,
      deaths: 0,
      recovered: 23,
    },
    {
      date: "2020-3-17",
      confirmed: 98,
      deaths: 0,
      recovered: 23,
    },
    {
      date: "2020-3-18",
      confirmed: 113,
      deaths: 0,
      recovered: 26,
    },
    {
      date: "2020-3-19",
      confirmed: 140,
      deaths: 0,
      recovered: 31,
    },
    {
      date: "2020-3-20",
      confirmed: 140,
      deaths: 2,
      recovered: 31,
    },
  ],
  Philippines: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-17",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-19",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-22",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-24",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-25",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-26",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-27",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-28",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 3,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 5,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 6,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 10,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 20,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 33,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-11",
      confirmed: 49,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-12",
      confirmed: 52,
      deaths: 2,
      recovered: 2,
    },
    {
      date: "2020-3-13",
      confirmed: 64,
      deaths: 5,
      recovered: 2,
    },
    {
      date: "2020-3-14",
      confirmed: 111,
      deaths: 8,
      recovered: 2,
    },
    {
      date: "2020-3-15",
      confirmed: 140,
      deaths: 11,
      recovered: 2,
    },
    {
      date: "2020-3-16",
      confirmed: 142,
      deaths: 12,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 187,
      deaths: 12,
      recovered: 5,
    },
    {
      date: "2020-3-18",
      confirmed: 202,
      deaths: 19,
      recovered: 5,
    },
    {
      date: "2020-3-19",
      confirmed: 217,
      deaths: 17,
      recovered: 8,
    },
    {
      date: "2020-3-20",
      confirmed: 230,
      deaths: 18,
      recovered: 8,
    },
  ],
  India: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-17",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-18",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-19",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-20",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-21",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-22",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-23",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-24",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-25",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-26",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-27",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-28",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-29",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-2",
      confirmed: 5,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-3",
      confirmed: 5,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-4",
      confirmed: 28,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-5",
      confirmed: 30,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-6",
      confirmed: 31,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-7",
      confirmed: 34,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-8",
      confirmed: 39,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-9",
      confirmed: 43,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-10",
      confirmed: 56,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-11",
      confirmed: 62,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-3-12",
      confirmed: 73,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-3-13",
      confirmed: 82,
      deaths: 2,
      recovered: 4,
    },
    {
      date: "2020-3-14",
      confirmed: 102,
      deaths: 2,
      recovered: 4,
    },
    {
      date: "2020-3-15",
      confirmed: 113,
      deaths: 2,
      recovered: 13,
    },
    {
      date: "2020-3-16",
      confirmed: 119,
      deaths: 2,
      recovered: 13,
    },
    {
      date: "2020-3-17",
      confirmed: 142,
      deaths: 3,
      recovered: 14,
    },
    {
      date: "2020-3-18",
      confirmed: 156,
      deaths: 3,
      recovered: 14,
    },
    {
      date: "2020-3-19",
      confirmed: 194,
      deaths: 4,
      recovered: 15,
    },
    {
      date: "2020-3-20",
      confirmed: 244,
      deaths: 5,
      recovered: 20,
    },
  ],
  Italy: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 20,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 62,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 155,
      deaths: 3,
      recovered: 2,
    },
    {
      date: "2020-2-24",
      confirmed: 229,
      deaths: 7,
      recovered: 1,
    },
    {
      date: "2020-2-25",
      confirmed: 322,
      deaths: 10,
      recovered: 1,
    },
    {
      date: "2020-2-26",
      confirmed: 453,
      deaths: 12,
      recovered: 3,
    },
    {
      date: "2020-2-27",
      confirmed: 655,
      deaths: 17,
      recovered: 45,
    },
    {
      date: "2020-2-28",
      confirmed: 888,
      deaths: 21,
      recovered: 46,
    },
    {
      date: "2020-2-29",
      confirmed: 1128,
      deaths: 29,
      recovered: 46,
    },
    {
      date: "2020-3-1",
      confirmed: 1694,
      deaths: 34,
      recovered: 83,
    },
    {
      date: "2020-3-2",
      confirmed: 2036,
      deaths: 52,
      recovered: 149,
    },
    {
      date: "2020-3-3",
      confirmed: 2502,
      deaths: 79,
      recovered: 160,
    },
    {
      date: "2020-3-4",
      confirmed: 3089,
      deaths: 107,
      recovered: 276,
    },
    {
      date: "2020-3-5",
      confirmed: 3858,
      deaths: 148,
      recovered: 414,
    },
    {
      date: "2020-3-6",
      confirmed: 4636,
      deaths: 197,
      recovered: 523,
    },
    {
      date: "2020-3-7",
      confirmed: 5883,
      deaths: 233,
      recovered: 589,
    },
    {
      date: "2020-3-8",
      confirmed: 7375,
      deaths: 366,
      recovered: 622,
    },
    {
      date: "2020-3-9",
      confirmed: 9172,
      deaths: 463,
      recovered: 724,
    },
    {
      date: "2020-3-10",
      confirmed: 10149,
      deaths: 631,
      recovered: 724,
    },
    {
      date: "2020-3-11",
      confirmed: 12462,
      deaths: 827,
      recovered: 1045,
    },
    {
      date: "2020-3-12",
      confirmed: 12462,
      deaths: 827,
      recovered: 1045,
    },
    {
      date: "2020-3-13",
      confirmed: 17660,
      deaths: 1266,
      recovered: 1439,
    },
    {
      date: "2020-3-14",
      confirmed: 21157,
      deaths: 1441,
      recovered: 1966,
    },
    {
      date: "2020-3-15",
      confirmed: 24747,
      deaths: 1809,
      recovered: 2335,
    },
    {
      date: "2020-3-16",
      confirmed: 27980,
      deaths: 2158,
      recovered: 2749,
    },
    {
      date: "2020-3-17",
      confirmed: 31506,
      deaths: 2503,
      recovered: 2941,
    },
    {
      date: "2020-3-18",
      confirmed: 35713,
      deaths: 2978,
      recovered: 4025,
    },
    {
      date: "2020-3-19",
      confirmed: 41035,
      deaths: 3405,
      recovered: 4440,
    },
    {
      date: "2020-3-20",
      confirmed: 47021,
      deaths: 4032,
      recovered: 4440,
    },
  ],
  Sweden: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 21,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 35,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 94,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 101,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 161,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 203,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 248,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 355,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 500,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 599,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 814,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 961,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 1022,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 1103,
      deaths: 6,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 1190,
      deaths: 7,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 1279,
      deaths: 10,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 1439,
      deaths: 11,
      recovered: 16,
    },
    {
      date: "2020-3-20",
      confirmed: 1639,
      deaths: 16,
      recovered: 16,
    },
  ],
  Spain: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-16",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-17",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-18",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-19",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-20",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-21",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-22",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-23",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-24",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-25",
      confirmed: 6,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-26",
      confirmed: 13,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-27",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-28",
      confirmed: 32,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-29",
      confirmed: 45,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-1",
      confirmed: 84,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-2",
      confirmed: 120,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-3",
      confirmed: 165,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-4",
      confirmed: 222,
      deaths: 2,
      recovered: 2,
    },
    {
      date: "2020-3-5",
      confirmed: 259,
      deaths: 3,
      recovered: 2,
    },
    {
      date: "2020-3-6",
      confirmed: 400,
      deaths: 5,
      recovered: 2,
    },
    {
      date: "2020-3-7",
      confirmed: 500,
      deaths: 10,
      recovered: 30,
    },
    {
      date: "2020-3-8",
      confirmed: 673,
      deaths: 17,
      recovered: 30,
    },
    {
      date: "2020-3-9",
      confirmed: 1073,
      deaths: 28,
      recovered: 32,
    },
    {
      date: "2020-3-10",
      confirmed: 1695,
      deaths: 35,
      recovered: 32,
    },
    {
      date: "2020-3-11",
      confirmed: 2277,
      deaths: 54,
      recovered: 183,
    },
    {
      date: "2020-3-12",
      confirmed: 2277,
      deaths: 55,
      recovered: 183,
    },
    {
      date: "2020-3-13",
      confirmed: 5232,
      deaths: 133,
      recovered: 193,
    },
    {
      date: "2020-3-14",
      confirmed: 6391,
      deaths: 195,
      recovered: 517,
    },
    {
      date: "2020-3-15",
      confirmed: 7798,
      deaths: 289,
      recovered: 517,
    },
    {
      date: "2020-3-16",
      confirmed: 9942,
      deaths: 342,
      recovered: 530,
    },
    {
      date: "2020-3-17",
      confirmed: 11748,
      deaths: 533,
      recovered: 1028,
    },
    {
      date: "2020-3-18",
      confirmed: 13910,
      deaths: 623,
      recovered: 1081,
    },
    {
      date: "2020-3-19",
      confirmed: 17963,
      deaths: 830,
      recovered: 1107,
    },
    {
      date: "2020-3-20",
      confirmed: 20410,
      deaths: 1043,
      recovered: 1588,
    },
  ],
  Belgium: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-18",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-19",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 13,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 23,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 50,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 109,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 169,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 200,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 239,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 267,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 314,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 314,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 559,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 689,
      deaths: 4,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 886,
      deaths: 4,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 1058,
      deaths: 5,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 1243,
      deaths: 10,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 1486,
      deaths: 14,
      recovered: 31,
    },
    {
      date: "2020-3-19",
      confirmed: 1795,
      deaths: 21,
      recovered: 31,
    },
    {
      date: "2020-3-20",
      confirmed: 2257,
      deaths: 37,
      recovered: 1,
    },
  ],
  Egypt: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 3,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 15,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 15,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 49,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 55,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 59,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 60,
      deaths: 1,
      recovered: 27,
    },
    {
      date: "2020-3-12",
      confirmed: 67,
      deaths: 1,
      recovered: 27,
    },
    {
      date: "2020-3-13",
      confirmed: 80,
      deaths: 2,
      recovered: 27,
    },
    {
      date: "2020-3-14",
      confirmed: 109,
      deaths: 2,
      recovered: 27,
    },
    {
      date: "2020-3-15",
      confirmed: 110,
      deaths: 2,
      recovered: 21,
    },
    {
      date: "2020-3-16",
      confirmed: 150,
      deaths: 2,
      recovered: 27,
    },
    {
      date: "2020-3-17",
      confirmed: 196,
      deaths: 4,
      recovered: 32,
    },
    {
      date: "2020-3-18",
      confirmed: 196,
      deaths: 6,
      recovered: 32,
    },
    {
      date: "2020-3-19",
      confirmed: 256,
      deaths: 6,
      recovered: 32,
    },
    {
      date: "2020-3-20",
      confirmed: 285,
      deaths: 8,
      recovered: 39,
    },
  ],
  Lebanon: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 13,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 22,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 22,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 32,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 32,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 41,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 61,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 61,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 77,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 93,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 110,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 99,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 120,
      deaths: 3,
      recovered: 3,
    },
    {
      date: "2020-3-18",
      confirmed: 133,
      deaths: 3,
      recovered: 3,
    },
    {
      date: "2020-3-19",
      confirmed: 157,
      deaths: 4,
      recovered: 4,
    },
    {
      date: "2020-3-20",
      confirmed: 163,
      deaths: 4,
      recovered: 4,
    },
  ],
  Iraq: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 26,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 32,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 35,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 35,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 40,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 54,
      deaths: 4,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 60,
      deaths: 6,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 60,
      deaths: 6,
      recovered: 3,
    },
    {
      date: "2020-3-10",
      confirmed: 71,
      deaths: 7,
      recovered: 3,
    },
    {
      date: "2020-3-11",
      confirmed: 71,
      deaths: 7,
      recovered: 15,
    },
    {
      date: "2020-3-12",
      confirmed: 71,
      deaths: 8,
      recovered: 15,
    },
    {
      date: "2020-3-13",
      confirmed: 101,
      deaths: 9,
      recovered: 24,
    },
    {
      date: "2020-3-14",
      confirmed: 110,
      deaths: 10,
      recovered: 26,
    },
    {
      date: "2020-3-15",
      confirmed: 116,
      deaths: 10,
      recovered: 26,
    },
    {
      date: "2020-3-16",
      confirmed: 124,
      deaths: 10,
      recovered: 26,
    },
    {
      date: "2020-3-17",
      confirmed: 154,
      deaths: 11,
      recovered: 32,
    },
    {
      date: "2020-3-18",
      confirmed: 164,
      deaths: 12,
      recovered: 43,
    },
    {
      date: "2020-3-19",
      confirmed: 192,
      deaths: 13,
      recovered: 43,
    },
    {
      date: "2020-3-20",
      confirmed: 208,
      deaths: 17,
      recovered: 49,
    },
  ],
  Oman: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 12,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-4",
      confirmed: 15,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-5",
      confirmed: 16,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-6",
      confirmed: 16,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-7",
      confirmed: 16,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-8",
      confirmed: 16,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-9",
      confirmed: 16,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-10",
      confirmed: 18,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-11",
      confirmed: 18,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-12",
      confirmed: 18,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-13",
      confirmed: 19,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-14",
      confirmed: 19,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-15",
      confirmed: 22,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-16",
      confirmed: 22,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-17",
      confirmed: 24,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-18",
      confirmed: 39,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-3-19",
      confirmed: 48,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-3-20",
      confirmed: 48,
      deaths: 0,
      recovered: 12,
    },
  ],
  Afghanistan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 21,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 22,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 22,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 22,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 24,
      deaths: 0,
      recovered: 1,
    },
  ],
  Bahrain: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 33,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 33,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 36,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 41,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 47,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 49,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 49,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 52,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 55,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 60,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-7",
      confirmed: 85,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-8",
      confirmed: 85,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-9",
      confirmed: 95,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-3-10",
      confirmed: 110,
      deaths: 0,
      recovered: 22,
    },
    {
      date: "2020-3-11",
      confirmed: 195,
      deaths: 0,
      recovered: 35,
    },
    {
      date: "2020-3-12",
      confirmed: 195,
      deaths: 0,
      recovered: 35,
    },
    {
      date: "2020-3-13",
      confirmed: 189,
      deaths: 0,
      recovered: 44,
    },
    {
      date: "2020-3-14",
      confirmed: 210,
      deaths: 0,
      recovered: 44,
    },
    {
      date: "2020-3-15",
      confirmed: 214,
      deaths: 0,
      recovered: 60,
    },
    {
      date: "2020-3-16",
      confirmed: 214,
      deaths: 1,
      recovered: 77,
    },
    {
      date: "2020-3-17",
      confirmed: 228,
      deaths: 1,
      recovered: 81,
    },
    {
      date: "2020-3-18",
      confirmed: 256,
      deaths: 1,
      recovered: 88,
    },
    {
      date: "2020-3-19",
      confirmed: 278,
      deaths: 1,
      recovered: 100,
    },
    {
      date: "2020-3-20",
      confirmed: 285,
      deaths: 1,
      recovered: 100,
    },
  ],
  Kuwait: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 26,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 43,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 45,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 45,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 45,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 56,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 56,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 56,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 58,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 58,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 61,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 64,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 64,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 69,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 72,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-12",
      confirmed: 80,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-13",
      confirmed: 80,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-14",
      confirmed: 104,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-15",
      confirmed: 112,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-16",
      confirmed: 123,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-17",
      confirmed: 130,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-18",
      confirmed: 142,
      deaths: 0,
      recovered: 15,
    },
    {
      date: "2020-3-19",
      confirmed: 148,
      deaths: 0,
      recovered: 18,
    },
    {
      date: "2020-3-20",
      confirmed: 159,
      deaths: 0,
      recovered: 18,
    },
  ],
  Algeria: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 24,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-13",
      confirmed: 26,
      deaths: 2,
      recovered: 8,
    },
    {
      date: "2020-3-14",
      confirmed: 37,
      deaths: 3,
      recovered: 12,
    },
    {
      date: "2020-3-15",
      confirmed: 48,
      deaths: 4,
      recovered: 12,
    },
    {
      date: "2020-3-16",
      confirmed: 54,
      deaths: 4,
      recovered: 12,
    },
    {
      date: "2020-3-17",
      confirmed: 60,
      deaths: 4,
      recovered: 12,
    },
    {
      date: "2020-3-18",
      confirmed: 74,
      deaths: 7,
      recovered: 12,
    },
    {
      date: "2020-3-19",
      confirmed: 87,
      deaths: 9,
      recovered: 32,
    },
    {
      date: "2020-3-20",
      confirmed: 90,
      deaths: 11,
      recovered: 32,
    },
  ],
  Croatia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 32,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 38,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 49,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 57,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 65,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-18",
      confirmed: 81,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-19",
      confirmed: 105,
      deaths: 1,
      recovered: 5,
    },
    {
      date: "2020-3-20",
      confirmed: 128,
      deaths: 1,
      recovered: 5,
    },
  ],
  Switzerland: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 27,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 42,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 56,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-4",
      confirmed: 90,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-5",
      confirmed: 114,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-6",
      confirmed: 214,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-7",
      confirmed: 268,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-8",
      confirmed: 337,
      deaths: 2,
      recovered: 3,
    },
    {
      date: "2020-3-9",
      confirmed: 374,
      deaths: 2,
      recovered: 3,
    },
    {
      date: "2020-3-10",
      confirmed: 491,
      deaths: 3,
      recovered: 3,
    },
    {
      date: "2020-3-11",
      confirmed: 652,
      deaths: 4,
      recovered: 4,
    },
    {
      date: "2020-3-12",
      confirmed: 652,
      deaths: 4,
      recovered: 4,
    },
    {
      date: "2020-3-13",
      confirmed: 1139,
      deaths: 11,
      recovered: 4,
    },
    {
      date: "2020-3-14",
      confirmed: 1359,
      deaths: 13,
      recovered: 4,
    },
    {
      date: "2020-3-15",
      confirmed: 2200,
      deaths: 14,
      recovered: 4,
    },
    {
      date: "2020-3-16",
      confirmed: 2200,
      deaths: 14,
      recovered: 4,
    },
    {
      date: "2020-3-17",
      confirmed: 2700,
      deaths: 27,
      recovered: 4,
    },
    {
      date: "2020-3-18",
      confirmed: 3028,
      deaths: 28,
      recovered: 15,
    },
    {
      date: "2020-3-19",
      confirmed: 4075,
      deaths: 41,
      recovered: 15,
    },
    {
      date: "2020-3-20",
      confirmed: 5294,
      deaths: 54,
      recovered: 15,
    },
  ],
  Austria: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 21,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 29,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 41,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 55,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 79,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 104,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 131,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-10",
      confirmed: 182,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-11",
      confirmed: 246,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-12",
      confirmed: 302,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-3-13",
      confirmed: 504,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-14",
      confirmed: 655,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-15",
      confirmed: 860,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-16",
      confirmed: 1018,
      deaths: 3,
      recovered: 6,
    },
    {
      date: "2020-3-17",
      confirmed: 1332,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 1646,
      deaths: 4,
      recovered: 9,
    },
    {
      date: "2020-3-19",
      confirmed: 2013,
      deaths: 6,
      recovered: 9,
    },
    {
      date: "2020-3-20",
      confirmed: 2388,
      deaths: 6,
      recovered: 9,
    },
  ],
  Israel: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 3,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-28",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-29",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-1",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-2",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-3",
      confirmed: 12,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 15,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 20,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 37,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-7",
      confirmed: 43,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-8",
      confirmed: 61,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-9",
      confirmed: 61,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-10",
      confirmed: 83,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-11",
      confirmed: 109,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-12",
      confirmed: 131,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-13",
      confirmed: 161,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-14",
      confirmed: 193,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-15",
      confirmed: 251,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-16",
      confirmed: 255,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-17",
      confirmed: 337,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-3-18",
      confirmed: 433,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-3-19",
      confirmed: 677,
      deaths: 0,
      recovered: 11,
    },
    {
      date: "2020-3-20",
      confirmed: 705,
      deaths: 0,
      recovered: 14,
    },
  ],
  Pakistan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 19,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-12",
      confirmed: 20,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-13",
      confirmed: 28,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-14",
      confirmed: 31,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-15",
      confirmed: 53,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-16",
      confirmed: 136,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 236,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 299,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 454,
      deaths: 2,
      recovered: 13,
    },
    {
      date: "2020-3-20",
      confirmed: 501,
      deaths: 3,
      recovered: 13,
    },
  ],
  Brazil: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 25,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 31,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 38,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 52,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 151,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 151,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 162,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 200,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 321,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 372,
      deaths: 3,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 621,
      deaths: 6,
      recovered: 2,
    },
    {
      date: "2020-3-20",
      confirmed: 793,
      deaths: 11,
      recovered: 2,
    },
  ],
  Georgia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 25,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 30,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 33,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 33,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 34,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 38,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 40,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 43,
      deaths: 0,
      recovered: 1,
    },
  ],
  Greece: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 31,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 45,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 46,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 73,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 73,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 89,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 99,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 99,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 190,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 228,
      deaths: 3,
      recovered: 8,
    },
    {
      date: "2020-3-15",
      confirmed: 331,
      deaths: 4,
      recovered: 8,
    },
    {
      date: "2020-3-16",
      confirmed: 331,
      deaths: 4,
      recovered: 8,
    },
    {
      date: "2020-3-17",
      confirmed: 387,
      deaths: 5,
      recovered: 8,
    },
    {
      date: "2020-3-18",
      confirmed: 418,
      deaths: 5,
      recovered: 8,
    },
    {
      date: "2020-3-19",
      confirmed: 418,
      deaths: 6,
      recovered: 8,
    },
    {
      date: "2020-3-20",
      confirmed: 495,
      deaths: 6,
      recovered: 19,
    },
  ],
  "North Macedonia": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 14,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 14,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 14,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 26,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 35,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 48,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 67,
      deaths: 0,
      recovered: 1,
    },
  ],
  Norway: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 25,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 32,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 56,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 87,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 108,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 147,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 176,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 205,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 400,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 598,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 702,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 996,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 1090,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 1221,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 1333,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 1463,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 1550,
      deaths: 6,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 1746,
      deaths: 7,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 1914,
      deaths: 7,
      recovered: 1,
    },
  ],
  Romania: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 9,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-8",
      confirmed: 15,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-9",
      confirmed: 15,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-10",
      confirmed: 25,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-11",
      confirmed: 45,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-12",
      confirmed: 49,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-13",
      confirmed: 89,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-3-14",
      confirmed: 123,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-15",
      confirmed: 131,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-16",
      confirmed: 158,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-3-17",
      confirmed: 184,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-18",
      confirmed: 260,
      deaths: 0,
      recovered: 19,
    },
    {
      date: "2020-3-19",
      confirmed: 277,
      deaths: 0,
      recovered: 25,
    },
    {
      date: "2020-3-20",
      confirmed: 308,
      deaths: 0,
      recovered: 25,
    },
  ],
  Estonia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 79,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 115,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 171,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 205,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 225,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 258,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 267,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 283,
      deaths: 0,
      recovered: 1,
    },
  ],
  "San Marino": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 10,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 16,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 21,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 21,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 23,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 36,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 36,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 51,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 62,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 69,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 80,
      deaths: 5,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 80,
      deaths: 5,
      recovered: 4,
    },
    {
      date: "2020-3-15",
      confirmed: 101,
      deaths: 5,
      recovered: 4,
    },
    {
      date: "2020-3-16",
      confirmed: 109,
      deaths: 7,
      recovered: 4,
    },
    {
      date: "2020-3-17",
      confirmed: 109,
      deaths: 7,
      recovered: 4,
    },
    {
      date: "2020-3-18",
      confirmed: 119,
      deaths: 11,
      recovered: 4,
    },
    {
      date: "2020-3-19",
      confirmed: 119,
      deaths: 11,
      recovered: 4,
    },
    {
      date: "2020-3-20",
      confirmed: 144,
      deaths: 14,
      recovered: 4,
    },
  ],
  Belarus: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 9,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-11",
      confirmed: 9,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-12",
      confirmed: 12,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-13",
      confirmed: 27,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-14",
      confirmed: 27,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-15",
      confirmed: 27,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-16",
      confirmed: 36,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-17",
      confirmed: 36,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-18",
      confirmed: 51,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-19",
      confirmed: 51,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-3-20",
      confirmed: 69,
      deaths: 0,
      recovered: 5,
    },
  ],
  Iceland: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 26,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 34,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 43,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 50,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 50,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 58,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 69,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 85,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 103,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 134,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 156,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 171,
      deaths: 5,
      recovered: 8,
    },
    {
      date: "2020-3-16",
      confirmed: 180,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 220,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 250,
      deaths: 1,
      recovered: 5,
    },
    {
      date: "2020-3-19",
      confirmed: 330,
      deaths: 1,
      recovered: 5,
    },
    {
      date: "2020-3-20",
      confirmed: 409,
      deaths: 0,
      recovered: 5,
    },
  ],
  Lithuania: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 12,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 17,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 25,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 27,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 36,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 49,
      deaths: 0,
      recovered: 1,
    },
  ],
  Mexico: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 5,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-4",
      confirmed: 5,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-5",
      confirmed: 5,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-6",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 7,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 7,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-11",
      confirmed: 8,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-12",
      confirmed: 12,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-13",
      confirmed: 12,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-14",
      confirmed: 26,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-15",
      confirmed: 41,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-16",
      confirmed: 53,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-17",
      confirmed: 82,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-18",
      confirmed: 93,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-19",
      confirmed: 118,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-3-20",
      confirmed: 164,
      deaths: 1,
      recovered: 4,
    },
  ],
  "New Zealand": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 28,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 39,
      deaths: 0,
      recovered: 0,
    },
  ],
  Nigeria: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 12,
      deaths: 0,
      recovered: 1,
    },
  ],
  Ireland: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 21,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 34,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 43,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 43,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 90,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 129,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 129,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 169,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 223,
      deaths: 2,
      recovered: 5,
    },
    {
      date: "2020-3-18",
      confirmed: 292,
      deaths: 2,
      recovered: 5,
    },
    {
      date: "2020-3-19",
      confirmed: 557,
      deaths: 3,
      recovered: 5,
    },
    {
      date: "2020-3-20",
      confirmed: 683,
      deaths: 3,
      recovered: 5,
    },
  ],
  Luxembourg: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 34,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 51,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 59,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 77,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 140,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 203,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 335,
      deaths: 4,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 484,
      deaths: 4,
      recovered: 0,
    },
  ],
  Monaco: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
  ],
  Qatar: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 262,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 262,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 320,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 337,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-15",
      confirmed: 401,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-16",
      confirmed: 439,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-17",
      confirmed: 439,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-18",
      confirmed: 452,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-19",
      confirmed: 460,
      deaths: 0,
      recovered: 4,
    },
    {
      date: "2020-3-20",
      confirmed: 470,
      deaths: 0,
      recovered: 10,
    },
  ],
  Ecuador: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 28,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 28,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 37,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 58,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 111,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 199,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 367,
      deaths: 5,
      recovered: 0,
    },
  ],
  Azerbaijan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 11,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-12",
      confirmed: 11,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-13",
      confirmed: 15,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-14",
      confirmed: 15,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-15",
      confirmed: 23,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-16",
      confirmed: 15,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-17",
      confirmed: 28,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-18",
      confirmed: 28,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-19",
      confirmed: 44,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-3-20",
      confirmed: 44,
      deaths: 1,
      recovered: 6,
    },
  ],
  Armenia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 26,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 52,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 78,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 84,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 115,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 136,
      deaths: 0,
      recovered: 1,
    },
  ],
  "Dominican Republic": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 21,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 21,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 34,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 72,
      deaths: 2,
      recovered: 0,
    },
  ],
  Indonesia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 27,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-11",
      confirmed: 34,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-12",
      confirmed: 34,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-13",
      confirmed: 69,
      deaths: 4,
      recovered: 2,
    },
    {
      date: "2020-3-14",
      confirmed: 96,
      deaths: 5,
      recovered: 8,
    },
    {
      date: "2020-3-15",
      confirmed: 117,
      deaths: 5,
      recovered: 8,
    },
    {
      date: "2020-3-16",
      confirmed: 134,
      deaths: 5,
      recovered: 8,
    },
    {
      date: "2020-3-17",
      confirmed: 172,
      deaths: 5,
      recovered: 8,
    },
    {
      date: "2020-3-18",
      confirmed: 227,
      deaths: 19,
      recovered: 11,
    },
    {
      date: "2020-3-19",
      confirmed: 311,
      deaths: 25,
      recovered: 11,
    },
    {
      date: "2020-3-20",
      confirmed: 369,
      deaths: 32,
      recovered: 15,
    },
  ],
  Portugal: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 30,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 30,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 41,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 59,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 59,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 112,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 169,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-15",
      confirmed: 245,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-16",
      confirmed: 331,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-17",
      confirmed: 448,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-18",
      confirmed: 448,
      deaths: 2,
      recovered: 3,
    },
    {
      date: "2020-3-19",
      confirmed: 785,
      deaths: 3,
      recovered: 3,
    },
    {
      date: "2020-3-20",
      confirmed: 1020,
      deaths: 6,
      recovered: 5,
    },
  ],
  Andorra: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 2,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 39,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 39,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 53,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 75,
      deaths: 0,
      recovered: 1,
    },
  ],
  Latvia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 17,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 26,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 30,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 34,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 49,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 71,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 86,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 111,
      deaths: 0,
      recovered: 1,
    },
  ],
  Morocco: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 5,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 6,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 7,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 17,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 28,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 29,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 38,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 49,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 63,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 77,
      deaths: 3,
      recovered: 1,
    },
  ],
  "Saudi Arabia": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 20,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 21,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 45,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 86,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 103,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 103,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 118,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 171,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-18",
      confirmed: 171,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-19",
      confirmed: 274,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-3-20",
      confirmed: 344,
      deaths: 0,
      recovered: 8,
    },
  ],
  Senegal: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 4,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 24,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 24,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 26,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 31,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 31,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-20",
      confirmed: 38,
      deaths: 0,
      recovered: 2,
    },
  ],
  Argentina: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 12,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 12,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 17,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 19,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 19,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 31,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 34,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 45,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 56,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 68,
      deaths: 2,
      recovered: 3,
    },
    {
      date: "2020-3-18",
      confirmed: 79,
      deaths: 2,
      recovered: 3,
    },
    {
      date: "2020-3-19",
      confirmed: 97,
      deaths: 3,
      recovered: 3,
    },
    {
      date: "2020-3-20",
      confirmed: 128,
      deaths: 3,
      recovered: 3,
    },
  ],
  Chile: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 43,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 61,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 74,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 155,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 201,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 238,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 238,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 434,
      deaths: 0,
      recovered: 6,
    },
  ],
  Jordan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 17,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 34,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 52,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 69,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 85,
      deaths: 0,
      recovered: 1,
    },
  ],
  Ukraine: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 14,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 14,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 16,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 29,
      deaths: 3,
      recovered: 0,
    },
  ],
  Hungary: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 30,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 32,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 39,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 50,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 58,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 73,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-20",
      confirmed: 85,
      deaths: 3,
      recovered: 2,
    },
  ],
  Liechtenstein: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 28,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 28,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 28,
      deaths: 0,
      recovered: 0,
    },
  ],
  Poland: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 22,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 31,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 49,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 68,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 103,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 119,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 177,
      deaths: 4,
      recovered: 13,
    },
    {
      date: "2020-3-17",
      confirmed: 238,
      deaths: 5,
      recovered: 13,
    },
    {
      date: "2020-3-18",
      confirmed: 251,
      deaths: 5,
      recovered: 13,
    },
    {
      date: "2020-3-19",
      confirmed: 355,
      deaths: 5,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 425,
      deaths: 5,
      recovered: 1,
    },
  ],
  Tunisia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 29,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 39,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 54,
      deaths: 1,
      recovered: 0,
    },
  ],
  "Bosnia and Herzegovina": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 25,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 26,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 38,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 63,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-20",
      confirmed: 89,
      deaths: 0,
      recovered: 2,
    },
  ],
  Slovenia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 31,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 57,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 89,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 141,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 181,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 219,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 253,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 275,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 275,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 286,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 341,
      deaths: 1,
      recovered: 0,
    },
  ],
  "South Africa": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 38,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 51,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 62,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 62,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 116,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 150,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 202,
      deaths: 0,
      recovered: 0,
    },
  ],
  Bhutan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  Cameroon: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 20,
      deaths: 0,
      recovered: 0,
    },
  ],
  Colombia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 22,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 34,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 54,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 65,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 93,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 102,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 128,
      deaths: 0,
      recovered: 1,
    },
  ],
  "Costa Rica": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 22,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 26,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 27,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 35,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 41,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 50,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 69,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 89,
      deaths: 1,
      recovered: 0,
    },
  ],
  Peru: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 28,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 38,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 43,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 86,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 117,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 145,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 234,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 234,
      deaths: 3,
      recovered: 1,
    },
  ],
  Serbia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 35,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 46,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 48,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 55,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 65,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 83,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 103,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 135,
      deaths: 1,
      recovered: 1,
    },
  ],
  Slovakia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 32,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 44,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 54,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 63,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 72,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 105,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 123,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 137,
      deaths: 1,
      recovered: 0,
    },
  ],
  Togo: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
  ],
  Malta: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 12,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 21,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 30,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 38,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 38,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 53,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-20",
      confirmed: 64,
      deaths: 0,
      recovered: 2,
    },
  ],
  Martinique: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 15,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 16,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 19,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 23,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 32,
      deaths: 1,
      recovered: 0,
    },
  ],
  Bulgaria: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 23,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 41,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 51,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 52,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 67,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 92,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 94,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 127,
      deaths: 3,
      recovered: 0,
    },
  ],
  Maldives: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
  ],
  Bangladesh: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 8,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 10,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-18",
      confirmed: 14,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-19",
      confirmed: 17,
      deaths: 1,
      recovered: 3,
    },
    {
      date: "2020-3-20",
      confirmed: 20,
      deaths: 1,
      recovered: 3,
    },
  ],
  Paraguay: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 13,
      deaths: 0,
      recovered: 0,
    },
  ],
  Albania: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 12,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 23,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 33,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 38,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 42,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 51,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 55,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 59,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 64,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 70,
      deaths: 2,
      recovered: 0,
    },
  ],
  Cyprus: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 26,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 26,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 33,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 46,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 49,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 67,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 67,
      deaths: 0,
      recovered: 0,
    },
  ],
  Brunei: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 37,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 40,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 50,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 54,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 56,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 68,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 75,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 78,
      deaths: 0,
      recovered: 1,
    },
  ],
  US: [
    {
      date: "2020-1-22",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 11,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-10",
      confirmed: 11,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-11",
      confirmed: 12,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-12",
      confirmed: 12,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-13",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-14",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-15",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-16",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-17",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-18",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-19",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-20",
      confirmed: 13,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-21",
      confirmed: 15,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-22",
      confirmed: 15,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-23",
      confirmed: 15,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-24",
      confirmed: 51,
      deaths: 0,
      recovered: 5,
    },
    {
      date: "2020-2-25",
      confirmed: 51,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-2-26",
      confirmed: 57,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-2-27",
      confirmed: 58,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-2-28",
      confirmed: 60,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-29",
      confirmed: 68,
      deaths: 1,
      recovered: 7,
    },
    {
      date: "2020-3-1",
      confirmed: 74,
      deaths: 1,
      recovered: 7,
    },
    {
      date: "2020-3-2",
      confirmed: 98,
      deaths: 6,
      recovered: 7,
    },
    {
      date: "2020-3-3",
      confirmed: 118,
      deaths: 7,
      recovered: 7,
    },
    {
      date: "2020-3-4",
      confirmed: 149,
      deaths: 11,
      recovered: 7,
    },
    {
      date: "2020-3-5",
      confirmed: 217,
      deaths: 12,
      recovered: 7,
    },
    {
      date: "2020-3-6",
      confirmed: 262,
      deaths: 14,
      recovered: 7,
    },
    {
      date: "2020-3-7",
      confirmed: 402,
      deaths: 17,
      recovered: 7,
    },
    {
      date: "2020-3-8",
      confirmed: 518,
      deaths: 21,
      recovered: 7,
    },
    {
      date: "2020-3-9",
      confirmed: 583,
      deaths: 22,
      recovered: 7,
    },
    {
      date: "2020-3-10",
      confirmed: 959,
      deaths: 28,
      recovered: 8,
    },
    {
      date: "2020-3-11",
      confirmed: 1281,
      deaths: 36,
      recovered: 8,
    },
    {
      date: "2020-3-12",
      confirmed: 1663,
      deaths: 40,
      recovered: 12,
    },
    {
      date: "2020-3-13",
      confirmed: 2179,
      deaths: 47,
      recovered: 12,
    },
    {
      date: "2020-3-14",
      confirmed: 2727,
      deaths: 54,
      recovered: 12,
    },
    {
      date: "2020-3-15",
      confirmed: 3499,
      deaths: 63,
      recovered: 12,
    },
    {
      date: "2020-3-16",
      confirmed: 4632,
      deaths: 85,
      recovered: 17,
    },
    {
      date: "2020-3-17",
      confirmed: 6421,
      deaths: 108,
      recovered: 17,
    },
    {
      date: "2020-3-18",
      confirmed: 7783,
      deaths: 118,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 13677,
      deaths: 200,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 19100,
      deaths: 244,
      recovered: 0,
    },
  ],
  "Burkina Faso": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 20,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 33,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 40,
      deaths: 1,
      recovered: 0,
    },
  ],
  "Holy See": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Mongolia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
  ],
  Panama: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 8,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 11,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 27,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 36,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 43,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 55,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 69,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 86,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 109,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 137,
      deaths: 1,
      recovered: 0,
    },
  ],
  China: [
    {
      date: "2020-1-22",
      confirmed: 548,
      deaths: 17,
      recovered: 28,
    },
    {
      date: "2020-1-23",
      confirmed: 643,
      deaths: 18,
      recovered: 30,
    },
    {
      date: "2020-1-24",
      confirmed: 920,
      deaths: 26,
      recovered: 36,
    },
    {
      date: "2020-1-25",
      confirmed: 1406,
      deaths: 42,
      recovered: 39,
    },
    {
      date: "2020-1-26",
      confirmed: 2075,
      deaths: 56,
      recovered: 49,
    },
    {
      date: "2020-1-27",
      confirmed: 2877,
      deaths: 82,
      recovered: 58,
    },
    {
      date: "2020-1-28",
      confirmed: 5509,
      deaths: 131,
      recovered: 101,
    },
    {
      date: "2020-1-29",
      confirmed: 6087,
      deaths: 133,
      recovered: 120,
    },
    {
      date: "2020-1-30",
      confirmed: 8141,
      deaths: 171,
      recovered: 135,
    },
    {
      date: "2020-1-31",
      confirmed: 9802,
      deaths: 213,
      recovered: 214,
    },
    {
      date: "2020-2-1",
      confirmed: 11891,
      deaths: 259,
      recovered: 275,
    },
    {
      date: "2020-2-2",
      confirmed: 16630,
      deaths: 361,
      recovered: 463,
    },
    {
      date: "2020-2-3",
      confirmed: 19716,
      deaths: 425,
      recovered: 614,
    },
    {
      date: "2020-2-4",
      confirmed: 23707,
      deaths: 491,
      recovered: 843,
    },
    {
      date: "2020-2-5",
      confirmed: 27440,
      deaths: 563,
      recovered: 1115,
    },
    {
      date: "2020-2-6",
      confirmed: 30587,
      deaths: 633,
      recovered: 1477,
    },
    {
      date: "2020-2-7",
      confirmed: 34110,
      deaths: 718,
      recovered: 1999,
    },
    {
      date: "2020-2-8",
      confirmed: 36814,
      deaths: 805,
      recovered: 2596,
    },
    {
      date: "2020-2-9",
      confirmed: 39829,
      deaths: 905,
      recovered: 3219,
    },
    {
      date: "2020-2-10",
      confirmed: 42354,
      deaths: 1012,
      recovered: 3918,
    },
    {
      date: "2020-2-11",
      confirmed: 44386,
      deaths: 1112,
      recovered: 4636,
    },
    {
      date: "2020-2-12",
      confirmed: 44759,
      deaths: 1117,
      recovered: 5082,
    },
    {
      date: "2020-2-13",
      confirmed: 59895,
      deaths: 1369,
      recovered: 6217,
    },
    {
      date: "2020-2-14",
      confirmed: 66358,
      deaths: 1521,
      recovered: 7977,
    },
    {
      date: "2020-2-15",
      confirmed: 68413,
      deaths: 1663,
      recovered: 9298,
    },
    {
      date: "2020-2-16",
      confirmed: 70513,
      deaths: 1766,
      recovered: 10755,
    },
    {
      date: "2020-2-17",
      confirmed: 72434,
      deaths: 1864,
      recovered: 12462,
    },
    {
      date: "2020-2-18",
      confirmed: 74211,
      deaths: 2003,
      recovered: 14206,
    },
    {
      date: "2020-2-19",
      confirmed: 74619,
      deaths: 2116,
      recovered: 15962,
    },
    {
      date: "2020-2-20",
      confirmed: 75077,
      deaths: 2238,
      recovered: 18014,
    },
    {
      date: "2020-2-21",
      confirmed: 75550,
      deaths: 2238,
      recovered: 18704,
    },
    {
      date: "2020-2-22",
      confirmed: 77001,
      deaths: 2443,
      recovered: 22699,
    },
    {
      date: "2020-2-23",
      confirmed: 77022,
      deaths: 2445,
      recovered: 23187,
    },
    {
      date: "2020-2-24",
      confirmed: 77241,
      deaths: 2595,
      recovered: 25015,
    },
    {
      date: "2020-2-25",
      confirmed: 77754,
      deaths: 2665,
      recovered: 27676,
    },
    {
      date: "2020-2-26",
      confirmed: 78166,
      deaths: 2717,
      recovered: 30084,
    },
    {
      date: "2020-2-27",
      confirmed: 78600,
      deaths: 2746,
      recovered: 32930,
    },
    {
      date: "2020-2-28",
      confirmed: 78928,
      deaths: 2790,
      recovered: 36329,
    },
    {
      date: "2020-2-29",
      confirmed: 79356,
      deaths: 2837,
      recovered: 39320,
    },
    {
      date: "2020-3-1",
      confirmed: 79932,
      deaths: 2872,
      recovered: 42162,
    },
    {
      date: "2020-3-2",
      confirmed: 80136,
      deaths: 2914,
      recovered: 44854,
    },
    {
      date: "2020-3-3",
      confirmed: 80261,
      deaths: 2947,
      recovered: 47450,
    },
    {
      date: "2020-3-4",
      confirmed: 80386,
      deaths: 2983,
      recovered: 50001,
    },
    {
      date: "2020-3-5",
      confirmed: 80537,
      deaths: 3015,
      recovered: 52292,
    },
    {
      date: "2020-3-6",
      confirmed: 80690,
      deaths: 3044,
      recovered: 53944,
    },
    {
      date: "2020-3-7",
      confirmed: 80770,
      deaths: 3072,
      recovered: 55539,
    },
    {
      date: "2020-3-8",
      confirmed: 80823,
      deaths: 3100,
      recovered: 57388,
    },
    {
      date: "2020-3-9",
      confirmed: 80860,
      deaths: 3123,
      recovered: 58804,
    },
    {
      date: "2020-3-10",
      confirmed: 80887,
      deaths: 3139,
      recovered: 60181,
    },
    {
      date: "2020-3-11",
      confirmed: 80921,
      deaths: 3161,
      recovered: 61644,
    },
    {
      date: "2020-3-12",
      confirmed: 80932,
      deaths: 3172,
      recovered: 62901,
    },
    {
      date: "2020-3-13",
      confirmed: 80945,
      deaths: 3180,
      recovered: 64196,
    },
    {
      date: "2020-3-14",
      confirmed: 80977,
      deaths: 3193,
      recovered: 65660,
    },
    {
      date: "2020-3-15",
      confirmed: 81003,
      deaths: 3203,
      recovered: 67017,
    },
    {
      date: "2020-3-16",
      confirmed: 81033,
      deaths: 3217,
      recovered: 67910,
    },
    {
      date: "2020-3-17",
      confirmed: 81058,
      deaths: 3230,
      recovered: 68798,
    },
    {
      date: "2020-3-18",
      confirmed: 81102,
      deaths: 3241,
      recovered: 69755,
    },
    {
      date: "2020-3-19",
      confirmed: 81156,
      deaths: 3249,
      recovered: 70535,
    },
    {
      date: "2020-3-20",
      confirmed: 81250,
      deaths: 3253,
      recovered: 71266,
    },
  ],
  Iran: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 2,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 5,
      deaths: 2,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 18,
      deaths: 4,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 28,
      deaths: 5,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 43,
      deaths: 8,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 61,
      deaths: 12,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 95,
      deaths: 16,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 139,
      deaths: 19,
      recovered: 49,
    },
    {
      date: "2020-2-27",
      confirmed: 245,
      deaths: 26,
      recovered: 49,
    },
    {
      date: "2020-2-28",
      confirmed: 388,
      deaths: 34,
      recovered: 73,
    },
    {
      date: "2020-2-29",
      confirmed: 593,
      deaths: 43,
      recovered: 123,
    },
    {
      date: "2020-3-1",
      confirmed: 978,
      deaths: 54,
      recovered: 175,
    },
    {
      date: "2020-3-2",
      confirmed: 1501,
      deaths: 66,
      recovered: 291,
    },
    {
      date: "2020-3-3",
      confirmed: 2336,
      deaths: 77,
      recovered: 291,
    },
    {
      date: "2020-3-4",
      confirmed: 2922,
      deaths: 92,
      recovered: 552,
    },
    {
      date: "2020-3-5",
      confirmed: 3513,
      deaths: 107,
      recovered: 739,
    },
    {
      date: "2020-3-6",
      confirmed: 4747,
      deaths: 124,
      recovered: 913,
    },
    {
      date: "2020-3-7",
      confirmed: 5823,
      deaths: 145,
      recovered: 1669,
    },
    {
      date: "2020-3-8",
      confirmed: 6566,
      deaths: 194,
      recovered: 2134,
    },
    {
      date: "2020-3-9",
      confirmed: 7161,
      deaths: 237,
      recovered: 2394,
    },
    {
      date: "2020-3-10",
      confirmed: 8042,
      deaths: 291,
      recovered: 2731,
    },
    {
      date: "2020-3-11",
      confirmed: 9000,
      deaths: 354,
      recovered: 2959,
    },
    {
      date: "2020-3-12",
      confirmed: 10075,
      deaths: 429,
      recovered: 2959,
    },
    {
      date: "2020-3-13",
      confirmed: 11364,
      deaths: 514,
      recovered: 2959,
    },
    {
      date: "2020-3-14",
      confirmed: 12729,
      deaths: 611,
      recovered: 2959,
    },
    {
      date: "2020-3-15",
      confirmed: 13938,
      deaths: 724,
      recovered: 4590,
    },
    {
      date: "2020-3-16",
      confirmed: 14991,
      deaths: 853,
      recovered: 4590,
    },
    {
      date: "2020-3-17",
      confirmed: 16169,
      deaths: 988,
      recovered: 5389,
    },
    {
      date: "2020-3-18",
      confirmed: 17361,
      deaths: 1135,
      recovered: 5389,
    },
    {
      date: "2020-3-19",
      confirmed: 18407,
      deaths: 1284,
      recovered: 5710,
    },
    {
      date: "2020-3-20",
      confirmed: 19644,
      deaths: 1433,
      recovered: 6745,
    },
  ],
  "Korea, South": [
    {
      date: "2020-1-22",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 24,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-8",
      confirmed: 24,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-9",
      confirmed: 25,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-10",
      confirmed: 27,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-11",
      confirmed: 28,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-2-12",
      confirmed: 28,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-13",
      confirmed: 28,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-14",
      confirmed: 28,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-15",
      confirmed: 28,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-2-16",
      confirmed: 29,
      deaths: 0,
      recovered: 9,
    },
    {
      date: "2020-2-17",
      confirmed: 30,
      deaths: 0,
      recovered: 10,
    },
    {
      date: "2020-2-18",
      confirmed: 31,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-19",
      confirmed: 31,
      deaths: 0,
      recovered: 12,
    },
    {
      date: "2020-2-20",
      confirmed: 104,
      deaths: 1,
      recovered: 16,
    },
    {
      date: "2020-2-21",
      confirmed: 204,
      deaths: 2,
      recovered: 16,
    },
    {
      date: "2020-2-22",
      confirmed: 433,
      deaths: 2,
      recovered: 16,
    },
    {
      date: "2020-2-23",
      confirmed: 602,
      deaths: 6,
      recovered: 18,
    },
    {
      date: "2020-2-24",
      confirmed: 833,
      deaths: 8,
      recovered: 18,
    },
    {
      date: "2020-2-25",
      confirmed: 977,
      deaths: 10,
      recovered: 22,
    },
    {
      date: "2020-2-26",
      confirmed: 1261,
      deaths: 12,
      recovered: 22,
    },
    {
      date: "2020-2-27",
      confirmed: 1766,
      deaths: 13,
      recovered: 22,
    },
    {
      date: "2020-2-28",
      confirmed: 2337,
      deaths: 13,
      recovered: 22,
    },
    {
      date: "2020-2-29",
      confirmed: 3150,
      deaths: 16,
      recovered: 27,
    },
    {
      date: "2020-3-1",
      confirmed: 3736,
      deaths: 17,
      recovered: 30,
    },
    {
      date: "2020-3-2",
      confirmed: 4335,
      deaths: 28,
      recovered: 30,
    },
    {
      date: "2020-3-3",
      confirmed: 5186,
      deaths: 28,
      recovered: 30,
    },
    {
      date: "2020-3-4",
      confirmed: 5621,
      deaths: 35,
      recovered: 41,
    },
    {
      date: "2020-3-5",
      confirmed: 6088,
      deaths: 35,
      recovered: 41,
    },
    {
      date: "2020-3-6",
      confirmed: 6593,
      deaths: 42,
      recovered: 135,
    },
    {
      date: "2020-3-7",
      confirmed: 7041,
      deaths: 44,
      recovered: 135,
    },
    {
      date: "2020-3-8",
      confirmed: 7314,
      deaths: 50,
      recovered: 118,
    },
    {
      date: "2020-3-9",
      confirmed: 7478,
      deaths: 53,
      recovered: 118,
    },
    {
      date: "2020-3-10",
      confirmed: 7513,
      deaths: 54,
      recovered: 247,
    },
    {
      date: "2020-3-11",
      confirmed: 7755,
      deaths: 60,
      recovered: 288,
    },
    {
      date: "2020-3-12",
      confirmed: 7869,
      deaths: 66,
      recovered: 333,
    },
    {
      date: "2020-3-13",
      confirmed: 7979,
      deaths: 66,
      recovered: 510,
    },
    {
      date: "2020-3-14",
      confirmed: 8086,
      deaths: 72,
      recovered: 510,
    },
    {
      date: "2020-3-15",
      confirmed: 8162,
      deaths: 75,
      recovered: 510,
    },
    {
      date: "2020-3-16",
      confirmed: 8236,
      deaths: 75,
      recovered: 1137,
    },
    {
      date: "2020-3-17",
      confirmed: 8320,
      deaths: 81,
      recovered: 1407,
    },
    {
      date: "2020-3-18",
      confirmed: 8413,
      deaths: 84,
      recovered: 1540,
    },
    {
      date: "2020-3-19",
      confirmed: 8565,
      deaths: 91,
      recovered: 1540,
    },
    {
      date: "2020-3-20",
      confirmed: 8652,
      deaths: 94,
      recovered: 1540,
    },
  ],
  France: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 11,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-13",
      confirmed: 11,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-14",
      confirmed: 11,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-15",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-16",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-17",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-18",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-19",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-20",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-21",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-22",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-23",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-24",
      confirmed: 12,
      deaths: 1,
      recovered: 4,
    },
    {
      date: "2020-2-25",
      confirmed: 14,
      deaths: 1,
      recovered: 11,
    },
    {
      date: "2020-2-26",
      confirmed: 18,
      deaths: 2,
      recovered: 11,
    },
    {
      date: "2020-2-27",
      confirmed: 38,
      deaths: 2,
      recovered: 11,
    },
    {
      date: "2020-2-28",
      confirmed: 57,
      deaths: 2,
      recovered: 11,
    },
    {
      date: "2020-2-29",
      confirmed: 100,
      deaths: 2,
      recovered: 12,
    },
    {
      date: "2020-3-1",
      confirmed: 130,
      deaths: 2,
      recovered: 12,
    },
    {
      date: "2020-3-2",
      confirmed: 191,
      deaths: 3,
      recovered: 12,
    },
    {
      date: "2020-3-3",
      confirmed: 204,
      deaths: 4,
      recovered: 12,
    },
    {
      date: "2020-3-4",
      confirmed: 288,
      deaths: 4,
      recovered: 12,
    },
    {
      date: "2020-3-5",
      confirmed: 380,
      deaths: 6,
      recovered: 12,
    },
    {
      date: "2020-3-6",
      confirmed: 656,
      deaths: 9,
      recovered: 12,
    },
    {
      date: "2020-3-7",
      confirmed: 957,
      deaths: 11,
      recovered: 12,
    },
    {
      date: "2020-3-8",
      confirmed: 1134,
      deaths: 19,
      recovered: 12,
    },
    {
      date: "2020-3-9",
      confirmed: 1217,
      deaths: 19,
      recovered: 12,
    },
    {
      date: "2020-3-10",
      confirmed: 1792,
      deaths: 33,
      recovered: 12,
    },
    {
      date: "2020-3-11",
      confirmed: 2290,
      deaths: 48,
      recovered: 12,
    },
    {
      date: "2020-3-12",
      confirmed: 2290,
      deaths: 48,
      recovered: 12,
    },
    {
      date: "2020-3-13",
      confirmed: 3678,
      deaths: 79,
      recovered: 12,
    },
    {
      date: "2020-3-14",
      confirmed: 4487,
      deaths: 91,
      recovered: 12,
    },
    {
      date: "2020-3-15",
      confirmed: 4523,
      deaths: 91,
      recovered: 12,
    },
    {
      date: "2020-3-16",
      confirmed: 6668,
      deaths: 148,
      recovered: 12,
    },
    {
      date: "2020-3-17",
      confirmed: 7699,
      deaths: 148,
      recovered: 12,
    },
    {
      date: "2020-3-18",
      confirmed: 9105,
      deaths: 148,
      recovered: 12,
    },
    {
      date: "2020-3-19",
      confirmed: 10947,
      deaths: 243,
      recovered: 12,
    },
    {
      date: "2020-3-20",
      confirmed: 12726,
      deaths: 450,
      recovered: 12,
    },
  ],
  "Cruise Ship": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 61,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 61,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 64,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 135,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 135,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 175,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 175,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 218,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 285,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 355,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 454,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 542,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 621,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-20",
      confirmed: 634,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-2-21",
      confirmed: 634,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-2-22",
      confirmed: 634,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-2-23",
      confirmed: 691,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 691,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 691,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 705,
      deaths: 4,
      recovered: 10,
    },
    {
      date: "2020-2-27",
      confirmed: 705,
      deaths: 4,
      recovered: 10,
    },
    {
      date: "2020-2-28",
      confirmed: 705,
      deaths: 6,
      recovered: 10,
    },
    {
      date: "2020-2-29",
      confirmed: 705,
      deaths: 6,
      recovered: 10,
    },
    {
      date: "2020-3-1",
      confirmed: 705,
      deaths: 6,
      recovered: 10,
    },
    {
      date: "2020-3-2",
      confirmed: 705,
      deaths: 6,
      recovered: 10,
    },
    {
      date: "2020-3-3",
      confirmed: 706,
      deaths: 6,
      recovered: 10,
    },
    {
      date: "2020-3-4",
      confirmed: 706,
      deaths: 6,
      recovered: 10,
    },
    {
      date: "2020-3-5",
      confirmed: 706,
      deaths: 6,
      recovered: 10,
    },
    {
      date: "2020-3-6",
      confirmed: 696,
      deaths: 6,
      recovered: 40,
    },
    {
      date: "2020-3-7",
      confirmed: 696,
      deaths: 6,
      recovered: 40,
    },
    {
      date: "2020-3-8",
      confirmed: 696,
      deaths: 6,
      recovered: 40,
    },
    {
      date: "2020-3-9",
      confirmed: 696,
      deaths: 6,
      recovered: 40,
    },
    {
      date: "2020-3-10",
      confirmed: 696,
      deaths: 6,
      recovered: 40,
    },
    {
      date: "2020-3-11",
      confirmed: 696,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-12",
      confirmed: 696,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-13",
      confirmed: 696,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-14",
      confirmed: 696,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-15",
      confirmed: 696,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-16",
      confirmed: 696,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-17",
      confirmed: 696,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-18",
      confirmed: 712,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-19",
      confirmed: 712,
      deaths: 7,
      recovered: 325,
    },
    {
      date: "2020-3-20",
      confirmed: 712,
      deaths: 7,
      recovered: 325,
    },
  ],
  Denmark: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 24,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-7",
      confirmed: 24,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-8",
      confirmed: 37,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-9",
      confirmed: 92,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-10",
      confirmed: 264,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-11",
      confirmed: 444,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-12",
      confirmed: 617,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-13",
      confirmed: 804,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-14",
      confirmed: 836,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-15",
      confirmed: 875,
      deaths: 2,
      recovered: 1,
    },
    {
      date: "2020-3-16",
      confirmed: 933,
      deaths: 3,
      recovered: 1,
    },
    {
      date: "2020-3-17",
      confirmed: 1025,
      deaths: 4,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 1116,
      deaths: 4,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 1225,
      deaths: 6,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 1337,
      deaths: 9,
      recovered: 1,
    },
  ],
  Czechia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 19,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 31,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 31,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 41,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 91,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 94,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 141,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 189,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 253,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 298,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-17",
      confirmed: 396,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-18",
      confirmed: 464,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-19",
      confirmed: 694,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-20",
      confirmed: 833,
      deaths: 0,
      recovered: 4,
    },
  ],
  "Taiwan*": [
    {
      date: "2020-1-22",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-7",
      confirmed: 16,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-8",
      confirmed: 17,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-9",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-10",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-11",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-12",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 18,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 18,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-15",
      confirmed: 18,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-16",
      confirmed: 20,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-17",
      confirmed: 22,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-18",
      confirmed: 22,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-19",
      confirmed: 23,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-20",
      confirmed: 24,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-21",
      confirmed: 26,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-22",
      confirmed: 26,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-23",
      confirmed: 28,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-2-24",
      confirmed: 30,
      deaths: 1,
      recovered: 5,
    },
    {
      date: "2020-2-25",
      confirmed: 31,
      deaths: 1,
      recovered: 5,
    },
    {
      date: "2020-2-26",
      confirmed: 32,
      deaths: 1,
      recovered: 5,
    },
    {
      date: "2020-2-27",
      confirmed: 32,
      deaths: 1,
      recovered: 5,
    },
    {
      date: "2020-2-28",
      confirmed: 34,
      deaths: 1,
      recovered: 6,
    },
    {
      date: "2020-2-29",
      confirmed: 39,
      deaths: 1,
      recovered: 9,
    },
    {
      date: "2020-3-1",
      confirmed: 40,
      deaths: 1,
      recovered: 9,
    },
    {
      date: "2020-3-2",
      confirmed: 41,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-3-3",
      confirmed: 42,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-3-4",
      confirmed: 42,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-3-5",
      confirmed: 44,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-3-6",
      confirmed: 45,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-3-7",
      confirmed: 45,
      deaths: 1,
      recovered: 12,
    },
    {
      date: "2020-3-8",
      confirmed: 45,
      deaths: 1,
      recovered: 13,
    },
    {
      date: "2020-3-9",
      confirmed: 45,
      deaths: 1,
      recovered: 15,
    },
    {
      date: "2020-3-10",
      confirmed: 47,
      deaths: 1,
      recovered: 17,
    },
    {
      date: "2020-3-11",
      confirmed: 48,
      deaths: 1,
      recovered: 17,
    },
    {
      date: "2020-3-12",
      confirmed: 49,
      deaths: 1,
      recovered: 20,
    },
    {
      date: "2020-3-13",
      confirmed: 50,
      deaths: 1,
      recovered: 20,
    },
    {
      date: "2020-3-14",
      confirmed: 53,
      deaths: 1,
      recovered: 20,
    },
    {
      date: "2020-3-15",
      confirmed: 59,
      deaths: 1,
      recovered: 20,
    },
    {
      date: "2020-3-16",
      confirmed: 67,
      deaths: 1,
      recovered: 20,
    },
    {
      date: "2020-3-17",
      confirmed: 77,
      deaths: 1,
      recovered: 22,
    },
    {
      date: "2020-3-18",
      confirmed: 100,
      deaths: 1,
      recovered: 22,
    },
    {
      date: "2020-3-19",
      confirmed: 108,
      deaths: 1,
      recovered: 26,
    },
    {
      date: "2020-3-20",
      confirmed: 135,
      deaths: 2,
      recovered: 26,
    },
  ],
  Vietnam: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-2",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-3",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-4",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-5",
      confirmed: 8,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-6",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-7",
      confirmed: 10,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-8",
      confirmed: 13,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-9",
      confirmed: 13,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-10",
      confirmed: 14,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-11",
      confirmed: 15,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-2-12",
      confirmed: 15,
      deaths: 0,
      recovered: 6,
    },
    {
      date: "2020-2-13",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-14",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-15",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-16",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-17",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-18",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-19",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-20",
      confirmed: 16,
      deaths: 0,
      recovered: 7,
    },
    {
      date: "2020-2-21",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-22",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-23",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-24",
      confirmed: 16,
      deaths: 0,
      recovered: 14,
    },
    {
      date: "2020-2-25",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-2-26",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-2-27",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-2-28",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-2-29",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-1",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-2",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-3",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-4",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-5",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-6",
      confirmed: 16,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-7",
      confirmed: 18,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-8",
      confirmed: 30,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-9",
      confirmed: 30,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-10",
      confirmed: 31,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-11",
      confirmed: 38,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-12",
      confirmed: 39,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-13",
      confirmed: 47,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-14",
      confirmed: 53,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-15",
      confirmed: 56,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-16",
      confirmed: 61,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-17",
      confirmed: 66,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-18",
      confirmed: 75,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-19",
      confirmed: 85,
      deaths: 0,
      recovered: 16,
    },
    {
      date: "2020-3-20",
      confirmed: 91,
      deaths: 0,
      recovered: 16,
    },
  ],
  Russia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-13",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-14",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-15",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-16",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-17",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-18",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-19",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-20",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-21",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-22",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-23",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-24",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-25",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-26",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-27",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-28",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-2-29",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-1",
      confirmed: 2,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-2",
      confirmed: 3,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-3",
      confirmed: 3,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-4",
      confirmed: 3,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-5",
      confirmed: 4,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-6",
      confirmed: 13,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-7",
      confirmed: 13,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-8",
      confirmed: 17,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-9",
      confirmed: 17,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-10",
      confirmed: 20,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-11",
      confirmed: 20,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-12",
      confirmed: 28,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-13",
      confirmed: 45,
      deaths: 0,
      recovered: 3,
    },
    {
      date: "2020-3-14",
      confirmed: 59,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-15",
      confirmed: 63,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-16",
      confirmed: 90,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-17",
      confirmed: 114,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-18",
      confirmed: 147,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-19",
      confirmed: 199,
      deaths: 1,
      recovered: 9,
    },
    {
      date: "2020-3-20",
      confirmed: 253,
      deaths: 1,
      recovered: 9,
    },
  ],
  Moldova: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 30,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 30,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 49,
      deaths: 1,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 66,
      deaths: 1,
      recovered: 1,
    },
  ],
  Bolivia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
  ],
  Honduras: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
  ],
  "United Kingdom": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-13",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-14",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-15",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-2-16",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-17",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-18",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-19",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-20",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-21",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-22",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-23",
      confirmed: 9,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-24",
      confirmed: 13,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-25",
      confirmed: 13,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-26",
      confirmed: 13,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-27",
      confirmed: 15,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-28",
      confirmed: 20,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-2-29",
      confirmed: 23,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-1",
      confirmed: 36,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-2",
      confirmed: 40,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-3",
      confirmed: 51,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-4",
      confirmed: 86,
      deaths: 0,
      recovered: 8,
    },
    {
      date: "2020-3-5",
      confirmed: 116,
      deaths: 1,
      recovered: 8,
    },
    {
      date: "2020-3-6",
      confirmed: 164,
      deaths: 2,
      recovered: 8,
    },
    {
      date: "2020-3-7",
      confirmed: 207,
      deaths: 2,
      recovered: 18,
    },
    {
      date: "2020-3-8",
      confirmed: 274,
      deaths: 3,
      recovered: 18,
    },
    {
      date: "2020-3-9",
      confirmed: 322,
      deaths: 4,
      recovered: 18,
    },
    {
      date: "2020-3-10",
      confirmed: 384,
      deaths: 6,
      recovered: 19,
    },
    {
      date: "2020-3-11",
      confirmed: 459,
      deaths: 8,
      recovered: 19,
    },
    {
      date: "2020-3-12",
      confirmed: 459,
      deaths: 8,
      recovered: 19,
    },
    {
      date: "2020-3-13",
      confirmed: 802,
      deaths: 8,
      recovered: 19,
    },
    {
      date: "2020-3-14",
      confirmed: 1144,
      deaths: 21,
      recovered: 19,
    },
    {
      date: "2020-3-15",
      confirmed: 1145,
      deaths: 21,
      recovered: 19,
    },
    {
      date: "2020-3-16",
      confirmed: 1551,
      deaths: 56,
      recovered: 21,
    },
    {
      date: "2020-3-17",
      confirmed: 1960,
      deaths: 56,
      recovered: 53,
    },
    {
      date: "2020-3-18",
      confirmed: 2642,
      deaths: 72,
      recovered: 67,
    },
    {
      date: "2020-3-19",
      confirmed: 2716,
      deaths: 138,
      recovered: 67,
    },
    {
      date: "2020-3-20",
      confirmed: 4014,
      deaths: 178,
      recovered: 67,
    },
  ],
  "Congo (Kinshasa)": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Cote d'Ivoire": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 5,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-18",
      confirmed: 6,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-19",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
    {
      date: "2020-3-20",
      confirmed: 9,
      deaths: 0,
      recovered: 1,
    },
  ],
  Jamaica: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 10,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 12,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 13,
      deaths: 0,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 15,
      deaths: 1,
      recovered: 2,
    },
    {
      date: "2020-3-20",
      confirmed: 16,
      deaths: 1,
      recovered: 2,
    },
  ],
  Turkey: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 47,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 98,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 192,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 359,
      deaths: 4,
      recovered: 0,
    },
  ],
  Cuba: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 11,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 16,
      deaths: 1,
      recovered: 0,
    },
  ],
  Guyana: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 4,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 4,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 7,
      deaths: 1,
      recovered: 0,
    },
  ],
  Kazakhstan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 33,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 35,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 44,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 49,
      deaths: 3,
      recovered: 0,
    },
  ],
  Ethiopia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
  ],
  Sudan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
  ],
  Guinea: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Kenya: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Antigua and Barbuda": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Uruguay: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 29,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 50,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 79,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 94,
      deaths: 0,
      recovered: 0,
    },
  ],
  Ghana: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 11,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 16,
      deaths: 0,
      recovered: 0,
    },
  ],
  Namibia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
  ],
  Seychelles: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Trinidad and Tobago": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 9,
      deaths: 0,
      recovered: 0,
    },
  ],
  Venezuela: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 33,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 36,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 42,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 42,
      deaths: 0,
      recovered: 0,
    },
  ],
  Eswatini: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Gabon: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 3,
      deaths: 1,
      recovered: 0,
    },
  ],
  Guatemala: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 2,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 6,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 6,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 9,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 12,
      deaths: 1,
      recovered: 0,
    },
  ],
  Mauritania: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  Rwanda: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 7,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 8,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 17,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Saint Lucia": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Saint Vincent and the Grenadines": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Suriname: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
  ],
  Kosovo: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Central African Republic": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Congo (Brazzaville)": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Equatorial Guinea": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 4,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
  ],
  Uzbekistan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 15,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 23,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 33,
      deaths: 0,
      recovered: 0,
    },
  ],
  Netherlands: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 10,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 18,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 24,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 38,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 82,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 128,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 188,
      deaths: 1,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 265,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 321,
      deaths: 3,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 382,
      deaths: 4,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 503,
      deaths: 5,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 503,
      deaths: 5,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 806,
      deaths: 10,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 962,
      deaths: 12,
      recovered: 2,
    },
    {
      date: "2020-3-15",
      confirmed: 1138,
      deaths: 20,
      recovered: 2,
    },
    {
      date: "2020-3-16",
      confirmed: 1416,
      deaths: 24,
      recovered: 2,
    },
    {
      date: "2020-3-17",
      confirmed: 1711,
      deaths: 43,
      recovered: 2,
    },
    {
      date: "2020-3-18",
      confirmed: 2058,
      deaths: 58,
      recovered: 2,
    },
    {
      date: "2020-3-19",
      confirmed: 2467,
      deaths: 77,
      recovered: 2,
    },
    {
      date: "2020-3-20",
      confirmed: 3003,
      deaths: 107,
      recovered: 2,
    },
  ],
  Benin: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  Liberia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  Somalia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Tanzania: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
  ],
  Barbados: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 5,
      deaths: 0,
      recovered: 0,
    },
  ],
  Montenegro: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 14,
      deaths: 0,
      recovered: 0,
    },
  ],
  Kyrgyzstan: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 6,
      deaths: 0,
      recovered: 0,
    },
  ],
  Mauritius: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 12,
      deaths: 0,
      recovered: 0,
    },
  ],
  Zambia: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  Djibouti: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Gambia, The": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Bahamas, The": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
  ],
  Chad: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  "El Salvador": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Fiji: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Nicaragua: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Madagascar: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 3,
      deaths: 0,
      recovered: 0,
    },
  ],
  Haiti: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 2,
      deaths: 0,
      recovered: 0,
    },
  ],
  Angola: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Cabo Verde": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Niger: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  "Papua New Guinea": [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
  Zimbabwe: [
    {
      date: "2020-1-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-30",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-1-31",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-20",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-21",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-22",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-23",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-24",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-25",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-26",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-27",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-28",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-2-29",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-1",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-2",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-3",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-4",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-5",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-6",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-7",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-8",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-9",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-10",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-11",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-12",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-13",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-14",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-15",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-16",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-17",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-18",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-19",
      confirmed: 0,
      deaths: 0,
      recovered: 0,
    },
    {
      date: "2020-3-20",
      confirmed: 1,
      deaths: 0,
      recovered: 0,
    },
  ],
};

export { data, test };
