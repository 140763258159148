import React from "react";

function useTimeout(callback, delay) {
  const savedCallback = React.useRef(null);
  const timeout = React.useRef(null);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback && savedCallback.current();
    }
    if (delay !== null) {
      timeout.current = setTimeout(tick, delay);
      return () => clearTimeout(timeout.current);
    }
  }, [delay]);
}

export default useTimeout;
