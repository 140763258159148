import React from "react";
import { LinePath } from "@vx/shape";
import { curveLinear } from "@vx/curve";
import { scaleTime, scaleLinear } from "@vx/scale";
import { extent, max, min } from "d3-array";
import { data as raw } from "../data/timeSeries";
import moment from "moment";
import styled from "../designSystem";
import { Sentiment } from "../designSystem/utils";
import { observer } from "mobx-react-lite";

const series = Object.keys(raw).map((key) =>
  raw[key].map((item) => {
    return {
      date: moment.utc(item.date),
      value: item.confirmed,
    };
  })
);

export const sparkData = series.reduce((rec, d) => {
  return rec.concat(d);
}, []);

// accessors
const x = (d) => d.date;
const y = (d) => d.value;

export type SparkLineData = { date: Date; value: number };
type SparkLineProps = {
  height: number;
  width: number;
  data: SparkLineData[];
  sentiment: Sentiment;
};

const StyledSpark = styled.svg<{ sentiment: Sentiment }>`
  stroke: ${({ theme, sentiment }) => theme[sentiment]};
`;

const Sparkline: React.FC<SparkLineProps> = ({
  height,
  width,
  data,
  sentiment = "neutral",
}) => {
  // bounds
  const xMax = width;
  const yMax = height - 10; // account for padding
  if (!data) {
    return null;
  }

  // scales
  const xScale = scaleTime({
    range: [0, xMax],
    domain: extent(data, x),
  });
  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [min(data, y), max(data, y)],
  });

  return (
    <StyledSpark width={width} height={height} sentiment={sentiment}>
      <LinePath
        data={data}
        x={(d) => xScale(x(d))}
        y={(d) => yScale(y(d))}
        strokeWidth={2}
        strokeLinecap="round"
        curve={curveLinear}
      />
    </StyledSpark>
  );
};

export default observer(Sparkline);
