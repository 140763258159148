import React from "react";
import { LatLngBounds } from "react-leaflet";
import Flexbox from "../../layout/flexbox";
import { StyledMap } from "../leafletMap";
import { Helmet } from "react-helmet";
import "leaflet/dist/leaflet.css";
import L, { LatLngBoundsLiteral } from "leaflet";
import { GeoRender } from "../../utils/map";
import { observer } from "mobx-react-lite";
import { featureByIso2 } from "../../utils/countryFeature";

type CountryShapeProps = {
  countryCodeIsoAlpha2: string;
};

const CountryShape: React.FC<CountryShapeProps> = ({
  countryCodeIsoAlpha2,
}) => {
  const feature = featureByIso2(countryCodeIsoAlpha2);
  if (!feature) {
    return <div />;
  }

  const geoJSON = L.geoJSON(feature);
  const bounds: LatLngBounds = geoJSON.getBounds();
  const maxBounds: LatLngBoundsLiteral = [
    [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
    [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
  ];

  return (
    <Flexbox h100 className="d3-container" style={{ position: "relative" }}>
      <StyledMap
        center={bounds.getCenter()}
        zoomControl={false}
        scrollWheelZoom={false}
        dragging={false}
        bounds={maxBounds}
        preferCanvas
      >
        <Helmet>
          <link
            rel="stylesheet"
            href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
            integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
            crossOrigin=""
          />
        </Helmet>
        <GeoRender feature={feature} />
      </StyledMap>
    </Flexbox>
  );
};

CountryShape.displayName = "CountryShape";
export default observer(CountryShape);
