import { observer } from "mobx-react-lite";
import TooltipInner, { getTooltipRows } from "./tooltipInner";
import { GeoJSON, Tooltip } from "react-leaflet";
import React from "react";
import { IndexedKeys } from "../App";

type ChoroplethCountryProps = {
  geoFeature: any;
  countryData: any;
  handleClick?: any;
  primaryFilter?: IndexedKeys;
  selectedTimeRangeFilter?: any;
  style?: any;
  idx?: number;
};

const ChoroplethCountry: React.FC<ChoroplethCountryProps> = ({
  geoFeature,
  countryData,
  handleClick,
  primaryFilter,
  selectedTimeRangeFilter,
  style,
  idx,
}) => {
  const { name: countryName } = countryData;
  const iso2Code = geoFeature.properties.ISO_2_CODE;
  // console.log(geoFeature, style, countryName);

  return (
    <GeoJSON
      key={iso2Code + idx}
      style={style}
      data={geoFeature}
      onClick={() => handleClick(iso2Code)}
    >
      <Tooltip className="leaflet-wrapper">
        <TooltipInner
          rows={getTooltipRows(
            countryData,
            primaryFilter,
            selectedTimeRangeFilter
          )}
          title={countryName}
        />
      </Tooltip>
    </GeoJSON>
  );
};

const RenderCountry = observer(ChoroplethCountry);

export default RenderCountry;
