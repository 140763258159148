import React from "react";
import { Switch, Route } from "react-router-dom";
import Breadcrumb from "./breadcrumb";
import { observer } from "mobx-react-lite";

export type HeaderBreacrumbProps = {
  isHeaderCollapsed?: boolean;
  useHambuger?: boolean;
  createdTime?: number;
};

const HeaderBreacrumbs: React.FC<HeaderBreacrumbProps> = (props) => {
  return (
    <Switch>
      <Route path={["/", "/voice", "/info"]} exact>
        <Breadcrumb
          title="WHO Coronavirus Disease (COVID-19) Dashboard"
          {...props}
        />
      </Route>
      <Route path="/explorer" exact>
        <Breadcrumb title="Explore the Data" {...props} />
      </Route>
      <Route path={["/region/:id/country/:id", "/region/:id"]}>
        <Breadcrumb {...props} />
      </Route>
    </Switch>
  );
};

export default observer(HeaderBreacrumbs);
