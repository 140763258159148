import { css } from "../designSystem";

export const sizes = {
  xl: 1420,
  l: 1020,
  m: 768,
  s: 576,
};

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args: any) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${(css as any)(...args)}
    }
  `;
  return acc;
}, {});

// use this to debug
// *{outline: 1px solid purple}
// ${(media as any).xl`
//     *{outline: 1px solid magenta};
// `}
// ${(media as any).l`
//     *{outline: 1px solid #bada55};
// `}
// ${(media as any).m`
//     *{outline: 1px solid orange}
// `}
// ${(media as any).s`
//     *{outline: 1px solid red}
// `}
