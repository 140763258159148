import React from "react";
import { observer } from "mobx-react-lite";
import ReactMarkdown from "react-markdown";

// this allows changing the target based on link
// currently all are opening in a new tab
function targetFn(url, text, title) {
  return "_blank";
}

type MarkdownRendererProps = {
  markdown: string;
};

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ markdown }) => {
  return (
    <ReactMarkdown escapeHtml={false} source={markdown} linkTarget={targetFn} />
  );
};

MarkdownRenderer.displayName = "MarkdownRenderer";
export default observer(MarkdownRenderer);
