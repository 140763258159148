import topoJson from "../assets/geo/topojson-WHO-world-map-optimized.json";
import { feature } from "topojson-client/dist/topojson-client";
import { whoCountryNamesByIso2 as countryDetailsByIsoAscii2 } from "../whoCountryNames";
import { compare } from "natural-orderby";

/**
 * Resolves long-form ISO offical English country names, common country names and ISO 3166-1 alpha-2 codes to
 * ISO 3166-1 alpha-3 codes.
 * @param country
 * @returns {string}
 * @constructor
 */
const countryToIso3 = function (country: string): string {
  if (!longOrTwoToThree[country]) {
    return;
  }

  return longOrTwoToThree[country];
};

const world = feature(topoJson, (topoJson as any).objects.countries);
const countryFeaturesIndexed = {};
world.features.forEach((feature) => {
  countryFeaturesIndexed[feature.properties.ISO_2_CODE] = feature;
});

export function getCountryNameFromId(id) {
  return isoAlpha2toCountryName(id);
}

export function findRegionByCountryIso2(iso2) {
  const found = countryFeaturesIndexed[iso2];
  return found?.properties?.WHO_REGION;
}

/**
 * Maps ISO 3166-1 alpha-3 codes to ISO 3166-1 alpha-2 codes.
 * @param isoAlpha3
 */
export function isoAlpha3toIsoAlpha2(isoAlpha3: string): string {
  return isoAlpha3toAlpha2[isoAlpha3];
}

/**
 * Maps ISO 3166-1 alpha-2 codes to ISO 3166-1 alpha-3 codes.
 * @param isoAlpha2
 */
export function isoAlpha2toIsoAlpha3(isoAlpha2: string): string {
  return longOrTwoToThree[isoAlpha2];
}

export function isoAlpha2toCountryName(isoAlpha2: string): string {
  return isoAlpha2 && countryDetailsByIsoAscii2[isoAlpha2]?.ADM0_VIZ_NAME;
}

export function isoAlpha3toCountryName(isoAlpha3: string): string {
  return isoAlpha2toCountryName(isoAlpha3toIsoAlpha2(isoAlpha3));
}

export function matchSovrnToName(isoAlpha2: string): boolean {
  const sovereign = countryDetailsByIsoAscii2[
    isoAlpha2
  ]?.ADM0_SOVRN?.toLowerCase();
  const name = countryDetailsByIsoAscii2[isoAlpha2]?.ADM0_NAME?.toLowerCase();
  return sovereign === name;
}

const longOrTwoToThree = {
  Afghanistan: "AFG",
  Albania: "ALB",
  Algeria: "DZA",
  "American Samoa": "ASM",
  Andorra: "AND",
  Angola: "AGO",
  Anguilla: "AIA",
  Antarctica: "ATA",
  "Antigua & Barbuda": "ATG",
  "Antigua and Barbuda": "ATG",
  Argentina: "ARG",
  Armenia: "ARM",
  Aruba: "ABW",
  Australia: "AUS",
  Austria: "AUT",
  Azerbaijan: "AZE",
  "Bahamas, The": "BHS",
  Bahamas: "BHS",
  Bahrain: "BHR",
  Bangladesh: "BGD",
  Barbados: "BRB",
  Belarus: "BLR",
  Belgium: "BEL",
  Belize: "BLZ",
  Benin: "BEN",
  Bermuda: "BMU",
  Bhutan: "BTN",
  Bolivia: "BOL",
  "Bolivia (Plurinational State of)": "BOL",
  "Bonaire, Sint Eustatius and Saba": "BES",
  Bosnia: "BIH",
  "Bosnia and Herzegovina": "BIH",
  Botswana: "BWA",
  "Bouvet Island": "BVT",
  Brazil: "BRA",
  "British Indian Ocean Territory": "IOT",
  "British Virgin Islands": "VGB",
  Brunei: "BRN",
  "Brunei Darussalam": "BRN",
  Bulgaria: "BGR",
  "Burkina Faso": "BFA",
  Burundi: "BDI",
  "Cabo Verde": "CPV",
  Cambodia: "KHM",
  Cameroon: "CMR",
  Canada: "CAN",
  "Cape Verde": "CPV",
  "Caribbean Netherlands": "BES",
  "Cayman Islands": "CYM",
  "Central African Republic": "CAF",
  Chad: "TCD",
  "Channel Islands": "GBR",
  Chile: "CHL",
  China: "CHN",
  "China, Hong Kong Special Administrative Region": "HKG",
  "Macao SAR, China": "MAC",
  "China, Macao Special Administrative Region": "MAC",
  "China, Macao SAR": "MAC",
  "Christmas Island": "CXR",
  "Cocos (Keeling) Islands": "CCK",
  Colombia: "COL",
  Comoros: "COM",
  "Congo, Dem. Rep.": "COG",
  "Congo, Rep.": "COG",
  Congo: "COG",
  "Congo - Brazzaville": "COG",
  "Congo - Kinshasa": "COD",
  "Cook Islands": "COK",
  "Costa Rica": "CRI",
  Croatia: "HRV",
  Cuba: "CUB",
  Curacao: "CUW",
  Curaçao: "CUW",
  Cyprus: "CYP",
  Czechia: "CZE",
  "Czech Republic": "CZE",
  "Cote d'Ivoire": "CIV",
  "Côte d'Ivoire": "CIV",
  "Côte d’Ivoire": "CIV",
  "Korea, Dem. People’s Rep.": "PRK",
  "Dem. People's Republic of Korea": "PRK",
  "Democratic People's Republic of Korea": "PRK",
  "Democratic Republic of the Congo": "COD",
  Denmark: "DNK",
  Djibouti: "DJI",
  Dominica: "DMA",
  "Dominican Republic": "DOM",
  Ecuador: "ECU",
  "Egypt, Arab Rep.": "EGY",
  Egypt: "EGY",
  "El Salvador": "SLV",
  "Equatorial Guinea": "GNQ",
  Eritrea: "ERI",
  Estonia: "EST",
  Ethiopia: "ETH",
  "Faeroe Islands": "FRO",
  "Falkland Islands": "FLK",
  "Falkland Islands (Malvinas)": "FLK",
  "Faroe Islands": "FRO",
  Fiji: "FJI",
  Finland: "FIN",
  France: "FRA",
  "French Guiana": "GUF",
  "French Polynesia": "PYF",
  "French Southern Territories": "ATF",
  Gabon: "GAB",
  "Gambia, The": "GMB",
  Gambia: "GMB",
  Georgia: "GEO",
  Germany: "DEU",
  Ghana: "GHA",
  Gibraltar: "GIB",
  Greece: "GRC",
  Greenland: "GRL",
  Grenada: "GRD",
  Guadeloupe: "GLP",
  Guam: "GUM",
  Guatemala: "GTM",
  Guernsey: "GGY",
  Guinea: "GIN",
  "Guinea-Bissau": "GNB",
  Guyana: "GUY",
  Haiti: "HTI",
  "Heard & McDonald Islands": "HMD",
  "Holy See": "VAT",
  Honduras: "HND",
  "Hong Kong SAR, China": "HKG",
  "China, Hong Kong SAR": "HKG",
  "Hong Kong": "HKG",
  Hungary: "HUN",
  Iceland: "ISL",
  India: "IND",
  Indonesia: "IDN",
  Iran: "IRN",
  "Iran, Islamic Rep.": "IRN",
  "Iran (Islamic Republic of)": "IRN",
  Iraq: "IRQ",
  Ireland: "IRL",
  "Isle of Man": "IMN",
  Israel: "ISR",
  Italy: "ITA",
  Jamaica: "JAM",
  Japan: "JPN",
  Jersey: "JEY",
  Jordan: "JOR",
  Kazakhstan: "KAZ",
  Kenya: "KEN",
  Kiribati: "KIR",
  Kuwait: "KWT",
  "Kyrgyz Republic": "KGZ",
  Kyrgyzstan: "KGZ",
  "Lao PDR": "LOA",
  "Lao People's Democratic Republic": "LAO",
  Laos: "LAO",
  Latvia: "LVA",
  Lebanon: "LBN",
  Lesotho: "LSO",
  Liberia: "LBR",
  Libya: "LBY",
  Liechtenstein: "LIE",
  Lithuania: "LTU",
  Luxembourg: "LUX",
  Macau: "MAC",
  "North Macedonia": "MKD",
  Macedonia: "MKD",
  Madagascar: "MDG",
  Malawi: "MWI",
  Malaysia: "MYS",
  Maldives: "MDV",
  Mali: "MLI",
  Malta: "MLT",
  "Marshall Islands": "MHL",
  Martinique: "MTQ",
  Mauritania: "MRT",
  Mauritius: "MUS",
  Mayotte: "MYT",
  Mexico: "MEX",
  Micronesia: "FSM",
  "Micronesia, Fed. Sts.": "FSM",
  "Micronesia (Fed. States of)": "FSM",
  "Micronesia (Federated States of)": "FSM",
  Moldova: "MDA",
  Monaco: "MCO",
  Mongolia: "MNG",
  Montenegro: "MNE",
  Montserrat: "MSR",
  Morocco: "MAR",
  Mozambique: "MOZ",
  Myanmar: "MMR",
  Namibia: "NAM",
  Nauru: "NRU",
  Nepal: "NPL",
  Netherlands: "NLD",
  "New Caledonia": "NCL",
  "New Zealand": "NZL",
  Nicaragua: "NIC",
  Niger: "NER",
  Nigeria: "NGA",
  Niue: "NIU",
  "Norfolk Island": "NFK",
  "North Korea": "PRK",
  "Northern Mariana Islands": "MNP",
  Norway: "NOR",
  Oman: "OMN",
  Pakistan: "PAK",
  Palau: "PLW",
  Palestine: "PSE",
  "Palestine, State of": "PSE",
  "Palestinian Territory": "PSE",
  Panama: "PAN",
  "Papua New Guinea": "PNG",
  Paraguay: "PRY",
  Peru: "PER",
  Philippines: "PHL",
  Pitcairn: "PCN",
  "Pitcairn Islands": "PCN",
  Poland: "POL",
  Portugal: "PRT",
  "Puerto Rico": "PRI",
  Qatar: "QAT",
  "Korea, Rep.": "KOR",
  "Republic of Korea": "KOR",
  "Republic of Moldova": "MDA",
  Romania: "ROU",
  Russia: "RUS",
  "Russian Federation": "RUS",
  Rwanda: "RWA",
  Réunion: "REU",
  "Saint Barthélemy": "BLM",
  "Saint Helena": "SHN",
  "Saint Kitts and Nevis": "KNA",
  "Saint Lucia": "LCA",
  "Saint Martin (French part)": "MAF",
  "Saint Pierre and Miquelon": "SPM",
  "Saint Vincent and the Grenadines": "VCT",
  Samoa: "WSM",
  "San Marino": "SMR",
  "Sao Tome and Principe": "STP",
  Sark: "",
  "Saudi Arabia": "SAU",
  Senegal: "SEN",
  Serbia: "SRB",
  Seychelles: "SYC",
  "Sierra Leone": "SLE",
  Singapore: "SGP",
  "Sint Maarten": "SXM",
  "Sint Maarten (Dutch part)": "SXM",
  "Slovak Republic": "SVK",
  Slovakia: "SVK",
  Slovenia: "SVN",
  "Solomon Islands": "SLB",
  Somalia: "SOM",
  "South Africa": "ZAF",
  "South Georgia & South Sandwich Islands": "SGS",
  "South Korea": "KOR",
  "South Sudan": "SSD",
  Spain: "ESP",
  "Sri Lanka": "LKA",
  "St. Barthélemy": "BLM",
  "St. Helena": "SHN",
  "St. Kitts and Nevis": "KNA",
  "St. Kitts & Nevis": "KNA",
  "St. Lucia": "LCA",
  "St. Martin (French part)": "MAF",
  "St. Martin": "MAF",
  "St. Pierre & Miquelon": "SPM",
  "St. Vincent and the Grenadines": "VCT",
  "St. Vincent & Grenadines": "VCT",
  "State of Palestine": "PSE",
  Sudan: "SDN",
  Suriname: "SUR",
  "Svalbard & Jan Mayen": "SJM",
  "Svalbard and Jan Mayen Islands": "SJM",
  Swaziland: "SWZ",
  Eswatini: "SWZ",
  Sweden: "SWE",
  Switzerland: "CHE",
  Syria: "SYR",
  "Syrian Arab Republic": "SYR",
  "São Tomé & Príncipe": "STP",
  Taiwan: "TWN",
  "China, Taiwan Province of China": "TWN",
  Tajikistan: "TJK",
  Tanzania: "TZA",
  Thailand: "THA",
  "The former Yugoslav Republic of Macedonia": "MKD",
  "Timor-Leste": "TLS",
  Togo: "TGO",
  Tokelau: "TKL",
  Tonga: "TON",
  "Trinidad & Tobago": "TTO",
  "Trinidad and Tobago": "TTO",
  Tunisia: "TUN",
  Turkey: "TUR",
  Turkmenistan: "TKM",
  "Turks & Caicos Islands": "TCA",
  "Turks and Caicos Islands": "TCA",
  Tuvalu: "TUV",
  "U.S. Outlying Islands": "UMI",
  "Virgin Islands (U.S.)": "VIR",
  "U.S. Virgin Islands": "VIR",
  UK: "GBR",
  Uganda: "UGA",
  Ukraine: "UKR",
  "United Arab Emirates": "ARE",
  "United Kingdom of Great Britain and Northern Ireland": "GBR",
  "United Republic of Tanzania": "TZA",
  "United States Virgin Islands": "VIR",
  "United Kingdom": "GBR",
  "United States": "USA",
  "United States of America": "USA",
  Uruguay: "URY",
  Uzbekistan: "UZB",
  Vanuatu: "VUT",
  "Vatican City": "VAT",
  "Venezuela, RB": "VEN",
  Venezuela: "VEN",
  "Venezuela (Bolivarian Republic of)": "VEN",
  "Viet Nam": "VNM",
  Vietnam: "VNM",
  "Wallis & Futuna": "WLF",
  "Wallis and Futuna Islands": "WLF",
  "Western Sahara": "ESH",
  "Yemen, Rep.": "YEM",
  Yemen: "YEM",
  Zambia: "ZMB",
  Zimbabwe: "ZWE",
  "Åland Islands": "ALA",
  AD: "AND",
  AE: "ARE",
  AF: "AFG",
  AG: "ATG",
  AI: "AIA",
  AL: "ALB",
  AM: "ARM",
  AO: "AGO",
  AQ: "ATA",
  AR: "ARG",
  AS: "ASM",
  AT: "AUT",
  AU: "AUS",
  AW: "ABW",
  AX: "ALA",
  AZ: "AZE",
  BA: "BIH",
  BB: "BRB",
  BD: "BGD",
  BE: "BEL",
  BF: "BFA",
  BG: "BGR",
  BH: "BHR",
  BI: "BDI",
  BJ: "BEN",
  BL: "BLM",
  BM: "BMU",
  BN: "BRN",
  BO: "BOL",
  BQ: "BES",
  BR: "BRA",
  BS: "BHS",
  BT: "BTN",
  BV: "BVT",
  BW: "BWA",
  BY: "BLR",
  BZ: "BLZ",
  CA: "CAN",
  CC: "CCK",
  CD: "COD",
  CF: "CAF",
  CG: "COG",
  CH: "CHE",
  CI: "CIV",
  CK: "COK",
  CL: "CHL",
  CM: "CMR",
  CN: "CHN",
  CO: "COL",
  CR: "CRI",
  CU: "CUB",
  CV: "CPV",
  CW: "CUW",
  CX: "CXR",
  CY: "CYP",
  CZ: "CZE",
  DE: "DEU",
  DJ: "DJI",
  DK: "DNK",
  DM: "DMA",
  DO: "DOM",
  DZ: "DZA",
  EC: "ECU",
  EE: "EST",
  EG: "EGY",
  EH: "ESH",
  ER: "ERI",
  ES: "ESP",
  ET: "ETH",
  FI: "FIN",
  FJ: "FJI",
  FK: "FLK",
  FM: "FSM",
  FO: "FRO",
  FR: "FRA",
  GA: "GAB",
  GB: "GBR",
  GD: "GRD",
  GE: "GEO",
  GF: "GUF",
  GG: "GGY",
  GH: "GHA",
  GI: "GIB",
  GL: "GRL",
  GM: "GMB",
  GN: "GIN",
  GP: "GLP",
  GQ: "GNQ",
  GR: "GRC",
  GS: "SGS",
  GT: "GTM",
  GU: "GUM",
  GW: "GNB",
  GY: "GUY",
  HK: "HKG",
  HM: "HMD",
  HN: "HND",
  HR: "HRV",
  HT: "HTI",
  HU: "HUN",
  ID: "IDN",
  IE: "IRL",
  IL: "ISR",
  IM: "IMN",
  IN: "IND",
  IO: "IOT",
  IQ: "IRQ",
  IR: "IRN",
  IS: "ISL",
  IT: "ITA",
  JE: "JEY",
  JM: "JAM",
  JO: "JOR",
  JP: "JPN",
  KE: "KEN",
  KG: "KGZ",
  KH: "KHM",
  KI: "KIR",
  KM: "COM",
  KN: "KNA",
  KP: "PRK",
  KR: "KOR",
  XK: "XKX",
  KW: "KWT",
  KY: "CYM",
  KZ: "KAZ",
  LA: "LAO",
  LB: "LBN",
  LC: "LCA",
  LI: "LIE",
  LK: "LKA",
  LR: "LBR",
  LS: "LSO",
  LT: "LTU",
  LU: "LUX",
  LV: "LVA",
  LY: "LBY",
  MA: "MAR",
  MC: "MCO",
  MD: "MDA",
  ME: "MNE",
  MF: "MAF",
  MG: "MDG",
  MH: "MHL",
  MK: "MKD",
  ML: "MLI",
  MM: "MMR",
  MN: "MNG",
  MO: "MAC",
  MP: "MNP",
  MQ: "MTQ",
  MR: "MRT",
  MS: "MSR",
  MT: "MLT",
  MU: "MUS",
  MV: "MDV",
  MW: "MWI",
  MX: "MEX",
  MY: "MYS",
  MZ: "MOZ",
  NA: "NAM",
  NC: "NCL",
  NE: "NER",
  NF: "NFK",
  NG: "NGA",
  NI: "NIC",
  NL: "NLD",
  NO: "NOR",
  NP: "NPL",
  NR: "NRU",
  NU: "NIU",
  NZ: "NZL",
  OM: "OMN",
  PA: "PAN",
  PE: "PER",
  PF: "PYF",
  PG: "PNG",
  PH: "PHL",
  PK: "PAK",
  PL: "POL",
  PM: "SPM",
  PN: "PCN",
  PR: "PRI",
  PS: "PSE",
  PT: "PRT",
  PW: "PLW",
  PY: "PRY",
  QA: "QAT",
  RE: "REU",
  RO: "ROU",
  RS: "SRB",
  RU: "RUS",
  RW: "RWA",
  SA: "SAU",
  SB: "SLB",
  SC: "SYC",
  SD: "SDN",
  SS: "SSD",
  SE: "SWE",
  SG: "SGP",
  SH: "SHN",
  SI: "SVN",
  SJ: "SJM",
  SK: "SVK",
  SL: "SLE",
  SM: "SMR",
  SN: "SEN",
  SO: "SOM",
  SR: "SUR",
  ST: "STP",
  SV: "SLV",
  SX: "SXM",
  SY: "SYR",
  SZ: "SWZ",
  TC: "TCA",
  TD: "TCD",
  TF: "ATF",
  TG: "TGO",
  TH: "THA",
  TJ: "TJK",
  TK: "TKL",
  TL: "TLS",
  TM: "TKM",
  TN: "TUN",
  TO: "TON",
  TR: "TUR",
  TT: "TTO",
  TV: "TUV",
  TW: "TWN",
  TZ: "TZA",
  UA: "UKR",
  UG: "UGA",
  UM: "UMI",
  US: "USA",
  UY: "URY",
  UZ: "UZB",
  VA: "VAT",
  VC: "VCT",
  VE: "VEN",
  VG: "VGB",
  VI: "VIR",
  VN: "VNM",
  VU: "VUT",
  WF: "WLF",
  WS: "WSM",
  YE: "YEM",
  YT: "MYT",
  ZA: "ZAF",
  ZM: "ZMB",
  ZW: "ZWE",
  CS: "SCG",
  AN: "ANT",
};

const isoAlpha3toAlpha2 = {
  ABW: "AW",
  AFG: "AF",
  AGO: "AO",
  AIA: "AI",
  ALA: "AX",
  ALB: "AL",
  AND: "AD",
  ANT: "AN",
  ARE: "AE",
  ARG: "AR",
  ARM: "AM",
  ASM: "AS",
  ATA: "AQ",
  ATF: "TF",
  ATG: "AG",
  AUS: "AU",
  AUT: "AT",
  AZE: "AZ",
  BDI: "BI",
  BEL: "BE",
  BEN: "BJ",
  BES: "BQ",
  BFA: "BF",
  BGD: "BD",
  BGR: "BG",
  BHR: "BH",
  BHS: "BS",
  BIH: "BA",
  BLM: "BL",
  BLR: "BY",
  BLZ: "BZ",
  BMU: "BM",
  BOL: "BO",
  BRA: "BR",
  BRB: "BB",
  BRN: "BN",
  BTN: "BT",
  BVT: "BV",
  BWA: "BW",
  CAF: "CF",
  CAN: "CA",
  CCK: "CC",
  CHE: "CH",
  CHL: "CL",
  CHN: "CN",
  CIV: "CI",
  CMR: "CM",
  COD: "CD",
  COG: "CG",
  COK: "CK",
  COL: "CO",
  COM: "KM",
  CPV: "CV",
  CRI: "CR",
  CUB: "CU",
  CUW: "CW",
  CXR: "CX",
  CYM: "KY",
  CYP: "CY",
  CZE: "CZ",
  DEU: "DE",
  DJI: "DJ",
  DMA: "DM",
  DNK: "DK",
  DOM: "DO",
  DZA: "DZ",
  ECU: "EC",
  EGY: "EG",
  ERI: "ER",
  ESH: "EH",
  ESP: "ES",
  EST: "EE",
  ETH: "ET",
  FIN: "FI",
  FJI: "FJ",
  FLK: "FK",
  FRA: "FR",
  FRO: "FO",
  FSM: "FM",
  GAB: "GA",
  GBR: "GB",
  GEO: "GE",
  GGY: "GG",
  GHA: "GH",
  GIB: "GI",
  GIN: "GN",
  GLP: "GP",
  GMB: "GM",
  GNB: "GW",
  GNQ: "GQ",
  GRC: "GR",
  GRD: "GD",
  GRL: "GL",
  GTM: "GT",
  GUF: "GF",
  GUM: "GU",
  GUY: "GY",
  HKG: "HK",
  HMD: "HM",
  HND: "HN",
  HRV: "HR",
  HTI: "HT",
  HUN: "HU",
  IDN: "ID",
  IMN: "IM",
  IND: "IN",
  IOT: "IO",
  IRL: "IE",
  IRN: "IR",
  IRQ: "IQ",
  ISL: "IS",
  ISR: "IL",
  ITA: "IT",
  JAM: "JM",
  JEY: "JE",
  JOR: "JO",
  JPN: "JP",
  KAZ: "KZ",
  KEN: "KE",
  KGZ: "KG",
  KHM: "KH",
  KIR: "KI",
  KNA: "KN",
  KOR: "KR",
  KWT: "KW",
  LAO: "LA",
  LBN: "LB",
  LBR: "LR",
  LBY: "LY",
  LCA: "LC",
  LIE: "LI",
  LKA: "LK",
  LSO: "LS",
  LTU: "LT",
  LUX: "LU",
  LVA: "LV",
  MAC: "MO",
  MAF: "MF",
  MAR: "MA",
  MCO: "MC",
  MDA: "MD",
  MDG: "MG",
  MDV: "MV",
  MEX: "MX",
  MHL: "MH",
  MKD: "MK",
  MLI: "ML",
  MLT: "MT",
  MMR: "MM",
  MNE: "ME",
  MNG: "MN",
  MNP: "MP",
  MOZ: "MZ",
  MRT: "MR",
  MSR: "MS",
  MTQ: "MQ",
  MUS: "MU",
  MWI: "MW",
  MYS: "MY",
  MYT: "YT",
  NAM: "NA",
  NCL: "NC",
  NER: "NE",
  NFK: "NF",
  NGA: "NG",
  NIC: "NI",
  NIU: "NU",
  NLD: "NL",
  NOR: "NO",
  NPL: "NP",
  NRU: "NR",
  NZL: "NZ",
  OMN: "OM",
  PAK: "PK",
  PAN: "PA",
  PCN: "PN",
  PER: "PE",
  PHL: "PH",
  PLW: "PW",
  PNG: "PG",
  POL: "PL",
  PRI: "PR",
  PRK: "KP",
  PRT: "PT",
  PRY: "PY",
  PSE: "PS",
  PYF: "PF",
  QAT: "QA",
  REU: "RE",
  ROU: "RO",
  RUS: "RU",
  RWA: "RW",
  SAU: "SA",
  SCG: "CS",
  SDN: "SD",
  SEN: "SN",
  SGP: "SG",
  SGS: "GS",
  SHN: "SH",
  SJM: "SJ",
  SLB: "SB",
  SLE: "SL",
  SLV: "SV",
  SMR: "SM",
  SOM: "SO",
  SPM: "PM",
  SRB: "RS",
  SSD: "SS",
  STP: "ST",
  SUR: "SR",
  SVK: "SK",
  SVN: "SI",
  SWE: "SE",
  SWZ: "SZ",
  SXM: "SX",
  SYC: "SC",
  SYR: "SY",
  TCA: "TC",
  TCD: "TD",
  TGO: "TG",
  THA: "TH",
  TJK: "TJ",
  TKL: "TK",
  TKM: "TM",
  TLS: "TL",
  TON: "TO",
  TTO: "TT",
  TUN: "TN",
  TUR: "TR",
  TUV: "TV",
  TWN: "TW",
  TZA: "TZ",
  UGA: "UG",
  UKR: "UA",
  UMI: "UM",
  URY: "UY",
  USA: "US",
  UZB: "UZ",
  VAT: "VA",
  VCT: "VC",
  VEN: "VE",
  VGB: "VG",
  VIR: "VI",
  VNM: "VN",
  VUT: "VU",
  WLF: "WF",
  WSM: "WS",
  XKX: "XK",
  YEM: "YE",
  ZAF: "ZA",
  ZMB: "ZM",
  ZWE: "ZW",
};

const iso3toCountryName = {
  AFG: "Afghanistan",
  ALB: "Albania",
  DZA: "Algeria",
  ASM: "American Samoa",
  AND: "Andorra",
  AGO: "Angola",
  AIA: "Anguilla",
  ATA: "Antarctica",
  ATG: "Antigua and Barbuda",
  ARG: "Argentina",
  ARM: "Armenia",
  ABW: "Aruba",
  AUS: "Australia",
  AUT: "Austria",
  AZE: "Azerbaijan",
  BHS: "Bahamas",
  BHR: "Bahrain",
  BGD: "Bangladesh",
  BRB: "Barbados",
  BLR: "Belarus",
  BEL: "Belgium",
  BLZ: "Belize",
  BEN: "Benin",
  BMU: "Bermuda",
  BTN: "Bhutan",
  BOL: "Bolivia (Plurinational State of)",
  BES: "Caribbean Netherlands",
  BIH: "Bosnia and Herzegovina",
  BWA: "Botswana",
  BVT: "Bouvet Island",
  BRA: "Brazil",
  IOT: "British Indian Ocean Territory",
  VGB: "British Virgin Islands",
  BRN: "Brunei Darussalam",
  BGR: "Bulgaria",
  BFA: "Burkina Faso",
  BDI: "Burundi",
  CPV: "Cape Verde",
  KHM: "Cambodia",
  CMR: "Cameroon",
  CAN: "Canada",
  CYM: "Cayman Islands",
  CAF: "Central African Republic",
  TCD: "Chad",
  CHL: "Chile",
  CHN: "China",
  HKG: "Hong Kong",
  MAC: "Macau",
  CXR: "Christmas Island",
  CCK: "Cocos (Keeling) Islands",
  COL: "Colombia",
  COM: "Comoros",
  COG: "Congo - Brazzaville",
  COD: "Democratic Republic of the Congo",
  COK: "Cook Islands",
  CRI: "Costa Rica",
  HRV: "Croatia",
  CUB: "Cuba",
  CUW: "Curaçao",
  CYP: "Cyprus",
  CZE: "Czech Republic",
  CIV: "Côte d’Ivoire",
  PRK: "North Korea",
  DNK: "Denmark",
  DJI: "Djibouti",
  DMA: "Dominica",
  DOM: "Dominican Republic",
  ECU: "Ecuador",
  EGY: "Egypt",
  SLV: "El Salvador",
  GNQ: "Equatorial Guinea",
  ERI: "Eritrea",
  EST: "Estonia",
  ETH: "Ethiopia",
  FRO: "Faroe Islands",
  FLK: "Falkland Islands (Malvinas)",
  FJI: "Fiji",
  FIN: "Finland",
  FRA: "France",
  GUF: "French Guiana",
  PYF: "French Polynesia",
  ATF: "French Southern Territories",
  GAB: "Gabon",
  GMB: "Gambia",
  GEO: "Georgia",
  DEU: "Germany",
  GHA: "Ghana",
  GIB: "Gibraltar",
  GRC: "Greece",
  GRL: "Greenland",
  GRD: "Grenada",
  GLP: "Guadeloupe",
  GUM: "Guam",
  GTM: "Guatemala",
  GGY: "Guernsey",
  GIN: "Guinea",
  GNB: "Guinea-Bissau",
  GUY: "Guyana",
  HTI: "Haiti",
  HMD: "Heard & McDonald Islands",
  VAT: "Vatican City",
  HND: "Honduras",
  HUN: "Hungary",
  ISL: "Iceland",
  IND: "India",
  IDN: "Indonesia",
  IRN: "Iran (Islamic Republic of)",
  IRQ: "Iraq",
  IRL: "Ireland",
  IMN: "Isle of Man",
  ISR: "Israel",
  ITA: "Italy",
  JAM: "Jamaica",
  JPN: "Japan",
  JEY: "Jersey",
  JOR: "Jordan",
  KAZ: "Kazakhstan",
  KEN: "Kenya",
  KIR: "Kiribati",
  KWT: "Kuwait",
  KGZ: "Kyrgyzstan",
  LAO: "Laos",
  LVA: "Latvia",
  LBN: "Lebanon",
  LSO: "Lesotho",
  LBR: "Liberia",
  LBY: "Libya",
  LIE: "Liechtenstein",
  LTU: "Lithuania",
  LUX: "Luxembourg",
  MKD: "The former Yugoslav Republic of Macedonia",
  MDG: "Madagascar",
  MWI: "Malawi",
  MYS: "Malaysia",
  MDV: "Maldives",
  MLI: "Mali",
  MLT: "Malta",
  MHL: "Marshall Islands",
  MTQ: "Martinique",
  MRT: "Mauritania",
  MUS: "Mauritius",
  MYT: "Mayotte",
  MEX: "Mexico",
  FSM: "Micronesia (Federated States of)",
  MDA: "Republic of Moldova",
  MCO: "Monaco",
  MNG: "Mongolia",
  MNE: "Montenegro",
  MSR: "Montserrat",
  MAR: "Morocco",
  MOZ: "Mozambique",
  MMR: "Myanmar",
  NAM: "Namibia",
  NRU: "Nauru",
  NPL: "Nepal",
  NLD: "Netherlands",
  NCL: "New Caledonia",
  NZL: "New Zealand",
  NIC: "Nicaragua",
  NER: "Niger",
  NGA: "Nigeria",
  NIU: "Niue",
  NFK: "Norfolk Island",
  MNP: "Northern Mariana Islands",
  NOR: "Norway",
  OMN: "Oman",
  PAK: "Pakistan",
  PLW: "Palau",
  PSE: "State of Palestine",
  PAN: "Panama",
  PNG: "Papua New Guinea",
  PRY: "Paraguay",
  PER: "Peru",
  PHL: "Philippines",
  PCN: "Pitcairn Islands",
  POL: "Poland",
  PRT: "Portugal",
  PRI: "Puerto Rico",
  QAT: "Qatar",
  KOR: "South Korea",
  ROU: "Romania",
  RUS: "Russian Federation",
  RWA: "Rwanda",
  REU: "Réunion",
  BLM: "St. Barthélemy",
  SHN: "St. Helena",
  KNA: "St. Kitts & Nevis",
  LCA: "St. Lucia",
  MAF: "St. Martin",
  SPM: "St. Pierre & Miquelon",
  VCT: "St. Vincent & Grenadines",
  WSM: "Samoa",
  SMR: "San Marino",
  STP: "São Tomé & Príncipe",
  SAU: "Saudi Arabia",
  SEN: "Senegal",
  SRB: "Serbia",
  SYC: "Seychelles",
  SLE: "Sierra Leone",
  SGP: "Singapore",
  SXM: "Sint Maarten (Dutch part)",
  SVK: "Slovakia",
  SVN: "Slovenia",
  SLB: "Solomon Islands",
  SOM: "Somalia",
  ZAF: "South Africa",
  SGS: "South Georgia & South Sandwich Islands",
  SSD: "South Sudan",
  ESP: "Spain",
  LKA: "Sri Lanka",
  SDN: "Sudan",
  SUR: "Suriname",
  SJM: "Svalbard and Jan Mayen Islands",
  SWZ: "Swaziland",
  SWE: "Sweden",
  CHE: "Switzerland",
  SYR: "Syrian Arab Republic",
  TWN: "Taiwan",
  TJK: "Tajikistan",
  TZA: "United Republic of Tanzania",
  THA: "Thailand",
  TLS: "Timor-Leste",
  TGO: "Togo",
  TKL: "Tokelau",
  TON: "Tonga",
  TTO: "Trinidad and Tobago",
  TUN: "Tunisia",
  TUR: "Turkey",
  TKM: "Turkmenistan",
  TCA: "Turks and Caicos Islands",
  TUV: "Tuvalu",
  UMI: "U.S. Outlying Islands",
  VIR: "United States Virgin Islands",
  GBR: "United Kingdom",
  UGA: "Uganda",
  UKR: "Ukraine",
  ARE: "United Arab Emirates",
  USA: "United States of America",
  URY: "Uruguay",
  UZB: "Uzbekistan",
  VUT: "Vanuatu",
  VEN: "Venezuela (Bolivarian Republic of)",
  VNM: "Vietnam",
  WLF: "Wallis and Futuna Islands",
  ESH: "Western Sahara",
  YEM: "Yemen",
  ZMB: "Zambia",
  ZWE: "Zimbabwe",
  ALA: "Åland Islands",
};

export const countryFlagWhiteList = [
  "AF",
  "AO",
  "AL",
  "AD",
  "AE",
  "AR",
  "AM",
  "AG",
  "AU",
  "AT",
  "AZ",
  "BI",
  "BE",
  "BJ",
  "BF",
  "BD",
  "BG",
  "BH",
  "BS",
  "BA",
  "BY",
  "BZ",
  "BO",
  "BR",
  "BB",
  "BN",
  "BT",
  "BW",
  "CF",
  "CA",
  "CH",
  "CL",
  "CN",
  "CM",
  "CK",
  "CO",
  "KM",
  "CV",
  "CR",
  "CU",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DM",
  "DK",
  "DO",
  "DZ",
  "EC",
  "EG",
  "ER",
  "ES",
  "EE",
  "ET",
  "FI",
  "FJ",
  "FR",
  "FM",
  "GA",
  "GB",
  "GE",
  "GH",
  "GN",
  "GM",
  "GW",
  "GQ",
  "GR",
  "GD",
  "GT",
  "GY",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IN",
  "IE",
  "IR",
  "IQ",
  "IS",
  "IL",
  "IT",
  "JM",
  "JO",
  "JP",
  "KZ",
  "KE",
  "KG",
  "KH",
  "KI",
  "KN",
  "KR",
  "KW",
  "LA",
  "LB",
  "LI",
  "LR",
  "LY",
  "LC",
  "LK",
  "LS",
  "LT",
  "LU",
  "LV",
  "MA",
  "MC",
  "MD",
  "MG",
  "MV",
  "MX",
  "MH",
  "MK",
  "ML",
  "MT",
  "MM",
  "ME",
  "MN",
  "MZ",
  "MR",
  "MU",
  "MW",
  "MY",
  "NA",
  "NE",
  "NG",
  "NI",
  "NU",
  "NL",
  "NO",
  "NP",
  "NR",
  "NZ",
  "OM",
  "PK",
  "PA",
  "PE",
  "PH",
  "PW",
  "PL",
  "KP",
  "PT",
  "PY",
  "QA",
  "RO",
  "RU",
  "RW",
  "SA",
  "SD",
  "SN",
  "SG",
  "SB",
  "SL",
  "SV",
  "SM",
  "SO",
  "RS",
  "SS",
  "ST",
  "SR",
  "SK",
  "SI",
  "SE",
  "SZ",
  "SC",
  "SY",
  "TD",
  "TG",
  "TH",
  "TJ",
  "TM",
  "TL",
  "TO",
  "TT",
  "TN",
  "TR",
  "TV",
  "TZ",
  "UG",
  "UA",
  "UY",
  "US",
  "UZ",
  "VC",
  "VE",
  "VN",
  "VU",
  "WS",
  "YE",
  "ZA",
  "ZM",
  "ZW",
  "CD",
  "CG",
  "PG",
  "CI",

  "VA",
];

export function canShowFlag(code: string) {
  if (!code) {
    return false;
  }
  return countryFlagWhiteList.includes(code);
}

export const countryOptionsList = Object.keys(countryDetailsByIsoAscii2).map(
  (key) => ({
    label: countryDetailsByIsoAscii2[key].ADM0_VIZ_NAME,
    value: key,
    iso3: countryDetailsByIsoAscii2[key].ISO_3_CODE,
  })
);

export const sortedCountries = countryOptionsList.slice().sort((a, b) => {
  if ((a.label === "Kosovo[1]") != (b.label === "Kosovo[1]")) {
    return a.label === "Kosovo[1]" ? 1 : -1;
  }
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
});

type FilterOption = {
  label: string;
  value: string;
  data: { label: string; value: string; iso3: string };
};

const contains = (haystack: string, needle: string) => {
  haystack = haystack
    .replace(",", "")
    .replace("(", "")
    .replace(")", "")
    .replace("’", "'")
    .toLowerCase();
  needle = needle
    .replace(",", "")
    .replace("(", "")
    .replace(")", "")
    .replace("’", "'")
    .toLowerCase();
  return (
    haystack.startsWith(needle) ||
    haystack.split(" ").filter((chunk) => chunk.startsWith(needle)).length > 0
  );
};

export const countrySearchFilter = (
  option: FilterOption,
  searchText: string
) => {
  searchText = (searchText || "").toLowerCase();
  return (
    option.data.value.toLowerCase() === searchText || // exact match ISO2
    option.data.iso3.toLowerCase() === searchText || // exact match ISO3
    contains(option.data.label, searchText) ||
    // check string without accent marks
    contains(
      option.data.label.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      searchText
    )
  );
};

const compareFn = compare();
countryOptionsList.sort((a, b) => compareFn(a.label, b.label));

export { countryToIso3 };
