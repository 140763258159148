export type Region =
  | "Eastern Mediterranean"
  | "Europe"
  | "Africa"
  | "Americas"
  | "South-East Asia"
  | "Western Pacific";

export type RegionCode = "AFRO" | "EURO" | "WPRO" | "AMRO" | "EMRO" | "SEARO";
export const getRegionName = (regionCode: RegionCode) => {
  switch (regionCode) {
    case "AFRO":
      return "Africa";
    case "EURO":
      return "Europe";
    case "WPRO":
      return "Western Pacific";
    case "AMRO":
      return "Americas";
    case "EMRO":
      return "Eastern Mediterranean";
    case "SEARO":
      return "South-East Asia";
  }
};
