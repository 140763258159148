import DataSet from "../utils/data/DataSet";
import moment from "moment";
import { getCountryNameFromId } from "../utils/countryCode";

function quoteCommaCells(row: any[]): any[] {
  return row?.map((c) => (~`${c}`.indexOf(",") ? `"${c}"` : c));
}

export class SimpleCSV {
  private includeHeader: boolean;

  constructor(
    readonly rows: any,
    readonly header?: any,
    readonly separator = ",",
    readonly newline = "\n"
  ) {
    this.includeHeader = header && header.length > 0;

    if (this.includeHeader && header.length !== rows[0].length) {
      throw Error("Header column count does not match rows column count");
    }
  }

  /**
   * TODO: currently assumes first column is a Date object.
   * TODO: field sanitization/mapping & column injection is very hard-coded to WHO's requested rules, could be generalized
   * pretty easily if needed.
   * @param dataSet
   * @param includeHeader
   * @param dateFormat
   */
  public static fromDataSet(
    dataSet: DataSet,
    includeHeader = true,
    dateFormat = "YYYY-MM-DD", // e.g. "2020-01-24" which is an ISO-acceptable date format,
    customHeaders = null
  ): SimpleCSV {
    // dataSet.fields
    const rows = dataSet.rows;
    let header = [];
    if (includeHeader) {
      if (customHeaders) {
        header = customHeaders;
      } else {
        header = dataSet.fields.map((f) => f.getName());
        // manually insert new column for full country name
        header.splice(2, 0, "Country Name");
      }
    }

    return new SimpleCSV(
      rows.map((r) => {
        if (r[1] === "DP") {
          return quoteCommaCells([
            moment.utc(r[0].valueOf()).format(dateFormat),
            "", // ISO Alpha2 country code column, no real value for Diamond Princess.
            "International conveyance (Diamond Princess)", // hard-coded name cause some joker deleted the mapping
            r[2] || "", // filter out "null" if region not found, e.g. Diamond Princess
            ...r.slice(3),
          ]);
        } else {
          return quoteCommaCells([
            moment.utc(r[0].valueOf()).format(dateFormat),
            r[1], // ISO Alpha2 country code
            getCountryNameFromId(r[1]),
            r[2] || "", // filter out "null" if region not found, e.g. Diamond Princess
            ...r.slice(3),
          ]);
        }
      }),
      header
    );
  }

  public toString(): string {
    const lines = [];
    if (this.includeHeader) {
      lines.push(this.header.join(this.separator));
    }

    this.rows.forEach((row) => {
      lines.push(row.join(this.separator));
    });

    return lines.join(this.newline);
  }

  /**
   * Typical arrangement of data[][] with outer array being rows.
   */
  public to2dArray(): any[][] {
    const result = [];

    if (this.includeHeader) {
      result.push(this.header.slice());
    }

    result.push(...this.rows);
    return result;
  }
}
