import Dimension from "./Dimension";
import Metric from "./Metric";
import Direction from "./Direction";
import Field from "./Field";
import moment from "moment";
import "moment-timezone";
import { compare } from "natural-orderby";

type SortFunc<T> = (a: T, b: T) => number;

export interface DimensionDataGroup {
  dimension: Dimension;
  value: any;
  data: DataSet;
}

export type GroupedData = {
  dimension: Dimension;
  value: any;
  data: DataSet;
};

type MappedData =
  | number
  | {
      [a: string]: MappedData;
    };

/**
 * Immutable object containing analytics data
 */
export default class DataSet {
  readonly dimensions: readonly Dimension[];

  readonly metrics: readonly Metric[];

  readonly fields: ReadonlyArray<Field<string>>;

  readonly rows: ReadonlyArray<ReadonlyArray<any>>;

  readonly totals: readonly number[];

  constructor(
    dimensions: Dimension[] | readonly Dimension[],
    metrics: Metric[] | readonly Metric[],
    rows: any[][] | ReadonlyArray<ReadonlyArray<any>>,
    totals?: readonly number[],
    cast = true
  ) {
    this.dimensions = dimensions.slice() as readonly Dimension[];
    this.metrics = metrics.slice() as readonly Metric[];
    // @ts-ignore
    this.fields = [].concat(dimensions).concat(metrics) as ReadonlyArray<
      Field<string>
    >;

    rows = rows || [];
    // @ts-ignore
    this.rows = rows.map((row) => {
      return this.fields.map((field: Field<string>, index) => {
        if (!cast) {
          return row[index];
        }

        const castValue = field.cast(row[index]);

        // fix for benchmarking sentiment
        if (field.name === "SENTIMENT") {
          if (castValue === "-2") {
            return "Negative";
          } else if (castValue === "2") {
            return "Positive";
          }
        }

        return castValue;
      });
    });

    this.totals = totals || [];
  }

  toJSON() {
    return {
      metrics: this.metrics,
      dimensions: this.dimensions,
      rows: this.rows.map((row) => {
        return row.map((value) => {
          if (value instanceof Date) {
            return value.getTime();
          }
          return value;
        });
      }),
    };
  }

  static create(dataSet: any) {
    return new DataSet(
      dataSet.dimensions.map((dimension: Dimension) => {
        return new Dimension(dimension.name, dimension.type);
      }),
      dataSet.metrics.map((metric: Metric) => {
        return new Metric(
          metric.name,
          metric.type,
          metric.dataType,
          metric.aggregateFunction
        );
      }),
      dataSet.rows,
      dataSet.totals
    );
  }

  getFirstMetric(): Metric | undefined {
    return this.metrics.length > 0 ? this.metrics[0] : undefined;
  }

  getFirstDimension(): Dimension | undefined {
    return this.dimensions.length > 0 ? this.dimensions[0] : undefined;
  }

  getMetricFieldIndex(m: Metric | string): number {
    if (!m) {
      return -1;
    }

    const metricIndex = this.getMetricIndex(m);
    if (metricIndex === -1) {
      return metricIndex;
    }

    return metricIndex + this.dimensions.length;
  }

  getMetricIndex(m: Metric | string): number {
    if (!m) {
      return -1;
    }

    if (typeof m === "string") {
      return this.metrics.findIndex((metric) => metric.name === m);
    } else {
      return this.metrics.findIndex(
        (metric) => metric.name === m.name && metric.type === m.type
      );
    }
  }

  getDimension(d: string | number): Dimension | undefined {
    if (d === undefined) {
      return undefined;
    }

    if (typeof d === "string") {
      const index = this.getDimensionIndex(d);
      return this.dimensions[index];
    } else {
      return this.dimensions[d];
    }
  }

  getDimensionIndex(d: Dimension | string): number {
    if (!d) {
      return -1;
    }

    if (typeof d === "string") {
      return this.dimensions.findIndex((dimension) => dimension.name === d);
    } else {
      return this.dimensions.findIndex(
        (dimension) => dimension.name === d.name && dimension.type === d.type
      );
    }
  }

  valueAt(offset: number): any {
    const rows = this.rows;
    const row = (rows && rows.length && rows[0].length && rows[0]) || null;
    let value = null;

    if (row && offset < row.length) {
      value = row[offset];
    }

    return value;
  }

  getTotals(): readonly number[] {
    return this.totals;
  }

  getTotal(metric: Metric): number {
    return this.totals[this.assertMetricIndex(metric)];
  }

  indexedTotals(): { [a: string]: number } {
    const values = {};
    this.totals.forEach((value, index) => {
      values[this.metrics[index].name] = value;
    });
    return values;
  }

  getFirstTotal(): number | undefined {
    return this.totals?.length > 0 ? this.totals[0] : undefined;
  }

  /**
   * Returns a copy of the given AnalyticsResult, sorted by the given Metric
   *
   * @param metric The Metric to sort by
   * @param fn The sort function
   * @returns {DataSet}
   */
  sortByMetric(metric: Metric, fn: SortFunc<number>): DataSet {
    return this.sortByFieldIndexComparator(this.assertMetricIndex(metric), fn);
  }

  /**
   * Returns a copy of the given AnalyticsResult, sorted by the given Metric and the specified Direction
   *
   * @param metric The Metric to sort by
   * @param dir The Direction to sort by, Direction.ASC or Direction.DESC
   * @returns {DataSet}
   */
  sortByMetricDirection(metric: Metric, dir: Direction): DataSet {
    return this.sortByFieldIndexComparator(
      this.assertMetricIndex(metric),
      this.getComparator(dir)
    );
  }

  /**
   * Returns a copy of the given AnalyticsResult, sorted by the given Dimension
   *
   * @param dimension The Dimension to sort by
   * @param fn The sort function
   * @returns {DataSet}
   */
  sortByDimension(dimension: Dimension, fn: SortFunc<any>): DataSet {
    return this.sortByFieldIndexComparator(
      this.assertDimensionIndex(dimension),
      fn
    );
  }

  /**
   * Returns a copy of the given AnalyticsResult, sorted by the given Dimension and the specified Direction
   *
   * @param dimension The Dimension to sort by
   * @param dir The Direction to sort by
   * @returns {DataSet}
   */
  sortByDimensionDirection(dimension: Dimension, dir: Direction): DataSet {
    return this.sortByFieldIndexComparator(
      this.assertDimensionIndex(dimension),
      this.getComparator(dir)
    );
  }

  /**
   * Returns a copy of the given AnalyticsResult, sorted by the field at the given index and the given value sort function
   *
   * @param index The field index to sort by
   * @param fn The value sort function
   * @returns {DataSet}
   */
  sortByFieldIndexComparator(index: number, fn: SortFunc<any>): DataSet {
    return this.derivedDataset((rows) => {
      return rows
        .slice()
        .sort((a: readonly any[], b: readonly any[]): number => {
          // If the dimension has a sortValue, use that when sorting.
          if (
            a[index] !== undefined &&
            a[index] !== null &&
            a[index].sortValue
          ) {
            const aVal = a[index].sortValue;
            const bVal =
              b[index] !== undefined && b[index] !== null
                ? b[index].sortValue
                : null;
            return fn(aVal, bVal);
          }

          return fn(a[index], b[index]);
        });
    }, false);
  }

  /**
   * Returns a copy of the given AnalyticsResult, sorted by the given row sort function
   *
   * @param fn The row sort funciton
   * @returns {DataSet}
   */
  sort(fn: SortFunc<readonly any[]>): DataSet {
    return this.derivedDataset((rows) => {
      return rows.slice().sort(fn);
    }, false);
  }

  /**
   * Returns a copy of the given AnalyticsResult, filtered by the given row filter function
   *
   * @param fn The row filter function
   * @returns {DataSet}
   */
  filter(fn: (a: readonly any[]) => boolean): DataSet {
    return this.derivedDataset((rows) => {
      return rows.filter(fn);
    }, false);
  }

  /**
   * Returns a unique key representing the dimension values in the row
   * @param row
   * @param dimensions
   * @returns {string}
   */
  rowKey(row: any[] | ReadonlyArray<any>, dimensions?: Dimension[]): string {
    if (!dimensions) {
      return row
        .slice(0, this.dimensions.length)
        .map((value) => value.toString())
        .join("|||");
    }

    return dimensions
      .map((dimension: Dimension) => {
        const dimIndex = this.getDimensionIndex(dimension);
        return row[dimIndex];
      })
      .join("|||");
  }

  /**
   * Given a current row, return the percent change between 0.0 and 100 from the previous data set
   *
   * @param currentRow
   * @param currentDataSet
   * @param previousDataSet
   * @param metric
   * @param dimensions
   * @returns {number}
   */
  percentChange(
    currentRow: any[] | ReadonlyArray<any>,
    currentDataSet: DataSet,
    previousDataSet?: DataSet,
    metric?: Metric,
    dimensions?: Dimension[]
  ): number | undefined {
    if (!previousDataSet) {
      return;
    }

    let percentChange = 0;
    const rowKey = currentDataSet.rowKey(currentRow, dimensions);
    if (!metric) {
      metric = this.getFirstMetric();
    }
    if (!metric) {
      return;
    }
    const metricIndex = this.getMetricFieldIndex(metric);
    const currentValue: number = currentRow[metricIndex] || 0;

    const previousRows = previousDataSet.rows.filter(
      (prevPeriodRow: readonly any[]) => {
        const prevRowKey = previousDataSet.rowKey(prevPeriodRow, dimensions);
        return prevRowKey === rowKey;
      }
    );

    if (previousRows.length > 0) {
      const previousRow = previousRows[0];
      const previousValue: number = previousRow[metricIndex] || 0;
      if (previousValue === 0 && currentValue > 0) {
        percentChange = 100;
      } else {
        percentChange = (currentValue / previousValue - 1) * 100;
      }
    } else if (currentValue > 0) {
      percentChange = 100;
    }

    return percentChange;
  }

  static percentChanged(current: number, previous: number): number {
    if (previous === 0.0 || previous === undefined) {
      if (current === 0.0) {
        return 0.0;
      } else {
        return 100.0;
      }
    } else {
      const percentChange = (current / previous - 1) * 100.0;
      return +percentChange.toFixed(3);
    }
  }

  /**
   * Returns a copy of AnalyticsResult with the given Metric removed
   *
   * @param metric
   * @returns {DataSet}
   */
  removeMetric(metric: Metric): DataSet {
    const index = this.assertMetricIndex(metric);

    const rows = this.rows.map((row) => {
      // splice doesn't work the same on tuples. Copy it to an array first
      const modded = row.slice(0);
      modded.splice(index, 1);
      return modded;
    });

    const metrics = this.metrics.slice();
    metrics.splice(index - this.dimensions.length, 1);

    return new DataSet(this.dimensions, metrics, rows, this.totals, false);
  }

  removeDimension(dimension: Dimension): DataSet {
    const index = this.assertDimensionIndex(dimension);

    const rows = this.rows.map((row) => {
      // splice doesn't work the same on tuples. Copy it to an array first
      const modded = row.slice();
      modded.splice(index, 1);
      return modded;
    });

    const dimensions = this.dimensions.slice();
    dimensions.splice(index, 1);

    return new DataSet(dimensions, this.metrics, rows, this.totals, false);
  }

  filterRowsData(): DataSet {
    if (this.dimensions?.[0]?.name) {
      const dimensionName = this.dimensions[0].name;
      if (dimensionName === "COUNTRY" || dimensionName === "Country") {
        const rows = this.rows.filter((row) => row[0] !== "Unknown");
        return new DataSet(
          this.dimensions,
          this.metrics,
          rows,
          this.totals,
          false
        );
      } else {
        return this;
      }
    } else {
      return this;
    }
  }

  reorderDimensions(dimensions: Dimension[]): DataSet {
    const dimIndexes = dimensions.map((dimension: Dimension) => {
      return this.assertDimensionIndex(dimension);
    });

    const metricIndexes = this.metrics.map((metric: Metric) => {
      return this.assertMetricIndex(metric);
    });

    const rows = this.rows.map((row) => {
      const newRow: any[] = [];

      dimIndexes.forEach((index: number) => {
        newRow.push(row[index]);
      });

      metricIndexes.forEach((index: number) => {
        newRow.push(row[index]);
      });

      return newRow;
    });

    return new DataSet(dimensions, this.metrics, rows, this.totals, false);
  }

  reorderMetrics(metrics: Metric[]): DataSet {
    const dimIndexes = this.dimensions.map((dimension: Dimension) => {
      return this.assertDimensionIndex(dimension);
    });

    const metricIndexes = metrics.map((metric: Metric) => {
      return this.assertMetricIndex(metric);
    });

    const rows = this.rows.map((row) => {
      const newRow: any[] = [];

      dimIndexes.forEach((index: number) => {
        newRow.push(row[index]);
      });

      metricIndexes.forEach((index: number) => {
        newRow.push(row[index]);
      });

      return newRow;
    });

    return new DataSet(this.dimensions, metrics, rows, this.totals, false);
  }

  /**
   * Get all the rows for a given Dimension value.
   *
   * @param dimension
   * @param value
   * @returns {DataSet}
   */
  pluck(dimension: Dimension, value?: any): DataSet {
    const index = this.assertDimensionIndex(dimension);
    let rows: any[][];

    if (typeof value !== "undefined") {
      const valueString = value + "";
      rows = this.rows.filter((row) => {
        if (value.id && row[index].id) {
          return row[index].id === value.id;
        } else {
          return row[index] + "" === valueString;
        }
      }) as any[][];
    } else {
      rows = this.rows as any[][];
    }

    rows = rows.map((row) => {
      // splice doesn't work the same on tuples. Copy it to an array first
      const modded = row.slice();
      modded.splice(index, 1);
      return modded;
    });

    const dimensions = this.dimensions.slice();
    dimensions.splice(index, 1);

    return new DataSet(dimensions, this.metrics, rows, this.totals, false);
  }

  /**
   * Group all the rows by the given Dimension's values
   *
   * @param dimension
   * @returns {DimensionDataGroup[]}
   */
  groupBy(dimension: Dimension): Array<GroupedData> {
    dimension = dimension || this.getFirstDimension();
    const metrics = this.metrics;

    try {
      const index = this.assertDimensionIndex(dimension);
      const dimensions = this.dimensions.slice();
      dimensions.splice(index, 1);

      const indexedGroups: {
        [value: string]: { value: any; rows: any[][]; totals: number[] };
      } = {};

      this.rows.forEach((row) => {
        const value = row[index];
        const dupIndex =
          value === null || value === undefined ? "" : value + "";

        let group: { value: any; rows: any[][]; totals: number[] };
        if (dupIndex in indexedGroups) {
          group = indexedGroups[dupIndex];
        } else {
          group = indexedGroups[dupIndex] = {
            value,
            rows: [],
            totals: metrics.map(() => 0),
          };
        }

        // splice doesn't work the same on tuples. Copy it to an array first
        const modded = row.slice();
        modded.splice(index, 1);

        metrics.forEach((metric, index) => {
          group.totals[index] += modded[dimensions.length + index];
        });

        group.rows.push(modded);
      });

      return Object.keys(indexedGroups).map((value) => {
        const group = indexedGroups[value];
        return {
          dimension,
          value: group.value,
          data: new DataSet(
            dimensions,
            this.metrics,
            group.rows,
            group.totals,
            false
          ),
        };
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  /**
   * Group all the rows by the given Dimension's values
   *
   * @param dimension
   * @param valueFn
   * @returns {DimensionDataGroup[]}
   */
  groupByAggregate(
    dimension: Dimension,
    valueFn?: (dimValue: any) => any
  ): DataSet {
    dimension = dimension || this.getFirstDimension();
    const metrics = this.metrics;

    const index = this.assertDimensionIndex(dimension);
    const dimensions = this.dimensions;

    const indexedGroups: {
      [value: string]: {
        value: any;
        totals: number[];
        counts: number[];
        maxs: number[];
        mins: number[];
      };
    } = {};

    this.rows.forEach((row) => {
      const value = valueFn ? valueFn(row[index]) : row[index];
      const dupIndex = value === null || value === undefined ? "" : value + "";

      let group: {
        value: any;
        totals: number[];
        counts: number[];
        maxs: number[];
        mins: number[];
      };
      if (dupIndex in indexedGroups) {
        group = indexedGroups[dupIndex];
      } else {
        const stub = metrics.map(() => 0);
        group = indexedGroups[dupIndex] = {
          value,
          totals: stub.slice(),
          counts: stub.slice(),
          maxs: stub.slice(),
          mins: stub.slice(),
        };
      }

      metrics.forEach((metric, index) => {
        const val = row[dimensions.length + index];
        group.totals[index] += val || 0;
        group.counts[index] += 1;
        group.maxs[index] = Math.max(group.maxs[index], val);
        group.mins[index] = Math.min(group.maxs[index], val);
      });
    });

    const rows = Object.keys(indexedGroups).map((value) => {
      const group = indexedGroups[value];
      return [group.value, ...group.totals];
    });

    return new DataSet([dimension], this.metrics, rows, this.totals, false);
  }

  /**
   * Group all the original row offsets by the given Dimension's values
   *
   * @param dimension
   * @returns { number[][] }
   */
  groupByRowOffsets(dimension: Dimension): number[][] {
    try {
      const index = this.assertDimensionIndex(dimension);

      const duplicates = {};
      const result: number[][] = [];

      this.rows.forEach((row) => {
        const value = row[index];
        const strVal = value ? value + "" : "";

        if (!(strVal in duplicates)) {
          const offsets: number[] = [];

          this.rows.forEach((rowInner, offset) => {
            if (rowInner[index] + "" === value + "") {
              offsets.push(offset);
            }
          });

          result.push(offsets);
          duplicates[strVal] = true;
        }
      });

      return result;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  getStringFormatter(dimension: Dimension, formatter?: any) {
    if (typeof formatter === "string") {
      const formatString = formatter;
      return function (value: any): string {
        if (value === 0 || value) {
          return moment(value).format(formatString);
        }
        return "";
      };
    }

    if (typeof formatter === "function") {
      return formatter;
    }

    return function (value: any) {
      if (value === 0 || value) {
        if (dimension) {
          return dimension.stringify(value);
        }
        if (value instanceof Date) {
          return value.toISOString();
        }
        return value + "";
      }
      return "";
    };
  }

  allStrings(): string[] {
    if (!this.dimensions) {
      return [];
    }

    const map = {};

    this.dimensions.forEach((dimension) => {
      const categories = this.categories(dimension);
      categories.forEach((category) => {
        map[category] = true;
      });
    });

    return Object.keys(map);
  }

  /**
   * Return an array of all the unique values for the given dimension as Strings
   *
   * @param dimension
   * @param formatter Function to convert values to a formatted string
   * @returns {any[]}
   */
  categories(dimension?: Dimension, formatter?: any): string[] {
    dimension = dimension || this.dimensions[0];
    const index = this.assertDimensionIndex(dimension);

    const duplicates = {};
    let values: any[] = [];

    formatter = this.getStringFormatter(dimension, formatter);

    this.rows.forEach((row) => {
      const val: any = row[index];
      const strVal = formatter(row[index]);
      if (!(strVal in duplicates)) {
        values.push(val);
        duplicates[strVal] = true;
      }
    });

    if (dimension.type !== "STRING") {
      values.sort(this.getComparator(Direction.ASC));
    }

    values = values.map(formatter);

    return values;
  }

  /**
   * @param rowCopier
   * @param cast
   * @returns {DataSet}
   */
  derivedDataset(
    rowCopier: (
      rows: ReadonlyArray<ReadonlyArray<any>>
    ) => any[][] | Array<ReadonlyArray<any>>,
    cast = true
  ): DataSet {
    // copy and transform the this
    const newRows = rowCopier(this.rows);

    // keep the metrics & dimensions
    return new DataSet(
      this.dimensions,
      this.metrics,
      newRows,
      this.totals,
      cast
    );
  }

  /**
   * Returns the total of the given Metric's values
   *
   * @param metric
   * @returns {number}
   */
  sum(metric?: Metric): number {
    let sum = 0;
    metric = metric || this.metrics[0];
    const metricIndex = this.assertMetricIndex(metric);

    let r,
      rl = this.rows.length;
    for (r = 0; r < rl; ++r) {
      const row = this.rows[r];
      sum += row[metricIndex];
    }

    return sum;
  }

  /**
   * Returns the lowest of the given Metric's values
   *
   * @param metric
   * @returns {number}
   */
  min(metric?: Metric): number {
    let min = null;
    metric = metric || this.metrics[0];
    const metricIndex = this.assertMetricIndex(metric);

    let r,
      rl = this.rows.length;
    for (r = 0; r < rl; ++r) {
      const value = this.rows[r][metricIndex];
      if (min === null || min > value) {
        min = value;
      }
    }

    return min || 0;
  }

  /**
   * Returns the highest of the given Metric's values
   *
   * @param metric
   * @returns {number}
   */
  max(metric?: Metric): number {
    let max = null;
    metric = metric || this.metrics[0];
    const metricIndex = this.assertMetricIndex(metric);

    let r,
      rl = this.rows.length;
    for (r = 0; r < rl; ++r) {
      const value = this.rows[r][metricIndex];
      if (max === null || max < value) {
        max = value;
      }
    }

    return max || 0;
  }

  periodicChange(): DataSet {
    switch (this.dimensions.length) {
      case 0:
        return this;
      case 1:
        if (!this.dimensions[0].isTimeSeries()) {
          return this;
        }
        const prevVals: number[] = this.metrics.map((metric, index) => 0);

        return this.sortByDimensionDirection(
          this.dimensions[0],
          Direction.ASC
        ).derivedDataset((rows) => {
          return rows.map((row) => {
            const newRow = [row[0]];
            this.metrics.forEach((metric, index) => {
              const curVal = row[index + 1] || 0;
              const prevVal = prevVals[index];
              newRow.push(curVal - prevVal);
              prevVals[index] = curVal;
            });
            return newRow;
          });
        }, false);
      case 2:
        if (
          !this.dimensions[0].isTimeSeries() &&
          !this.dimensions[1].isTimeSeries()
        ) {
          return this;
        }
        const nonTimeIndex = this.dimensions[0].isTimeSeries() ? 1 : 0;
        const nonTimeDim = this.dimensions[nonTimeIndex];

        const newRows: any[] = [];
        this.zeroFillDimensions()
          .groupBy(nonTimeDim)
          .forEach((value) => {
            value.data.periodicChange().rows.forEach((row) => {
              const metrics = row.slice();
              const tsValue = metrics.shift();
              const nonTimeValue = value.dimension.cast(value.value);
              newRows.push([
                nonTimeIndex === 0 ? nonTimeValue : tsValue,
                nonTimeIndex === 1 ? nonTimeValue : tsValue,
                ...metrics,
              ]);
            });
          });

        // keep the metrics & dimensions
        return new DataSet(
          this.dimensions,
          this.metrics,
          newRows,
          this.totals,
          false
        );
      default:
        throw new Error(
          `Sorry I didn't want to code cumulative for 3+ dimensions`
        );
    }
  }

  periodicPercentChange(): DataSet {
    switch (this.dimensions.length) {
      case 0:
        return this;
      case 1:
        if (!this.dimensions[0].isTimeSeries()) {
          return this;
        }
        const prevVals: number[] = this.metrics.map((metric, index) => 0);

        return this.sortByDimensionDirection(
          this.dimensions[0],
          Direction.ASC
        ).derivedDataset((rows) => {
          return rows.map((row) => {
            const newRow = [row[0]];
            this.metrics.forEach((metric, index) => {
              const curVal = row[index + 1] || 0;
              const prevVal = prevVals[index];
              if (prevVal === 0) {
                newRow.push(1);
              } else {
                newRow.push((curVal - prevVal) / prevVal);
              }
              prevVals[index] = curVal;
            });
            return newRow;
          });
        }, false);
      case 2:
        if (
          !this.dimensions[0].isTimeSeries() &&
          !this.dimensions[1].isTimeSeries()
        ) {
          return this;
        }
        const nonTimeIndex = this.dimensions[0].isTimeSeries() ? 1 : 0;
        const nonTimeDim = this.dimensions[nonTimeIndex];

        const newRows: any[] = [];
        this.zeroFillDimensions()
          .groupBy(nonTimeDim)
          .forEach((value) => {
            value.data.periodicPercentChange().rows.forEach((row) => {
              const metrics = row.slice();
              const tsValue = metrics.shift();
              const nonTimeValue = value.dimension.cast(value.value);
              newRows.push([
                nonTimeIndex === 0 ? nonTimeValue : tsValue,
                nonTimeIndex === 1 ? nonTimeValue : tsValue,
                ...metrics,
              ]);
            });
          });

        // keep the metrics & dimensions
        return new DataSet(
          this.dimensions,
          this.metrics,
          newRows,
          this.totals,
          false
        );
      default:
        throw new Error(
          `Sorry I didn't want to code cumulative for 3+ dimensions`
        );
    }
  }

  cumulative(): DataSet {
    switch (this.dimensions.length) {
      case 0:
        return this;
      case 1:
        if (!this.dimensions[0].isTimeSeries()) {
          return this;
        }
        const metricTotals: number[] = this.metrics.map((metric, index) => 0);
        return this.sortByDimensionDirection(
          this.dimensions[0],
          Direction.ASC
        ).derivedDataset((rows) => {
          return rows.map((row) => {
            const newRow = [row[0]];
            this.metrics.forEach((metric, index) => {
              newRow.push((metricTotals[index] += row[index + 1]));
            });
            return newRow;
          });
        }, false);
      case 2:
        if (
          !this.dimensions[0].isTimeSeries() &&
          !this.dimensions[1].isTimeSeries()
        ) {
          return this;
        }
        const nonTimeIndex = this.dimensions[0].isTimeSeries() ? 1 : 0;
        const nonTimeDim = this.dimensions[nonTimeIndex];

        const newRows: any[] = [];
        this.zeroFillDimensions()
          .groupBy(nonTimeDim)
          .forEach((value) => {
            value.data.cumulative().rows.forEach((row) => {
              const metrics = row.slice();
              const tsValue = metrics.shift();
              const nonTimeValue = value.dimension.cast(value.value);
              newRows.push([
                nonTimeIndex === 0 ? nonTimeValue : tsValue,
                nonTimeIndex === 1 ? nonTimeValue : tsValue,
                ...metrics,
              ]);
            });
          });

        // keep the metrics & dimensions
        return new DataSet(
          this.dimensions,
          this.metrics,
          newRows,
          this.totals,
          false
        );
      default:
        throw new Error(
          `Sorry I didn't want to code cumulative for 3+ dimensions`
        );
    }
  }

  limit(limit: number): DataSet {
    // keep the metrics & dimensions
    return new DataSet(
      this.dimensions,
      this.metrics,
      this.rows.slice(0, limit),
      this.totals,
      false
    );
  }

  collapseDimension(dimension: Dimension, metric?: Metric): DataSet {
    if (this.dimensions.length === 0) {
      throw new Error(
        "Cannot collapse dimension on dataset with no dimensions"
      );
    }

    dimension = dimension || this.dimensions[0];
    const dimensionIndex = this.assertDimensionIndex(dimension);
    const groupDimensions = this.dimensions.slice();
    groupDimensions.splice(dimensionIndex, 1);
    metric = metric || this.getFirstMetric();
    if (!metric) {
      return this;
    }

    const rows: any[][] = [];

    if (groupDimensions.length === 0) {
      const sortedData = this.sortByMetricDirection(metric, Direction.DESC);
      const topRow = sortedData.rows[0];
      const topDimVal = topRow[sortedData.getDimensionIndex(dimension)];
      const row = [topDimVal];

      this.metrics.forEach((metric: Metric) => {
        row.push(this.sum(metric));
      });
      rows.push(row);
    } else {
      const groupDimension = groupDimensions[0];
      const groupDimensionIndex = this.getDimensionIndex(groupDimension);

      const groups = this.groupBy(groupDimension);

      groups.forEach((group: DimensionDataGroup) => {
        let data = group.data;
        if (group.data.dimensions.length > 1) {
          data = group.data.collapseDimension(dimension);
          data.rows.forEach((row) => {
            const newRow = row.slice();
            newRow.splice(groupDimensionIndex, 0, group.value);
            rows.push(newRow);
          });
        } else {
          const sortedData = data.sortByMetricDirection(metric, Direction.DESC);
          const topRow = sortedData.rows[0];
          const topDimVal = topRow[sortedData.getDimensionIndex(dimension)];
          const row = [topDimVal];
          row.splice(groupDimensionIndex, 0, group.value);

          this.metrics.forEach((metric: Metric, index) => {
            row.push(group.data.totals[index]);
          });
          rows.push(row);
        }
      });
    }

    return new DataSet(this.dimensions, this.metrics, rows, this.totals, false);
  }

  indexByDimensions() {
    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }

    const dimensions = this.dimensions;
    const result: { [a: string]: MappedData } = {};
    this.rows.forEach((row: any) => {
      let obj: MappedData = result;
      // for each dimension, created a nested object, keyed by the dimension value in the row
      dimensions.forEach((dimension: Dimension, dimIndex) => {
        const dimValueKey = dimension.stringify(row[dimIndex]);
        const isLast = dimIndex + 1 === dimensions.length;
        if (isLast) {
          // add the full row value
          obj[dimValueKey] = row;
        } else {
          // create an empty nested object for the dim value
          obj = obj[dimValueKey] = obj[dimValueKey] || {};
        }
      });
    });

    return result;
  }

  /**
   * Returns a hashmap of key value pairs.
   *
   * @returns {{ [a: string]: MappedData }}
   */
  toMap(): { [a: string]: MappedData } {
    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }

    const result: { [a: string]: MappedData } = {};
    const metricIndices = this.metrics.map((metric: Metric) => {
      return this.getMetricFieldIndex(metric);
    });

    this.rows.forEach((row: any) => {
      let obj: MappedData = result;

      // for each dimension, created a nested object, keyed by the dimension value in the row
      this.dimensions.forEach((dimension: Dimension, dimIndex) => {
        const dimValueKey = dimension.stringify(row[dimIndex]);
        // create an empty nested object for the dim value
        obj = obj[dimValueKey] = obj[dimValueKey] || {};
      });

      // add metric values to the object, keyed by metric name
      this.metrics.forEach((metric: Metric, index) => {
        obj[metric.name] = row[metricIndices[index]];
      });
    });

    return result;
  }

  /**
   * Returns a hashmap of key value pairs.
   *
   * @returns {{ [a: string]: MappedData }}
   */
  toObjects(): Array<{ [key: string]: any }> {
    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }

    const metricIndices = this.metrics.map((metric: Metric) => {
      return this.getMetricFieldIndex(metric);
    });

    return this.rows.map((row: any) => {
      const obj: { [key: string]: any } = {};

      // for each dimension, created a nested object, keyed by the dimension value in the row
      this.dimensions.forEach((dimension: Dimension, dimIndex) => {
        obj[dimension.name] = row[dimIndex];
      });

      // add metric values to the object, keyed by metric name
      this.metrics.forEach((metric: Metric, index) => {
        obj[metric.name] = row[metricIndices[index]];
      });

      return obj;
    });
  }

  /**
   * Return an array of XY pairs, where X is the first Dimension and Y is the first Metric
   *
   * @param data
   * @returns {{x: any, y: number}[]}
   */
  toXY(): Array<{ x: any; y: number }> {
    if (this.dimensions.length === 0) {
      throw new Error("At least one dimension is required");
    }

    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }
    const firstMetric = this.getFirstMetric();
    if (!firstMetric) {
      return [];
    }

    const metricIndex = this.getMetricFieldIndex(firstMetric);

    return this.rows.map((tuple: any) => {
      return { x: tuple[0], y: tuple[metricIndex] };
    });
  }

  /**
   * Return an array of XY pairs, where X is the first Dimension and Y is the Nth Metric
   *
   * @param index // The index of the metric
   * @returns {{x: any, y: number}[]}
   */
  toXYN(index: number): Array<{ x: any; y: number }> {
    if (this.dimensions.length === 0) {
      throw new Error("At least one dimension is required");
    }

    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }

    const metricIndex = this.getMetricFieldIndex(this.metrics[index]);

    return this.rows.map((tuple: any) => {
      return { x: tuple[0], y: tuple[metricIndex] };
    });
  }

  /**
   * Return an array of XYZ tuples, where X is the first Dimension, Y is the first Metric, and Z is the second Metric
   *
   * @param data
   * @returns {{x: any, y: number, z: number}[]}
   */
  toXYZ(): Array<{ x: any; y: number; z: number }> {
    if (this.dimensions.length === 0) {
      throw new Error("At least one dimension is required");
    }

    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }

    const firstMetric = this.getFirstMetric();
    if (!firstMetric) {
      return [];
    }

    const yIndex = this.getMetricFieldIndex(firstMetric);
    const zIndex = yIndex + 1;

    return this.rows.map((tuple: any) => {
      return { x: tuple[0], y: tuple[yIndex], z: tuple[zIndex] || undefined };
    });
  }

  /**
   * Return an array of XY pairs with timezone adjusted to request timezone, where X is the first Dimension and Y is the Nth Metric
   *
   * @param index //the index of the metric
   * @param timeZone
   * @returns {{x: number, y: number}[]}
   */
  toLocalXYN(timeZone: string, index: number): Array<{ x: number; y: number }> {
    if (this.dimensions.length === 0) {
      throw new Error("At least one dimension is required");
    }

    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }

    const metricIndex = this.getMetricFieldIndex(this.metrics[index]);

    return this.rows.map((tuple: any) => {
      // @ts-ignore
      const localized = moment(tuple[0]).tz(timeZone);
      const formattedTime = localized?.format("YYYY-MM-DD HH:mm:ss");
      const browser = moment(formattedTime);

      return { x: browser.valueOf(), y: tuple[metricIndex] };
    });
  }

  /**
   * Return an array of XYZ tuples, where X is the first Metric, Y is the second Metric, and Z is the third Metric
   *
   * @param data
   * @returns {{x: any, y: number, z: number}[]}
   */
  toMetricXYZ(): Array<{ x: any; y: number; z: number }> {
    // if (this.dimensions.length === 0) {
    //     throw new Error('At least one dimension is required');
    // }

    if (this.metrics.length === 0) {
      throw new Error("At least one metric is required");
    }
    const firstMetric = this.getFirstMetric();
    if (!firstMetric) {
      return [];
    }

    const xIndex = this.getMetricFieldIndex(firstMetric);
    const yIndex = xIndex + 1;
    const zIndex = xIndex + 2;

    return this.rows.map((tuple: any) => {
      return {
        x: tuple[xIndex],
        y: tuple[yIndex],
        z: tuple[zIndex] || undefined,
      };
    });
  }

  /**
   * Merge the metrics and rows from one DataSet into another.
   * Returns a new, merged DataSet.
   *
   * @param primary The DataSet to merge to
   * @param additional The DataSet to merge in
   * @param additionalOrder The order to merge values in
   * @returns {DataSet}
   */
  static merge(
    primary: DataSet,
    additional: DataSet,
    additionalOrder: number[]
  ): DataSet {
    let dimension2: Dimension;
    let found: boolean;
    let newRow: any[];
    const newRows: any[][] = [];

    if (primary.dimensions.length !== additional.dimensions.length) {
      throw new Error("Dimension counts are different.  Cannot merge.");
    }

    // Perform sanity check to ensure that "primary" and "additional" have the same metric values
    primary.dimensions.forEach((dimension: Dimension, index: number) => {
      dimension2 = additional.dimensions[index];
      if (
        dimension.name !== dimension2.name ||
        dimension.type !== dimension2.type
      ) {
        throw new Error("Dimension types are different.  Cannot merge.");
      }
    });

    // Create a copy of the metrics
    const newMetrics = primary.metrics.concat([]);

    // Merge our additional metrics in the correct position.
    additional.metrics.forEach((metric: Metric, index: number) => {
      newMetrics.splice(additionalOrder[index], 0, metric);
    });

    const startOfValues = primary.dimensions.length;

    // Create our new unified rows array
    primary.rows.forEach((row: any, index: number) => {
      const rowKey = primary.rowKey(row);

      // Create copy of row to generate new results with
      newRow = row.slice();
      newRows.push(newRow);

      found = false;

      // Look for the rowKey in the "additional" values.  If found, then merge
      // them in the correct position.
      additional.rows.forEach((row2: any) => {
        if (additional.rowKey(row2) === rowKey) {
          row2.slice(startOfValues).forEach((value: any, index2: number) => {
            newRow.splice(startOfValues + additionalOrder[index2], 0, value);
          });

          found = true;
        }
      });

      // If we didn't find the value within the "additional" values, then
      // we need to put a default one in there.
      if (!found) {
        additional.metrics.forEach((metric: Metric, index2: number) => {
          let value: any;

          switch (metric.type) {
            case "STRING":
              value = "";
              break;

            case "INTEGER":
            case "DECIMAL":
            case "NUMBER":
            case "TIMESTAMP":
            case "TIME_INTERVAL":
            case "DATE":
              value = 0;
              break;
          }

          newRow.splice(startOfValues + additionalOrder[index2], 0, value);
        });
      }
    });

    return new DataSet(primary.dimensions, newMetrics, newRows, primary.totals);
  }

  // Returns a dataset with zeros converted to nulls.
  zeroKill() {
    const metrics = this.metrics;
    const dimCount = this.dimensions.length;

    const newRows = this.rows
      .filter((row) => {
        // remove rows with no metric values
        let hasValues = false;
        metrics.forEach((metric, index) => {
          if (
            row[index + dimCount] !== 0 &&
            row[index + dimCount] !== undefined &&
            row[index + dimCount] !== null
          ) {
            hasValues = true;
          }
        });
        return hasValues;
      })
      .map((row) => {
        // remove columns with no metric values
        return row.map((value, index) => {
          return value === 0 ? null : value;
        });
      });

    return new DataSet(
      this.dimensions,
      this.metrics,
      newRows,
      this.totals,
      false
    );
  }

  zeroFillDimensions() {
    const indexed = this.indexByDimensions();
    const newRows: any[][] = [];
    const categorieses = this.dimensions.map((dimension) =>
      this.categories(dimension)
    );
    const dimensions = this.dimensions;
    const metrics = this.metrics;

    const visitDimensions = (obj, dimIndex: number, rowFragment: any[]) => {
      const last = dimensions.length === dimIndex + 1;
      const dimension = dimensions[dimIndex];
      categorieses[dimIndex].forEach((value) => {
        const row = rowFragment.slice();
        const castValue = dimension.cast(value);
        row.push(castValue);

        if (last) {
          if (obj[value]) {
            newRows.push(obj[value]);
          } else {
            metrics.forEach((metric) => {
              row.push(0);
            });
            newRows.push(row);
          }
        } else {
          visitDimensions(obj[value] || {}, dimIndex + 1, row);
        }
      });
    };

    visitDimensions(indexed, 0, []);

    return new DataSet(
      this.dimensions,
      this.metrics,
      newRows,
      this.totals,
      true
    );
  }

  // Determines how fine-grained the date sequence will be
  // This string will be used by moment.js to fill in the gaps.
  private static timeSeriesPeriod(
    interval: string
  ): moment.unitOfTime.DurationConstructor | undefined {
    if (interval === "minute" || interval.indexOf("_1m") !== -1) {
      return "m";
    }

    if (interval === "hour" || interval.indexOf("_1h") !== -1) {
      return "h";
    }

    if (interval === "day" || interval.indexOf("_1d") !== -1) {
      return "d";
    }

    if (interval === "week" || interval.indexOf("_1w") !== -1) {
      return "w";
    }

    if (interval === "month" || interval.indexOf("_1M") !== -1) {
      return "M";
    }

    if (interval.indexOf("_1q") !== -1) {
      return "Q";
    }
  }

  private getComparator(direction: Direction) {
    if (direction === Direction.DESC) {
      return compare({ order: "desc" });
    }
    return compare();
  }

  private assertMetricIndex(metric: Metric) {
    const metricIndex = this.getMetricFieldIndex(metric);
    if (metricIndex === -1) {
      throw new Error(
        'The given metric "' +
          JSON.stringify(metric) +
          '" is not in the given data set.'
      );
    }
    return metricIndex;
  }

  private assertDimensionIndex(dimension: Dimension) {
    const dimensionIndex = this.getDimensionIndex(dimension);
    if (dimensionIndex === -1) {
      throw new Error(
        'The given dimension "' +
          JSON.stringify(dimension) +
          '" is not in the given data set.'
      );
    }
    return dimensionIndex;
  }
}
