import React from "react";
const BubbleMap = () => {
  return (
    <svg width="47px" height="24px" viewBox="0 0 47 24" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="_bits-/-map-/-bubble-icon-/-default"
          transform="translate(-11.000000, -5.000000)"
        >
          <g id="Group-7" transform="translate(11.000000, 5.000000)">
            <circle id="Oval" cx="20.5" cy="8" r="8"></circle>
            <circle
              id="Oval-Copy"
              opacity="0.8"
              cx="33"
              cy="18.5"
              r="5.5"
            ></circle>
            <circle
              id="Oval-Copy-7"
              opacity="0.7"
              cx="6"
              cy="14.5"
              r="5.5"
            ></circle>
            <circle
              id="Oval-Copy-2"
              opacity="0.6"
              cx="35.5"
              cy="3"
              r="2"
            ></circle>
            <circle
              id="Oval-Copy-3"
              opacity="0.5"
              cx="23.5"
              cy="19"
              r="2"
            ></circle>
            <circle
              id="Oval-Copy-8"
              opacity="0.4"
              cx="40.5"
              cy="9"
              r="2"
            ></circle>
            <circle
              id="Oval-Copy-5"
              opacity="0.4"
              cx="32.5"
              cy="9"
              r="3"
            ></circle>
            <circle
              id="Oval-Copy-6"
              opacity="0.3"
              cx="8.5"
              cy="4"
              r="3"
            ></circle>
            <circle
              id="Oval-Copy-9"
              opacity="0.2"
              cx="43.5"
              cy="16"
              r="3"
            ></circle>
            <circle
              id="Oval-Copy-4"
              opacity="0.2"
              cx="15.5"
              cy="21"
              r="3"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};
export default BubbleMap;
