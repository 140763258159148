import React from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { modes } from "./mapModeToggle";
import { primaryFilterOptions, timeRangeFilterOptions } from "./mapContainer";
import theme from "../designSystem/theme";
import { makeThresholds, makeLegendLabels } from "./ChoroplethLegend";
import { scaleThreshold } from "d3";
import { scaleLinear } from "@vx/scale";
import useBreakpoint from "../hooks/useBreakpoint";
import { getLegendItems } from "./stackedSizeLegend";

function useMapLegendState() {
  const [legendExpanded, setLegendExpanded] = useLocalStorage(
    "legendOpen",
    false
  );
  const [mode, setMode] = useLocalStorage("mode", modes[0]);

  const [primaryMapFilter, setPrimaryMapFilter] = useLocalStorage(
    "filter",
    primaryFilterOptions[0].value
  );
  const [selectedTimeRangeFilter, setTimeRangeFilter] = useLocalStorage(
    "time-range-filter",
    timeRangeFilterOptions[0]
  );

  const perMillion = selectedTimeRangeFilter.value === "Per Million";

  return {
    mode,
    setMode,
    primaryMapFilter,
    setPrimaryMapFilter,
    selectedTimeRangeFilter,
    setTimeRangeFilter,
    perMillion,
    legendExpanded,
    setLegendExpanded,
  };
}

type metricCombinations =
  | "Confirmed"
  | "Deaths"
  | "perMillionConfirmed"
  | "perMillionDeaths";

function useMapLegend(countryVals, primaryMapFilter, perMillion) {
  const breakpoint = useBreakpoint();
  const useHamburger = breakpoint === "s" || breakpoint === "m";

  const yMax = Math.max(...countryVals);
  const yMin = Math.min(...countryVals);

  const min = 5;
  const max = 25;
  const minMobile = 6;
  const maxMobile = 20;
  const minBubbleSize = useHamburger ? minMobile : min;
  const maxBubbleSize = useHamburger ? maxMobile : max;

  const colorStops = theme.choropleth[`${primaryMapFilter}ColorStops`];

  countryVals.sort(function (a, b) {
    return b - a;
  });

  const adjustedCountryVals = countryVals
    .slice(1, countryVals.length)
    .filter((value) => value !== 0);

  const dynamicThresholds = makeThresholds(adjustedCountryVals[0]);
  dynamicThresholds.reverse();
  dynamicThresholds.push(yMax);

  // from WHO
  // Cases total:
  // 1 – 1000
  // 1001 – 10,000
  // 10,001 – 50,000
  // 50,001 – 300,000
  // >300,000

  // Death total:
  // 1 - 10
  // 11 - 25
  // 26 - 100
  // 101 - 10,000
  // >10,000

  // Cases per 1 million:
  // 0.01 - 10.00
  // 10.01 - 100.00
  // 100.01 - 1,000.00
  // 1000.01 - 5000.00
  // >5000.00

  // Deaths per 1 million:
  // 0.01-1.00
  // 1.01 - 10.00
  // 10.01 - 100.00
  // 100.01 - 500.00
  // >500.00

  const magicNumbers = {
    Confirmed: {
      min: 1,
      range: [100, 10000, 50000, 300000, yMax],
    },
    Deaths: {
      min: 1,
      range: [10, 100, 1000, 10000, yMax],
    },
    perMillionConfirmed: {
      min: 0.01,
      range: [10, 100, 1000, 5000, yMax],
    },
    perMillionDeaths: {
      min: 0.01,
      range: [1, 10, 100, 500, yMax],
    },
  };

  const metricCombination: metricCombinations = `${
    perMillion ? "perMillion" : ""
  }${primaryMapFilter}` as metricCombinations;
  const useMagicNumbers = magicNumbers[metricCombination];
  const thresholds = useMagicNumbers
    ? magicNumbers[metricCombination].range
    : dynamicThresholds;

  const thresholdScale = scaleThreshold()
    .domain(thresholds)
    .range([0, 1, 2, 3, 4]);

  const legendLabels = makeLegendLabels(
    thresholds,
    undefined,
    useMagicNumbers && magicNumbers[metricCombination].min
  );

  const colorScale = (value) => {
    const index = thresholdScale(value);
    return colorStops[index];
  };

  const radiusScale = scaleLinear({
    domain: [0, 5],
    range: [minBubbleSize, maxBubbleSize],
  });
  const bubbleSizes = new Array(5)
    .fill(0)
    .map((item, index) => radiusScale(index));

  const yScale = (value) => {
    const index = thresholdScale(value);
    return bubbleSizes[index];
  };

  const sizeLegendItems = getLegendItems(yScale, [0, ...thresholds]).map(
    (item, index) => {
      return {
        ...item,
        value: legendLabels[index],
      };
    }
  );

  return {
    perMillion,
    sizeLegendItems,
    yScale,
    colorScale,
    yMax,
    yMin,
    legendLabels,
    colorStops,
  };
}

export default useMapLegend;
export { useMapLegendState };
