import React, { useState, useCallback } from "react";
import { scaleLinear } from "@vx/scale";
import LeafletMap, { StyledMap } from "./leafletMap";
import { MetricHudData } from "./mapMetricHud";
import { IndexedKeys, DataSets } from "../App";
import useLocalStorage from "../hooks/useLocalStorage";
import { getSizeLegendItems } from "./sizeLegend";
import { Viewport } from "react-leaflet";
import { observer } from "mobx-react-lite";
import MapHud from "./mapHud";
import useBreakpoint from "../hooks/useBreakpoint";
import styled from "styled-components";
import Flexbox from "../layout/flexbox";
import { media } from "../designSystem/responsive";
import theme from "../designSystem/theme";
import { opacify } from "polished";
import { MapMode, modes } from "./mapModeToggle";
import { generateColorBlendColors, setScale } from "../utils/colors";
import { makeLegendLabels, makeThresholds } from "./ChoroplethLegend";
import { scaleThreshold } from "d3-scale";
import { countryPopulation } from "../data/countryStats";
import { getLegendItems } from "./stackedSizeLegend";
import useMapLegend, { useMapLegendState } from "./useMapLegend";

type MapContainerProps = {
  dataSets: DataSets;
  metricData: MetricHudData;
};

export const Hero = styled(Flexbox)`
  /* height: calc(100vh - ${theme.magicNumbers.navHeight}); */
  height: calc(100vh - ${theme.magicNumbers.navHeight} - ${
  theme.magicNumbers.storyModeHeight
});
  width: 100%;
  position: relative;
  ${(media as any).m`
    height: calc(60vh);
  `}
  ${(media as any).m`
    ${StyledMap} {
      height: calc(100% - 100px);
      box-shadow: none;
    }
  `}
`;

export type FilterOption = { value: IndexedKeys; label: string };
export const primaryFilterOptions: FilterOption[] = [
  { value: "Confirmed", label: "Cases" },
  //we should be adding daily cases here
  // { value: "Confirmed", label: "New Cases },
  { value: "Deaths", label: "Deaths" },
  // we may be adding this in:
  // { value: "Population", label: "Population" },
];

export type TimeRangeFilterValues =
  | "Total"
  | "Per Million"
  | "Last 7 Days"
  | "Last 24 hours";

export type TimeRangeFilterOption = {
  value: TimeRangeFilterValues;
  label: string;
  metric?: string;
};

export const timeRangeFilterOptions: TimeRangeFilterOption[] = [
  {
    value: "Total",
    label: "Total",
  },
  {
    value: "Per Million",
    label: "Total per 1 million population",
    metric: "per 1 million population",
  },
  // {
  //   value: "Last 7 Days",
  //   label: "Newly reported in last 7 days",
  // },
  // {
  //   value: "Last 24 hours",
  //   label: "Newly reported in last 24 hours",
  // },
];

const MapContainer: React.FC<MapContainerProps> = ({
  dataSets,
  metricData: { totals, byDayCumulative, today, yesterday },
}) => {
  const {
    mode,
    setMode,
    primaryMapFilter,
    setPrimaryMapFilter,
    selectedTimeRangeFilter,
    setTimeRangeFilter,
    perMillion,
    legendExpanded,
    setLegendExpanded,
  } = useMapLegendState();

  const { byCountry } = dataSets;
  const countryVals = byCountry.toObjects().map((country) => {
    const populationMillions =
      (countryPopulation(country.Country) || 10000000) / 1000;
    return country[primaryMapFilter] / (perMillion ? populationMillions : 1);
  });

  const {
    sizeLegendItems,
    yScale,
    colorScale,
    yMax,
    yMin,
    legendLabels,
    colorStops,
  } = useMapLegend(countryVals, primaryMapFilter, perMillion);

  const breakpoint = useBreakpoint();
  let initialZoom = 2.5;
  switch (breakpoint) {
    case "s":
    case "m":
      initialZoom = 0.5;
      break;
    case "l":
    case "xl":
      initialZoom = 1.5;
      break;
  }

  const viewPortDefaults: Viewport = {
    center: [30, -10],
    zoom: initialZoom,
  };

  const [viewPort, setViewPort] = useState<Viewport>(viewPortDefaults);
  const handleMapReset = useCallback(() => {
    if (viewPort.zoom > initialZoom) {
      setViewPort({ ...viewPort, ...viewPortDefaults });
    }
  }, [viewPort, setViewPort]);

  if (!dataSets) {
    return null;
  }

  return (
    <Hero data-id="map-container">
      <LeafletMap
        mode={mode}
        useGlobalBounds
        data={dataSets}
        primaryFilter={primaryMapFilter}
        selectedTimeRangeFilter={selectedTimeRangeFilter}
        yScale={yScale}
        colorScale={colorScale}
        viewPort={viewPort}
        setViewPort={setViewPort}
        perMillion={perMillion}
      />
      <MapHud
        legendExpanded={legendExpanded}
        setLegendExpanded={setLegendExpanded}
        mode={mode}
        onSetMode={setMode}
        min={yMin}
        max={yMax}
        colorStops={colorStops}
        totals={totals}
        byDayCumulative={byDayCumulative}
        today={today}
        yesterday={yesterday}
        primaryMapFilter={primaryMapFilter}
        setPrimaryMapFilter={setPrimaryMapFilter}
        sizeLegendItems={sizeLegendItems}
        setTimeRangeFilter={setTimeRangeFilter}
        selectedTimeRangeFilter={selectedTimeRangeFilter}
        handleMapReset={handleMapReset}
        dataSets={dataSets}
        legendLabels={legendLabels}
        showDownload
      />
    </Hero>
  );
};

MapContainer.displayName = "MapContainer";
export default observer(MapContainer);
