import latlng from "../assets/geo/lat-lng.json";

export type Coordinates = {
  latitude: number;
  longitude: number;
};

const indexed: { [country: string]: Coordinates } = {};

latlng.ref_country_codes.forEach((entry) => {
  indexed[entry.alpha2] = indexed[entry.alpha3] = indexed[
    entry.country
  ] = indexed[entry.numeric] = {
    latitude: entry.customLatitude ? entry.customLatitude : entry.latitude,
    longitude: entry.customLongitude ? entry.customLongitude : entry.longitude,
  };
});

const countryCoordinates = (
  countryCodeOrName: string
): Coordinates | undefined => indexed[countryCodeOrName];

export default countryCoordinates;
