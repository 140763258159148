import Field, { FieldType, FieldDataType } from "./Field";

export default class Metric extends Field<string> {
  aggregateFunction: string;

  constructor(
    name: string,
    type?: FieldType,
    dataType?: FieldDataType,
    aggregateFunction?: string
  ) {
    super(
      name,
      typeof type !== "undefined" && type !== null ? type : "NUMBER",
      dataType || "NUMERIC"
    );

    // Mainly needed from invocation from unit test where aggregateFunction isn't defined
    if (aggregateFunction === undefined) {
      aggregateFunction = "SUM";
    }

    this.aggregateFunction = aggregateFunction;
  }

  isDecorator(): boolean {
    return (
      this.aggregateFunction === "CHANGE" ||
      this.aggregateFunction === "PERCENTAGE_CHANGE" ||
      this.aggregateFunction === "PERCENTAGE"
    );
  }

  getName(): string {
    switch (this.aggregateFunction) {
      case "CHANGE":
        return "change in " + this.name;

      case "PERCENTAGE":
        return this.name + " %";

      case "PERCENTAGE_CHANGE":
        return this.name + " %";

      default:
        return this.name;
    }
  }
}
