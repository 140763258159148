import React, { useEffect, useRef, useState } from "react";
import { feature as topFeature } from "topojson-client/dist/topojson-client";
import southSudanData from "../assets/geo/south-sudan-topo.json";
import {
  GeoJSON,
  TileLayer,
  Pane,
  FeatureGroup,
  Tooltip,
  Marker,
  LayerGroup,
} from "react-leaflet";
import { observer } from "mobx-react-lite";
import world from "../assets/geo/who-world-map-topo.json";
import { IndexedKeys } from "../App";
import TooltipInner, { getTooltipRows } from "./tooltipInner";
import { featureByIso2 } from "../utils/countryFeature";
import askaiChin from "../assets/geo/askai_chin_topo.json";
import { GeoJSONFillable, Patterns } from "react-leaflet-geojson-patterns";
import theme from "../designSystem/theme";
import { LatLngTuple } from "leaflet";
import { renderCountryName } from "./leafletMap";
import ChoroplethCountry from "./ChoroplethCountry";
import { getArunachalBorderSegment } from "../utils/map";

type ChoroplethMapProps = {
  feature?: any;
  data?: {
    [key: string]: any;
  }[];
  handleCountryClick?: (code: string) => void;
  primaryFilter?: IndexedKeys;
  selectedTimeRangeFilter?: any;
  colorScale?: any;
  showLabels: boolean;
  labelLimit: number;
};

const ChoroplethMap: React.FC<ChoroplethMapProps> = ({
  feature: selectedFeature,
  data,
  handleCountryClick,
  primaryFilter,
  selectedTimeRangeFilter,
  colorScale,
  showLabels,
  labelLimit,
}) => {
  const isSouthSudan = selectedFeature?.properties["ISO_2_CODE"] === "SS";
  const borderingSouthSudan = ["UG", "CG", "CF", "ET"];
  const renderOverDisputedBorders: boolean = borderingSouthSudan.includes(
    selectedFeature?.properties["ISO_2_CODE"]
  );

  const arunachalBorderSegment = getArunachalBorderSegment(
    selectedFeature?.properties["ISO_2_CODE"]
  );

  const worldFeatures = topFeature(world, (world as any).objects.countries);

  const kosovo = featureByIso2("XK");
  const serbia = featureByIso2("RS");

  const kosovoCountry = data.find((country) => {
    return country?.Country === "XK";
  });
  const kosovoColor =
    kosovoCountry[primaryFilter] === 0
      ? theme.choropleth[`${primaryFilter}Zero`]
      : colorScale(kosovoCountry[primaryFilter]);

  const isKosovoSelected = selectedFeature?.properties?.ISO_2_CODE === "XK";
  const renderSerbiaBorder =
    isKosovoSelected || selectedFeature?.properties?.ISO_2_CODE === "RS";

  const askaiChinFeature = topFeature(
    askaiChin,
    (askaiChin as any).objects.areas
  ).features[0];

  let selectedCountry;
  let selectedOverStyle;
  if (selectedFeature) {
    selectedCountry = data.find((country) => {
      return country?.Country === selectedFeature?.properties?.ISO_2_CODE;
    });
  }

  let visibleLabelCount = 0;

  const {
    selectedBorder,
    selectedWeight,
    generalBorder,
    generalWeight,
    notApplicable,
  } = theme.choropleth.mapStyles;

  return (
    <>
      <Pane name="countries-and-selected" style={{ zIndex: 1 }}>
        <FeatureGroup>
          {worldFeatures.features.map((feature, idx) => {
            const country = data.find((country) => {
              return country?.Country === feature.properties.ISO_2_CODE;
            });

            if (country) {
              const hasZeroVal = country[primaryFilter] === 0;

              const color = hasZeroVal
                ? theme.choropleth[`${primaryFilter}Zero`]
                : colorScale(country[primaryFilter]);

              const selectedStyle = () => {
                return {
                  pointerEvents: "none",
                  fillColor: color,
                  fillOpacity: 1,
                  color: selectedBorder,
                  weight: selectedWeight,
                  zIndex: 5,
                };
              };

              const generalStyle = () => {
                return {
                  weight: generalWeight,
                  opacity: 1,
                  fillOpacity: 1,
                  fillColor: color,
                  color: generalBorder,
                };
              };

              const isSelected =
                feature.properties.ISO_2_CODE ===
                selectedFeature?.properties["ISO_2_CODE"];

              if (isSelected) {
                selectedOverStyle = selectedStyle;
                return;
              }

              if (feature.properties.ISO_2_CODE === "XK") {
                return;
              }

              return (
                <ChoroplethCountry
                  geoFeature={feature}
                  countryData={country}
                  handleClick={handleCountryClick}
                  primaryFilter={primaryFilter}
                  selectedTimeRangeFilter={selectedTimeRangeFilter}
                  style={generalStyle}
                  idx={idx}
                />
              );
            }

            const style = () => {
              return {
                weight: generalWeight,
                opacity: 1,
                color: generalBorder,
                fillOpacity: 1,
                fillColor: notApplicable,
              };
            };
            return (
              <GeoJSON
                key={feature.properties.ISO_2_CODE}
                style={style}
                data={feature}
              />
            );
          })}
        </FeatureGroup>
      </Pane>

      {selectedFeature && !renderOverDisputedBorders && !isKosovoSelected && (
        <Pane name="selected-feature" style={{ zIndex: 4 }}>
          <FeatureGroup>
            <GeoJSONWrapper
              key={"min_geo1"}
              data={selectedFeature}
              country={selectedCountry}
              style={selectedOverStyle}
              primaryFilter={primaryFilter}
              selectedTimeRangeFilter={selectedTimeRangeFilter}
            />
          </FeatureGroup>
        </Pane>
      )}

      {renderSerbiaBorder && (
        <Pane name="kosovo-border" style={{ zIndex: 5 }}>
          <ChoroplethCountry
            geoFeature={serbia}
            countryData={data.find((country) => {
              return country?.Country === "RS";
            })}
            handleClick={handleCountryClick}
            primaryFilter={primaryFilter}
            selectedTimeRangeFilter={selectedTimeRangeFilter}
            style={() => {
              return {
                pointerEvents: "none",
                fillColor: "#FFFFFF",
                fillOpacity: 0,
                color: selectedBorder,
                weight: selectedWeight,
              };
            }}
          />
        </Pane>
      )}

      <Pane name="kosovo" style={{ zIndex: 6 }}>
        <ChoroplethCountry
          geoFeature={kosovo}
          countryData={kosovoCountry}
          handleClick={handleCountryClick}
          primaryFilter={primaryFilter}
          selectedTimeRangeFilter={selectedTimeRangeFilter}
          style={() => {
            return {
              weight: renderSerbiaBorder ? selectedWeight : generalWeight,
              opacity: 1,
              fillOpacity: 1,
              fillColor: kosovoColor,
              color: renderSerbiaBorder ? selectedBorder : generalBorder,
            };
          }}
        />
      </Pane>

      <Pane name="disputed-borders" style={{ zIndex: 7 }}>
        <TileLayer
          url="https://dashboards-dev.sprinklr.com/WHO-maps/who-choropleth-disputed/{z}/{x}/{y}.png"
          opacity={1}
          noWrap={true}
        />
        <GeoJSONFillable
          key={"askai-chin"}
          style={() => {
            return {
              weight: generalWeight,
              opacity: 1,
              color: generalBorder,
              fillOpacity: 1,
              fillPattern: Patterns.StripePattern({
                key: "stripes",
                angle: -45,
                weight: 4,
                spaceWeight: 1,
                color: notApplicable,
                spaceColor: "#FFFFFF",
              }),
            };
          }}
          data={askaiChinFeature}
        />
      </Pane>

      {/* Waiting for WHO feedback regarding India border selection treatment */}
      {selectedFeature && arunachalBorderSegment && (
        <Pane name="arunachal-pradesh-india-border" style={{ zIndex: 8 }}>
          <GeoJSON
            key={`arunachal-pradesh-${selectedFeature.properties.ISO_2_CODE}`}
            data={arunachalBorderSegment}
            style={selectedOverStyle}
          />
        </Pane>
      )}

      {selectedFeature && renderOverDisputedBorders && !isKosovoSelected && (
        <Pane name="selected-feature" style={{ zIndex: 8 }}>
          <FeatureGroup>
            <GeoJSONWrapper
              key={"min_geo1"}
              data={selectedFeature}
              style={selectedOverStyle}
              country={selectedCountry}
              primaryFilter={primaryFilter}
              selectedTimeRangeFilter={selectedTimeRangeFilter}
            />
          </FeatureGroup>
        </Pane>
      )}

      {isSouthSudan && (
        <Pane name="ss-border" style={{ zIndex: 9 }}>
          <GeoJSON
            key={"ss-border"}
            data={topFeature(
              southSudanData,
              (southSudanData as any).objects.countries
            )}
            style={() => {
              return {
                color: "#474747",
              };
            }}
          />
        </Pane>
      )}

      <LayerGroup pane="markerPane" style={{ zIndex: `600!important` }}>
        {data.map((country, index) => {
          const { Country: countryCodeIsoAlpha2, name: countryName } = country;

          const coords: LatLngTuple = [
            country.coords.latitude,
            country.coords.longitude,
          ];

          const hasZeroVal = country[primaryFilter] === 0;

          return (
            <>
              {showLabels &&
                country.inView &&
                visibleLabelCount++ <= labelLimit && (
                  <Marker
                    key={countryCodeIsoAlpha2}
                    position={coords}
                    attribution="test"
                    transform={hasZeroVal && `translate3d(0, 10px, 0)`}
                    icon={renderCountryName(
                      // remove western sahara
                      countryCodeIsoAlpha2 !== "EH" ? countryName : "",
                      hasZeroVal
                    )}
                  />
                )}
            </>
          );
        })}
      </LayerGroup>
    </>
  );
};

type GeoJSONWrapperProps = {
  data: any;
  style: any;
  country?: any;
  primaryFilter?: IndexedKeys;
  selectedTimeRangeFilter?: any;
};

const GeoJSONWrapperBase: React.FC<GeoJSONWrapperProps> = ({
  data: initialData,
  style,
  country,
  primaryFilter,
  selectedTimeRangeFilter,
}) => {
  const ref = useRef(null);
  const counter = useRef(0);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (ref.current) {
      ref.current?.leafletElement
        .clearLayers()
        .addData(initialData)
        .bringToBack();
      setData(initialData);
      counter.current = counter.current + 1;
    }
  }, [initialData]);

  return (
    <GeoJSON key={counter.current} ref={ref} data={data} style={style}>
      <Tooltip className="leaflet-wrapper">
        <TooltipInner
          rows={getTooltipRows(country, primaryFilter, selectedTimeRangeFilter)}
          title={
            data.features
              ? data.features[0].properties.ADM0_VIZ_N
              : data.properties?.ADM0_VIZ_N
          }
        />
      </Tooltip>
    </GeoJSON>
  );
};

const GeoRender = observer(ChoroplethMap);
const GeoJSONWrapper = observer(GeoJSONWrapperBase);

export default GeoRender;
