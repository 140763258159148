import React from "react";
import { observer } from "mobx-react-lite";
import {
  Accordion as ReachAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import "@reach/accordion/styles.css";
import Flexbox from "../layout/flexbox";
import styled from "../designSystem";
import { TextOne, TextTwo } from "../designSystem/typography";
import { StyledDateStamp } from "./breadcrumb";
import { ChevronRight } from "@styled-icons/feather/ChevronRight";

const Item = styled(AccordionItem)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.hardBorder};
`;

const Title = styled(Flexbox)`
  [data-reach-accordion-button] {
    ${TextOne};
    padding: 0;
    margin: 0;
    background: none;
    border: none;
  }
`;

const Content = styled(AccordionPanel)`
  margin-top: 1em;
  ${TextTwo};
`;

const StyledChevron = styled(ChevronRight)`
  height: 20px;
  width: 20px;
  will-change: transform;
  transition: transform 150ms;
  [data-reach-accordion-button][aria-expanded="true"] + & {
    transform: rotate(90deg);
  }
`;

export type AccordionData = {
  title: string;
  subtitle?: string;
  content: JSX.Element;
};

type AccordionProps = {
  data: AccordionData[];
};

const Accordion: React.FC<AccordionProps> = ({ data }) => {
  return (
    <ReachAccordion collapsible>
      {data.map((item) => (
        <Item key={item.title}>
          <Title vertical left gap="s">
            <Flexbox noGrow gap="m" middle>
              <AccordionButton>{item.title}</AccordionButton>
              <StyledChevron />
            </Flexbox>
            {item.subtitle && (
              <StyledDateStamp>{item.subtitle}</StyledDateStamp>
            )}
          </Title>
          <Content>{item.content}</Content>
        </Item>
      ))}
    </ReachAccordion>
  );
};

Accordion.displayName = "Accordion";
export default observer(Accordion);
