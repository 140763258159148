import React, { useState, useCallback } from "react";
import ContainedSection, { Layout } from "../layout/sections";
import Metric, { StyledMetricWrapper } from "../components/metric";
import Flexbox from "../layout/flexbox";
import ColumnChart from "./ColumnChart/columnChart";
// import ColumnChartLegacy from "./columnChart";
import Header from "../components/header";
import Dropdown from "../components/dropdown";
import Attribution, { StyledAttribution } from "../components/attribution";
import styled from "../designSystem";
import { media } from "../designSystem/responsive";
import { ColumnItem } from "./ColumnChart/columnChart";
import { observer } from "mobx-react-lite";
import theme from "../designSystem/theme";

type TrendGroupProps = {
  totals?: number[];
  metric: string;
  title: string;
  data: ColumnItem[][];
};

const aggregationOptions = [
  {
    value: "CUMULATIVE",
    label: "Cumulative",
  },
  {
    value: "DAILY",
    label: "Daily",
  },
];

const StyledColumnRow = styled(Layout)`
  height: 200px;
  > div:first-child {
    flex: 0 0 16%;
  }
  ${(media as any).l`
    flex-direction: column;
    
    ${StyledAttribution} {
      font-size: 9px;
    }
    
    > div:first-child {
      flex: 0 0 20%;
    }
    > div {
      flex: 1 1 auto;
    }

    max-height: 375px;
  `}
`;

const StyledHeaderGroup = styled(Layout)`
  ${(media as any).l`
  position: relative;
  h2 {
    position: absolute;
    top: -22px;
    font-size: 19px;
  }
  `}
`;

const StyledMetricGroup = styled(Flexbox)`
  ${(media as any).l`
    ${StyledMetricWrapper}{
      
      [data-id="metric"] {
        font-size: 30px;
      }
      [data-id="metric-label"] {
        font-size: 16px;
      }
      align-items: flex-start;
    }
  `}
  ${(media as any).m`
    ${StyledMetricWrapper}{
      [data-id="metric"] {
        font-size: 25px;
      }
      [data-id="metric-label"] {
        font-size: 12px;
      }
    }
  `}
`;

const StyledDropdown = styled(Layout)`
  flex: 0 0 192px;
  ${(media as any).l`
    position: absolute;
    top: 12px;
    right: 0;
    width: 122px;
    font-size: 12px;
  `}
`;

const StyledContainedSection = styled(ContainedSection)`
  ${(media as any).m`
    border: 1px solid ${theme.softBorder};
    border-radius: 5px;
    max-width: 90% !important;
    padding: 38px 13px 0px;
  `}
`;

const TrendGroup: React.FC<TrendGroupProps> = ({
  totals,
  title,
  data,
  metric,
}) => {
  const [aggregation, setAggregation] = useState(aggregationOptions[1]);
  const handleOnChange = useCallback((selected) => setAggregation(selected), [
    setAggregation,
  ]);

  return (
    <StyledContainedSection vertical gap>
      <StyledHeaderGroup middle between>
        {title && <Header>{title}</Header>}
        <StyledDropdown>
          <Dropdown
            selected={aggregation}
            options={aggregationOptions}
            onChange={handleOnChange}
          />
        </StyledDropdown>
      </StyledHeaderGroup>
      <StyledColumnRow>
        <StyledMetricGroup
          vertical
          left
          gap="s"
          noGrow
          top
          style={{ msGridColumn: 1 } as any}
        >
          <Metric
            value={totals[0]}
            size="l"
            label={metric === "Confirmed" ? "Confirmed Cases" : metric}
          />
          <Attribution source="World Health Organization" />
        </StyledMetricGroup>
        <Flexbox>
          <ColumnChart
            metric={metric}
            data={aggregation.value === "CUMULATIVE" ? data[1] : data[0]}
          />
          {/* <ColumnChartLegacy
              data={aggregation.value === "CUMULATIVE" ? data[1] : data[0]}
              metrics={[metric]}
            /> */}
        </Flexbox>
      </StyledColumnRow>
    </StyledContainedSection>
  );
};

export default observer(TrendGroup);
