import moment from "moment";

export type FieldType =
  | "STRING"
  | "INTEGER"
  | "DECIMAL"
  | "NUMBER"
  | "TIMESTAMP"
  | "TIME_INTERVAL"
  | "DATE";

export type FieldDataType =
  | "NUMERIC"
  | "PERCENTAGE"
  | "DATETIME"
  | "STRING"
  | "TIME_DIFFERENCE"
  | "DATE_HISTOGRAM"
  | "RANGE"
  | "PICKLIST"
  | "CUSTOM"
  | "CURRENCY"
  | "CURRENCY_AUD"
  | "CURRENCY_CAD"
  | "CURRENCY_CHF"
  | "CURRENCY_CNY"
  | "CURRENCY_CZK"
  | "CURRENCY_DKK"
  | "CURRENCY_EUR"
  | "CURRENCY_GBP"
  | "CURRENCY_INR"
  | "CURRENCY_JPY"
  | "CURRENCY_KRW"
  | "CURRENCY_NOK"
  | "CURRENCY_NZD"
  | "CURRENCY_PLN"
  | "CURRENCY_RUB"
  | "CURRENCY_SEK"
  | "CURRENCY_SGD"
  | "CURRENCY_TRY"
  | "CURRENCY_USD"
  | "CURRENCY_ZAR";

export default class Field<T> {
  name: T;
  type: FieldType;
  dataType: FieldDataType;

  constructor(name: T, type: FieldType, dataType: FieldDataType) {
    this.name = name;

    if (typeof type === "undefined" || type === null) {
      throw new Error("Field type is required!");
    }

    this.type = type;
    this.dataType = dataType;
  }

  getName(): T {
    return this.name;
  }

  cast(value: any): any {
    if (value === null || typeof value === "undefined") {
      return null;
    }

    let intVal: number;

    switch (this.type) {
      case "INTEGER":
        if (
          value === "" ||
          value === 0 ||
          value === null ||
          value === undefined
        ) {
          return 0;
        }

        intVal = parseInt(value, 10);
        if (isNaN(intVal)) {
          throw new Error('"' + value + '" is not a valid integer');
        }

        return intVal;

      case "TIME_INTERVAL":
      case "NUMBER":
      case "DECIMAL":
        if (
          value === "" ||
          value === 0 ||
          value === null ||
          value === undefined
        ) {
          return 0;
        }

        const floatVal: number = parseFloat(value);
        if (isNaN(floatVal)) {
          throw new Error('"' + value + '" is not a valid decimal');
        }

        return floatVal;

      case "STRING":
        return value === 0 || value ? value + "" : "";

      case "DATE":
      case "TIMESTAMP":
        if (value instanceof Date) {
          return value;
        }

        if (value === "" || value === null || value === undefined) {
          return null;
        }

        if (typeof value === "string") {
          const ts = Date.parse(value);
          if (!isNaN(ts)) {
            return new Date(ts);
          }

          const m = moment(value);
          if (m.isValid()) {
            return m.toDate();
          }

          intVal = parseInt(value, 10);
          if (isNaN(intVal)) {
            throw new Error('"' + value + '" is not a valid timestamp');
          }

          value = intVal;
        }

        if (typeof value === "number") {
          return new Date(value);
        }

        throw new Error('"' + value + '" is not a valid timestamp');

      default:
        throw new Error("Unable to cast type " + this.type);
    }
  }
}
