import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { Map as LayerMap, Viewport, ZoomControl } from "react-leaflet";
import { Helmet } from "react-helmet";
import "leaflet/dist/leaflet.css";
import L, { LatLngBounds, LatLngBoundsLiteral, LatLngTuple } from "leaflet";
import styled, { css } from "../designSystem";
import { DataSets, IndexedKeys } from "../App";
import {
  findRegionByCountryIso2,
  isoAlpha2toCountryName,
} from "../utils/countryCode";
import theme, { getMetricColor, shadow } from "../designSystem/theme";
import { media } from "../designSystem/responsive";
import { useHistory } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import countryCoordinates from "../utils/countryCoordinates";
import { GeoRender } from "../utils/map";
import { opacify } from "polished";
import { observer } from "mobx-react-lite";
import MapDots from "./mapDots";
import useBreakpoint from "../hooks/useBreakpoint";
import { countryFeatures, featureByIso2 } from "../utils/countryFeature";
import ChoroplethMap from "./choroplethMap";
import { MapMode } from "./mapModeToggle";
import { countryPopulation } from "../data/countryStats";

export const CountryNameText = ({ name }) => (
  <div style={{ color: "#474747", lineHeight: 1, fontSize: "16px" }}>
    {name ?? "test"}
  </div>
);

export const renderCountryName = (name, offset?) =>
  L.divIcon({
    className: `map-element-name ${offset ? "offset" : ""}`,
    html: ReactDOMServer.renderToString(<CountryNameText name={name} />),
  });

const labelZoomThreshold = 4;

type StyledMapProps = {
  pointsMode?: "a" | "b";
  primaryFilter?: string;
};

export const StyledMap = styled(LayerMap)<StyledMapProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  &.leaflet-container {
    background-color: ${theme.mapBackground};
  }

  .leaflet-countries-and-selected-pane {
    path.leaflet-interactive {
      cursor: grab;
    }
  }
  img.leaflet-tile.leaflet-tile-loaded {
    backface-visibility: hidden;
  }
  .leaflet-control-attribution {
    display: none;
  }
  .leaflet-control-zoom.leaflet-control-zoom {
    margin-right: 20px;
    margin-top: 20px;
    position: relative;
  }

  .leaflet-bar {
    border: 1px solid ${theme.softBorder} !important;
    box-shadow: none;
    border-radius: 5px;
  }
  &.leaflet-container a.leaflet-control-zoom-out,
  &.leaflet-container a.leaflet-control-zoom-in {
    width: 36px;
    height: 36px;
    line-height: 36px;
    color: ${theme.icon};
    ${(media as any).m`
      font-size: 16px;
    `}
  }
  &.leaflet-container .leaflet-bar a:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &.leaflet-container .leaflet-bar a:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .leaflet-wrapper {
    min-width: 200px;
    border: 1px solid ${theme.softBorder};
    /* background: #fff; */
    padding: 16px;
    opacity: 100%;
  }
  .leaflet-container a {
    ${shadow};
    opacity: 1;
  }

  .leaflet-marker-icon.map-element-name {
    pointer-events: none;
    width: 100px !important;
    & > div {
      transform: translate3d(-50%, 0, 0);
      width: 100%;
      text-align: center;
    }
    &.offset {
      top: 15px;
    }
  }

  .dot.leaflet-interactive {
    stroke-width: 0px;
    will-change: fill-opacity, stroke, stroke-width;
    transition: fill-opacity 300ms, stroke-width 300ms 100ms, stroke 300ms 100ms;
  }
  ${({ pointsMode, theme, primaryFilter }) =>
    pointsMode === "a" &&
    primaryFilter &&
    css`
      .dot.leaflet-interactive:hover {
        stroke: ${opacify(-0.5, getMetricColor(primaryFilter, theme))};
        /* stroke-width: 10px; */
        fill-opacity: 1;
      }
    `}
  ${({ pointsMode, theme, primaryFilter }) =>
    pointsMode === "b" &&
    primaryFilter &&
    css`
      .dot.leaflet-interactive {
        stroke: ${opacify(-0.5, getMetricColor(primaryFilter, theme))};
        /* stroke-width: 10px; */
        fill-opacity: 1;
      }
    `}
`;

type LeafletMapProps = {
  data: DataSets;
  center?: LatLngTuple;
  primaryFilter: IndexedKeys;
  yScale: any;
  colorScale: any;
  viewPort: Viewport;
  setViewPort?: (viewport: Viewport) => void;
  featuredCountryIso2?: string;
  initialBounds?: LatLngBoundsLiteral;
  useGlobalBounds?: boolean;
  noReportedData?: boolean;
  mode: MapMode;
  perMillion?: boolean;
  selectedTimeRangeFilter?: any;
};

const LeafletMap: React.FC<LeafletMapProps> = ({
  data,
  primaryFilter,
  yScale,
  colorScale,
  viewPort,
  setViewPort,
  featuredCountryIso2,
  initialBounds,
  useGlobalBounds,
  mode,
  perMillion,
  selectedTimeRangeFilter,
}) => {
  const history = useHistory();
  const point = useBreakpoint();
  const mapRef = useRef<LayerMap>();
  const [bounds, setBounds] = useState<LatLngBounds>();
  const [showLabels, setShowLabels] = useState<boolean>(false);

  const breakpoint = useBreakpoint();
  const useHambuger = breakpoint === "s" || breakpoint === "m";
  let minZoom = 1;
  switch (breakpoint) {
    case "m":
    case "s":
      minZoom = 0;
      break;
  }

  useEffect(() => {
    if (featuredCountryIso2) {
      setShowLabels(true);
    }
  }, [featuredCountryIso2]);

  const feature = featureByIso2(featuredCountryIso2);

  const handleCircleClick = useCallback(
    (iso2Code) => {
      const region = findRegionByCountryIso2(iso2Code);
      history.push({
        pathname: `/region/${region?.toLowerCase()}/country/${iso2Code?.toLowerCase()}`,
      });
    },
    [history]
  );

  const handleViewPortChange = useCallback(
    (newViewPort: Viewport) => {
      if (newViewPort?.zoom >= labelZoomThreshold) {
        setShowLabels(true);
      } else {
        setShowLabels(false);
      }
      if (setViewPort) {
        setViewPort(newViewPort);
      }
      const b = mapRef?.current?.leafletElement?.getBounds();
      if (b) {
        setBounds(b);
      }
    },
    [setShowLabels, setViewPort]
  );

  const foundCountries = {};

  const countryObjects = useMemo(() => {
    const objects: any[] =
      data?.byCountry
        .toObjects()
        .filter((value) => {
          foundCountries[value.Country] = value.Country;
          const coords = countryCoordinates(value.Country);
          if (!coords || (coords.latitude === 0 && coords.longitude === 0)) {
            return false;
          }
          value.coords = coords;
          value.name = isoAlpha2toCountryName(value.Country);

          const change = data.countriesDailyChange[value.Country] || {};
          value.change = change[primaryFilter];

          if (value.Country === featuredCountryIso2) {
            value.inView = true;
            return true;
          }

          if (!bounds) {
            return true;
          }
          value.inView = bounds.contains({
            lat: coords.latitude,
            lng: coords.longitude,
          });

          return true;
        })
        .map((country) => {
          if (perMillion) {
            const populationMillions =
              (countryPopulation(country.Country) || 10000000) / 1000;
            return {
              ...country,
              Deaths: country.Deaths / populationMillions,
              Confirmed: country.Confirmed / populationMillions,
            };
          } else {
            return country;
          }
        })
        .sort((a, b) => b[primaryFilter] - a[primaryFilter]) || [];

    countryFeatures.forEach((country) => {
      if (
        !(country.properties.ISO_2_CODE in foundCountries) &&
        country.properties.ISO_2_CODE !== "AN" &&
        country.properties.ISO_2_CODE !== "EH"
      ) {
        const coords = countryCoordinates(country.properties.ISO_2_CODE);

        objects.push({
          Country: country.properties.ISO_2_CODE,
          Region: country.properties.WHO_REGION,
          Confirmed: 0,
          "Cumulative Confirmed": 0,
          Deaths: 0,
          "Cumulative Deaths": 0,
          change: undefined,
          name: isoAlpha2toCountryName(country.properties.ISO_2_CODE),
          coords,
          inView:
            !!bounds &&
            bounds.contains({
              lat: coords.latitude,
              lng: coords.longitude,
            }),
        });
      }
    });

    // Note the filter here, this is important. If a data payload from the WHO API is missing countries that aren't
    // present in src/assets/geo/lat-lng.json we wind up without coordinates, which has significant fallout.
    return objects.filter((o) => !!o.coords);
  }, [data, foundCountries, primaryFilter, featuredCountryIso2, bounds]);

  if (!data) {
    return null;
  }

  const globalBounds: LatLngBoundsLiteral = countryObjects.map((country) => [
    country.coords.latitude,
    country.coords.longitude,
  ]);

  return (
    <StyledMap
      data-id="map"
      pointsMode="a"
      ref={mapRef}
      zoomControl={false}
      scrollWheelZoom={false}
      zoomSnap={0.5}
      zoomDelta={1}
      minZoom={minZoom}
      maxZoom={10}
      bounds={useGlobalBounds ? globalBounds : initialBounds}
      onViewportChanged={handleViewPortChange}
      viewport={viewPort}
      maxBounds={
        [
          [-90, -280],
          [90, 280],
        ] as LatLngBoundsLiteral
      }
    >
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
          integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
          crossOrigin=""
        />
      </Helmet>
      <ZoomControl position="topright" />

      {mode.name === "Choropleth Map" && (
        <ChoroplethMap
          feature={feature}
          data={countryObjects}
          handleCountryClick={handleCircleClick}
          primaryFilter={primaryFilter}
          selectedTimeRangeFilter={selectedTimeRangeFilter}
          colorScale={colorScale}
          showLabels={showLabels}
          labelLimit={15}
        />
      )}

      {mode.name === "Bubble Map" && (
        <>
          <GeoRender feature={feature} />
          <MapDots
            countryObjects={countryObjects}
            handleCircleClick={handleCircleClick}
            yScale={yScale}
            primaryFilter={primaryFilter}
            selectedTimeRangeFilter={selectedTimeRangeFilter}
            showLabels={showLabels}
            labelLimit={15}
          />
        </>
      )}
    </StyledMap>
  );
};

LeafletMap.displayName = "LeafletMap";
export default observer(LeafletMap);
