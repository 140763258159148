import styled, { css } from "../designSystem";
import { media } from "./responsive";

const TextPrimary = ({ theme }) =>
  css`
    color: ${theme.textPrimary};
  `;

// breadcrumb on desktop
export const TextOne = ({ theme }) => css`
  // default
  color: ${theme.textPrimary};
  font-family: Helvetica-Bold, Arial;
  font-weight: bold;
  // large
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  // mobile
  ${(media as any).m`
    font-size: 13px;
    line-height: 16px;
  `}
`;

// breadcrumb on desktop @collapsed
export const TextTwo = ({ theme }) => css`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
`;

export const TextThree = ({ theme }) => css`
  font-size: 10px;
  line-height: 12px;
  font-family: Helvetica-Bold, Arial;
  font-weight: bold;
  letter-spacing: 0px;
`;

// attribution and header timestamp
export const TextFour = ({ theme }) => css`
  font-size: 10px;
  font-family: Helvetica, Arial;
  line-height: 12px;
  letter-spacing: 0px;
  color: ${theme.textPrimary};
  // in design; removed per design review
  // ${(media as any).m`
  //   line-height: 10px;
  //   font-size: 8px;
  // `}
`;

// Header
export const TextFive = ({ theme }) => css`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 25px;
  font-weight: bold;
  margin: 0;
  ${(media as any).s`
    font-size: 20px;
  `}
`;

export const Header = styled.h2`
  ${TextFive}
`;

// SubHeader
export const TextSix = ({ theme }) => css`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  ${(media as any).s`
    font-size: 16px;
  `}
`;

export const SubHeader = styled.h3`
  ${TextSix}
`;

// PageTitle
export const TextSeven = ({ theme }) => css`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 20px;
  font-weight: bold;
  margin: 0;
`;

// FooterLinks
export const TextEight = ({ theme }) => css`
  color: ${({ theme }) => theme.textPrimary};
  font-size: 12px;
  font-weight: normal;
  ${(media as any).m`
    font-size: 18px;
    margin-bottom: 12px;
  `}
`;

export const PageTitle = styled.h1`
  ${TextSeven}
`;

export const RemoveLinkStyles = () => css`
  // color: inherit;
  text-decoration: none;
`;

export const ExternalLink = styled.a`
  ${TextEight};
  ${RemoveLinkStyles};
`;

// export {};
