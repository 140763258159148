import React, { useCallback, useRef } from "react";
import { Bar } from "@vx/shape";
import { Group } from "@vx/group";
import useResizeObserver from "use-resize-observer/polyfilled";
import { scaleBand, scaleLinear, scaleTime } from "@vx/scale";
import moment from "moment";
import styled, { css } from "../../designSystem";
import Flexbox from "../../layout/flexbox";
import theme, { getColor } from "../../designSystem/theme";
import { Region } from "../../utils/regions";
import XAxis from "../xAxis";
import ColumnToolTip, {
  tooltipWidth,
  arrowWidth,
  useToolTip,
  getTipOffsets,
  getTipData,
  emptyTip,
} from "./columnToolTip";
import {
  getDateFormat,
  numTicksForWidth,
  options,
  getBandwidth,
} from "./columnAxis";
import { extent } from "d3-array";
import { localPoint } from "@vx/event";

type StyledStackedColumnChartProps = {
  aspect?: string;
};
const StyledStackedColumnChart = styled(Flexbox)<StyledStackedColumnChartProps>`
  max-width: 100%;
  position: relative;
  cursor: pointer;
  .bar_fill {
    fill: transparent;
  }
  .segment_fill {
    transition: fill 300ms, opacity 300ms;
  }
`;

type StackedColumn = {
  x: Date;
  data: number[];
};

// accessors
const x = (d) => moment.utc(d.x).valueOf();
const y = (d) => d.y;

type StackedColumnChartProps = {
  metrics: string[];
  regions?: Region[];
  data: StackedColumn[];
  activeSegments?: string;
};

const getY = (item) => {
  return item.data.reduce((prev, curr) => prev + curr, 0);
};

const getSegmetColor = (index, metrics, activeSegments) => {
  if (
    (metrics && metrics[index] && !activeSegments) ||
    (!!activeSegments && metrics[index] === activeSegments)
  ) {
    return getColor(metrics[index] as Region);
  } else {
    return theme.chartSecondary;
  }
};

const StackedColumnChart: React.FC<StackedColumnChartProps> = ({
  data,
  metrics,
  regions,
  activeSegments,
}) => {
  const { ref: chartRef, height = 0, width = 0 } = useResizeObserver();
  const tooltipTimeout = useRef(null);

  const margin = {
    top: 0,
    right: 0,
    bottom: 40,
    left: 0,
  };

  const [toolTip, setToolTip] = useToolTip();
  const { open: tooltipOpen, index: tooltipIndex } = toolTip;

  // bounds
  const xMax = width;
  const yMax = !!height ? height - margin.bottom : 0;

  const yMaxDomain = Math.max(...data.map((item) => getY(item)));

  // scales
  const xScale = scaleBand({
    domain: data.map(x),
    padding: 0.2,
    range: [0, xMax],
  });

  const yScale = scaleLinear({
    domain: [0, yMaxDomain],
    nice: true,
    range: [yMax, 0],
  });

  const xScaleTicks = scaleTime({
    range: [0, xMax],
    domain: extent(data, x),
  });

  const xTicks = data.map((xTicks) => moment.utc(xTicks.x).format("MMM D"));

  const handleScrub = (event) => {
    if (event) {
      const { x: localX } = localPoint(event);
      const percentge = localX / width;
      const barIndex = Math.abs(Math.ceil(data.length * percentge - 1));

      if (tooltipIndex !== barIndex) {
        if (tooltipTimeout.current) {
          clearTimeout(tooltipTimeout.current);
        }
        const bar = data[barIndex];

        if (bar) {
          const barX = xScale(x(bar));

          const { barXOffset, tipOffset } = getTipOffsets(
            width,
            barX,
            arrowWidth,
            tooltipWidth
          );

          setToolTip({
            open: true,
            offset: tipOffset,
            x: barXOffset + xScale.bandwidth() / 2 - 1,
            index: barIndex,
            y: yMax,
            data: getTipData(data, bar, barIndex),
          });
        }
      }
    }
  };

  const resetScrub = useCallback(() => {
    tooltipTimeout.current = setTimeout(() => {
      setToolTip(emptyTip);
    }, 300);
  }, [setToolTip]);

  return (
    <StyledStackedColumnChart ref={chartRef}>
      <svg width={width} height={height}>
        <Group top={margin.top}>
          <Group>
            {data.map((bar, barIndex) => {
              const barWidth = xScale.bandwidth();
              const barHeight = yMax - yScale(getY(bar));
              const barX = xScale(x(bar));
              const barY = yMax - barHeight;

              return (
                <Group key={barIndex}>
                  <Bar
                    rx={2}
                    ry={2}
                    className={`bar_fill bar_index_${barIndex + 1}`}
                    key={`bar-${barIndex}`}
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                  />
                  <Group>
                    {bar.data.map((segment, index) => {
                      const segmentHeight = yMax - yScale(segment);
                      const prev = bar.data.slice(0, index);

                      const segmentY =
                        barY +
                        prev.reduce(
                          (prev, curr) => prev + (yMax - yScale(curr)),
                          0
                        );

                      return (
                        <Bar
                          className={`segment_fill segment_index_${index + 1}`}
                          key={`segment-${index}`}
                          x={barX}
                          fill={getSegmetColor(index, metrics, activeSegments)}
                          opacity={
                            !!tooltipIndex && barIndex !== tooltipIndex
                              ? 0.4
                              : 1
                          }
                          y={segmentY}
                          width={barWidth}
                          height={segmentHeight}
                        />
                      );
                    })}
                  </Group>
                </Group>
              );
            })}
          </Group>
        </Group>

        <Bar
          x={0}
          y={0}
          width={width}
          height={height}
          fill="transparent"
          onTouchStart={handleScrub}
          onTouchMove={handleScrub}
          onTouchEnd={resetScrub}
          onMouseMove={handleScrub}
          onMouseLeave={resetScrub}
        />

        <XAxis
          left={0}
          top={yMax + 15}
          xMax={xMax}
          bandwidth={getBandwidth(width)}
          dateFormat={getDateFormat(width)}
          hideAxisLine={false}
          label=""
          labelType="STRING"
          numTicksForWidth={numTicksForWidth(width)}
          options={options}
          xScale={xScaleTicks}
          xValues={xTicks}
        />
      </svg>

      {tooltipOpen && (
        <ColumnToolTip toolTip={toolTip} metrics={metrics} regions={regions} />
      )}
    </StyledStackedColumnChart>
  );
};

export default StackedColumnChart;
