import geoData from "../assets/geo/who-world-map-topo.json";
import { feature } from "topojson-client/dist/topojson-client";
import kosovoData from "../assets/geo/Kosovo.json";

export type CountryFeature = {
  type: "Feature";
  properties: {
    WHO_REGION: string;
    ISO_2_CODE: string;
    ADM0_NAME: string;
    ADM0_VIZ_N: string;
  };
  geometry: any;
};

const features: CountryFeature[] = feature(
  geoData,
  (geoData as any).objects.countries
).features;
const featuresIndexed = {};

features.forEach(
  (item) => (featuresIndexed[item.properties["ISO_2_CODE"]] = item)
);

const featureByIso2 = (iso2: string): CountryFeature | undefined => {
  if (iso2 === "XK") {
    const data = feature(kosovoData, kosovoData.objects.countries);
    data.features[0].properties = kosovoData.objects.countries.properties;
    return data.features[0];
  }
  return featuresIndexed[iso2];
};

export { featureByIso2, features as countryFeatures };
