import React from "react";
import styled, { css } from "../designSystem";
import { getSpacing, SpacingSizes, reset } from "../designSystem/utils";

export type FlexboxProps = React.HTMLAttributes<HTMLDivElement> & {
  inline?: boolean;
  vertical?: boolean;
  noGrow?: boolean;
  grow?: number;
  shrink?: number;
  basis?: number | "auto";
  children?: React.ReactNode;
  gap?: SpacingSizes;
  pad?: SpacingSizes;
  top?: boolean;
  middle?: boolean;
  bottom?: boolean;
  left?: boolean;
  center?: boolean;
  right?: boolean;
  between?: boolean;
  around?: boolean;
  h100?: boolean;
};

export const getFlexProperties = (props: FlexboxProps) => {
  // check all booleans and return appropriate css
  const {
    top,
    middle,
    bottom,
    left,
    center,
    right,
    between,
    around,
    vertical,
  } = props;

  let primaryAxis = "initial";
  let secondaryAxis = "initial";

  // does primary axis have a value?
  if (left || center || right || between || around) {
    // set value
    if (left) {
      primaryAxis = "flex-start";
    } else if (center) {
      primaryAxis = "center";
    } else if (right) {
      primaryAxis = "flex-end";
    } else if (between) {
      primaryAxis = "space-between";
    } else if (around) {
      primaryAxis = "space-around";
    }
  }

  if (!vertical) {
    // secondary axis
    if (top || middle || bottom) {
      if (top) {
        secondaryAxis = "flex-start";
      } else if (middle) {
        secondaryAxis = "center";
      } else if (bottom) {
        secondaryAxis = "flex-end";
      }
    }
  }

  // if vertical flip it
  if (vertical) {
    if (left || center || right) {
      if (left) {
        secondaryAxis = "flex-start";
      } else if (center) {
        secondaryAxis = "center";
      } else if (right) {
        secondaryAxis = "flex-end";
      }
    }

    if (top || middle || bottom || between || around) {
      if (top) {
        primaryAxis = "flex-start";
      } else if (middle) {
        primaryAxis = "center";
      } else if (bottom) {
        primaryAxis = "flex-end";
      } else if (between) {
        primaryAxis = "space-between";
      } else if (around) {
        primaryAxis = "space-around";
      }
    }
  }

  return css`
    justify-content: ${primaryAxis};
    align-items: ${secondaryAxis};
  `;
};

const Flexbox = styled("div")<FlexboxProps>`
    min-height: 0px; // fix for Edge and FF
    min-width: 0px; // fix for Edge and FF
    display: ${({ inline }: FlexboxProps) => (inline ? "inline-flex" : "flex")};
    flex-direction: ${({ vertical }: FlexboxProps) =>
      vertical ? "column" : "row"};

    ${({ pad }: FlexboxProps) =>
      pad &&
      css`
        padding: ${getSpacing(pad)};
      `}

    ${({ gap, vertical }: FlexboxProps) =>
      gap &&
      vertical &&
      css`
        > * + * {
          margin-top: ${getSpacing(gap)};
        }
      `}
    ${({ gap, vertical }: FlexboxProps) =>
      gap &&
      !vertical &&
      css`
        > * + * {
          margin-left: ${getSpacing(gap)};
        }
      `}
    ${({ h100 }: FlexboxProps) =>
      h100 &&
      css`
        height: 100%;
      `}


    flex: ${({ noGrow }: FlexboxProps) => (noGrow ? "0 0 auto" : "1 1 auto")};
    ${getFlexProperties};
`;

Flexbox.defaultProps = {
  inline: false,
  vertical: false,
  noGrow: false,
  grow: 1,
  shrink: 1,
  basis: "auto",
  children: null,
};

Flexbox.displayName = "Flexbox";

export default Flexbox;
