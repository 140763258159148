import React, { useRef } from "react";
import Metric, { MetricSize } from "./metric";
import Flexbox from "../layout/flexbox";
import Sparkline, { SparkLineData } from "./sparkline";
import { Sentiment } from "../designSystem/utils";
import useResizeObserver from "use-resize-observer/polyfilled";
import styled from "../designSystem";
import { observer } from "mobx-react-lite";
import { media } from "../designSystem/responsive";
import useBreakpoint from "../hooks/useBreakpoint";

type MetricCardProps = {
  total: number;
  label: string;
  sparkData?: SparkLineData[];
  sentiment: Sentiment;
  isPercentage?: boolean;
  size?: MetricSize;
};

const StyledMetric = styled(Flexbox)``;

const MetricCard: React.FC<MetricCardProps> = ({
  total,
  label,
  sparkData,
  sentiment,
  isPercentage = false,
  size = "l",
}) => {
  const { ref, width = 0 } = useResizeObserver();
  const point = useBreakpoint();
  const useHambuger = point === "s" || point === "m";

  const desktop = size === "l" ? 100 : 50;
  const sparklineSize = useHambuger ? 30 : desktop;

  return (
    <StyledMetric vertical gap="s" ref={ref} data-id="metric-card">
      {!!sparkData && (
        <Sparkline
          height={sparklineSize}
          width={width}
          data={sparkData}
          sentiment={sentiment}
        />
      )}
      <Metric
        value={total}
        label={label}
        format={isPercentage ? "1.23k" : "1,234"}
        align="right"
        size={size}
        isPercentage={isPercentage}
      />
    </StyledMetric>
  );
};

MetricCard.displayName = "MetricCard";
export default observer(MetricCard);
