import React from "react";
import { LayerGroup, CircleMarker, Tooltip, Marker } from "react-leaflet";
import { LatLngTuple } from "leaflet";
import theme, { getMetricColor } from "../designSystem/theme";
import { renderCountryName } from "./leafletMap";
import styled from "../designSystem";
import { IndexedKeys } from "../App";
import { observer } from "mobx-react-lite";
import TooltipInner, { getTooltipRows } from "./tooltipInner";
import useBreakpoint from "../hooks/useBreakpoint";

type MapDotsProps = {
  countryObjects: {
    [key: string]: any;
  }[];
  handleCircleClick: (code: string) => void;
  yScale: any;
  primaryFilter: IndexedKeys;
  showLabels: boolean;
  labelLimit: number;
  selectedTimeRangeFilter: any;
};

export const MapTooltipLabel = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

const MapDots: React.FC<MapDotsProps> = ({
  countryObjects,
  handleCircleClick,
  yScale,
  primaryFilter,
  showLabels,
  labelLimit,
  selectedTimeRangeFilter,
}) => {
  let visibleLabelCount = 0;

  const point = useBreakpoint();

  return (
    <LayerGroup pane="markerPane" style={{ zIndex: `600!important` }}>
      {countryObjects.map((country, index) => {
        const { Country: countryCodeIsoAlpha2, name: countryName } = country;
        const coords: LatLngTuple = [
          country.coords.latitude,
          country.coords.longitude,
        ];

        const handleClick = () => handleCircleClick(countryCodeIsoAlpha2);
        const hasZeroVal = country[primaryFilter] === 0;

        return (
          <CircleMarker
            className="dot"
            key={countryCodeIsoAlpha2}
            center={coords}
            fillColor={
              hasZeroVal
                ? theme.mapDots.zeroData
                : getMetricColor(primaryFilter, theme)
            }
            fillOpacity={hasZeroVal ? 0.31 : 0.5}
            radius={hasZeroVal ? 5 : yScale(country[primaryFilter])}
            stroke={false}
            onclick={handleClick}
          >
            {point !== "s" && (
              <Tooltip className="leaflet-wrapper">
                <TooltipInner
                  rows={getTooltipRows(
                    country,
                    primaryFilter,
                    selectedTimeRangeFilter
                  )}
                  title={countryName}
                />
              </Tooltip>
            )}
            {showLabels && country.inView && visibleLabelCount++ <= labelLimit && (
              <Marker
                key={countryCodeIsoAlpha2}
                position={coords}
                attribution="test"
                transform={hasZeroVal && `translate3d(0, 10px, 0)`}
                icon={renderCountryName(
                  // remove western sahara
                  countryCodeIsoAlpha2 !== "EH" ? countryName : "",
                  hasZeroVal
                )}
              />
            )}
          </CircleMarker>
        );
      })}
    </LayerGroup>
  );
};

MapDots.displayName = "MapDots";
export default observer(MapDots);
