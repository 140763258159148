import React from "react";
import { observer } from "mobx-react-lite";
import Flexbox from "../layout/flexbox";
import styled, { css } from "../designSystem";
import { media } from "../designSystem/responsive";
import { IndexedKeyValues, IndexedKeys } from "../App";

type TabGroupProps = {
  tabs: {
    label?: string;
    value: string;
    icon?: React.ReactNode;
    title?: string;
  }[];
  isActive: string;
  metric?: IndexedKeys;
  onSelectTab: (value: string) => void;
  grow?: boolean;
};

const StyledTab = styled.button<{
  metric: IndexedKeys;
  title: string;
  isActive: boolean;
  grow: boolean;
}>`
  height: 38px;
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.softBorder};
  cursor: pointer;
  outline: none;
  font-size: 12px;

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0;
  }
  ${(media as any).s`
    font-size: 11px;
    padding: 0 8px;
  `};

  ${({ grow }) =>
    grow &&
    css`
      flex: 1 1 auto;
    `}

  ${({ isActive, metric, theme }) =>
    isActive
      ? css`
          background: ${theme.metrics[metric]};
          color: ${theme.textReverse};
          path {
            stroke: ${theme.textReverse};
          }
        `
      : css`
          background: ${theme.background};
          color: ${theme.textPrimary};
          path {
            stroke: ${theme.textPrimary};
          }
        `}
`;

const TabGroup: React.FC<TabGroupProps> = ({
  tabs,
  isActive,
  onSelectTab,
  grow = false,
  metric,
}) => {
  const activeIsMetric = IndexedKeyValues.includes(isActive as IndexedKeys);
  const derived = activeIsMetric ? isActive : "Confirmed";
  return (
    <Flexbox noGrow={!grow}>
      {tabs.map((item) => {
        const handleClick = () => {
          if (onSelectTab) {
            onSelectTab(item.value);
          }
        };
        return (
          <StyledTab
            title={item.title || item.label}
            grow={grow}
            key={item.value}
            metric={metric ? metric : (derived as IndexedKeys)}
            isActive={isActive === item.value}
            onClick={handleClick}
          >
            {item.label && <span>{item.label}</span>}
            {item.icon && item.icon}
          </StyledTab>
        );
      })}
    </Flexbox>
  );
};

TabGroup.displayName = "TabGroup";
export default observer(TabGroup);
