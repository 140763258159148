import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled, { ThemeProvider } from "./designSystem";
import theme from "./designSystem/theme";
import { FullWidthSection } from "./layout/sections";
import axios from "axios";
import DataSet, { GroupedData } from "./utils/data/DataSet";
import Direction from "./utils/data/Direction";
import SiteHeader from "./components/siteHeader";
import SiteFooter from "./components/siteFooter";
import ScrollToTop from "./components/scrollToTop";
import useResizeObserver from "use-resize-observer";
import { media } from "./designSystem/responsive";
import { GlobalStyles } from "./designSystem/utils";
import { SimpleCSV } from "./data/csv";
import { observer } from "mobx-react-lite";
import ErrorPage from "./components/ErrorPage";
import useBreakpoint from "./hooks/useBreakpoint";
import { animations, HeroLoaded, HeroLoading } from "./designSystem/animations";
import InfoPage from "./pages/infoPage";
import "./app.css";

export type IndexedKeys =
  | "Confirmed"
  | "Deaths"
  | "Cumulative Deaths"
  | "Cumulative Confirmed";

export const IndexedKeyValues: IndexedKeys[] = [
  "Confirmed",
  "Deaths",
  "Cumulative Deaths",
  "Cumulative Confirmed",
];
export type IndexedTotals = {
  [key in IndexedKeys]: number;
};

export type DataSets = {
  source: DataSet;
  byDay: DataSet;
  byDayCumulative: DataSet;
  dayGroups: GroupedData[];
  byCountry: DataSet;
  countryGroups: GroupedData[];
  topCountryGroups: GroupedData[];
  byRegion: DataSet;
  regionGroups: GroupedData[];
  countriesDailyChange: { [country: string]: IndexedKeys };
  today: IndexedTotals;
  yesterday: IndexedTotals;
  totals: IndexedTotals;
  csv: any[][];
  startDate: Date;
  endDate: Date;
  lastUpdate: Date;
};

const StyledFullWidthSection = styled(FullWidthSection)`
  position: relative;
  padding-top: ${({ theme }) => theme.magicNumbers.navHeight};

  ${(media as any).m`
    padding-top: ${({ theme }) => {
      return theme.magicNumbers.mobileNavHeight;
    }};
  `}
  ${animations}
`;

const WorldTrends = React.lazy(() => import("./pages/worldTrends"));
const CountryDetails = React.lazy(() => import("./pages/countryDetails"));
const Explore = React.lazy(() => import("./pages/explore"));

export const SiteDimsContext = React.createContext({ width: 0, height: 0 });
export type SerbiaKosovoTotals = {
  serbiaTotal: number;
  serbia: number;
  kosovo: number;
};

function App() {
  const [dataSets, setDataSets] = useState(undefined as DataSets);
  const [createdTime, setCreatedTime] = useState<number>();
  const { ref, width = 0, height = 0 } = useResizeObserver();
  const point = useBreakpoint();

  useEffect(() => {
    axios
      .get(
        // "https://dashboards-dev.sprinklr.com/data/9043/global-covid19-who-alpha2.json"
        "https://dashboards-dev.sprinklr.com/data/9043/global-covid19-who-gis.json"
      )
      // .get("https://dashboards-dev.sprinklr.com/data/8971/global-covid19.json")
      .then((response) => {
        const raw: any = response.data;
        const lastUpdateTime: any = raw?.lastUpdateTime;
        setCreatedTime(lastUpdateTime);
        const lastUpdate: Date = new Date(lastUpdateTime);
        const metrics: any[] = raw.metrics;
        const dimensions: any[] = raw.dimensions;
        const totals = metrics.map(() => {
          return 0;
        });
        raw.rows.forEach((row) => {
          metrics.forEach((metric, index) => {
            let value = row[dimensions.length + index];
            value = value === null || value === undefined ? 0 : value;
            totals[index] += value;
          });
        });

        let source = DataSet.create({ ...raw, totals });
        const csvData = source.reorderMetrics([
          source.metrics[2],
          source.metrics[3],
          source.metrics[0],
          source.metrics[1],
        ]);
        const csvHeaders = [
          "Date_reported",
          "Country_code",
          "Country",
          "WHO_region",
          "New_cases",
          "Cumulative_cases",
          "New_deaths",
          "Cumulative_deaths",
        ];
        const csv = SimpleCSV.fromDataSet(
          csvData,
          true,
          null,
          csvHeaders
        ).to2dArray();
        // console.log("source", source);

        const dayDimension = source.getDimension("day");
        const dayDimensionIndex = source.getDimensionIndex("day");
        const countryDim = source.getDimension("Country");
        const regionDim = source.getDimension("Region");

        let byDay = source
          .groupByAggregate(dayDimension)
          .sortByDimensionDirection(dayDimension, Direction.ASC);
        // console.log("byDay", byDay);

        const startDate = byDay.rows[0][0];
        const endDate = byDay.rows[byDay.rows.length - 1][0];
        // console.log("startDate", startDate);
        // console.log("endDate", endDate);

        // remove last day so that there's no 0 at the end
        byDay = new DataSet(
          byDay.dimensions,
          byDay.metrics,
          byDay.rows.slice(0, -1),
          byDay.totals,
          false
        );

        // filter out all rows in source 0 new cases and deaths on the last day
        const confirmedIndex = source.getMetricFieldIndex("Confirmed");
        const deathsIndex = source.getMetricFieldIndex("Deaths");
        source = source.filter((row) => {
          return !(
            row[dayDimensionIndex].valueOf() === endDate.valueOf() &&
            row[confirmedIndex] === 0 &&
            row[deathsIndex] === 0
          );
        });

        const lastDay = byDay.rows[byDay.rows.length - 1];
        const today: IndexedTotals = {
          Confirmed: lastDay[byDay.getMetricFieldIndex("Confirmed")],
          "Cumulative Confirmed":
            lastDay[byDay.getMetricFieldIndex("Cumulative Confirmed")],
          Deaths: lastDay[byDay.getMetricFieldIndex("Deaths")],
          "Cumulative Deaths":
            lastDay[byDay.getMetricFieldIndex("Cumulative Deaths")],
        };
        // console.log("today", today);

        const secondTolastDay = byDay.rows[byDay.rows.length - 2];
        const yesterday: IndexedTotals = {
          Confirmed: secondTolastDay[byDay.getMetricFieldIndex("Confirmed")],
          "Cumulative Confirmed":
            secondTolastDay[byDay.getMetricFieldIndex("Cumulative Confirmed")],
          Deaths: secondTolastDay[byDay.getMetricFieldIndex("Deaths")],
          "Cumulative Deaths":
            secondTolastDay[byDay.getMetricFieldIndex("Cumulative Deaths")],
        };

        const byDayCumulative = byDay.cumulative();
        // console.log("byDayCumulative", byDayCumulative);

        const confirmedMetricIndex = source.getMetricIndex("Confirmed");
        // console.log("confirmedMetricIndex", confirmedMetricIndex);
        const confirmedMetric = source.metrics[confirmedMetricIndex];

        const countriesLastDay = source
          .collapseDimension(regionDim)
          .removeDimension(regionDim)
          .periodicPercentChange()
          .pluck(dayDimension, endDate);

        const countriesLastDateConfirmedIndex = countriesLastDay.getMetricFieldIndex(
          "Confirmed"
        );
        const countriesLastDateDeathsIndex = countriesLastDay.getMetricFieldIndex(
          "Deaths"
        );
        const countriesDailyChange: any = countriesLastDay
          .filter((row) => {
            return (
              row[countriesLastDateConfirmedIndex] > 0 ||
              row[countriesLastDateDeathsIndex] > 0
            );
          })
          .toMap();

        // console.log("countriesDailyChange", countriesDailyChange);

        const byCountry = source
          .groupByAggregate(countryDim)
          .sortByMetricDirection(confirmedMetric, Direction.DESC);
        // console.log("byCountry", byCountry);

        const countryGroups = source.groupBy(countryDim);
        countryGroups.sort(
          (a, b) =>
            b.data.totals[confirmedMetricIndex] -
            a.data.totals[confirmedMetricIndex]
        );
        countryGroups.forEach((group) => {
          group.data = group.data.sortByDimensionDirection(
            group.data.getFirstDimension(),
            Direction.ASC
          );
        });
        const topCountryGroups = countryGroups.slice();
        topCountryGroups.length = 30;
        // console.log("topCountryGroups", topCountryGroups);

        const dayGroups = source
          .sortByDimensionDirection(dayDimension, Direction.ASC)
          .groupBy(dayDimension);
        // console.log("dayGroups", dayGroups);

        const noCountries = source
          .collapseDimension(countryDim)
          .removeDimension(countryDim)
          .zeroFillDimensions();

        const byRegion = noCountries
          .groupByAggregate(regionDim)
          .sortByMetricDirection(confirmedMetric, Direction.DESC);
        // console.log("byRegion", byRegion);

        const regionGroups = noCountries.groupBy(regionDim);
        regionGroups.sort(
          (a, b) =>
            b.data.totals[confirmedMetricIndex] -
            a.data.totals[confirmedMetricIndex]
        );
        // console.log("regionGroups", regionGroups);

        setDataSets({
          source,
          byDay,
          byDayCumulative,
          dayGroups,
          countriesDailyChange,
          byCountry,
          countryGroups,
          topCountryGroups,
          byRegion,
          regionGroups,
          today,
          yesterday,
          startDate,
          endDate,
          lastUpdate,
          csv,
          totals: source.indexedTotals() as IndexedTotals,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const footer = point !== "s" && point !== "m" && <SiteFooter />;

  // const countryCodesMap = {};
  // (geoData as any).objects.countries.geometries.forEach(
  //   (country) =>
  //     (countryCodesMap[country.properties.ISO_2_CODE] =
  //       country.properties.ISO_2_CODE)
  // );
  //
  // const countryCodes = Object.keys(countryCodesMap).sort().slice(225, 250);

  function getSerbiaKosovoTotals(): SerbiaKosovoTotals {
    const confirmedIndex = dataSets?.byCountry.getMetricFieldIndex("Confirmed");
    const serbia =
      dataSets?.byCountry.rows.find((row) => row[0] === "RS")[confirmedIndex] ??
      undefined;
    const kosovo =
      dataSets?.byCountry.rows.find((row) => row[0] === "XK")[confirmedIndex] ??
      undefined;
    return {
      serbiaTotal: serbia && kosovo ? serbia + kosovo : undefined,
      serbia,
      kosovo,
    };
  }

  return (
    <Router>
      <Switch>
        {/*<Route path={["/country-maps"]} exact>*/}
        {/*  {countryCodes.map((countryCode) => {*/}
        {/*    return (*/}
        {/*      <CountryCard*/}
        {/*        key={countryCode}*/}
        {/*        countryCodeIsoAlpha2={countryCode}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</Route>*/}
        <Route>
          <ThemeProvider theme={theme}>
            <SiteDimsContext.Provider value={{ width, height }}>
              <ScrollToTop />
              <StyledFullWidthSection h100 vertical center ref={ref}>
                <SiteHeader
                  createdTime={createdTime}
                  serbiaKosovo={getSerbiaKosovoTotals()}
                />
                <Switch>
                  <Route path={["/", "/trends", "/overview"]} exact>
                    <>
                      {!dataSets && <HeroLoading />}
                      {dataSets && (
                        <Suspense fallback={<HeroLoaded />}>
                          <WorldTrends dataSets={dataSets} />
                          {footer}
                        </Suspense>
                      )}
                    </>
                  </Route>
                  <Route path="/region/:region/country/:country" exact>
                    {dataSets && (
                      <Suspense fallback={""}>
                        <CountryDetails dataSets={dataSets} />
                        {footer}
                      </Suspense>
                    )}
                  </Route>
                  <Route path="/diamondPrincess" exact>
                    {dataSets && (
                      <Suspense fallback={""}>
                        <CountryDetails
                          dataSets={dataSets}
                          hideMap={true}
                          countryCode="DP"
                        />
                        {footer}
                      </Suspense>
                    )}
                  </Route>
                  <Route path="/info" exact>
                    {dataSets && <InfoPage csv={dataSets.csv} />}
                  </Route>
                  <Route path="/explorer" exact>
                    {dataSets && (
                      <Suspense fallback={""}>
                        <Explore dataSets={dataSets} />
                        {footer}
                      </Suspense>
                    )}
                  </Route>
                  <Route>
                    <ErrorPage />
                    {footer}
                  </Route>
                </Switch>
              </StyledFullWidthSection>
              <GlobalStyles />
            </SiteDimsContext.Provider>
          </ThemeProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default observer(App);
