import React, { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import Dropdown from "./dropdown";
import {
  countryOptionsList,
  matchSovrnToName,
  findRegionByCountryIso2,
  countryFlagWhiteList,
  canShowFlag,
  sortedCountries,
  countrySearchFilter,
} from "../utils/countryCode";
import Flexbox from "../layout/flexbox";
import theme from "../designSystem/theme";
import { observer } from "mobx-react-lite";
import styled, { css } from "../designSystem";
import { media } from "../designSystem/responsive";
import { CountryFlag } from "./breadcrumb";

type CountryNavDropdownProps = {
  isSmall: boolean;
  isHeaderCollapsed: boolean;
  useHambuger: boolean;
  mobileSearchOpen: boolean;
};

function countryPath(countryCode: string) {
  if (countryCode?.toUpperCase() === "DP") {
    return `/diamondPrincess`; // special handling for the fake country code "DP"
  } else {
    return `/region/${findRegionByCountryIso2(
      countryCode
    )?.toLowerCase()}/country/${countryCode?.toLowerCase()}`;
  }
}

type OptionType = {
  [key: string]: any;
};

const formatOptionWithIcon = (option: OptionType) => {
  const showFlag = canShowFlag(option?.value);

  return (
    <Flexbox gap="m" middle pad="s">
      {showFlag && <CountryFlag code={option?.value} />}
      <span style={{ paddingLeft: !showFlag ? "40px" : "0" }}>
        {option.label || option.name}
      </span>
    </Flexbox>
  );
};

const StyledCountryNavWrapper = styled(Flexbox)<{ mobileSearchOpen?: boolean }>`
  width: 100%;
  opacity: 1;
  transform: translateX(0);

  ${(media as any).m`
    opacity: ${({ mobileSearchOpen }) => (mobileSearchOpen ? "1" : "0")};
    will-change: opacity, transform;
    transform: ${({ mobileSearchOpen }) =>
      mobileSearchOpen ? "translate3d(0, 0, 0)" : "translate3d(24px, 0, 0)"};
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  `}
`;

const CountryNavDropdown: React.FC<CountryNavDropdownProps> = ({
  isSmall,
  isHeaderCollapsed,
  useHambuger,
  mobileSearchOpen,
}) => {
  const history = useHistory();
  const ref = useRef(0);
  const [selected, setSelected] = React.useState("");
  const searchHeight = useHambuger
    ? "36px"
    : isHeaderCollapsed
    ? "48px"
    : "58px";

  const searchFontSize = useHambuger ? "10px" : "16px";
  const sharedStyles = {
    width: "100%",
    height: searchHeight,
    minHeight: "initial",
    border: "none",
    borderRadius: "4px",
    background: theme.chartBackground,
    whiteSpace: "nowrap",
    fontSize: searchFontSize,
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      ...sharedStyles,
    }),
    control: (provided) => ({
      ...provided,
      ...sharedStyles,
    }),
  };

  const handleOnChange = useCallback(
    (selected) => {
      setSelected(selected.label);
      ref.current++;
      history.push({
        pathname: countryPath(selected?.value),
      });
    },
    [setSelected]
  );

  return (
    <StyledCountryNavWrapper
      style={{ width: "100%" }}
      mobileSearchOpen={mobileSearchOpen}
    >
      <Dropdown
        key={ref.current}
        selected={selected}
        components={{
          DropdownIndicator: () => null,
        }}
        formatOptionLabel={formatOptionWithIcon}
        placeholder={"Search by Country, Territory, or Area"}
        options={sortedCountries}
        name="countryDropDown"
        aria-label="countryDropDown"
        styles={customStyles}
        openMenuOnClick={false}
        isSearchable
        onChange={handleOnChange}
        filterOption={countrySearchFilter}
      />
    </StyledCountryNavWrapper>
  );
};

export default observer(CountryNavDropdown);
