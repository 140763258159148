import React, { useCallback } from "react";
import ContainedSection, { Layout } from "../layout/sections";
import Flexbox from "../layout/flexbox";
import appTheme from "../designSystem/theme";
import Attribution from "../components/attribution";
import styled from "../designSystem";
import { media } from "../designSystem/responsive";
import { HeaderLockup } from "./header";
import TrendCard, { StyledTrendCard } from "./trendCard";
import Direction from "../utils/data/Direction";
import { getRegionName } from "../utils/regions";
import { GroupedData } from "../utils/data/DataSet";
import { observer } from "mobx-react-lite";
import useBreakpoint from "../hooks/useBreakpoint";

type RegionSmallMultiplesProps = {
  regionGroups?: GroupedData[];
  title: string;
  subTitle: string;
  startDate?: Date;
  endDate?: Date;
};

const StyledLayout = styled(Layout)`
  /* margin-top: 55px; */
  flex-wrap: wrap;

  > div {
    flex: 0 0 48%;
    height: 200px;
  }
  > div:nth-child(-n + 4) {
    margin-bottom: 85px;
  }

  ${(media as any).l`
    > div:nth-child(-n+4) {
      margin-bottom: 65px;
    }
    `}
  ${(media as any).m`
    // margin-top: 0px;
    > div:nth-child(-n+5) {
      margin-bottom: 30px;
    }
    flex-direction: column;
    flex-wrap: no-wrap;
    > div {
      border-radius: 3px;
      flex: 1 1 auto;
    }
  `}
`;

const RegionSmallMultiples: React.FC<RegionSmallMultiplesProps> = ({
  regionGroups,
  title,
  subTitle,
  startDate,
  endDate,
}) => {
  const getTrendData = useCallback(
    (group) =>
      group.data
        .groupByAggregate(group.data.dimensions[0])
        .sortByDimensionDirection(group.data.dimensions[0], Direction.ASC),
    []
  );

  const breakpoint = useBreakpoint();
  const useHamburger = breakpoint === "s" || breakpoint === "m";

  // disable shared scale for now
  // const regionDailyMax = useMemo(
  //   () =>
  //     Math.max(
  //       ...regionGroups.map((value) => {
  //         const daily = value.data.groupByAggregate(value.data.dimensions[0]);
  //         return daily.max(
  //           value.data.metrics.find((value1) => value1.name === "Confirmed")
  //         );
  //       })
  //     ),
  //   [regionGroups]
  // );

  return (
    <ContainedSection vertical gap={useHamburger ? "l" : "xxxl"}>
      <HeaderLockup title={title} subTitle={subTitle} />
      <StyledLayout between>
        {regionGroups.map((group, index) => {
          return (
            <StyledTrendCard key={group.value}>
              <TrendCard
                startDate={startDate}
                endDate={endDate}
                data={getTrendData(group)}
                label={getRegionName(group.value)}
                metric="Confirmed Cases"
                region={getRegionName(group.value)}
              />
            </StyledTrendCard>
          );
        })}
      </StyledLayout>
      <Flexbox center>
        <Attribution source="World Health Organization" />
      </Flexbox>
    </ContainedSection>
  );
};

export default observer(RegionSmallMultiples);
