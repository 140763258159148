import React, { useState, useCallback, useMemo } from "react";
import ContainedSection from "../layout/sections";
import Flexbox from "../layout/flexbox";
import Attribution from "../components/attribution";
import { HeaderLockup } from "./header";
import DataSet, { GroupedData } from "../utils/data/DataSet";
import SortableDropdown, { Sort } from "../components/sortableDropdown";
import TabGroup from "../components/tabGroup";
import { ReactComponent as Cumulative } from "../assets/images/cumulative.svg";
import { ReactComponent as Daily } from "../assets/images/daily.svg";
import CountryCardList from "../components/countryCardList";
import { observer } from "mobx-react-lite";
import styled from "../designSystem";
import { media } from "../designSystem/responsive";
import { countryPopulation } from "../data/countryStats";
import moment from "moment";
import { IndexedKeys } from "../App";

type RegionCardsProps = {
  countryGroups?: GroupedData[];
  countryGroupsCumulative?: GroupedData[];
  source: DataSet;
  title: string;
  subTitle?: string;
};

// Per Country

//   Totals (for sorting / displayed in card)

//     All Daily Cases
//     All Daily Deaths

//     Per Million Daily Cases
//     Per Million Daily Deaths

//     Just 14 Day Daily Cases
//     Just 14 Day Daily Deaths

//     Just 24 Hour Daily Cases
//     Just 24 Hour Daily Deaths

//   Per-day (spark-line)

//     Cumulative Cases
//     Cumulative Deaths

//     Daily Cases
//     Daily Deaths

//     Per Million Cumulative Cases
//     Per Million Cumulative Deaths

//     Per Million Daily Cases
//     Per Million Daily Deaths

type TimeRangeFilterValues =
  | "Total"
  | "Per Million"
  | "Last 7 Days"
  | "Last 24 hours";

export type TimeRangeFilterOption = {
  value: TimeRangeFilterValues;
  label: string;
};

const timeRangeFilterOptions: TimeRangeFilterOption[] = [
  {
    value: "Total",
    label: "Total",
  },
  {
    value: "Per Million",
    label: "Total per 1 million population",
  },
  {
    value: "Last 7 Days",
    label: "Last 7 days",
  },
  {
    value: "Last 24 hours",
    label: "Last 24 hours",
  },
];

export type Aggregations = "Cumulative" | "Daily";

const aggregationOptions = [
  {
    value: "Daily",
    prefix: "",
    title: "Daily",
    icon: <Daily />,
  },
  {
    value: "Cumulative",
    prefix: "Cumulative ",
    title: "Cumulative",
    icon: <Cumulative />,
  },
];

const metricOptions = [
  {
    value: "Confirmed",
    label: "Cases",
  },
  {
    value: "Deaths",
    label: "Deaths",
  },
];

const StyledDropdownWrapper = styled(Flexbox)`
  width: 225px;
  ${(media as any).l`
    width: 122px;
    font-size: 12px;
  `}
  ${(media as any).m`
    width: 100%;
    margin-left: 0 !important;
    margin-top: 12px;
  `}
`;
const StyledHeader = styled(Flexbox)`
  ${(media as any).l`
    flex-direction: column;
    align-items: flex-start;
    > * + * {
        margin-left: 0;
        margin-top: 16px;
      }
  `}
`;

const StyledControls = styled(Flexbox)`
  ${(media as any).l`

    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

const RegionCards: React.FC<RegionCardsProps> = ({
  countryGroups,
  title,
  source,
  subTitle,
}) => {
  const [sortDirection, setSortDirection] = useState<Sort>("DESC");
  const [selectedAggregation, setSelectedAggregation] = useState<string>(
    aggregationOptions[0].value
  );
  const [selectedMetric, setSelectedMetric] = useState<string>(
    metricOptions[0].value
  );
  const [timeRangeFilter, setTimeRangeFilter] = useState<TimeRangeFilterOption>(
    timeRangeFilterOptions[0]
  );
  const [filteredCountryGroups, setFilteredCountryGroups] = useState<
    GroupedData[]
  >(countryGroups);

  React.useEffect(() => {
    const metricFieldIndex = countryGroups[0].data.getMetricFieldIndex(
      selectedMetric
    );
    const metricIndex = countryGroups[0].data.getMetricIndex(selectedMetric);
    const metric = countryGroups[0].data.metrics[metricIndex];

    const perMillion = timeRangeFilter.value === "Per Million";
    const last24Hours = timeRangeFilter.value === "Last 24 hours";
    const last7Days = timeRangeFilter.value === "Last 7 Days";

    const result = countryGroups.slice();

    result.sort((a, b) => {
      let aVal: number;
      let bVal: number;

      if (last24Hours) {
        aVal = a.data.rows[a.data.rows.length - 1][metricFieldIndex];
        bVal = b.data.rows[b.data.rows.length - 1][metricFieldIndex];
      } else if (last7Days) {
        aVal = a.data
          .derivedDataset((rows) => rows.slice(rows.length - 7, rows.length))
          .sum(metric);
        bVal = b.data
          .derivedDataset((rows) => rows.slice(rows.length - 7, rows.length))
          .sum(metric);
      } else if (perMillion) {
        const aPopulationMillions =
          (countryPopulation(a.value) || 10000000) / 1000;
        const bPopulationMillions =
          (countryPopulation(b.value) || 10000000) / 1000;
        aVal = a.data.totals[metricIndex] / aPopulationMillions;
        bVal = b.data.totals[metricIndex] / bPopulationMillions;
      } else {
        aVal = a.data.totals[metricIndex];
        bVal = b.data.totals[metricIndex];
      }

      if (sortDirection === "DESC") {
        return bVal - aVal;
      } else {
        return aVal - bVal;
      }
    });
    setFilteredCountryGroups(result);
  }, [countryGroups, selectedMetric, timeRangeFilter.value, sortDirection]);

  return (
    <ContainedSection vertical gap="xxl">
      <StyledHeader gap="xxl" middle between>
        <HeaderLockup title={title} subTitle={subTitle} />
        <StyledControls right gap noGrow>
          <TabGroup
            onSelectTab={(value) => setSelectedAggregation(value)}
            isActive={selectedAggregation}
            metric={selectedMetric as IndexedKeys}
            tabs={aggregationOptions}
          />
          <TabGroup
            onSelectTab={(value) => setSelectedMetric(value)}
            isActive={selectedMetric}
            tabs={metricOptions}
          />
          <StyledDropdownWrapper>
            <SortableDropdown
              sort={sortDirection}
              onSort={setSortDirection}
              selected={timeRangeFilter}
              options={timeRangeFilterOptions}
              onChange={(selected) => setTimeRangeFilter(selected)}
            />
          </StyledDropdownWrapper>
        </StyledControls>
      </StyledHeader>

      <CountryCardList
        last24Hours={timeRangeFilter.value === "Last 24 hours"}
        last7Days={timeRangeFilter.value === "Last 7 Days"}
        perMillion={timeRangeFilter.value === "Per Million"}
        cumulative={selectedAggregation === "Cumulative"}
        selectedMetric={selectedMetric}
        countryGroups={filteredCountryGroups.slice(0, 12)}
      />

      <Flexbox center>
        <Attribution source="World Health Organization" />
      </Flexbox>
    </ContainedSection>
  );
};

export default observer(RegionCards);
