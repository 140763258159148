import React from "react";
import moment from "moment";
import { ColumnItem } from "./ColumnChart/columnChart";
import { Region } from "../utils/regions";
import { observer } from "mobx-react-lite";
import TooltipInner, { TooltipRow } from "./tooltipInner";

type ColumnPopoverProps = {
  bar: ColumnItem;
  metrics?: string[];
  regions?: Region[];
  delta: number;
  size?: "s" | "m";
};

const ColumnPopover: React.FC<ColumnPopoverProps> = ({
  bar,
  regions,
  metrics,
  delta,
  size,
}) => {
  bar?.data?.length > 6 && bar.data.splice(6, bar.data.length);

  let tooltipRows: TooltipRow[] = [];
  if (bar.data) {
    tooltipRows = bar.data

      .map((segment, segmentIndex) => ({
        label: metrics[segmentIndex],
        metric: {
          name: metrics[segmentIndex],
          value: segment,
        },
      }))
      .filter((segment) => segment.metric.value); // do we actually want to filter 0's here?
  } else {
    tooltipRows = [
      {
        label: metrics[0] === "Confirmed" ? "Confirmed Cases" : metrics[0],
        metric: {
          name: regions[0] || metrics[0],
          value: bar.y,
        },
      },
    ];

    if (!!delta) {
      tooltipRows.push({
        label: delta >= 0 ? "Daily Increase" : "Daily Decrease",
        metric: {
          name: regions[0] || metrics[0],
          value: delta,
        },
      });
    }
  }

  return (
    <TooltipInner
      size={size}
      rows={tooltipRows}
      title={moment.utc(bar.x).format("MMMM D, YYYY")}
    />
  );
};

ColumnPopover.displayName = "ColumnPopover";
export default observer(ColumnPopover);
