import React from "react";
import { observer } from "mobx-react-lite";
import { ReactComponent as LogoCollapsed } from "../assets/images/who_logo_collapsed.svg";
import Flexbox from "../layout/flexbox";
import ContainedSection, { FullWidthSection } from "../layout/sections";
import styled from "../designSystem";
import { Link } from "react-router-dom";
import { ExternalLink } from "../designSystem/typography";

type SiteFooterProps = {};

const StyledWrapper = styled(FullWidthSection)`
  border-top: 1px solid ${({ theme }) => theme.softBorder};
`;

const StyledLogoCollapsed = styled(LogoCollapsed)`
  height: 50px;
  width: 50px;
`;

const StyledFooter = styled(ContainedSection)`
  height: 85px;
`;
const SiteFooter: React.FC<SiteFooterProps> = ({}) => {
  return (
    <StyledWrapper as="footer" center>
      <StyledFooter middle gap="l">
        <a target="_blank" href="https://www.who.int/">
          <StyledLogoCollapsed />
        </a>
        <ExternalLink
          target="_blank"
          href="https://extranet.who.int/publicemergency"
        >
          WHO Health Emergency Dashboard
        </ExternalLink>
        <ExternalLink
          target="_blank"
          href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019"
        >
          WHO (COVID-19) Homepage
        </ExternalLink>
      </StyledFooter>
    </StyledWrapper>
  );
};

SiteFooter.displayName = "SiteFooter";
export default observer(SiteFooter);
