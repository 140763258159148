import React, { useCallback } from "react";
import { LinePath, Line, Bar } from "@vx/shape";
import { localPoint } from "@vx/event";
import { scaleTime, scaleLinear } from "@vx/scale";
import { extent, max, bisector } from "d3-array";
import { curveLinear } from "@vx/curve";
import useResizeObserver from "use-resize-observer/polyfilled";
import Flexbox from "../../layout/flexbox";
import styled, { css } from "../../designSystem";
import appTheme from "../../designSystem/theme";
import moment from "moment";
import { observer } from "mobx-react-lite";

type HoverLineProps = {
  countryName: string;
  data: Array<{ date: Date; value: number }>;
  onScrub: (cases: number) => void;
  color: string;
};

const xSelector = (d) => d.date;
const ySelector = (d) => d.value;
const bisectDate = bisector((d) => (d as any).date).left;

const StyledScubberDate = styled(Flexbox)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 13px;
  color: ${appTheme.textSecondary};
`;

const HoverLine: React.FC<HoverLineProps> = ({ onScrub, data, color }) => {
  const dateFormat = "MMMM Do, YYYY";
  const { ref: chartRef, height = 0, width = 0 } = useResizeObserver();

  const xMax = width - 4;
  const yMax = height - 20;

  const [tooltipData, setTooltipData] = React.useState();
  const [tooltipLeft, setTooltipLeft] = React.useState();
  const [tooltipTop, setTooltipTop] = React.useState();

  const xScale = scaleTime({
    range: [0, xMax],
    domain: extent(data, xSelector),
  });

  const yScale = scaleLinear({
    range: [yMax - 3, 3],
    domain: [0, max(data, ySelector) * 3],
  });

  const hideTooltip = useCallback(() => {
    setTooltipData(undefined);
  }, []);

  const handleTooltip = useCallback(
    (event) => {
      const { x } = localPoint(event);
      const x0 = xScale.invert(x);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d0;
      if (d1 && d1.date) {
        d = x0 - xSelector(d0.date) > xSelector(d1.date) - x0 ? d1 : d0;
      }
      setTooltipData(d);
      setTooltipLeft(x);
      setTooltipTop(yScale(d.value));
      onScrub(d?.value);
    },
    [data, xScale, onScrub, yScale]
  );

  return (
    <Flexbox ref={chartRef}>
      <svg width={width} height={height}>
        <LinePath
          data={data}
          x={(d) => xScale(xSelector(d))}
          y={(d) => yScale(ySelector(d))}
          strokeWidth="3px"
          strokeLinecap="round"
          curve={curveLinear}
        />

        <Bar
          x={0}
          y={0}
          width={width}
          height={height}
          fill="transparent"
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onTouchEnd={hideTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={hideTooltip}
        />

        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: 0 }}
              to={{ x: tooltipLeft, y: yMax }}
              stroke={color}
              strokeWidth={2}
              style={{ pointerEvents: "none" }}
              strokeDasharray="2,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={8}
              fill={color}
              stroke="white"
              strokeWidth={4}
              style={{ pointerEvents: "none" }}
            />
          </g>
        )}
      </svg>
      {tooltipData && (
        <StyledScubberDate>
          {moment.utc(tooltipData?.date).format(dateFormat)}
        </StyledScubberDate>
      )}
    </Flexbox>
  );
};

export default observer(HoverLine);
