import React from "react";
import styled from "../designSystem";
import Flexbox from "../layout/flexbox";
import { isToday } from "date-fns";
import moment from "moment";
import { media } from "../designSystem/responsive";
import { Header, SubHeader, PageTitle } from "../designSystem/typography";

export const DateRange = styled.h3`
  font-size: 19px;
  font-weight: normal;
`;

export const dateFormat = "MMM D, YYYY";

export type DateRangeLockupProps = {
  dateRange?: {
    start: number | Date;
    end: number | Date;
  };
};

type PageTitleLockupProps = DateRangeLockupProps & {
  title: string;
};

export const DateRangeLockup: React.FC<DateRangeLockupProps> = ({
  dateRange: { start, end },
}) => {
  const startString = moment.utc(start).format(dateFormat);
  const endString = isToday(moment.utc(end).toDate())
    ? "Today"
    : moment.utc(end).format(dateFormat);
  const dateString = `${startString} - ${endString}`;
  return <DateRange>{dateString}</DateRange>;
};

export const PageTitleLockup: React.FC<PageTitleLockupProps> = ({
  title,
  dateRange,
}) => {
  return (
    <Flexbox vertical gap="s">
      <PageTitle>{title}</PageTitle>
      <DateRangeLockup dateRange={dateRange} />
    </Flexbox>
  );
};

const StyledHeaderLockup = styled(Flexbox)``;

export const HeaderLockup: React.FC<{ title?: string; subTitle?: string }> = ({
  title,
  subTitle,
}) => {
  return (
    <StyledHeaderLockup vertical gap="s">
      {title && <Header>{title}</Header>}
      {subTitle && <SubHeader>{subTitle}</SubHeader>}
    </StyledHeaderLockup>
  );
};

export { SubHeader };
export default Header;
