import styled from "../designSystem";
import { media } from "../designSystem/responsive";
import Flexbox from "./flexbox";
import { StyledMap } from "../components/leafletMap";
import theme from "../designSystem/theme";

const ContainedSection = styled(Flexbox)`
  width: 100%;
  padding: 20px;
  max-width: 80%;
  ${(media as any).xl`
    max-width: 95%;
  `}
  ${(media as any).l`
    max-width: 100%;
  `}
`;

export const FullWidthSection = styled(Flexbox)`
  align-items: center;
  width: 100%;
`;

export const Layout = styled(Flexbox)`
  min-height: 0;
  min-width: 0;
  width: 100%;
  height: 100%;
`;

export const stubStyles = {
  background: "#ddd",
  justifyContent: "center",
  alignItems: "center",
};

export default ContainedSection;
