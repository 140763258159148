import React from "react";
import Flexbox from "../layout/flexbox";
import getPrettyNumber, {
  getPrettyNumberString,
  DecimalFormat,
  getDecimalsByFormat,
} from "../utils/numberUtils";
import styled, { css } from "../designSystem";
import { observer } from "mobx-react-lite";

export type MetricSize = "s" | "sm" | "m" | "l";
type Align = "right" | "left";
type MetricProps = {
  value: number;
  format?: DecimalFormat;
  label?: string;
  active?: boolean;
  align?: Align;
  size?: MetricSize;
  isPercentage?: boolean;
};

const StyledLabel = styled.span<{ size: MetricSize }>`
  font-size: ${({ size }) => getSize(size, "label")}px;
  text-transform: lowercase;
`;

export const StyledMetricWrapper = styled(Flexbox)<{
  active: boolean;
  align: Align;
}>`
  color: ${({ theme, active }) =>
    active ? theme.chartPrimary : theme.textPrimary};

  [data-id="metric-label"] {
    ${({ align }) =>
      align === "right" &&
      css`
        text-align: right;
      `}
  }
`;

export function getSize(size: MetricSize, type: "label" | "metric"): number {
  const isMetric = type === "metric";

  switch (size) {
    case "s":
      return isMetric ? 18 : 10;
    case "sm":
      return isMetric ? 20 : 11;
    case "m":
      return isMetric ? 26 : 11;
    case "l":
      return isMetric ? 60 : 25;
  }
}
const StyledMetric = styled.span<{ size: MetricSize }>`
  font-size: ${({ size }) => getSize(size, "metric")}px;
  font-weight: bold;
`;

const Metric: React.FC<MetricProps> = ({
  value,
  format = "1,234",
  label,
  align = "left",
  active = false,
  size = "m",
  isPercentage = false,
}) => {
  let valueString;
  if (isPercentage) {
    const val = getPrettyNumber(
      value,
      getDecimalsByFormat(format, false),
      true
    );
    valueString = `${val.value}${val.suffix}`;
  } else {
    valueString = getPrettyNumberString(value, format, 2);
  }
  return (
    <StyledMetricWrapper
      data-id="metric-wrapper"
      vertical
      noGrow
      align={align}
      left={align === "left"}
      right={align === "right"}
      active={active}
    >
      <StyledMetric data-id="metric" size={size}>
        {valueString}
      </StyledMetric>
      {!!label && (
        <StyledLabel data-id="metric-label" size={size}>
          {label}
        </StyledLabel>
      )}
    </StyledMetricWrapper>
  );
};

Metric.displayName = "Metric";
export default observer(Metric);
